import React, { useState } from 'react';
import { Select, notification } from 'antd';
import { useSelector } from 'react-redux';
import { getResultExaminationForm , getFormContentToPrint } from 'utils/__function';
import { database } from 'configs/firebase';
import http from 'utils/http';
import html2canvas from 'html2canvas';
import getOriginImage from 'utils/getOriginImage';
import S3 from 'aws-s3';

import ExaminationFormTemplate from './ExaminationFormTemplate';

import styles from './index.module.scss';

const { Option } = Select;
const now = new Date();
const month = now.getMonth() + 1;

function FormResult({ booking }) {
  const [formContentPrint, setFormContentPrint] = useState('')
  const [formId, setFormId] = useState(null);
  const [optionForm, setOptionForm] = useState({});
  const [loading, setLoading] = useState(false);
  const employeeById = useSelector(state => state.employee.byId);

  const currentClinic = useSelector(state => state.workspace.currentClinic);
  let dirNameAWSS3 = null;
  let tryWaitCurrentClinic = setInterval(() => {
    if (currentClinic) {
      dirNameAWSS3 = `results/${currentClinic.id
        }/${now.getFullYear()}/${month}`;
      clearInterval(tryWaitCurrentClinic);
    }
  }, 200);

  const config = {
    bucketName: '2baby',
    dirName: dirNameAWSS3 /* optional */,
    region: 'ap-southeast-1',
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  };
  const S3Client = new S3(config);

  const examinationForms = useSelector(state =>
    state.examinationForm.ids
      .filter(id => state.examinationForm.byId[id])
      .map(id => state.examinationForm.byId[id])
      .filter(i => i.status)
  );

  const onHandleFinished = async (form, inputs, formContent, selectedImages, updateBookingStatus = true) => {
    setLoading(true)
    const result = getResultExaminationForm(form);
    let _formContentPrint = getFormContentToPrint(result, formContent, inputs, selectedImages)
    setFormContentPrint(_formContentPrint)

    const resultToUpload = getResultExaminationForm(form, true);
    const response = await handleExportImage()
    if (response && response.location && response.status === 204) {
      resultToUpload.images_url_conclude = [response.location]
      // updateResultImage(resultToUpload, response.location)
    }
    resultToUpload.booking_key = booking.key
    uploadResultToserver(resultToUpload, updateBookingStatus)
  }

  const handleExportImage = async () => {
    await timeout(500);
    try {
      const elementClone = document.querySelector('#content-preview');
      const canvas = await html2canvas(elementClone, {
        type: 'dataURL',
        allowTaint: false,
        useCORS: true
      });
      const imageUrl = canvas.toDataURL();

      const _image = await getOriginImage(imageUrl, `form-capture`);
      const response = await S3Client.uploadFile(_image);
      return response

    } catch (error) {
      console.error(error);
      return null
    }
  };

  const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const uploadResultToserver = (result, updateBookingStatus) => {
    http
      .put(`/bookings/${booking.id}/upload-result`, result)
      .then(response => {
        if (booking.key) {
          const bookingsRef = database.ref(
            `bookings/${currentClinic.id}/${booking.key}`
          );
          let data = {
            result: {
              ...result,
              uuid: response.data.uuid
            }
          }
          if (updateBookingStatus) {
            data.status = { id: 6, name: 'Khám xong' };
            data.priority = 99999;
            notification.success({ message: 'Hoàn thành ca khám', placement: 'topRight' });
          }
          bookingsRef.update(data);
        }
      }).catch((err) => {
        notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại.', placement: 'topRight' });
      }).finally(() => {
        setLoading(false)
      });
  }

  return (
    <div>
      <Select
        className="custom-select"
        value={formId}
        onChange={((formId) => {
          setFormId(formId)
          const examinationForm = examinationForms.find(ef => ef.id === formId)
          setOptionForm(examinationForm)
        })}
        placeholder="Chọn mẫu kết quả cần nhập"
      >
        {examinationForms.map(option => (
          <Option key={option.id} value={option.id}>
            {option.title}
          </Option>
        ))}
      </Select>
      {
        formId ?
          <ExaminationFormTemplate
            optionForm={optionForm}
            booking={{
              ...booking,
              clinic_name: currentClinic.name,
              doctor: employeeById[booking.doctor_id] || {}
            }}
            result={booking.result || {}}
            currentClinic={currentClinic}
            S3Client={S3Client}
            loading={loading}
            onHandleFinished={onHandleFinished}
          ></ExaminationFormTemplate>
          : <></>
      }
      <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
        <div className={styles.wrapper}>
          <div className="content-preview" id="content-preview">
            <div dangerouslySetInnerHTML={{ __html: formContentPrint }} id="form-inputs" className="content-form is-preview"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormResult;
