import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authorizationService from 'services/authorization';

export const authorize = createAsyncThunk('authorization/authorize', () =>
  authorizationService.authorize()
);

const authorization = createSlice({
  name: 'authorization',
  initialState: {
    user: {},
    tokenJwt: localStorage.getItem('token') || '',
    loading: false,
    error: null
  },
  reducers: {
    setTokenJwt: (state, action) => {
      state.tokenJwt = action.payload;
    }
  },
  extraReducers: {
    [authorize.pending]: state => {
      state.loading = true;
    },
    [authorize.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
    },
    [authorize.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { setTokenJwt } = authorization.actions;

export default authorization.reducer;
