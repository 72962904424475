import React, { useMemo, useEffect, useState } from 'react';
import { Button, Modal, DatePicker } from 'antd';

import { database } from 'configs/firebase';
import { ListBooking } from 'components';
import { useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import serviceBooking from 'services/booking';
import classnames from 'classnames';

import moment from 'moment';

import AddBooking from './AddBooking';
import EditBooking from './EditBooking';

import http from 'utils/http';
import { filZeroNumber, transformObjectToArray } from 'utils/__function';
import { defaultDate, dateFormat, defaultDateYMD } from 'utils/__variable';

import styles from './index.module.scss';

export default ({ history, location }) => {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const params = parse(location.search);
  const { date } = params;
  const isToday = !date || date === defaultDate;

  const [booking, setBooking] = useState({});
  const [flagCd, setFlagCd] = useState(0);
  const [flgaGS, setFlgaGS] = useState(false);

  const [bookings, setBookings] = useState();
  const [otherDayBookings, setOtherDayBookings] = useState();
  const [doctorsByDate, setDoctorsByDate] = useState({});

  const [visibleModal, setVisibleModal] = useState(false);

  const [loadingGetBookings, setLoadingGetBookings] = useState(false);

  const bookingsRef = useMemo(
    () => database.ref(`bookings/${currentClinic.id}`),
    [currentClinic.id]
  );

  useEffect(() => {
    if (bookingsRef) {
      setLoadingGetBookings(true);
      bookingsRef
        .orderByChild('date_day')
        .equalTo(defaultDate)
        .on('value', snapshot => {
          setBookings(snapshot.val());
          setLoadingGetBookings(false);
        });
    }
  }, [bookingsRef]);

  useEffect(() => {
    if (date && date !== defaultDate) {
      setLoadingGetBookings(true);
      serviceBooking
        .getBookings({
          year: date.split('-')[2],
          month: date.split('-')[1],
          day: date.split('-')[0]
        })
        .then(response => {
          setOtherDayBookings(response.data);
        })
        .catch(error => console.error(error))
        .then(() => setLoadingGetBookings(false));
    } else {
      setOtherDayBookings(null);
    }
  }, [date, currentClinic.id, flagCd]);

  const dataSource = useMemo(() => {
    let result = [];
    if (otherDayBookings) {
      result = otherDayBookings.map(o => ({ ...o, key: o.id }));
    } else if (bookings) {
      result = Object.entries(bookings).reduce((r, v) => {
        const _v = { ...v[1] };
        if (_v.result && _v.result.images_url) {
          _v.result.images_url = transformObjectToArray(_v.result.images_url);
        }
        return r.concat({ key: v[0], ...v[1] });
      }, []);
    }
    // console.log(result);
    return result;
  }, [bookings, otherDayBookings]);

  const handleSetFlagCd = () => {
    setFlagCd(new Date().getTime());
  };

  const onQueryParamsChange = ps => {
    history.push({
      ...location,
      search: stringify({ ...params, ...ps }, { arrayFormat: 'comma' })
    });
  };

  const handleClickToday = () => {
    onQueryParamsChange({ date: defaultDate });
  };

  const getSchedulees = async () => {
    if (!flgaGS) {
      setFlgaGS(true)
      const response = await http.get('/schedules', {
        params: {
          per_page: 9999,
          from_date: defaultDateYMD
        }
      });
      const schedules = response.data;
      let _doctorsByDate = {}
      for (let schedule of schedules) {
        if (!schedule.user) continue;
        if (!_doctorsByDate[schedule.date]) _doctorsByDate[schedule.date] = [];
        _doctorsByDate[schedule.date].push({...schedule.user, shift_id: schedule.shift})
      }
      for (const date in _doctorsByDate) {
        const key = 'id';
        const items = _doctorsByDate[date];
        _doctorsByDate[date] = [...new Map(items.map(item =>
          [item[key], item])).values()];
      }
      setDoctorsByDate(_doctorsByDate);
    }
  }

  useEffect(() => {
    getSchedulees();
  }, [getSchedulees]);

  const titleDays = useMemo(() => {
    const currentDay = moment(isToday ? defaultDate : date, dateFormat);

    const now = currentDay._d;
    const startDay = 1; // 0 = sunday, 1 = monday etc.
    const d = now.getDay(); // get the current day
    const weekStart = new Date(
      now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000
    ); //rewind to start day
    const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000); //add 6 days to get

    let _titleDays = [];
    const startDayTime = new Date(weekStart).getTime();
    const endDayTime = new Date(weekEnd).getTime();
    const arrayDays = [
      'CN',
      'Thứ 2',
      'Thứ 3',
      'Thứ 4',
      'Thứ 5',
      'Thứ 6',
      'Thứ 7'
    ];
    for (let i = startDayTime; i <= endDayTime;) {
      const _now = new Date(i);
      const objectPush = {
        day: arrayDays[_now.getDay()],
        date: filZeroNumber(_now.getDate()),
        month: filZeroNumber(_now.getMonth() + 1)
      };

      objectPush.value = `${_now.getFullYear()}-${objectPush.month}-${objectPush.date
        }`;

      objectPush.label = `${objectPush.date}-${objectPush.month
        }-${_now.getFullYear()}`;

      _titleDays.push(objectPush);

      i += 86400 * 1000;
    }
    return _titleDays;
  }, [isToday, date]);

  const isValidDate = useMemo(() => {
    if (!params.date) return true;
    if (
      moment(params.date, 'DD-MM-YYYY').startOf('day') >=
      moment().startOf('day')
    ) {
      return true;
    }
    return false;
  }, [params.date]);
  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between mb-2">
        <div className="left">
          <Button className="mr-2" onClick={handleClickToday}>
            Hôm nay
          </Button>
          <DatePicker
            inputReadOnly
            format={dateFormat}
            allowClear={false}
            showToday={false}
            value={moment(isToday ? defaultDate : date, dateFormat)}
            onChange={(value, valueString) => {
              onQueryParamsChange({ date: valueString });
            }}
          />
          <div className="day-of-weeks ml-2">
            {titleDays.map(titleDay => (
              <div
                key={titleDay.value}
                className={classnames('day-of-week', {
                  'is-selected':
                    (!date &&
                      String(titleDay.label).replaceAll(' ', '') ===
                      String(defaultDate).replaceAll(' ', '')) ||
                    (date && date === titleDay.label)
                })}
                onClick={() => onQueryParamsChange({ date: titleDay.label })}
              >
                <b>{titleDay.day}</b>
                <small className="ml-1">
                  {titleDay.date}/{titleDay.month}
                </small>
              </div>
            ))}
          </div>
        </div>
        {isValidDate && (
          <div>
            <Button
              type="success"
              onClick={() => {
                setVisibleModal(true);
                setBooking({});
              }}
            >
              Thêm lịch hẹn
            </Button>
          </div>
        )}
      </div>
      <ListBooking
        defaultDate={defaultDate}
        date={date}
        dataSource={dataSource.filter(
          r => r.date_day === (date || defaultDate)
        )}
        loading={loadingGetBookings}
        setBooking={setBooking}
        setVisibleModal={setVisibleModal}
        otherDayBookings={otherDayBookings}
        setOtherDayBookings={setOtherDayBookings}
        defaultDateParams={
          params.date ? moment(params.date, 'DD-MM-YYYY') : null
        }
        isToday={isToday}
        handleAddBookingSkip={_object => {
          setBooking({ ..._object, date_day: date || defaultDate });
          setVisibleModal(true);
        }}
        flagCd={flagCd}
      />
      <Modal
        width="750px"
        visible={visibleModal}
        title={null}
        footer={null}
        onCancel={() => {
          setVisibleModal(false);
          setBooking({});
        }}
        destroyOnClose
        maskClosable={false}
      >
        {booking.key ? (
          <EditBooking
            defaultDateParams={
              params.date ? moment(params.date, 'DD-MM-YYYY') : null
            }
            dataSource={dataSource}
            booking={booking}
            doctorsByDate={doctorsByDate}
            onCancel={() => {
              setVisibleModal(false);
              setBooking({});
            }}
            otherDayBookings={otherDayBookings}
            setOtherDayBookings={setOtherDayBookings}
            handleSetFlagCd={handleSetFlagCd}
          />
        ) : (
          <AddBooking
            defaultDateParams={
              params.date ? moment(params.date, 'DD-MM-YYYY') : null
            }
            booking={booking}
            bookings={bookings}
            doctorsByDate={doctorsByDate}
            otherDayBookings={otherDayBookings}
            setOtherDayBookings={setOtherDayBookings}
            onCancel={() => {
              setVisibleModal(false);
              setBooking({});
            }}
          />
        )}
      </Modal>
    </div>
  );
};
