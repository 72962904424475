import { createSlice } from '@reduxjs/toolkit';
import normalize from 'utils/normalize';

const service = createSlice({
  name: 'service',
  initialState: {
    byId: {},
    ids: [],
    printByGroup: []
  },
  reducers: {
    setServices: (state, action) => {
      const { byId, ids } = normalize(action.payload);
      state.byId = byId;
      state.ids = ids;
      state.printByGroup = action.payload.filter(s => s.print_by_group).map(s => s.id);
    },
    addService: (state, action) => {
      const service = action.payload;
      if (!state.ids.includes(service.id)) {
        state.byId[service.id] = service;
        state.ids = [service.id, ...state.ids];
      }
      state.printByGroup = state.ids.filter(id => state.byId[id].print_by_group);
    },
    editService: (state, action) => {
      const service = action.payload;
      state.byId[service.id] = {
        ...state.byId[service.id],
        ...service
      };
      state.printByGroup = state.ids.filter(id => state.byId[id].print_by_group);
    },
    deleteService: (state, action) => {
      const serviceId = action.payload;
      state.byId = {
        ...state.byId,
        [serviceId]: null
      };
    }
  }
});

export const { setServices, addService, editService, deleteService } = service.actions;

export default service.reducer;
