import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { database } from 'configs/firebase';
import http from 'utils/http';

function FormResult13({ booking }) {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const [loading, setLoading] = useState(false);

  const onConfirm = () => {
    setLoading(true)
    http.put(`/bookings/${booking.id}/complete`).then(response => {
      setLoading(false)
      if (booking.key) {
        const bookingsRef = database.ref(
          `bookings/${currentClinic.id}/${booking.key}`
        );
        bookingsRef.update({
          status: { id: 6, name: 'Khám xong' },
          priority: 9999
        });
      }
    });
  };

  return (
    <div id="form-unique">
      <Popconfirm
        title="Bạn có chắc chắn rằng lịch khám này đã xong？"
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        okText="Có"
        cancelText="Hủy"
        okType="danger"
        onConfirm={onConfirm}
      >
        <Button type="primary" loading={loading}>Đã xong</Button>
      </Popconfirm>
    </div>
  );
}

export default FormResult13;
