import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CountUp from 'react-countup';
import { SkeletonMetricHome } from 'containers/skeleton';

import iconGraph from 'assets/icons/graph-dashboard.png';
import iconCalendar from 'assets/icons/calendar-dashboard.png';
import iconService from 'assets/icons/service-dashboard.png';

import http from 'utils/http';

import { filZeroNumber } from 'utils/__function';
import useRole from 'hooks/useRole';

import styles from './index.module.scss';
import { ReportByDoctor } from 'containers/chart';

const convertMomentDate = mommentDate => {
  const now = new Date(mommentDate._d);
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);

  return `${_y}-${_m}-${_d}`;
};

const { Option } = Select;

function HomePage() {
  const history = useHistory();

  const [dataMetric, setDataMetric] = useState(null);
  const [dataReport, setDataReport] = useState([]);
  const [typeReport, setTypeReport] = useState('total');
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'days'));
  const [toDate, setToDate] = useState(moment().subtract(1, 'days'));
  const [typeQueryDay, setTypeQueryDay] = useState('yesterday');
  const { isValidRoles } = useRole();

  const handleChangeRoute = routeName => {
    history.push(routeName);
  };

  const getDataMetric = useCallback(async () => {
    const params = {
      from_date: convertMomentDate(fromDate),
      to_date: convertMomentDate(toDate)
    };

    const response = await http.get(`/dashboard`, { params });

    setDataMetric(response.data.index);

    const report = await http.get(`/reports/services-doctors`, { params });
    setDataReport(report.data)
  }, [fromDate, toDate]);

  useEffect(() => {
    getDataMetric();
  }, [getDataMetric]);

  const onChangeTypeReport = _typeReport => {
    setTypeReport(_typeReport)
  }

  const onChangeTypeQueryDay = _typeQueryDay => {
    setTypeQueryDay(_typeQueryDay);
    if (_typeQueryDay === 'yesterday') {
      setFromDate(moment().subtract(1, 'days'));
      setToDate(moment().subtract(1, 'days'));
    } else if (_typeQueryDay === 'today') {
      setFromDate(moment().subtract(0, 'days'));
      setToDate(moment().subtract(0, 'days'));
    } else if (_typeQueryDay === 'this_week') {
      const _fromDate = moment()
        .startOf('week')
        .isoWeekday(1);
      const _toDate = moment(_fromDate).add(7, 'days');
      setFromDate(_fromDate);
      setToDate(_toDate);
    } else if (_typeQueryDay === 'last_week') {
      const _toDate = moment()
        .startOf('week')
        .isoWeekday(1);
      const _fromDate = moment(_toDate).subtract(7, 'days');
      setFromDate(_fromDate);
      setToDate(_toDate);
    } else if (_typeQueryDay === 'this_month') {
      const _toDate = moment().endOf('month');
      const _fromDate = moment().startOf('month');
      setFromDate(_fromDate);
      setToDate(_toDate);
    } else if (_typeQueryDay === 'last_month') {
      const _fromDate = moment().startOf('month');
      setFromDate(
        moment()
          .startOf('month')
          .subtract(1, 'month')
      );
      setToDate(moment(_fromDate).subtract(1, 'days'));
    } else if (_typeQueryDay === 'this_year') {
      const _toDate = moment().endOf('year');
      const _fromDate = moment().startOf('year');
      setFromDate(_fromDate);
      setToDate(_toDate);
    }
  };

  const reportByDoctorMemo = useMemo(
    () => (
      <ReportByDoctor
        hiddenHeader={true}
        defaultToDate={toDate}
        defaultFromDate={fromDate}
      />
    ),
    [toDate, fromDate]
  );

  const onChangeFromDate = _fromDate => {
    setFromDate(_fromDate);
  };

  const onChangeToDate = _toDate => {
    setToDate(_toDate);
  };

  return (
    <div className={styles.wrapper}>
      <div className="left-side">
        <div className="flex shortcut">
          {isValidRoles(['clinic_admin', 'clinic_manager']) && (
            <>
              <div className="item item-setting">
                <div className="icon-item">
                  <img src={iconGraph} alt="" />
                </div>
                <b>Cấu hình phòng khám</b>
                <br />
                <b>Cài đặt các thông tin cho phòng khám</b>
                <br />
                <button
                  className="btn btn-success mt-2"
                  onClick={() => handleChangeRoute('/cau-hinh')}
                >
                  Cài đặt thông tin
                </button>
              </div>

              <div className="item item-service">
                <div className="icon-item">
                  <img src={iconService} alt="" />
                </div>
                <b>Quản lý dịch vụ</b>
                <br />
                <b>Thêm, cập nhật dịch vụ cho phòng khám</b>
                <br />
                <button
                  className="btn btn-warning mt-2"
                  onClick={() => handleChangeRoute('/dich-vu')}
                >
                  Quản lý dịch vụ
                </button>
              </div>
            </>
          )}
          <div className="item item-booking">
            <div className="icon-item">
              <img src={iconCalendar} alt="" />
            </div>
            <b>Quản lý lịch hẹn</b>
            <br />
            <b>Thêm, cập nhật lịch hẹn cho phòng khám</b>
            <br />
            <button
              className="btn btn-primary mt-2"
              onClick={() => handleChangeRoute('/lich-hen')}
            >
              Quản lý lịch hẹn
            </button>
          </div>
        </div>

        <div className="flex justify-content-flex-end mt-2 mb-2">
          <Select
            onChange={onChangeTypeQueryDay}
            className="mr-2"
            value={typeQueryDay}
            style={{ minWidth: '120px' }}
          >
            <Option value="yesterday">Hôm qua</Option>
            <Option value="today">Hôm nay</Option>
            <Option value="last_week">Tuần trước</Option>
            <Option value="this_week">Tuần này</Option>
            <Option value="last_month">Tháng trước</Option>
            <Option value="this_month">Tháng này</Option>
            <Option value="this_year">Năm nay</Option>
            <Option value="custom">Tuỳ chỉnh</Option>
          </Select>
          <div className="flex">
            <div className="from-date mr-1">
              <span className="mr-1">Từ ngày</span>
              <DatePicker
                allowClear={false}
                value={fromDate}
                format="DD-MM-YYYY"
                onChange={onChangeFromDate}
                disabled={typeQueryDay !== 'custom'}
              />
            </div>
            <div className="to-date">
              <span className="mr-1">Đến ngày</span>
              <DatePicker
                allowClear={false}
                value={toDate}
                format="DD-MM-YYYY"
                onChange={onChangeToDate}
                disabled={typeQueryDay !== 'custom'}
              />
            </div>
          </div>
        </div>

        {dataMetric ? (
          <div className="main-content">
            <div className="flex count-up">
              <div className="item">
                <div>
                  <b>Khách hàng mới</b>
                </div>
                <CountUp
                  className="count-up-value"
                  separator=","
                  end={dataMetric.total_customers_new}
                  duration={2.75}
                />
              </div>
              <div className="item">
                <div>
                  <b>Khách tái khám</b>
                </div>
                <CountUp
                  className="count-up-value"
                  separator=","
                  end={dataMetric.total_customers_reexamination}
                  duration={2.75}
                />
              </div>
              <div className="item">
                <div>
                  <b>Lượt đăng ký khám</b>
                </div>
                <CountUp
                  className="count-up-value"
                  separator=","
                  end={dataMetric.scheduled_visit}
                  duration={2.75}
                />
              </div>
              <div className="item">
                <div>
                  <b>Lượt khám thực tế</b>
                </div>
                <CountUp
                  className="count-up-value"
                  separator=","
                  end={dataMetric.actual_visit}
                  duration={2.75}
                />
              </div>
              <div className="item">
                <div>
                  <b>Lượt khám tháng {new Date().getMonth() + 1}</b>
                </div>
                <CountUp
                  className="count-up-value"
                  separator=","
                  end={dataMetric.monthly_visit}
                  duration={2.75}
                />
              </div>
            </div>
            {dataReport.length ? (
              <div className="table-content mt-2">
                <b style={{ fontSize: '20px' }}>Thống kê theo dịch vụ</b>
                <Select
                  onChange={onChangeTypeReport}
                  className="ml-2"
                  value={typeReport}
                  style={{ minWidth: '120px' }}
                >
                  <Option value="total">Lượt khám</Option>
                  <Option value="amount">Doanh thu</Option>
                </Select>
                <table className="mt-2">
                  <thead>
                    <tr>
                      <th>Dịch vụ</th>
                      {dataReport[0].doctors.map((item, index) => (
                        <th key={index}>{item.name === 'unknow' ? 'Khác' : item.name === 'total' ? 'Tổng ' + (typeReport === 'total' ? 'lượt khám' : 'doanh thu') : item.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataReport.map((i, key) => (
                      <tr key={key}>
                        <td className={i.name === 'total' ? 'total' : ''}>{i.name === 'unknow' ? 'Siêu âm khác' : i.name === 'total' ? 'Tổng' : i.name}</td>
                        {i.doctors.map((item, index) => (
                          <td key={index}>
                            <CountUp
                              className={i.name === 'total' || item.name === 'total' ? 'count-up-value total' : 'count-up-value'}
                              separator=","
                              end={typeReport === 'total' ? item.total : item.amount}
                              duration={2.75}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : ('')}
          </div>
        ) : (
          <SkeletonMetricHome />
        )}

        <div className="chart mt-2">{reportByDoctorMemo}</div>
      </div>
    </div>
  );
}

export default HomePage;
