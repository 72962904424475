function getDimensions(img) {
  return new Promise(resolve => {
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });
}

function dataURLtoFile(base64, fileName = Math.random()) {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${fileName}.jpg`, { type: mime });
}

export default async (dataUrl, fileName) => {
  const img = document.createElement('img');
  img.src = dataUrl;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const quality = 0.85;

  let { width, height } = await getDimensions(img);

  canvas.width = width * quality;
  canvas.height = height * quality;
  context.drawImage(img, 0, 0, width * quality, height * quality);

  const dataUrlResize = canvas.toDataURL('image/jpeg', quality);

  return dataURLtoFile(dataUrlResize, fileName);
};
