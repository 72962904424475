import { createSlice } from '@reduxjs/toolkit';
import normalize from 'utils/normalize';

const employee = createSlice({
  name: 'employee',
  initialState: {
    byId: {},
    ids: []
  },
  reducers: {
    setEmployees: (state, action) => {
      const { byId, ids } = normalize(action.payload);
      state.byId = byId;
      state.ids = ids;
    },
    addEmployee: (state, action) => {
      const employee = action.payload;
      if (!state.ids.includes(employee.id)) {
        state.byId[employee.id] = employee;
        state.ids = [employee.id, ...state.ids];
      }
    },
    editEmployee: (state, action) => {
      const employee = action.payload;
      state.byId[employee.id] = {
        ...state.byId[employee.id],
        ...employee
      };
    },
    deleteEmployee: (state, action) => {
      const employeeId = action.payload;
      state.byId = {
        ...state.byId,
        [employeeId]: null
      };
    }
  }
});

export const { setEmployees, addEmployee, editEmployee, deleteEmployee } = employee.actions;

export default employee.reducer;
