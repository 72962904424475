import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { DragDropContext } from 'react-beautiful-dnd';
import { ListBookingLobby } from 'containers/list';
import { useSelector } from 'react-redux';
import { database } from 'configs/firebase';
import {
  Input,
  Button,
  Form,
  InputNumber,
  Select,
  Popconfirm,
  notification,
  Modal,
  Checkbox
} from 'antd';
import { isPhoneNumber, formatPriceVND } from 'utils';
import {
  comparePriority,
  filZeroNumber,
  arrayNumberRoom,
  getWorkingShifts
} from 'utils/__function';
import { arrayStatus, defaultDate } from 'utils/__variable';
import serviceBooking from 'services/booking';
import serviceCustomer from "services/customer";

import http from 'utils/http';
import iconChart from 'assets/icons/chart.svg';
import classnames from 'classnames';

import styles from './index.module.scss';

const { Option } = Select;

const { confirm } = Modal;

const getEducation = doctor => {
  const educations = doctor.educations || [];
  const education = educations[0] || {};

  if (education.title) {
    return education.title + ' ' + doctor.name;
  }

  return doctor.name;
};

const convertDate = dateString => {
  const [_d, _m, _y] = dateString.split('-');
  return new Date(`${_y}-${_m}-${_d}`);
};

const reorder = (list, startIndex, endIndex) => {
  const result = list;
  const [removed] = list.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const payment = [
  { name: 'Tiền mặt', id: 1 },
  { name: 'Chuyển khoản', id: 2 }
];

// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

function LobbyPage() {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const services = useSelector(state =>
    state.service.ids
      .filter(id => state.service.byId[id])
      .map(id => state.service.byId[id])
  );

  const serviceById = useSelector(state => state.service.byId);
  const servicePrintByGroup = useSelector(state => state.service.printByGroup);
  const doctorById = useSelector(state => state.employee.byId);

  const [valueSearch, setValueSearch] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [bookings, setBookings] = useState({});
  const [bookingsForm, setBookingsForm] = useState([]);
  const [bookingsChecked, setBookingsChecked] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorsChecked, setErrorsChecked] = useState([]);
  const [roomCollapsed, setRoomCollapsed] = useState([]);
  const [flagRef, setFlagRef] = useState(new Date().getTime());

  const [roomDoctor, setRoomDoctor] = useState({});

  const [mode, setMode] = useState('');

  const [tabActive, setTabActive] = useState(0);
  const [showAllDoctor, setShowAllDoctor] = useState(false);

  const bookingsRef = useMemo(() => {
    return database.ref(`bookings/${currentClinic.id}`);
  }, [currentClinic.id, flagRef]);

  const roomDoctorRef = useMemo(
    () => database.ref(`roomDoctor/${currentClinic.id}`),
    [currentClinic.id]
  );
  const forceUpdate = () => {
    setFlagRef(new Date().getTime());
  };
  useEffect(() => {
    bookingsRef.on('value', snapshot => {
      setBookings(snapshot.val() || {});
    });
  }, [bookingsRef]);

  useEffect(() => {
    roomDoctorRef.on('value', snapshot => {
      let _roomDoctor = {
        '-1': 'unknow'
      };
      const objectSnapshot = snapshot.val() || {};
      for (let key in objectSnapshot) {
        _roomDoctor[key] = objectSnapshot[key].doctorId;
      }
      console.log(_roomDoctor);
      setRoomDoctor(_roomDoctor);
    });
  }, [roomDoctorRef]);

  const workingShifts = useSelector(state =>
    state.workspace.currentWokingShift.ids
      .filter(id => state.workspace.currentWokingShift.byId[id])
      .map(id => state.workspace.currentWokingShift.byId[id])
  );

  const onChangeDoctorRoom = (doctorId, room) => {
    const roomDoctorRef = database.ref(
      `roomDoctor/${currentClinic.id}/${room}`
    );

    if (!doctorId) {
      roomDoctorRef.remove();
    } else {
      roomDoctorRef.update({ doctorId });
    }
  };

  const bookingsByDoctorId = useCallback(
    doctorId => {
      let _bookings = [];
      for (let key in bookings) {
        const booking = bookings[key];
        if (booking.id) {
          const bookingStatus = booking.status || {};

          if ([3, 4, 5].includes(bookingStatus.id)) {
            if (doctorId === 'unknow' || doctorId === '-1') {
              if (!booking.doctor_id || booking.doctor_id === -1) {
                _bookings.push({ key, ...booking });
              }
            } else {
              if (booking.doctor_id === Number(doctorId)) {
                _bookings.push({ key, ...booking });
              }
            }
          }
        }
      }
      return _bookings.sort(comparePriority);
    },
    [bookings]
  );

  const getSchedules = useCallback(async () => {
    let from_date = convertDate(defaultDate);
    let to_date = convertDate(defaultDate);

    try {
      const response = await http.get('/schedules', {
        params: {
          per_page: 9999,
          from_date,
          to_date
        }
      });
      setSchedules(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    const now = new Date();
    const _y = now.getFullYear();
    const _d = filZeroNumber(now.getDate());
    const _m = filZeroNumber(now.getMonth() + 1);

    const rsp = getWorkingShifts(workingShifts, now);
    let workingShift = rsp.workingShifts.find(ws => now >= ws.fromExtend && now < ws.endExtend)

    if (workingShift) {
      let _doctors = [];
      let doctorIds = [];
      for (let schedule of schedules) {
        if (!schedule.user || schedule.shift !== workingShift.id) continue;
        if (!doctorIds.includes(schedule.user.id)) {
          const start_time = new Date(`${_y}-${_m}-${_d} ${schedule.start_time}`);
          const finish_time = new Date(`${_y}-${_m}-${_d} ${schedule.finish_time}`);
          if (showAllDoctor || (!showAllDoctor && workingShift.fromExtend <= start_time && finish_time <= workingShift.endExtend)) {
            _doctors.push(schedule.user);
            doctorIds.push(schedule.user.id);
          }
        }
      }
      setDoctors(_doctors);
    }
  }, [schedules, showAllDoctor]);

  const arrayRooms = useMemo(() => arrayNumberRoom(doctors.length), [doctors]);
  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        bookingsByDoctorId(source.droppableId),
        source.index,
        destination.index
      );

      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        const bookingRefUpdate = database.ref(
          `bookings/${currentClinic.id}/${item.key}`
        );

        bookingRefUpdate.update({
          priority: Number(source.droppableId) * 100 + index + 1
        });
      }
    } else {
      const sourceBookings = bookingsByDoctorId(source.droppableId);

      const bookingSource = sourceBookings[source.index] || {};
      const statusId = bookingSource.status?.id;

      if ([4, 5].includes(statusId)) {
        Modal.warning({
          title:
            'Không thể thay đổi phòng khám khi lịch ở trạng thái đang khám hoặc đang gián đoạn.'
        });
        return;
      }

      const handleRemove = () => {
        const result = move(
          bookingsByDoctorId(source.droppableId),
          bookingsByDoctorId(destination.droppableId),
          source,
          destination
        );
        for (let doctorId in result) {
          const _bookings = result[doctorId];

          for (let index = 0; index < _bookings.length; index++) {
            const booking = _bookings[index];

            let objectChange = {
              priority: Number(doctorId) * 100 + (index + 1)
            };

            if (booking.doctor_id !== Number(doctorId)) {
              objectChange.doctor_id = Number(doctorId);
            }

            const refURL = `bookings/${currentClinic.id}/${booking.key}`;

            const bookingRefUpdate = database.ref(refURL);

            bookingRefUpdate.update(objectChange);
          }
        }
      };

      if (bookingSource.doctor_id_required) {
        const doctorRequired = doctorById[bookingSource.doctor_id_required];

        const contentConfirm = (
          <>
            Khách hàng <b>{bookingSource.user_name}</b> đã yêu cầu{' '}
            <b>{getEducation(doctorRequired)}</b> trước đó, bạn có thực sự hiện
            hành động này?
          </>
        );
        confirm({
          title: 'Thay đổi bác sĩ đã yêu cầu trước?',
          icon: <ExclamationCircleOutlined />,
          content: contentConfirm,
          okText: 'Đồng ý',
          cancelText: 'Hủy',
          onOk() {
            handleRemove();
          },
          onCancel() { }
        });
      } else {
        handleRemove();
      }
    }
  };

  const handleChangeBookingsForm = async (field, value, index) => {
    let _bookingsForm = [...bookingsForm];

    _bookingsForm[index][field] = value;

    if (field === 'phone_number') {
      if (isPhoneNumber(value)) {
        value = '+84' + value.substr(1, value.length)
        const response = await serviceCustomer.searchByPhone({ phone_number: value, include: 'babies' });
        if (response.meta.code === 200 && response.data.length) {
          const customer = response.data[0]
          _bookingsForm[index].user_name = customer.name || null;
          _bookingsForm[index].birthday = customer.date_of_birth ? Number(customer.date_of_birth.split('-')[0]) : null;
          _bookingsForm[index].weeks = customer.babies ? Number(customer.babies[0].gestational_week) : null;
        }
      }
    }

    if (field === 'service_id') {
      _bookingsForm[index].price = serviceById[value].price;
    }

    setBookingsForm(_bookingsForm);
  };

  const handleChangeBookingsChecked = (field, value, index) => {
    let _bookingsChecked = [...bookingsChecked];

    _bookingsChecked[index][field] = value;

    if (field === 'service_id') {
      _bookingsChecked[index].price = serviceById[value].price;
    }

    setBookingsChecked(_bookingsChecked);
  };

  const isError = (index, bookingForm_) => {
    let _errors = [];

    const fields = [
      'phone_number',
      'user_name',
      'service_id',
      // 'doctor_id',
      'price'
    ];

    const bookingForm = bookingsForm[index] || bookingForm_ || {};

    for (let field of fields) {
      if (!bookingForm[field] && bookingForm[field] !== 0) {
        _errors.push({
          field: 'summary',
          text: 'Chưa nhập đủ thông tin'
        });
        break;
      }
    }

    if (bookingForm.phone_number && !isPhoneNumber(bookingForm.phone_number)) {
      _errors.push({ field: 'phone_number', text: 'SDT không hợp lệ' });
    }

    let _errorsArray = [...errors];

    _errorsArray[index] = _errors;

    setErrors(_errorsArray);

    return _errors.length !== 0;
  };

  const isErrorChecked = index => {
    let _errors = [];

    const fields = [
      'phone_number',
      'user_name',
      'service_id',
      // 'doctor_id',
      'price'
    ];

    const bookingChecked = bookingsChecked[index] || {};

    for (let field of fields) {
      if (!bookingChecked[field] && bookingChecked[field] !== 0) {
        _errors.push({
          field: 'summary',
          text: 'Chưa nhập đủ thông tin'
        });
        break;
      }
    }

    if (
      bookingChecked.phone_number &&
      !isPhoneNumber(bookingChecked.phone_number)
    ) {
      _errors.push({ field: 'phone_number', text: 'SDT không hợp lệ' });
    }

    let _errorsArray = [...errorsChecked];

    _errorsArray[index] = _errors;

    setErrorsChecked(_errorsArray);

    return _errors.length !== 0;
  };

  const isErrorField = (index, field) => {
    if (!errors[index]) return false;

    let hasSummary = false;

    for (let error of errors[index]) {
      if (error.field === 'summary') {
        hasSummary = true;
        break;
      }
    }

    if (hasSummary) {
      return !bookingsForm[index][field] && bookingsForm[index][field] !== 0;
    }

    let isError = false;

    for (let error of errors[index]) {
      if (error.field === field) {
        isError = true;
      }
    }

    return isError;
  };

  const isErrorCheckedField = (index, field) => {
    if (!errorsChecked[index]) return false;

    let hasSummary = false;

    for (let error of errorsChecked[index]) {
      if (error.field === 'summary') {
        hasSummary = true;
        break;
      }
    }

    if (hasSummary) {
      return (
        !bookingsChecked[index][field] && bookingsChecked[index][field] !== 0
      );
    }

    let isError = false;

    for (let error of errorsChecked[index]) {
      if (error.field === field) {
        isError = true;
      }
    }

    return isError;
  };

  const removeOnDB = booking => {
    if (booking.id) {
      if (booking.status && booking.status.id > 1) {
        serviceBooking.cancelBooking(booking.id);
      } else {
        serviceBooking.deleteBooking(booking.id);
      }
    }
    if (booking.key) {
      const refURL = `bookings/${currentClinic.id}/${booking.key}`;
      const bookingRef = database.ref(refURL);
      if (booking.status && booking.status.id > 1) {
        bookingRef.update({
          status: arrayStatus[6]
        });
      } else {
        bookingRef.remove();
      }
    }
  };

  const onConfirmRemove = index => {
    let _bookingsForm = [...bookingsForm];

    removeOnDB(_bookingsForm[index]);

    _bookingsForm.splice(index, 1);

    setBookingsForm(_bookingsForm);

    if (_bookingsForm.length === 0) {
      setMode('');
    }
  };

  const onConfirmRemoveChecked = index => {
    let _bookingsChecked = [...bookingsChecked];

    removeOnDB(_bookingsChecked[index]);

    _bookingsChecked.splice(index, 1);

    setBookingsChecked(_bookingsChecked);
  };

  const handleCheckin = async (bookingForm, indexBooking, isChecked) => {
    let bookingData;
    let ids = [];
    if (!bookingForm.disabled) {
      if (bookingForm.key) {
        ids.push(bookingForm.id);
        bookingData = await handleUpdateBooking(bookingForm, isChecked);
      } else {
        ids.push(bookingForm.tmp_id);
        bookingData = await handleAddBooking(bookingForm, indexBooking, isChecked);
      }
    } else {
      bookingData = bookingForm;
      if (bookingForm.key) {
        ids.push(bookingForm.id);
      }
    }
    const bookingsHandle = isChecked ? bookingsChecked : bookingsForm;
    // kiểm tra các dịch vụ còn lại, nếu cùng là dịch vụ in theo group thì update hoặc tạo mới
    if (servicePrintByGroup.indexOf(bookingForm.service_id) !== -1) {
      for (const [k, b] of bookingsHandle.entries()) {
        if (servicePrintByGroup.indexOf(b.service_id) !== -1) {
          const _error = isError(-1, b);
          if (!_error) {
            if (b.key && b.id !== bookingForm.id) {
              ids.push(b.id);
              if (!b.disabled) {
                await handleUpdateBooking(b)
              }
            } else if (!b.key && indexBooking !== k) {
              ids.push(b.tmp_id);
              if (!b.disabled) {
                await handleAddBooking(b, k);
              }
            }
          }
        }
      }
    }
    saveBookingsToLocalStorage(bookingsHandle);

    // cập nhật lại danh sách tìm kiếm
    const _bookingsFilter = bookingsHandle.filter(b => (!b.tmp_id && ids.indexOf(b.id) === -1) || (b.tmp_id && ids.indexOf(b.tmp_id) === -1));

    if (isChecked) {
      setBookingsChecked(_bookingsFilter);
    } else {
      setBookingsForm(_bookingsFilter);
    }

    if (_bookingsFilter.length === 0) {
      setMode('');
      setValueSearch('');
    }

    handlePrintOrder(bookingData)
  }

  const handleAddBooking = async (booking, indexBooking, isChecked) => {
    if (!booking) return;
    let responseData = [];
    try {
      const bookingServices = [
        {
          service_id: booking.service_id,
          doctor_id: booking.doctor_id,
          price: booking.price
        }
      ];

      const formData = {
        phone_number: booking.phone_number,
        user_name: booking.user_name,
        birthday: booking.birthday,
        weeks: booking.weeks,
        mother_weight: booking.mother_weight,
        payment_method: booking.payment_method,
        date_time: booking.date_day && booking.date_time ? `${booking.date_day} ${booking.date_time}` : `${defaultDate} 00:00`,
        services: bookingServices,
        booking_appointment_status_id: 3,
        numerical_order: booking.numerical_order
      };

      if (formData.phone_number) {
        if (!formData.phone_number.includes('+84')) {
          formData.phone_number = '+84' + formData.phone_number.slice(1, 10);
        }
      }

      const responses = await serviceBooking.addBooking(
        formData
      );

      responseData = responses.data || [];

      const now = new Date();
      const _y = now.getFullYear();
      const _d = filZeroNumber(now.getDate());
      const _m = filZeroNumber(now.getMonth() + 1);
      const _h = filZeroNumber(now.getHours());
      const _min = filZeroNumber(now.getMinutes());

      for (let index = 0; index < responseData.length; index++) {
        const itemResponse = responseData[index];
        bookingsRef.push({
          ...itemResponse,
          status: arrayStatus[2],
          priority:
            itemResponse.doctor_id * 100 +
            bookingsByDoctorId(itemResponse.doctor_id).length +
            1,
          checked_time: `${_d}-${_m}-${_y} ${_h}:${_min}`
        });
      }
    } catch (error) {
      console.error(error);
    }

    if (isChecked) {
      let _bookingsChecked = [...bookingsChecked];

      if (indexBooking || indexBooking === 0) {
        _bookingsChecked.splice(indexBooking, 1);
        setBookingsChecked(_bookingsChecked);
      }
    } else {
      let _bookingsForm = [...bookingsForm];

      if (indexBooking || indexBooking === 0) {
        _bookingsForm.splice(indexBooking, 1);
        setBookingsForm(_bookingsForm);
      }
    }

    notification.success({ message: 'Thêm lịch khám thành công' });
    responseData[0].tmp_id = booking.tmp_id;
    return responseData[0];
  };

  const handleUpdateBooking = async (bookingForm, isChecked) => {
    const now = new Date();
    const _y = now.getFullYear();
    const _d = filZeroNumber(now.getDate());
    const _m = filZeroNumber(now.getMonth() + 1);
    const _h = filZeroNumber(now.getHours());
    const _min = filZeroNumber(now.getMinutes());

    let _values = {
      ...bookingForm,
      checked_time:
        bookingForm.checked_time || `${_d}-${_m}-${_y} ${_h}:${_min}`,
      weeks: bookingForm.weeks || null,
      mother_weight: bookingForm.mother_weight || null,
      payment_method: bookingForm.payment_method || 1,
      birthday: bookingForm.birthday || null,
      booking_appointment_status_id: 3
    };

    if (!bookingForm.phone_number.includes('+84')) {
      _values.phone_number = '+84' + bookingForm.phone_number.slice(1, 10);
    }

    delete _values.key;
    delete _values.date_time;
    delete _values.date_day;
    delete _values.numerical_order;

    serviceBooking.editBooking(_values);

    const priority =
      bookingForm.doctor_id * 100 +
      bookingsByDoctorId(bookingForm.doctor_id).length +
      1;

    const bookingUpdateRef = database.ref(
      `bookings/${currentClinic.id}/${bookingForm.key}`
    );

    const objectUpdate = {
      ..._values,
      status: arrayStatus[2],
      priority
    };

    if (bookings[bookingForm.key].doctor_id) {
      objectUpdate.doctor_id_required = bookings[bookingForm.key].doctor_id;
    }

    bookingUpdateRef.update(objectUpdate);

    if (isChecked) {
      const _bookingsFilter = bookingsChecked.filter(
        b => b.id !== bookingForm.id
      );

      setBookingsChecked(_bookingsFilter);
    } else {
      const _bookingsFilter = bookingsForm.filter(b => b.id !== bookingForm.id);

      setBookingsForm(_bookingsFilter);
      if (_bookingsFilter.length === 0) {
        setMode('');
        setValueSearch('');
      }
    }
    return bookingForm;
  };

  const saveBookingsToLocalStorage = (bookingsHandle) => {
    let bookingsForm_ = bookingsHandle.filter(b => b.service_id)
      .map(b => {
        return { ...b, service_names: [serviceById[b.service_id].name] }
      })

    localStorage.setItem(
      'bookingsFormPrintOrder',
      JSON.stringify(bookingsForm_)
    );
    localStorage.setItem('serviceById', JSON.stringify(serviceById));
    localStorage.setItem('servicePrintByGroup', JSON.stringify(servicePrintByGroup));
  }

  const handlePrintOrder = bookingForm => {
    const serviceNames = [serviceById[bookingForm.service_id].name];

    localStorage.setItem(
      'bookingPrintOrder',
      JSON.stringify({
        ...bookingForm,
        clinic_name: currentClinic.name,
        service_names: serviceNames
      })
    );

    window.open('/in-phieu-kham', '_blank');
  };

  const isValidDoctorId = useCallback(
    doctorId => {
      const doctorIds = doctors.map(d => d.id);
      return doctorIds.includes(doctorId);
    },
    [doctors]
  );

  const onSearch = value => {
    let _value = value.toLowerCase();
    let _bookingsForm = [];

    for (const key in bookings) {
      const booking = bookings[key];

      const bookingStatus = booking.status || {};

      if (bookingStatus.id === 7) continue;

      let numericalOrder = (booking.numerical_order || '').toLowerCase();
      let phoneNumber = (booking.phone_number || '')
        .replace('+84', '0')
        .toLowerCase();
      let userName = (booking.user_name || '').toLowerCase();

      if (
        numericalOrder.includes(_value) ||
        phoneNumber.includes(_value) ||
        userName.includes(_value)
      ) {
        let objectPush = {
          key,
          id: booking.id,
          phone_number: booking.phone_number,
          user_name: booking.user_name,
          birthday: booking.birthday,
          weeks: booking.weeks,
          date_day: booking.date_day,
          date_time: booking.date_time,
          doctor_id: '',
          service_id: booking.service_id || '',
          price: booking.price,
          mother_weight: booking.mother_weight || null,
          numerical_order: booking.numerical_order,
          payment_method: booking.payment_method,
          disabled: bookingStatus.id > 1,
          status: bookingStatus
        };

        if (booking.birthday) {
          objectPush.birthday = Number(booking.birthday);
        }

        if (booking.doctor_id) {
          const doctorId = Number(booking.doctor_id);
          if (isValidDoctorId(doctorId)) {
            objectPush.doctor_id = doctorId;
          }
        }
        _bookingsForm.push(objectPush);
      }
    }

    setMode('search');

    setBookingsForm(_bookingsForm);
  };

  useEffect(() => {
    if (!valueSearch && mode === 'search') {
      setMode('');
      setBookingsForm([]);
    }
  }, [valueSearch, mode]);

  const metricBookings = doctorId => {
    let _metricBookings = [0, 0, 0, 0, 0];
    let _bookingsFilter = [];

    for (let key in bookings) {
      const booking = bookings[key];
      _bookingsFilter.push(booking);
    }

    if (doctorId) {
      _bookingsFilter = _bookingsFilter.filter(b => b.doctor_id === doctorId);
    }

    for (let b of _bookingsFilter) {
      const status = b.status || {};
      if (status.id === 3) {
        _metricBookings[0] += 1;
        if (b.service_id) {
          if ([1, 2].includes(b.service_id)) {
            _metricBookings[1] += 1;
          } else if ([3, 4].includes(b.service_id)) {
            _metricBookings[2] += 1;
          } else {
            _metricBookings[3] += 1;
          }
        }
      }

      if (status.id === 6) {
        _metricBookings[4] += 1;
      }
    }

    return _metricBookings;
  };

  const roomStatus = doctorId => {
    let _roomStatus = {};
    let _bookingsFilter = [];
    let _isDoing = false;

    for (let key in bookings) {
      const booking = bookings[key];
      if (booking.doctor_id !== doctorId) continue;
      _bookingsFilter.push(booking);
      if ((booking.status || {}).id === 4) {
        _isDoing = true;
      }
    }

    if (_bookingsFilter.length === 0) {
      _roomStatus = {
        id: 1,
        text: 'DS trống'
      };
    } else {
      if (_isDoing) {
        _roomStatus = {
          id: 2,
          text: 'Đang khám'
        };
      } else {
        _roomStatus = {
          id: 3,
          text: 'Đang nghỉ'
        };
      }
    }

    return _roomStatus;
  };

  useEffect(() => {
    let _bookingsChecked = [...bookingsChecked];
    let _bookingIds = [];
    let hasChange = false;

    const _bookingCheckedIds = _bookingsChecked.map(b => b.id);

    for (let key in bookings) {
      const booking = bookings[key];
      _bookingIds.push(booking.id)

      if (_bookingCheckedIds.includes(booking.id)) {
        continue;
      }

      const statusId = booking?.status?.id;

      if (statusId === 2) {
        hasChange = true;
        _bookingsChecked.push({
          ...booking,
          key
        });
      }
    }
    let difference = _bookingCheckedIds.filter(x => !_bookingIds.includes(x));
    if (difference.length) {
      let numericalOrderRemoved = [];
      difference.forEach(d => {
        if (d !== undefined) {
          const bookingRemoved = _bookingsChecked.find(_b => _b.id === d)
          if (bookingRemoved) {
            numericalOrderRemoved.push(bookingRemoved.numerical_order)
          }
        }
      })
      if (numericalOrderRemoved.length) {
        hasChange = true
        _bookingsChecked = _bookingsChecked.filter(_b => !numericalOrderRemoved.includes(_b.numerical_order))
      }
    }

    if (hasChange) {
      setBookingsChecked(_bookingsChecked);
    }
  }, [bookings]);

  return (
    <div className={styles.wrapper}>
      <div className="header">
        <div className="flex justify-content-between mb-2">
          <div className="flex justify-content-between">
            <div className="wrapper-input-search">
              <Input.Group compact>
                <Input.Search
                  value={valueSearch}
                  onChange={event => setValueSearch(event.target.value)}
                  placeholder="Nhập tên, SDT, mã KH"
                  allowClear
                  onSearch={onSearch}
                />
              </Input.Group>
            </div>
            <Checkbox className="ml-2" onChange={(e) => { setShowAllDoctor(e.target.checked) }}>Tất cả bác sĩ</Checkbox>
          </div>
          <Button
            type="success"
            onClick={() => {
              setMode('add');
              setBookingsForm([
                {
                  phone_number: '',
                  user_name: '',
                  birthday: '',
                  weeks: '',
                  service_id: '',
                  doctor_id: '',
                  price: '',
                  mother_weight: '',
                  payment_method: 1
                }
              ]);
            }}
          >
            Thêm ngay
          </Button>
        </div>
        {mode && (
          <div
            style={{
              borderTop: '1px solid #DC7B62',
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <div className="flex justify-content-between align-items-center">
              <b style={{ fontSize: '18px' }}>
                {mode === 'add'
                  ? 'Nhập thông tin ca khám'
                  : `Kết quả tìm kiếm (${bookingsForm.length})`}
              </b>
              <Button size="sm" onClick={() => setMode('')}>
                Đóng
              </Button>
            </div>
            {bookingsForm.map((bookingForm, index) => (
              <div key={index}>
                <div
                  className={classnames('form-add flex', {
                    'hidden-label': index !== 0,
                    'has-error': !!errors[index]
                  })}
                >
                  <Form.Item label="Mã KH" style={{ width: '60px' }}>
                    <Input value={bookingForm.numerical_order} disabled />
                  </Form.Item>
                  <Form.Item
                    label="SDT"
                    style={{ width: '120px' }}
                    validateStatus={
                      isErrorField(index, 'phone_number') ? 'error' : ''
                    }
                  >
                    <Input
                      style={{ width: '100% ' }}
                      maxLength={10}
                      value={(bookingForm.phone_number || '').replace(
                        '+84',
                        '0'
                      )}
                      onChange={event => {
                        handleChangeBookingsForm(
                          'phone_number',
                          event.target.value,
                          index
                        );
                      }}
                      disabled={bookingForm.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Họ tên"
                    style={{ width: '200px' }}
                    validateStatus={
                      isErrorField(index, 'user_name') ? 'error' : ''
                    }
                  >
                    <Input
                      value={bookingForm.user_name}
                      onChange={event => {
                        handleChangeBookingsForm(
                          'user_name',
                          event.target.value,
                          index
                        );
                      }}
                      onBlur={() => { }}
                      disabled={bookingForm.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Năm sinh"
                    style={{ width: '65px' }}
                    className="form-item-birthday"
                  >
                    <InputNumber
                      value={bookingForm.birthday}
                      onChange={value => {
                        handleChangeBookingsForm('birthday', value, index);
                      }}
                      onBlur={() => {
                        if (bookingForm.birthday < 1950) {
                          handleChangeBookingsForm('birthday', 1950, index);
                        }
                      }}
                      style={{ width: '100%' }}
                      maxLength={4}
                      disabled={bookingForm.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Tuần thai"
                    style={{ width: '65px' }}
                    className="form-item-weeks"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      maxLength={2}
                      value={bookingForm.weeks}
                      onChange={value => {
                        handleChangeBookingsForm('weeks', value, index);
                      }}
                      onBlur={() => {
                        if (bookingForm.weeks < 0) {
                          handleChangeBookingsForm('weeks', 0, index);
                        }
                      }}
                      disabled={bookingForm.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Cân nặng"
                    style={{ width: '65px' }}
                    className="form-item-mother-weight"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      maxLength={5}
                      value={bookingForm.mother_weight}
                      onChange={value => {
                        handleChangeBookingsForm('mother_weight', value, index);
                      }}
                      onBlur={() => {
                        if (bookingForm.mother_weight < 0) {
                          handleChangeBookingsForm('mother_weight', 0, index);
                        }
                      }}
                      disabled={bookingForm.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Dịch vụ"
                    style={{ width: '250px' }}
                    validateStatus={
                      isErrorField(index, 'service_id') ? 'error' : ''
                    }
                  >
                    <Select
                      style={{ width: '100%' }}
                      value={bookingForm.service_id}
                      onChange={value =>
                        handleChangeBookingsForm('service_id', value, index)
                      }
                      showSearch
                      showAction="focus"
                      optionFilterProp="name"
                      disabled={bookingForm.disabled}
                    >
                      {services.map(service => (
                        <Option
                          key={service.id}
                          value={service.id}
                          name={service.name}
                        >
                          {service.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Bác sĩ"
                    style={{ width: '200px' }}
                    validateStatus={
                      isErrorField(index, 'doctor_id') ? 'error' : ''
                    }
                  >
                    <Select
                      style={{ width: '100%' }}
                      value={bookingForm.doctor_id}
                      onChange={value =>
                        handleChangeBookingsForm('doctor_id', value, index)
                      }
                      showSearch
                      showAction="focus"
                      optionFilterProp="name"
                      disabled={bookingForm.disabled}
                    >
                      {doctors.map(doctor => (
                        <Option
                          key={doctor.id}
                          value={doctor.id}
                          name={doctor.name}
                        >
                          {doctor.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Giá dịch vụ"
                    style={{ width: '100px' }}
                    className="form-item-price"
                    validateStatus={isErrorField(index, 'price') ? 'error' : ''}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      value={bookingForm.price}
                      formatter={value => formatPriceVND(value)}
                      onChange={value => {
                        handleChangeBookingsForm('price', value, index);
                      }}
                      onBlur={() => {
                        if (bookingForm.price < 0) {
                          handleChangeBookingsForm('price', 0, index);
                        }
                      }}
                      disabled={bookingForm.disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Thanh toán"
                    style={{ width: '150px' }}
                  >
                    <Select
                      value={
                        bookingForm.payment_method !== 2
                          ? 'Tiền mặt'
                          : 'Chuyển khoản'
                      }
                      style={{ width: '100%' }}
                      onChange={value => {
                        handleChangeBookingsForm('payment_method', value, index);
                      }}
                      disabled={bookingForm.disabled}
                    >
                      {payment.map(payment => (
                        <Option key={payment.id} value={payment.id}>
                          {payment.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="." className="form-item-action-print">
                    <Button
                      type="primary"
                      onClick={() => {
                        const _error = isError(index);
                        if (!_error) {
                          handleCheckin(bookingForm, index)
                        }
                      }}
                      disabled={bookingForm.status?.id === 6}
                    >
                      In phiếu
                    </Button>
                  </Form.Item>
                  <Form.Item
                    label="."
                    className={classnames('form-item-action-add-service', {
                      'first-row': index === 0
                    })}
                  >
                    <Button
                      type="success"
                      onClick={() => {
                        let _bookingsForm = [...bookingsForm];
                        _bookingsForm.insert(index + 1, {
                          phone_number: bookingForm.phone_number,
                          user_name: bookingForm.user_name,
                          birthday: bookingForm.birthday,
                          weeks: bookingForm.weeks,
                          date_day: bookingForm.date_day,
                          date_time: bookingForm.date_time,
                          service_id: '',
                          doctor_id: '',
                          price: '',
                          mother_weight: bookingForm.mother_weight,
                          numerical_order: bookingForm.numerical_order ? bookingForm.numerical_order : '',
                          payment_method: 1,
                          tmp_id: (new Date()).toString()
                        });
                        setBookingsForm(_bookingsForm);
                      }}
                    >
                      Thêm DV
                    </Button>
                  </Form.Item>
                  {bookingForm.status?.id !== 6 && bookingForm.status?.id !== 7 ?
                    <>
                      <Form.Item label="." className="form-item-action-remove">
                        <Popconfirm
                          placement="topRight"
                          title="Bạn có thực sự muốn hủy dịch vụ khám này?"
                          okText="Có"
                          cancelText="Không"
                          okType="danger"
                          onConfirm={() => onConfirmRemove(index)}
                        >
                          <MinusCircleOutlined />
                        </Popconfirm>
                      </Form.Item>
                    </> :
                    <>
                      <Form.Item label="." className="form-item-action-remove">
                        <CheckOutlined />
                      </Form.Item>
                    </>
                  }
                </div>
                {errors[index] && errors[index].length !== 0 ? (
                  <div className="show-error">
                    {errors[index].map((e, indexError) => (
                      <small className="error-item" key={indexError}>
                        <i>
                          {indexError !== 0 ? ', ' : ''} {e.text}
                        </i>
                      </small>
                    ))}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}

        {bookingsChecked.length !== 0 && (
          <div className="list-booking-checked">
            <div
              style={{
                borderTop: '1px solid #DC7B62',
                paddingTop: '8px',
                paddingBottom: '8px'
              }}
            >
              <b style={{ fontSize: '18px' }}>
                Danh sách KH checkin app ({bookingsChecked.length})
              </b>
              {bookingsChecked.map((bookingChecked, index) => (
                <div key={index}>
                  <div
                    className={classnames('form-add flex', {
                      'hidden-label': index !== 0,
                      'has-error': !!errorsChecked[index]
                    })}
                  >
                    <Form.Item label="Mã KH" style={{ width: '60px' }}>
                      <Input value={bookingChecked.numerical_order} disabled />
                    </Form.Item>
                    <Form.Item
                      label="SDT"
                      style={{ width: '120px' }}
                      validateStatus={
                        isErrorCheckedField(index, 'phone_number')
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        style={{ width: '100% ' }}
                        maxLength={10}
                        value={(bookingChecked.phone_number || '').replace(
                          '+84',
                          '0'
                        )}
                        onChange={event => {
                          handleChangeBookingsChecked(
                            'phone_number',
                            event.target.value,
                            index
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Họ tên"
                      style={{ width: '200px' }}
                      validateStatus={
                        isErrorCheckedField(index, 'user_name') ? 'error' : ''
                      }
                    >
                      <Input
                        value={bookingChecked.user_name}
                        onChange={event => {
                          handleChangeBookingsChecked(
                            'user_name',
                            event.target.value,
                            index
                          );
                        }}
                        onBlur={() => { }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Năm sinh"
                      style={{ width: '65px' }}
                      className="form-item-birthday"
                    >
                      <InputNumber
                        value={bookingChecked.birthday}
                        onChange={value => {
                          handleChangeBookingsChecked('birthday', value, index);
                        }}
                        onBlur={() => {
                          if (bookingChecked.birthday < 1950) {
                            handleChangeBookingsChecked(
                              'birthday',
                              1950,
                              index
                            );
                          }
                        }}
                        style={{ width: '100%' }}
                        maxLength={4}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tuần thai"
                      style={{ width: '65px' }}
                      className="form-item-weeks"
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        maxLength={2}
                        value={bookingChecked.weeks}
                        onChange={value => {
                          handleChangeBookingsChecked('weeks', value, index);
                        }}
                        onBlur={() => {
                          if (bookingChecked.weeks < 0) {
                            handleChangeBookingsChecked('weeks', 0, index);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Cân nặng"
                      style={{ width: '65px' }}
                      className="form-item-mother-weight"
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        maxLength={5}
                        value={bookingChecked.mother_weight}
                        onChange={value => {
                          handleChangeBookingsChecked(
                            'mother_weight',
                            value,
                            index
                          );
                        }}
                        onBlur={() => {
                          if (bookingChecked.mother_weight < 0) {
                            handleChangeBookingsChecked(
                              'mother_weight',
                              0,
                              index
                            );
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Dịch vụ"
                      style={{ width: '250px' }}
                      validateStatus={
                        isErrorCheckedField(index, 'service_id') ? 'error' : ''
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        value={bookingChecked.service_id}
                        onChange={value =>
                          handleChangeBookingsChecked(
                            'service_id',
                            value,
                            index
                          )
                        }
                        showSearch
                        showAction="focus"
                        optionFilterProp="name"
                      >
                        {services.map(service => (
                          <Option
                            key={service.id}
                            value={service.id}
                            name={service.name}
                          >
                            {service.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Bác sĩ"
                      style={{ width: '200px' }}
                      validateStatus={
                        isErrorCheckedField(index, 'doctor_id') ? 'error' : ''
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        value={bookingChecked.doctor_id}
                        onChange={value =>
                          handleChangeBookingsChecked('doctor_id', value, index)
                        }
                        showSearch
                        showAction="focus"
                        optionFilterProp="name"
                      >
                        {doctors.map(doctor => (
                          <Option
                            key={doctor.id}
                            value={doctor.id}
                            name={doctor.name}
                          >
                            {doctor.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Giá dịch vụ"
                      style={{ width: '100px' }}
                      className="form-item-price"
                      validateStatus={
                        isErrorCheckedField(index, 'price') ? 'error' : ''
                      }
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        value={bookingChecked.price}
                        formatter={value => formatPriceVND(value)}
                        onChange={value => {
                          handleChangeBookingsChecked('price', value, index);
                        }}
                        onBlur={() => {
                          if (bookingChecked.price < 0) {
                            handleChangeBookingsChecked('price', 0, index);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Thanh toán"
                      style={{ width: '150px' }}
                    >
                      <Select
                        value={
                          bookingChecked.payment_method !== 2
                            ? 'Tiền mặt'
                            : 'Chuyển khoản'
                        }
                        style={{ width: '100%' }}
                        onChange={value => {
                          handleChangeBookingsChecked('payment_method', value, index);
                        }}
                        disabled={bookingChecked.disabled}
                      >
                        {payment.map(payment => (
                          <Option key={payment.id} value={payment.id}>
                            {payment.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="." className="form-item-action-print">
                      <Button
                        type="primary"
                        onClick={() => {
                          const _error = isErrorChecked(index);
                          if (!_error) {
                            handleCheckin(bookingChecked, index, true)
                          }
                        }}
                        disabled={bookingChecked.status?.id === 6}
                      >
                        In phiếu
                      </Button>
                    </Form.Item>
                    <Form.Item
                      label="."
                      className={classnames('form-item-action-add-service', {
                        'first-row': index === 0
                      })}
                    >
                      <Button
                        type="success"
                        onClick={() => {
                          let _bookingsChecked = [...bookingsChecked];
                          _bookingsChecked.insert(index + 1, {
                            phone_number: bookingChecked.phone_number,
                            user_name: bookingChecked.user_name,
                            birthday: bookingChecked.birthday,
                            weeks: bookingChecked.weeks,
                            date_day: bookingChecked.date_day,
                            date_time: bookingChecked.date_time,
                            service_id: '',
                            doctor_id: '',
                            price: '',
                            mother_weight: bookingChecked.mother_weight,
                            numerical_order: bookingChecked.numerical_order ? bookingChecked.numerical_order : '',
                            payment_method: 1,
                            tmp_id: (new Date()).toString()
                          });
                          setBookingsChecked(_bookingsChecked);
                        }}
                      >
                        Thêm DV
                      </Button>
                    </Form.Item>
                    {bookingChecked.status?.id !== 6 && bookingChecked.status?.id !== 7 ?
                      <>
                        <Form.Item label="." className="form-item-action-remove">
                          <Popconfirm
                            placement="topRight"
                            title="Bạn có thực sự muốn xóa dịch vụ khám này?"
                            okText="Có"
                            cancelText="Không"
                            okType="danger"
                            onConfirm={() => onConfirmRemoveChecked(index)}
                          >
                            <MinusCircleOutlined />
                          </Popconfirm>
                        </Form.Item>
                      </> :
                      <>
                        <Form.Item label="." className="form-item-action-remove">
                          <CheckOutlined />
                        </Form.Item>
                      </>
                    }
                  </div>
                  {errorsChecked[index] && errorsChecked[index].length !== 0 ? (
                    <div className="show-error">
                      {errorsChecked[index].map((e, indexError) => (
                        <small className="error-item" key={indexError}>
                          <i>
                            {indexError !== 0 ? ', ' : ''} {e.text}
                          </i>
                        </small>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="main flex mt-2">
        <div className="main__left">
          <DragDropContext onDragEnd={onDragEnd}>
            {arrayRooms.map((room, index) => {
              let doctor = {};
              let _bookings = [];
              const doctorId = roomDoctor[room];
              const isCollapsed = roomCollapsed.includes(room);

              if (doctorId) {
                doctor = doctorById[doctorId] ? doctorById[doctorId] : {
                  id: -1,
                  name: 'Bác sĩ khác'
                };
                _bookings = bookingsByDoctorId(doctorId);
              }

              let doctorsAssigned = [];

              for (let rId in roomDoctor) {
                if (roomDoctor[rId] && arrayRooms.indexOf(rId) !== -1) {
                  doctorsAssigned.push(roomDoctor[rId]);
                }
              }
              const doctorsValid = doctors.filter(d => {
                if (!doctorsAssigned.includes(d.id) || d.id === doctor.id) {
                  return true;
                }
                return false;
              });

              return (
                <ListBookingLobby
                  key={room}
                  isActive={index === tabActive}
                  setIsActive={() => setTabActive(index)}
                  doctor={doctor}
                  bookings={_bookings || []}
                  doctors={doctorsValid}
                  room={room}
                  isCollapsed={isCollapsed}
                  onChangeRoom={onChangeDoctorRoom}
                  forceUpdate={forceUpdate}
                  onCollapsed={() => {
                    if (isCollapsed) {
                      setRoomCollapsed(roomCollapsed.filter(r => r !== room));
                    } else {
                      setRoomCollapsed(roomCollapsed.concat(room));
                    }
                  }}
                />
              );
            })}
          </DragDropContext>
        </div>
        {doctors.length !== 0 && (
          <div className="main__right">
            <div className="main__right-header flex">
              <img
                src={iconChart}
                style={{ marginRight: '15px' }}
                alt="icon-chart"
              />
              <div style={{ flex: 1 }}>
                <b style={{ fontSize: '18px' }}>
                  Phòng khám ({metricBookings()[0]} ca chờ)
                </b>
                <br />
                <div className="flex justify-content-between">
                  <div>{metricBookings()[1]} ca 2D</div>
                  <div>{metricBookings()[2]} ca 5D</div>
                  <div>{metricBookings()[3]} ca DV khác</div>
                </div>
              </div>
            </div>
            <div className="metrics">
              {doctors.map(doctor => {
                const _roomStatus = roomStatus(doctor.id);
                return (
                  <div key={doctor.id} className="item-metric">
                    <div className="flex justify-content-between">
                      <b className="doctor-name">{doctor.name}</b>
                      <div
                        className={classnames('doctor-status', {
                          'is-rest': _roomStatus.id === 3,
                          'is-empty': _roomStatus.id === 1,
                          'is-doing': _roomStatus.id === 2
                        })}
                      >
                        {_roomStatus.text}
                      </div>
                    </div>
                    <div className="item-metric-detail">
                      <span>
                        Tổng số ca chờ: {metricBookings(doctor.id)[0]} ca
                      </span>
                      <br />
                      <span>
                        Chờ khám: {metricBookings(doctor.id)[1]} ca 2D,{' '}
                        {metricBookings(doctor.id)[2]} ca 5D,{' '}
                        {metricBookings(doctor.id)[3]} ca khác
                      </span>
                      <br />
                      <span>Đã khám: {metricBookings(doctor.id)[4]} ca</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LobbyPage;
