import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, notification, Switch, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { editExaminationForm, deleteExaminationForm } from 'reducers/examinationForm';
import serviceExaminationForm from 'services/examinationForm';

function DataTableExaminationForm({ dataSource, setWorkingShift }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuth = useSelector(state => state.authorization.user);
  const roleUserAuth = useMemo(() => {
    if (!userAuth || !userAuth.roles) return '';
    const role = userAuth.roles[0] || {};

    return role;
  }, [userAuth]);

  const onChange = (examinationFormId, status) => {
    const examinationForm = { id: examinationFormId, status }
    serviceExaminationForm.editExaminationForm(examinationForm).then(rsp => {
      dispatch(editExaminationForm(rsp.data));
      notification.success({
        message: 'Cập nhật trạng thái thành công.'
      });
    }).catch(err => {
      if (err.response.data) {
        notification.error({
          message: err.response.data.meta.message
        });
      } else {
        notification.error({
          message: 'Không thể cập nhật trạng thái lúc này, vui lòng thử lại sau!'
        });
      }
    });
  }

  const onConfirmDelete = examinationFormId => {

    serviceExaminationForm.deleteExaminationForm(examinationFormId).then(rsp => {
      dispatch(deleteExaminationForm(examinationFormId));
      notification.success({
        message: 'Xóa mẫu phiếu kết quả thành công'
      });
    }).catch(err => {
      if (err.response.data) {
        notification.error({
          message: err.response.data.meta.message
        });
      } else {
        notification.error({
          message: 'Không thể xóa mẫu phiếu kết quả lúc này, vui lòng thử lại sau!'
        });
      }
    });
  };

  const columns = [
    {
      key: 'index',
      title: 'STT',
      dataIndex: 'index',
      render: (r, object, index) => {
        return index + 1;
      }
    },
    {
      key: 'title',
      title: 'Mẫu phiếu kết quả',
      dataIndex: 'title'
    },
    {
      key: 'description',
      title: 'Mô tả',
      dataIndex: 'description'
    },
    {
      key: 'status',
      title: 'Trạng thái',
      render: (r, object) => {
        return <Switch
          defaultChecked={object.status}
          onChange={checked => onChange(object.id, checked)}
          checkedChildren="Hiển thị"
          unCheckedChildren="Ẩn"
        />
      }
    },
    {
      key: 'action',
      render: (r, object) => {
        let isAllowEdit = roleUserAuth.name === 'clinic_admin' || roleUserAuth.name === 'clinic_manager';
        return (
          isAllowEdit ?
            <>
              <Tooltip
                title="Copy mẫu phiếu kết quả"
                onClick={() => history.push(`/mau-phieu-ket-qua/tao-moi?copy_id=${object.id}`)}
              >
                <CopyOutlined />
              </Tooltip>
              <EditOutlined
                className="pointer ml-1"
                onClick={() => history.push(`/mau-phieu-ket-qua/${object.id}`)}
              />
              <Popconfirm
                placement="topLeft"
                title="Xác nhận xóa mẫu phiếu kết quả này?"
                okText="Xóa"
                cancelText="Hủy"
                okType="danger"
                onConfirm={() => onConfirmDelete(object.id)}
              >
                <DeleteOutlined className="ml-1" />
              </Popconfirm>
            </>
            :
            <></>
        );
      }
    }
  ];
  return <Table columns={columns} dataSource={dataSource.map(d => ({ ...d, key: d.id }))} pagination={false} />;
}

export default DataTableExaminationForm;
