import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import { DataTableService } from 'containers/datatable';
import { FormService } from 'containers/form';

import styles from './index.module.scss';

function ServicePage() {
  const services = useSelector(state =>
    state.service.ids
      .filter(id => state.service.byId[id])
      .map(id => state.service.byId[id])
  );

  const [visibleModal, setVisibleModal] = useState(false);
  const [service, setService] = useState(null);

  const openModal = () => {
    setService(null);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setService(null);
  };

  const handleSetService = _service => {
    setService(_service);
    setVisibleModal(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between mb-2">
        <h1>Quản lý dịch vụ</h1>
        <Button type="primary" onClick={openModal}>
          Thêm mới
        </Button>
      </div>
      <div className="main">
        <DataTableService dataSource={services} setService={handleSetService} />
      </div>
      <Modal
        title={service ? 'Cập nhật dịch vụ' : 'Thêm mới dịch vụ'}
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
      >
        <FormService service={service} onClose={closeModal} />
      </Modal>
    </div>
  );
}

export default ServicePage;
