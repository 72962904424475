import React, { useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import dcmImg1 from 'assets/images/documents/document-1.jpg';

import styles from './index.module.scss';

function DocumentPage() {
  useEffect(() => {
    document.title = 'Hướng dẫn sử dụng'
  }, []);
  const videos = [
    {
      title: 'Hướng dẫn cài đặt lịch làm việc bác sĩ',
      link: 'https://www.youtube.com/watch?v=p7v4Vx8TEqE',
      thumbnail: dcmImg1
    }
  ];
  const style = { background: '#0092ff', padding: '8px 0' };

  return (
    <div className={styles.wrapper}>

      <div className="main">
        <div className="header flex justify-content-between mb-2">
          <div className="left flex">
            <a href="https://www.youtube.com/channel/UCxvwLx54gm-aowByyskdajQ" target="_blank" style={{ marginRight: '15px' }}>
              <Button type="primary">
                VIDEO HƯỚNG DẪN SỬ DỤNG
              </Button>
            </a>
            <a href="https://drive.google.com/file/d/12I8c9soBIPxvBdo20IJc1nbqAi4cphKk/view?usp=sharing" target="_blank">
              <Button type="primary">
                TÀI LIỆU HƯỚNG DẪN SỬ DỤNG
              </Button>
            </a>
          </div>
          <div className="right flex">
            <a href="tel:02473045689">
              <Button type="primary">
                Hotline: 0247 304 5689
              </Button>
            </a>
          </div>
        </div>
        <Row gutter={16}>
          {
            videos.map(video => (
              <Col className="gutter-row" span={8}>
                <a href={video.link} target="_blank">
                  <img src={video.thumbnail} />
                  <h4>{video.title}</h4>
                </a>
              </Col>
            ))
          }
        </Row>

      </div>
    </div>
  );
}

export default DocumentPage;
