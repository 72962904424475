import React, { useEffect, useState } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import { formatPriceVND } from 'utils';
import { Redirect } from 'react-router-dom';
import { filZeroNumber } from 'utils/__function';

import styles from './index.module.scss';

function PrintOrderPage() {
  const clinicInfo = JSON.parse(localStorage.getItem('clinicInfo'));
  const serviceById = JSON.parse(localStorage.getItem('serviceById'));
  const servicePrintByGroup = JSON.parse(localStorage.getItem('servicePrintByGroup'));
  const [bookingsForm, setBookingsForm] = useState([]);
  const [bookingPrintOrder, setBookingPrintOrder] = useState({});
  const [numericalOrder, setNumericalOrder] = useState();

  let title = 'Phiếu đăng ký khám thai sản ';
  let isNotService = true;
  let bookingsNotService = [];
  if (bookingPrintOrder) {
    if (servicePrintByGroup.indexOf(bookingPrintOrder.service_id) === -1) {
      title = 'Phiếu đăng ký dịch vụ';
      isNotService = false;
    } else {
      bookingsNotService = bookingsForm.filter(b => servicePrintByGroup.indexOf(b.service_id) !== -1);
    }
  }

  useEffect(() => {
    try {
      const _bookingPrintOrder = JSON.parse(
        localStorage.getItem('bookingPrintOrder')
      );
      setNumericalOrder(_bookingPrintOrder.numerical_order);
      setBookingPrintOrder(_bookingPrintOrder);

      const _bookingsFormPrintOrder = JSON.parse(
        localStorage.getItem('bookingsFormPrintOrder')
      );
      setBookingsForm(_bookingsFormPrintOrder);
    } catch (error) {
      setBookingPrintOrder(null);
    }

    return () => {
      localStorage.setItem('bookingPrintOrder', '');
    };
  }, []);

  const dateTime = React.useMemo(() => {
    const now = new Date();
    const _y = now.getFullYear();
    const _d = filZeroNumber(now.getDate());
    const _m = filZeroNumber(now.getMonth() + 1);
    return `Ngày ${_d} tháng ${_m} năm ${_y}`;
  }, []);

  if (!bookingPrintOrder) {
    return <Redirect to="/lich-hen" />;
  }

  const getPrice = (id) => {
    let b = bookingsNotService.find(b => b.service_id === id);
    return b && b.price ? b.price : 0;
  }

  const getTotalNotServicePrice = () => {
    return bookingsNotService.reduce((a, b) => a + b.price, 0)
  }

  return (
    <div className={styles.wrapper}>
      <div className="header-print flex justify-content-between">
        <div className="left">
          <b>Xem trước bản in</b>
        </div>
        <div className="right">
          <button onClick={() => {
            window.print()
          }} className="pointer">
            <PrinterOutlined /> In phiếu khám
          </button>
        </div>
      </div>
      <div id="inner" className={isNotService ? 'not-service' : ''}>
        <table>
          <tbody>
            <tr>
              <td colSpan="4" className="td-clinic-name">
                {bookingPrintOrder.clinic_name}
              </td>
            </tr>
            <tr>
              <td colSpan="4" className="td-title">
                {title}
              </td>
            </tr>
            <tr>
              <td className="numerical-order" style={{ textAlign: 'center' }}>
                <b>{numericalOrder}</b>
              </td>
              <td colSpan={3} className="date-time">
                {dateTime}
              </td>
            </tr>
            <tr>
              <td className="td-first">Họ tên:</td>
              <td className="td-name">{bookingPrintOrder.user_name}</td>
              <td className="td-second" style={{ width: '75px' }}>Năm sinh:</td>
              <td className="td-third">{bookingPrintOrder.birthday}</td>
            </tr>
            <tr>
              <td className="td-first">SĐT:</td>
              <td>{bookingPrintOrder.phone_number}</td>
              <td className="td-second">Tuần thai:</td>
              <td className="td-third">{bookingPrintOrder.weeks}</td>
            </tr>
            {isNotService ? (<>
              <tr>
                <td className="td-first text-center" colSpan="3">Loại</td>
                <td className="td-second">Thành tiền</td>
              </tr>
              {servicePrintByGroup.map((id, idx) => (
                <tr>
                  <td className="td-first text-right">{idx + 1}</td>
                  <td className="td-second" colSpan="2">{serviceById[id].name}</td>
                  <td className="td-third text-right">
                    {formatPriceVND(getPrice(id)) + ' VND'}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="td-first text-center" colSpan="3">TỔNG TIỀN DỊCH VỤ KHÁM THAI</td>
                <td className="text-right">
                  {formatPriceVND(getTotalNotServicePrice()) + ' VND'}
                </td>
              </tr>
            </>) : (<>
              <tr>
                <td className="td-first">Dịch vụ:</td>
                <td colSpan="3">
                  {(bookingPrintOrder.service_names || []).map(
                    (serviceName, index) => (
                      <span key={index + 1}>{serviceName}</span>
                    )
                  )}
                </td>
              </tr>
              <tr>
                <td className="td-first" style={{ width: '82px' }}>Thành tiền:</td>
                <td colSpan="3">
                  {bookingPrintOrder.price
                    ? formatPriceVND(bookingPrintOrder.price) + ' VND'
                    : ''}
                </td>
              </tr></>)
            }
            <tr>
              <td>Ghi chú:</td>
              <td colSpan={3}>{bookingPrintOrder.note || ''}</td>
            </tr>
            <tr>
              <td colSpan="4" className="td-wifi">
                {clinicInfo.wifi_info || ''}
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="td-note">
                {clinicInfo.note_print_order || ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PrintOrderPage;
