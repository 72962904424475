import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { DataTableExaminationForm } from 'containers/datatable';
import useExitPrompt from 'hooks/useExitPrompt';

import styles from './index.module.scss';

function ExaminationForm() {
  const history = useHistory();
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  useEffect(() => {
    setShowExitPrompt(false)
  }, [])

  const examinationForms = useSelector(state =>
    state.examinationForm.ids
      .filter(id => state.examinationForm.byId[id])
      .map(id => state.examinationForm.byId[id])
  );

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between mb-2">
        <h1 style={{ marginBottom: 0 }}>Danh sách mẫu phiếu kết quả</h1>
        <Button type="primary" onClick={() => history.push('/mau-phieu-ket-qua/tao-moi')}>
          Thêm mới
        </Button>
      </div>
      <div className="main examination-form-table">
        <DataTableExaminationForm
          dataSource={examinationForms}
        />
      </div>
    </div>
  );
}

export default ExaminationForm;
