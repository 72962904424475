import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Form, Input, Modal, Select, Tooltip, notification, Row, Col, InputNumber
} from 'antd';
import {
  CloseOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import serviceInputForm from 'services/inputForm';
import { addInputForm } from 'reducers/inputForm';

import styles from './FormExaminationForm.module.scss';

const { Option } = Select;
const { TextArea } = Input;

const config = {
  height: '842px',
  with: '595px',
  toolbar: {
    items: [
      'sourceEditing',
      '|',
      'fontFamily',
      'fontSize',
      'fontColor',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'highlight',
      '|',
      'alignment',
      '|',
      'outdent',
      'indent',
      'bulletedList',
      'numberedList',
      '|',
      'insertTable',
      'horizontalLine',
      '|',
      'undo',
      'redo'
    ]
  },
  fontSize: {
    options: [
      9,
      11,
      13,
      'default',
      16,
      17,
      19,
      21,
      23,
      25,
      27,
      29
    ]
  },
  alignment: {
    options: ['left', 'center', 'right', 'justify']
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3' }
    ]
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
    tableProperties: {
      defaultProperties: {
        width: '100%',
      }
    }
  },
};

export default ({
  onSubmit,
  examinationForm = {},
  submitText,
  loading
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editor, setEditor] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectInputMode, setSelectInputMode] = useState(true);
  const [inputSelected, setInputSelected] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [positionEditor, setPositionEditor] = useState();
  const [loadingInput, setLoadingInput] = useState(false);
  const [inputType, setInputType] = useState('string');
  const [inputSize, setInputSize] = useState('small');
  const [form] = Form.useForm();

  const [examinationFormInputs, setExaminationFormInputs] = useState(examinationForm.inputs || []);
  const [formContent, setFormContent] = useState();

  useEffect(() => {
    let _content = (examinationForm.content || '').replace(/<style [^>]+>(.*?)<\/style>/g, '');
    const inputs = examinationForm.inputs;
    const regex = /\{\{.*?\}\}/gim;
    const matchs = _content.match(regex);
    if (matchs && inputs) {
      matchs.forEach((match) => {
        const input = inputs.find(i => `{{${i.code}}}` === match)
        if (input) {
          _content = _content.replaceAll(match, `<mark class="marker-green">${match}</mark>`)
        } else {
          console.log(match);
          _content = _content.replaceAll(match, `<mark class="marker-yellow">${match}</mark>`)
        }
      })
    }
    setFormContent(_content)
    if (editor) {
      editor.model.change(writer => {
        const viewFragment = editor.data.processor.toView(_content);
        const modelFragment = editor.data.toModel(viewFragment);
        editor.model.insertContent(modelFragment);
      });
    }
  }, [examinationForm, editor])


  const inputForms = useSelector(state =>
    state.inputForm.ids
      .filter(id => state.inputForm.byId[id])
      .map(id => state.inputForm.byId[id])
  );

  const formatNode = (node) => {
    return node.data;
  };

  const getCKEditor = (event, editor) => {
    let content = editor.getData();
    setFormContent(content)
  }

  const onFinish = (values) => {
    values.content = formContent;
    values.inputs = examinationFormInputs;
    onSubmit(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInputSelect = (value) => {
    form.setFieldsValue({ id: value });
  }

  const onTypeSelect = (value) => {
    setInputType(value);
  }

  const onSizeSelect = (value) => {
    setInputSize(value)
    if (value === 'small') {
      form.setFieldsValue({ number_row: 3 });
    } else if (value === 'medium') {
      form.setFieldsValue({ number_row: 5 });
    } else if (value === 'large') {
      form.setFieldsValue({ number_row: 7 });
    }
  }

  const onInputChange = (event) => {
    form.setFieldsValue({ id: event.target.value });
  }

  const handleInputFormSave = async (values) => {
    let _examinationFormInputs = [...examinationFormInputs]
    const id = values.id;
    let inputForm;
    if (Number.isInteger(id)) {
      inputForm = inputForms.find(i => i.id === values.id);
    } else {
      setLoadingInput(true)
      let inputData = {
        name: values.id
      }
      try {
        const rsp = await serviceInputForm.add(inputData);
        inputForm = {
          ...rsp.data,
          value: values.default_value,
          type: values.type,
          size: values.size,
          number_row: values.number_row
        }
        dispatch(addInputForm(inputForm))
      } catch (err) {
        console.log(err);
        notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại.', placement: 'bottomRight' });
      } finally {
        setLoadingInput(false)
      }

    }
    if (inputForm) {
      if (inputSelected.id) {
        const idx = _examinationFormInputs.findIndex(i => i.id === inputSelected.id)
        _examinationFormInputs[idx] = {
          ..._examinationFormInputs[idx],
          value: values.default_value,
          size: values.size,
          type: values.type,
          number_row: values.number_row
        }
      } else {
        editor.model.change(writer => {
          const content = `<mark class="marker-green">{{${inputForm.code}}}</mark>`;
          const viewFragment = editor.data.processor.toView(content);
          const modelFragment = editor.data.toModel(viewFragment);
          editor.model.insertContent(modelFragment, positionEditor);
        });
        const _input = {
          ...inputForm,
          value: values.default_value,
          size: values.size,
          type: values.type,
          number_row: values.number_row
        }
        _examinationFormInputs = [...examinationFormInputs, _input]
      }
    }
    setExaminationFormInputs(_examinationFormInputs)

    setInputSelected({})
    let content = editor.getData();
    setFormContent(content)
    setVisibleModal(false)
  };

  const handleShowSelectInputs = () => {
    setSelectInputMode(true)
    setInputSelected({})

    const selection = editor.model.document.selection,
      position = selection.getFirstPosition(),
      range = selection.getFirstRange();

    setPositionEditor(position)

    let textSelected = [...range.getItems()]
      .filter(node => node.data)
      .map(formatNode)
      .join('\n').replace(/<[^>]*>?/gm, '').trim();

    if (textSelected) {
      const input = examinationFormInputs.find(i => `{{${i.code}}}` === textSelected)
      if (input) {
        form.setFieldsValue({
          id: input.id,
          default_value: input.value,
          size: input.size || 'small',
          type: input.type,
          number_row: input.number_row || 3
        })
        setInputSelected(input);
        setInputType(input.type);
        setInputSize(input.size);
        if (input.type === 'text') {
          if (input.size === 'small') {
            form.setFieldsValue({ number_row: 3 });
          } else if (input.size === 'medium') {
            form.setFieldsValue({ number_row: 5 });
          } else if (input.size === 'large') {
            form.setFieldsValue({ number_row: 7 });
          }
        }
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
      form.setFieldsValue({ size: 'small', type: 'string', number_row: 3 });
    }
    setVisibleModal(true)
  }

  return (
    <div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          title: examinationForm.title || '',
          description: examinationForm.description || '',
          content: examinationForm.content || '',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[{ required: true, message: 'Tiêu đề không được để trống' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mô tả"
          name="description"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Nội dung"
          name="content"
        >
          <div className={styles.wrapper}>

            {showInput ?
              <Button
                type="primary"
                onClick={handleShowSelectInputs}
                className="mb-2"
              >
                Chọn Input
              </Button>
              : <></>
            }

            <CKEditor
              editor={Editor}
              config={config}
              data={formContent}
              onReady={editor => {
                setEditor(editor)
              }}
              onChange={(event, editor) => getCKEditor(event, editor)}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                setShowInput(true)
                getCKEditor(event, editor)
              }}
            />
          </div>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {submitText}
          </Button>
          <Button type="default" onClick={() => history.push('/mau-phieu-ket-qua')} className="ml-2">
            Hủy
          </Button>
        </Form.Item>
      </Form>

      <Modal
        width="800px"
        title="Chọn input"
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
      >
        <Form
          name="basic"
          layout="vertical"
          form={form}
          onFinish={handleInputFormSave}
          className={styles.wrapper}
        >
          <Form.Item
            className="mb-0"
            label="Tên"
            name="id"
            rules={[{ required: true, message: 'Tên không được để trống' }]}
          >
            <Input.Group>
              {!selectInputMode ?
                <Input style={{ width: '90%' }} name="id" placeholder="Nhập tên" onChange={onInputChange} /> :
                <Select
                  name="id"
                  style={{ width: '90%' }}
                  showSearch={true}
                  optionFilterProp="name"
                  placeholder="Chọn input"
                  onChange={onInputSelect}
                  value={inputSelected.id}
                >
                  {inputForms.map(inputForm => (
                    <Option key={inputForm.code} value={inputForm.id} name={inputForm.name}>
                      {inputForm.name}
                    </Option>
                  ))}
                </Select>}

              <Tooltip title={selectInputMode ? 'Tạo mới' : 'Hủy'}>
                <Button type="primary"
                  style={{ width: '10%' }}
                  onClick={() => {
                    setSelectInputMode(!selectInputMode)
                    form.setFieldsValue({ id: null });
                  }}
                >
                  {selectInputMode ? <PlusOutlined /> : <CloseOutlined />}
                </Button>
              </Tooltip>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label="Giá trị mặc định"
            name="default_value"
            value={inputSelected.value}
          >
            <TextArea rows={3} />
          </Form.Item>

          <Row>
            <Col span={8} className="pr-2">
              <Form.Item
                label="Kiểu dữ liệu"
                name="type"
              >
                <Select onChange={onTypeSelect}>
                  {/* <Option value="int">
                  Kiểu số
                </Option> */}
                  <Option value="string">
                    Kiểu chữ
                  </Option>
                  <Option value="text">
                    Kiểu văn bản
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} className="pr-2">
              <Form.Item
                label="Kích thước ô nhập liệu"
                name="size"
                value={inputSelected.size}
              >
                <Select onChange={onSizeSelect}>
                  <Option value="small">
                    Nhỏ
                  </Option>
                  <Option value="medium">
                    Trung bình
                  </Option>
                  <Option value="large">
                    Lớn
                  </Option>
                  {inputType == 'text' &&
                    <Option value="custom">
                      Tùy chọn
                    </Option>
                  }
                </Select>
              </Form.Item>

            </Col>
            <Col span={8} className="pr-2">
              {inputType == 'text' ?
                <Form.Item
                  label="Số dòng"
                  name="number_row"
                  value={inputSelected.value}
                >
                  <InputNumber disabled={inputSize !== 'custom'} min={1} />
                </Form.Item>
                : <></>}
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loadingInput}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
