import http from 'utils/http';

export default {
  getExaminationForms: (params = {}) =>
    http.get(`/examination-forms`, {
      params: {
        ...params,
        per_page: 9999,
        include: 'inputs'
      }
    }),
  addExaminationForm: (examinationForm) => {
    return http.post(`/examination-forms?include=inputs`, examinationForm);
  },
  editExaminationForm: (examinationForm) => {
    const _examinationForm = { ...examinationForm };
    delete _examinationForm.id;

    return http.put(`/examination-forms/${examinationForm.id}?include=inputs`, _examinationForm);
  },
  deleteExaminationForm: (examinationFormId) =>
    http.delete(`/examination-forms/${examinationFormId}`)
};
