import React, { useCallback, useEffect, useState } from 'react';

import http from 'utils/http';

import { SkeletonCustomerBookings } from 'containers/skeleton';

import styles from './index.module.scss';
import { DataTableCustomerBookings } from 'containers/datatable';

function CustomerDetail({ customer }) {
  const [bookings, setBookings] = useState(null);

  const getDetail = useCallback(async () => {
    let _bookings = [];
    try {
      const response = await http.get(`/customers/${customer.id}/bookings`, {
        params: {
          include: 'result'
        }
      });
      _bookings = response.data;
    } catch (error) {
      console.error(error);
    }
    setBookings(_bookings);
  }, [customer.id]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  return (
    <div className={styles.wrapper}>
      <div className="info flex">
        <div className="left flex-1">
          <table>
            <tbody>
              <tr>
                <td>Tên khách hàng: </td>
                <td>
                  <b>{customer.name}</b>
                </td>
              </tr>
              <tr>
                <td>Tuổi: </td>
                <td>
                  <b>
                    {customer.birthday_year
                      ? `${customer.birthday_year} tuổi`
                      : ''}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Tuần thai: </td>
                <td>
                  <b>
                    {customer.gestational_week
                      ? `${customer.gestational_week} tuần`
                      : ''}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="right flex-1">
          <table>
            <tbody>
              <tr>
                <td>Điện thoại: </td>
                <td>
                  <b>{customer.phone_number}</b>
                </td>
              </tr>
              <tr>
                <td>Địa chỉ: </td>
                <td>
                  <b>{customer.address}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-2">
        {bookings ? (
          <DataTableCustomerBookings dataSource={bookings} />
        ) : (
          <SkeletonCustomerBookings />
        )}
      </div>
    </div>
  );
}

export default CustomerDetail;
