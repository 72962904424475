import http from 'utils/http';

export default {
  getWorkingShifts: (params = {}) =>
    http.get(`/working-shifts`, {
      params: {
        ...params,
        per_page: 9999
      }
    }),
  addWorkingShift: (workingShift) => {
    return http.post(`/working-shifts`, workingShift);
  },
  editWorkingShift: (workingShift) => {
    const _workingShift = { ...workingShift };
    delete _workingShift.id;

    return http.put(`/working-shifts/${workingShift.id}`, _workingShift);
  },
  changeStatusWorkingShift: (workingShiftId, status) => {
    return http.put(`/working-shifts/${workingShiftId}/status`, { status });
  },
  deleteWorkingShift: (workingShiftId) =>
    http.delete(`/working-shifts/${workingShiftId}`)
};
