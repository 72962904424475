import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import serviceCustomer from 'services/customer';
import {
  Form,
  Button,
  Input,
  Select,
  InputNumber,
  Col,
  Row,
  DatePicker,
  notification
} from 'antd';

import { isPhoneNumber, formatPriceVND } from 'utils';
import { PlusSquareOutlined, DeleteOutlined } from '@ant-design/icons';
import { arrayStatus, dateFormat, defaultDate } from 'utils/__variable';
import { database } from 'configs/firebase';
import { filZeroNumber, getStepTime, copyToClipboard, getWorkingShifts } from 'utils/__function';

import styles from './FormBooking.module.scss';

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const validateMessages = {
  required: 'Không được để trống!',
  types: {
    number: 'Không hợp lệ'
  },
  number: {
    range: 'Không hợp lệ',
    min: 'Không hợp lệ'
  }
};

const disabledDate = current => current && current < moment().startOf('day');

const now = new Date();
const _d = filZeroNumber(now.getDate());
const _m = filZeroNumber(now.getMonth() + 1);
const _y = now.getFullYear();

export const convertDate = _date => {
  const _y = _date.getFullYear();
  const _d = filZeroNumber(_date.getDate());
  const _m = filZeroNumber(_date.getMonth() + 1);

  return `${_d}-${_m}-${_y}`;
};

export const convertDateYMD = _date => {
  const _y = _date.getFullYear();
  const _d = filZeroNumber(_date.getDate());
  const _m = filZeroNumber(_date.getMonth() + 1);

  return `${_y}-${_m}-${_d}`;
};

export default ({
  onSubmit,
  booking = {},
  submitText,
  loading,
  onCancel,
  defaultDateParams,
  doctorsByDate
}) => {
  const isEdit = !!booking.id;
  const serviceById = useSelector(state => state.service.byId);
  const clinic = useSelector(state => state.workspace.currentClinic);

  const [form] = Form.useForm();
  const [dateTimeValue, setDateTimeValue] = useState('08:00');
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [dateDayValue, setDateDayValue] = useState(moment());
  const [resultText, setResultText] = useState(null);
  const [doctorsOption, setDoctorsOption] = React.useState([]);
  const [workingShifts, setWorkingShifts] = React.useState([]);

  const isTodayMemo = useMemo(() => {
    return convertDate(new Date(dateDayValue)) === defaultDate;
  }, [dateDayValue]);

  const [services, setServices] = useState(booking.services || [
    {
      service_id: booking.service_id || '',
      doctor_id: booking.doctor_id || '',
      price: booking.price || ''
    }
  ]);

  useEffect(() => {
    if (defaultDateParams) {
      setDateDayValue(defaultDateParams);
    }
  }, [defaultDateParams]);

  useEffect(() => {
    if (booking.date_time) {
      setDateTimeValue(booking.date_time);
    }
    if (booking.date_day) {
      setDateDayValue(moment(booking.date_day, dateFormat));
    }
    if (booking.payment_method) {
      setPaymentMethod(booking.payment_method);
    }
  }, [booking]);

  const [doctorRoom, setDoctorRoom] = React.useState({});

  const doctorRoomRef = React.useMemo(
    () => database.ref(`doctor_room/${clinic.id}`),
    [clinic.id]
  );

  const updateDoctorRoom = _doctorRoom => {
    setDoctorRoom(_doctorRoom);
  };

  React.useEffect(() => {
    if (doctorRoomRef) {
      doctorRoomRef.on('value', snapshot => {
        let _doctorRoom = {};
        for (let key in snapshot.val()) {
          const item = snapshot.val()[key];
          _doctorRoom[item.doctor_id] = {
            room: item.room,
            key
          };
        }
        updateDoctorRoom(_doctorRoom);
      });
    }
  }, [doctorRoomRef]);

  const servicesOption = useSelector(state =>
    state.service.ids
      .filter(id => state.service.byId[id])
      .map(id => state.service.byId[id])
  );

  const _workingShifts = useSelector(state =>
    state.workspace.currentWokingShift.ids
      .filter(id => state.workspace.currentWokingShift.byId[id])
      .map(id => state.workspace.currentWokingShift.byId[id])
  );

  useMemo(async () => {
    const rsp = getWorkingShifts(_workingShifts, new Date(dateDayValue));
    const workingShiftIds = rsp.workingShifts.map(ws => ws.id)
    setWorkingShifts(rsp.workingShifts);

    const _doctorsByDate = { ...doctorsByDate }
    const dateSelected = convertDateYMD(new Date(dateDayValue));
    let doctors = _doctorsByDate[dateSelected] ? _doctorsByDate[dateSelected] : [];
    doctors = doctors.filter(d => workingShiftIds.includes(d.shift_id))
    setDoctorsOption(doctors);

    const doctorIds = doctors.map(d => d.id);
    let _services = [...services];
    _services.forEach((s, idx) => {
      if (s.doctor_id && !doctorIds.includes(s.doctor_id)) {
        _services[idx]._doctor_id = _services[idx].doctor_id;
        _services[idx].doctor_id = null;
      } else if (s._doctor_id) {
        _services[idx].doctor_id = _services[idx]._doctor_id;
      }
    })
    setServices(_services)
  }, [dateDayValue]);

  const payment = [
    { name: 'Tiền mặt', id: 1 },
    { name: 'Chuyển khoản', id: 2 }
  ];
  const filterServicesOption = (indexService, value) => {
    if (value < 5) {
      let indexSA = null;

      for (let i = 0; i < services.length; i++) {
        const s = services[i];
        if (s.service_id && s.service_id < 5) {
          indexSA = i;
          break;
        }
      }

      if (indexSA !== null && indexSA !== indexService) {
        return false;
      }
      return true;
    }
    return true;
  };
  // console.log(paymentMethod);
  const onFinish = values => {
    let formData = {
      user_name: values.user_name,
      phone_number: values.phone_number,
      date_day: convertDate(new Date(dateDayValue)),
      date_time: dateTimeValue,
      payment_method: paymentMethod,
      note: values.note
    };

    const isToday = formData.date_day === defaultDate;

    if (values.birthday) {
      formData.birthday = Number(values.birthday);
    }

    if (values.mother_weight) {
      formData.mother_weight = Number(values.mother_weight);
    }

    if (values.weeks) {
      formData.weeks = Number(values.weeks);
    }

    if (values.phone_number[0] !== '+') {
      formData.phone_number =
        '+84' + values.phone_number.substr(1, values.phone_number.length);
    }

    formData.services = services.map(service => {
      let result = {
        booking_id: service.booking_id
      };

      if (service.service_id) {
        result.service_id = service.service_id;
      }
      if (service.doctor_id) {
        result.doctor_id = service.doctor_id;
      }
      if (service.price || service.price === 0) {
        result.price = service.price;
      }
      return result;
    });

    if (isEdit) {
      delete formData.priority;
      if (booking.checked_time) {
        formData.checked_time = booking.checked_time;
      }

      formData.id = booking.id;
      formData.key = booking.key;
      onSubmit(formData, isToday);
    } else {
      formData.status = arrayStatus[0];
      const callback = numerical_order => {
        setResultText({
          user_name: formData.user_name,
          numerical_order,
          date_time: formData.date_time,
          servicesName: formData.services.map(s => {
            if (s.service_id) {
              return (serviceById[s.service_id] || {}).name || 'Chưa xác định';
            }
            return 'Chưa xác định';
          }),
          address: clinic.address,
          contact: formData.phone_number
        });
      };
      onSubmit(formData, isToday, callback);
    }
  };

  const handleEnterPhoneNumber = async (target) => {
    let phone = target.value;
    if (isPhoneNumber(phone)) {
      phone = '+84' + phone.substr(1, phone.length)
      const response = await serviceCustomer.searchByPhone({ phone_number: phone, include: 'babies' });
      if (response.meta.code === 200 && response.data.length) {
        const customer = response.data[0]
        form.setFieldsValue({
          user_name: customer.name || null,
          birthday: customer.date_of_birth ? Number(customer.date_of_birth.split('-')[0]) : null,
          weeks: customer.babies ? Number(customer.babies[0].gestational_week) : null,
        })
      }
    }
  }

  const handleChangeService = (key, value, index) => {
    let _services = [...services];
    const objectChange = {
      [key]: value
    };

    if (key === 'doctor_id') {
      if (doctorRoom[value] && doctorRoom[value].room) {
        objectChange.room_id = doctorRoom[value].room;
      }
    }

    if (key === 'service_id') {
      objectChange.price = serviceById[value].price;
      if ((_services[index] || {}).error === 'Giá dịch vụ không hợp lệ') {
        objectChange.error = '';
      }
    }

    _services[index] = {
      ..._services[index],
      ...objectChange
    };

    setServices(_services);
  };
  const handleChangePayment = event => {
    setPaymentMethod(event);
  };

  const isValidTime = _time => {
    const _dateDayValue = dateDayValue.startOf('day');
    const _toDayValue = moment().startOf('day');

    if (_dateDayValue > _toDayValue) return true;
    else if (_dateDayValue < _toDayValue) return false;

    return new Date(`${_y}-${_m}-${_d} ${_time}`) > new Date();
  };
  
  const dateTimeOptions = useMemo(() => {
    let _dateTimeOptions = [];
    if (!workingShifts.length) return _dateTimeOptions;

    for (let workingShift of workingShifts) {
      const duration_kip_work = 10; // workingShift.duration_kip_work
      let steps = getStepTime(workingShift.fromText, workingShift.endText, duration_kip_work);
      steps = steps.filter(step => isValidTime(step.value));
      if (steps.length !== 0) {
        _dateTimeOptions.push({ key: workingShift.id, label: workingShift.name, value: steps });
      }
    }

    if (!_dateTimeOptions.length && isTodayMemo) {
      const latestWorkingShift = workingShifts[workingShifts.length - 1];
      let steps = getStepTime(latestWorkingShift.fromText, latestWorkingShift.endText, latestWorkingShift.duration_kip_work);
      const totalSteps = steps.length;
      let lastTime = steps[totalSteps - 1];
      setDateTimeValue(lastTime.value);
      _dateTimeOptions.push({ key: latestWorkingShift.id, label: latestWorkingShift.name, value: [lastTime] });
    }

    const listValue = _dateTimeOptions[0]?.value || [];

    const firstOption = listValue[0];

    if (!booking.date_time) {
      setDateTimeValue(firstOption.value);
    } else {
      let listStepsValid = [];
      for (let shiftSteps of _dateTimeOptions) {
        listStepsValid = [...listStepsValid, ...shiftSteps.value];
      }
      let arStepValid = listStepsValid.map(o => {
        return o.value;
      });
      if (!arStepValid.includes(booking.date_time)) {
        setDateTimeValue(firstOption.value);
      }
    }
    return _dateTimeOptions;
  }, [
    workingShifts,
    booking.date_time,
    dateDayValue,
    isTodayMemo
  ]);

  const handleCopyToClipboard = () => {
    const text = document.getElementById('result-content');

    copyToClipboard(text.innerText);

    setResultText(null);

    onCancel();

    notification.success({ message: 'Thêm lịch hẹn khám thành công' });
  };

  const dateDay = React.useMemo(() => {
    const arrayDays = [
      'chủ nhật',
      'thứ 2',
      'thứ 3',
      'thứ 4',
      'thứ 5',
      'thứ 6',
      'thứ 7'
    ];
    const now = new Date(dateDayValue);
    const day = now.getDay();
    const _y = now.getFullYear();
    const _d = filZeroNumber(now.getDate());
    const _m = filZeroNumber(now.getMonth() + 1);
    return `${arrayDays[day]} ngày ${_d}/${_m}/${_y}`;
  }, [dateDayValue]);
  const resultTextContent = resultText ? (
    <>
      <div className="wrapper-result-content">
        <div id="result-content">
          <div>Phòng khám {clinic.name} xác nhận :</div>
          <div>
            1. Tên khách hàng: <b>{resultText.user_name}</b>
          </div>
          <div>
            2. Số điện thoại: <b>{resultText.contact}</b>
          </div>
          <div>
            3. Mã khách hàng: <b>{resultText.numerical_order}</b>
          </div>
          <div>
            4. Giờ đặt hẹn:{' '}
            <b>
              {resultText.date_time}, {dateDay}
            </b>
          </div>
          {/* <div>
            Dịch vụ: <b>{resultText.servicesName.join(', ')}</b>
          </div>
          <div>Địa chỉ: {clinic.address}</div>
          <div>
            Liên hệ: <b>{clinic.contact}</b>
          </div> */}
          <div>
            Quý khách vui lòng qua phòng khám theo khung giờ đã hẹn, khi đến Quý
            khách đọc mã khách hàng ở trên với nhân viên lễ tân để được hướng
            dẫn.
            <br />
            {clinic.name} trân trọng cảm ơn.
          </div>
        </div>
      </div>
      <div className="group-action mt-2">
        <Button
          className="submit-button"
          type="primary"
          onClick={handleCopyToClipboard}
        >
          Copy và thoát
        </Button>
      </div>
    </>
  ) : null;

  const formContent = (
    <>
      <Row gutter={8}>
        <Col span={6}>
          <div className="input-phone-number">
            <Form.Item
              label="Số điện thoại"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: 'Chưa nhập SDT'
                },
                {
                  validator: async (rule, value) => {
                    if (value && !isPhoneNumber(value)) {
                      throw new Error('SDT không hợp lệ!');
                    }
                  }
                }
              ]}
              validateTrigger={['onBlur']}
            >
              <Input style={{ width: '100% ' }} maxLength={10} onChange={event => {
                handleEnterPhoneNumber(event.target);
              }} />
            </Form.Item>
          </div>
        </Col>
        <Col span={6}>
          <div xs={24} lg={8} className="input-user-name">
            <Form.Item
              label="Họ tên"
              name="user_name"
              rules={[{ required: true, message: 'Chưa nhập tên' }]}
            >
              <Input />
            </Form.Item>
          </div>
        </Col>

        <Col span={4} className="input-birthday">
          <Form.Item
            label="Năm sinh"
            name="birthday"
            rules={[{ type: 'number', min: 1950, max: 2010 }]}
            validateTrigger={['onBlur']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={4} className="input-weeks">
          <Form.Item
            label="Tuần thai"
            name="weeks"
            rules={[{ type: 'number', min: 1, max: 50 }]}
            validateTrigger={['onBlur']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Cân nặng"
            name="mother_weight"
            rules={[{ type: 'number', min: 0 }]}
            validateTrigger={['onBlur']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      {services.map((s, index) => (
        <Row key={index} gutter={8}>
          <Col span={6}>
            <Form.Item
              label={
                index !== 0 ? null : (
                  <span>
                    Dịch vụ{' '}
                    {
                      <PlusSquareOutlined
                        className="pointer"
                        onClick={() => {
                          setServices([
                            ...services,
                            {
                              service_id: '',
                              doctor_id: '',
                              price: ''
                            }
                          ]);
                        }}
                      />
                    }
                  </span>
                )
              }
            >
              <Select
                value={s.service_id}
                style={{ width: '100%' }}
                onChange={value => {
                  handleChangeService('service_id', value, index);
                }}
              >
                {servicesOption
                  .filter(s => filterServicesOption(index, s.id))
                  .map(service => (
                    <Option
                      key={service.id}
                      value={service.id}
                      disabled={services.some(s => s.service_id === service.id)}
                    >
                      {service.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={index !== 0 ? null : 'Bác sĩ chỉ định'}>
              <Select
                value={s.doctor_id}
                style={{ width: '100%' }}
                onChange={event => {
                  handleChangeService('doctor_id', event, index);
                }}
                allowClear={true}
              >
                {doctorsOption.map(doctor => (
                  <Option key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={index !== 0 ? null : 'Giá dịch vụ'}
              validateStatus={s.errorPrice ? 'error' : ''}
              help={s.errorPrice || null}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={value => formatPriceVND(value)}
                value={s.price}
                onBlur={() => {
                  if (s.price || s.price === 0) {
                    if (s.price > 0) {
                      handleChangeService('errorPrice', '', index);
                    } else {
                      handleChangeService('errorPrice', 'Không hợp lệ', index);
                    }
                  }
                }}
                onChange={value => {
                  handleChangeService('price', value, index);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={services.length > 1 ? 7 : 8}>
            <Form.Item label={index !== 0 ? null : 'Thanh toán'}>
              <Select
                value={
                  paymentMethod === 1
                    ? 'Tiền mặt'
                    : 'Chuyển khoản'
                }
                style={{ width: '100%' }}
                onChange={handleChangePayment}
              >
                {payment.map(payment => (
                  <Option key={payment.id} value={payment.id}>
                    {payment.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {index !== 0 && !s.booking_id && (
            <Col span={1}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  setServices(services.filter((s, i) => i !== index));
                }}
              >
                <DeleteOutlined style={{ marginLeft: '-6px' }} />
              </Button>
            </Col>
          )}
        </Row>
      ))}
      <Form.Item label="Ghi chú" name="note" className="input-note">
        <TextArea />
      </Form.Item>
      <div className="group-action">
        <Button className="cancel-button" onClick={onCancel}>
          Quay lại
        </Button>
        <Button
          className="submit-button"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {submitText}
        </Button>
      </div>
    </>
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        user_name: booking.user_name || null,
        phone_number: booking.phone_number
          ? booking.phone_number.replace('+84', '0')
          : null,
        birthday:
          booking.birthday && booking.birthday !== '0'
            ? Number(booking.birthday)
            : null,
        weeks: booking.weeks ? Number(booking.weeks) : null,
        mother_weight: booking.mother_weight
          ? Number(booking.mother_weight)
          : null,
        note: booking.note || null
      }}
      className={styles.container}
      validateMessages={validateMessages}
    >
      <div className="wrapper-title">
        <span className="title-text">
          {isEdit ? 'Cập nhật lịch khám' : 'Thêm lịch khám'}
        </span>
        <DatePicker
          disabled={resultText}
          disabledDate={disabledDate}
          className="ml-2 picker-date-day"
          placeholder="Chọn ngày"
          format={dateFormat}
          value={dateDayValue}
          allowClear={false}
          onChange={setDateDayValue}
        />
        <Select
          disabled={resultText}
          className="ml-2 select-date-time"
          showSearch={true}
          placeholder="Chọn giờ"
          value={dateTimeValue || '08:00'}
          onChange={value => setDateTimeValue(value)}
        >
          {dateTimeOptions.map(dateTimeItem => (
            <OptGroup label={dateTimeItem.label} key={dateTimeItem.key}>
              {dateTimeItem.value.map(_itemTime => (
                <Option key={_itemTime.fromTime} value={_itemTime.value}>
                  {_itemTime.title}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </div>
      {resultText ? resultTextContent : formContent}
    </Form>
  );
};
