import React from 'react';
import { Popconfirm, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteWorkingShift } from 'reducers/workspace';
import serviceWorkingShift from 'services/workingShift';
import { getWorkingShiftsByDay } from 'utils/__function';
import styles from './index.module.scss';

function DataTableWorkShift({ dataSource, setWorkingShift }) {
  const dispatch = useDispatch();
  let workingShiftsByDay = getWorkingShiftsByDay(dataSource)

  const onConfirmDeleteWorkingShift = workingShiftId => {
    
    serviceWorkingShift.deleteWorkingShift(workingShiftId).then(rsp => {
      dispatch(deleteWorkingShift(workingShiftId));
      notification.success({
        message: 'Xóa cakip thành công'
      });
    }).catch(err => {
      if (err.response.data) {
        notification.error({
          message: err.response.data.meta.message
        });
      } else {
        notification.error({
          message: 'Không thể xóa cakip lúc này, vui lòng thử lại sau!'
        });
      }
    });
  };
  return (
    <div className={styles.wrapper}>
      <div className="main-title">
        <div className="title-item">
          <div className="title-item-skips">
            {workingShiftsByDay.map(itemSkip => (
              <div className="skip-item skip-item-bg" key={itemSkip.key}>
                <span className="title-skip">
                  <b>{itemSkip.title}</b>
                </span>
                {itemSkip.workingShifts.map(workingShift => (
                  <div className="working-shift-item" key={workingShift.id * Math.random()}>
                    <div className="working-shift-title">{workingShift.name}: {workingShift.start_time} - {workingShift.end_time}</div>
                    <div className="working-shift-time">
                      {workingShift.duration_kip_work} phút mỗi kip
                    </div>
                    <div className="working-shift-actions">
                      <EditOutlined
                        className="pointer"
                        onClick={() => setWorkingShift(workingShift)}
                      />
                      <Popconfirm
                        placement="topLeft"
                        title="Xác nhận xóa cakip này?"
                        okText="Xóa"
                        cancelText="Hủy"
                        okType="danger"
                        onConfirm={() => onConfirmDeleteWorkingShift(workingShift.id)}
                      >
                        <DeleteOutlined className="ml-1" />
                      </Popconfirm>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>);
}

export default DataTableWorkShift;
