import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrinterOutlined, InboxOutlined, UploadOutlined, LinkOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import http from 'utils/http';
import S3 from 'aws-s3';
import { database } from 'configs/firebase';
import { Button, Upload, Tooltip, message, notification } from "antd";
import { getFormContentToPrint } from 'utils/__function';

import styles from './index.module.scss';

const { Dragger } = Upload;

const now = new Date();
const month = now.getMonth() + 1;
let timeoutTooltip;

function PrintResultPage() {
  const location = useLocation();
  const [bookingPrintResult, setBookingPrintResult] = useState({});
  const [formId, setFormId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showUploadZone, setShowUploadZone] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [bookingId, setBookingId] = useState(null);
  const [resultUuid, setResultUuid] = React.useState('');
  const [visibleTooltip, setVisibleTooltip] = React.useState(null);

  const [formContent, setFormContent] = useState('');

  const currentClinic = useSelector(state => state.workspace.currentClinic);
  let clinicId;
  if (currentClinic) {
    clinicId = currentClinic.id;
  } else {
    clinicId = localStorage.getItem('clinic_id');
  }

  /**
   * Config AWSS3
   */
  let dirNameAWSS3 = null;
  let tryWaitCurrentClinic = setInterval(() => {
    if (clinicId) {
      dirNameAWSS3 = `results/${clinicId}/${now.getFullYear()}/${month}`;
      clearInterval(tryWaitCurrentClinic);
    }
  }, 200);
  const config = {
    bucketName: '2baby',
    dirName: dirNameAWSS3 /* optional */,
    region: 'ap-southeast-1',
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  };
  const S3Client = new S3(config);

  /**
   * Config dragger
   */
  const draggerProps = {
    accept: 'image/*',
    name: 'file',
    multiple: true,
    listType: "picture",
    defaultFileList: [...uploadedImages],
    openFileDialogOnClick: true,
    beforeUpload: file => {
      const imageType = /image.*/;
      if (!file.type.match(imageType)) {
        message.error(`Vui lòng chỉ tải lên hình ảnh.`);
      }
      return file.type.match(imageType) ? true : false;
    },
    customRequest: async (options) => {
      setLoading(true)
      const { onSuccess, onError, file, onProgress } = options;
      const response = await S3Client.uploadFile(file);
      if (response && response.location) {
        file.url = response.location;
        onSuccess(response.location);
      } else {
        const error = new Error("Some error");
        onError({ error });
      }

      return response && response.location ? response.location : '';
    },
    onChange(info) {
      const { status } = info.file;
      const allDone = info.fileList.every(f => f.status === 'done' || f.status === 'error')
      if (allDone) {
        setLoading(false)
      }
      if (status === 'done') {
        setUploadedImages(info.fileList.slice())
        message.success(`${info.file.name} ảnh được tải lên thành công.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} ảnh được tải lên thất bại.`);
      }
    },
    onDrop(e) { },
    onRemove(file) {
      let _uploadedImages = [...uploadedImages];
      _uploadedImages = _uploadedImages.filter(i => i.uid !== file.uid);
      setUploadedImages(_uploadedImages)
    }
  };

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    setFormId(params.form_id);
  }, [location.search]);

  useEffect(() => {
    if (formId) {
      try {
        const _bookingPrintResult = JSON.parse(
          localStorage.getItem(`bookingPrintResult_${formId}`)
        );
        setBookingPrintResult(_bookingPrintResult);

        if (_bookingPrintResult.result.images_url_ultrasound) {
          let _uploadedImages = _bookingPrintResult.result.images_url_ultrasound;
          setUploadedImages(_uploadedImages)
        }
      } catch (error) {
        console.log(error);
        setBookingPrintResult(null);
      }
    }
  }, [formId]);

  useEffect(() => {
    if (bookingPrintResult.id) {
      let result = bookingPrintResult.result;
      const optionForm = bookingPrintResult.optionForm;
      let _formContent = getFormContentToPrint(result, optionForm.content, optionForm.inputs, bookingPrintResult.selectedImages)
      setFormContent(_formContent);
    }
  }, [bookingPrintResult]);

  if (!bookingPrintResult) {
    window.location.href = '/danh-sach-kham';
  }

  const handlePrint = () => {
    setBookingId(bookingPrintResult.id)
    setShowUploadZone(false)
    setLoading(false)
  };

  useEffect(() => {
    if (showUploadZone === false) {
      try {
        if (clinicId) {
          window.onafterprint = () => {
            setShowUploadZone(true)
            if (bookingPrintResult.key) {
              const bookingsRef = database.ref(
                `bookings/${clinicId}/${bookingPrintResult.key}`
              );
              bookingsRef.update({
                status: { id: 6, name: 'Khám xong' },
                priority: 99999
              });
            }
          };
        }
        window.print();
      } catch (error) {
        console.log(error);
      }
    }
  }, [showUploadZone])

  const copyToClipboard = (text) => {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);

    return result;
  }

  const updateImages = () => {
    let _uploadedImages = [...uploadedImages];
    setLoading(true)
    const images_url_ultrasound = _uploadedImages.map(i => i.url || i.response)
    http.put(`/bookings/${bookingId}/result`, {
      images_url_ultrasound
    }).then(response => {
      setResultUuid(response.data.uuid)
      if (bookingPrintResult.key) {
        const bookingsRef = database.ref(
          `bookings/${clinicId}/${bookingPrintResult.key}`
        );
        bookingsRef.update({
          result: {
            ...bookingPrintResult.result,
            uuid: response.data.uuid
          }
        });
      }
      notification.success({ message: 'Cập nhật hình ảnh thành công', placement: 'bottomRight' });
    }).catch((err) => {
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại.', placement: 'bottomRight' });
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className="header-print flex justify-content-between">
        <div className="left">
          <b>Xem trước bản in</b>
        </div>
        <div className="right">
          {!resultUuid ? (<></>) : (
            <Tooltip
              title="Đã copy"
              trigger={['click']}
              visible={visibleTooltip === resultUuid}
            >
              <Button
                style={{ marginRight: '5px' }}
                onClick={() => {
                  setVisibleTooltip(resultUuid);

                  copyToClipboard(
                    `${process.env.REACT_APP_RESULT_URL}/timelines/${resultUuid}`
                  );

                  if (timeoutTooltip) {
                    clearTimeout(timeoutTooltip);
                  }

                  timeoutTooltip = setTimeout(() => {
                    setVisibleTooltip('');
                  }, 750);
                }}
              >
                <LinkOutlined /> Link kết quả
              </Button>
            </Tooltip>
          )}
          <Button
            type="primary"
            onClick={handlePrint}
          >
            <PrinterOutlined /> In kết quả
          </Button>
        </div>
      </div>

      <div className="content-preview" id="content-preview">
        {showUploadZone ? (
          <div className="upload-list-inline">
            <div className="user-fino">
              <table>
                <tbody>
                  <tr>
                    <td colSpan="4" className="td-clinic-name">
                      {bookingPrintResult.clinic_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="numerical-order" style={{ textAlign: 'center' }}>
                      <b>{bookingPrintResult.numerical_order}</b>
                    </td>
                    <td colSpan={3} className="date-time">
                      {bookingPrintResult.checked_time}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-first">Họ tên:</td>
                    <td className="td-name">{bookingPrintResult.result.ho_ten}</td>
                    <td className="td-second" style={{ width: '75px' }}>Năm sinh:</td>
                    <td className="td-third">{bookingPrintResult.result.nam_sinh}</td>
                  </tr>
                  <tr>
                    <td className="td-first">SĐT:</td>
                    <td>{bookingPrintResult.phone_number}</td>
                    <td className="td-second">Tuần thai:</td>
                    <td className="td-third">{bookingPrintResult.result.tuan_thai}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text"><b>Kéo thả ảnh hoặc click vào đây để tải ảnh lên</b></p>
            </Dragger>
            <Button
              className="update-images"
              type="primary"
              loading={loading}
              onClick={updateImages}
            >
              <UploadOutlined /> Cập nhật hình ảnh
            </Button>
          </div>
        ) :
          <div dangerouslySetInnerHTML={{ __html: formContent }} id="form-inputs" className="content-form is-preview"></div>
        }
      </div>
    </div>
  );
}

export default PrintResultPage;
