import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import classnames from 'classnames';
import { Button, Radio } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import ImagesPreview from 'components/ImagesPreview';
import TabsImage from 'components/Form/FormUploadResult/TabsImage/TabsImage';
import { defaultDateWithTime } from 'utils/__variable';
import { getEducation, resultToObjectFields } from 'utils/__function';
import PrintResultPage from '../../../../pages/print-result';

import styles from '../index.module.scss';

function FormResult3({
  booking,
  isPreview,
  result = {},
  defaultSelectedImages = [],
  S3Client,
  handleClickButton,
  handleFinishedExamination
}) {
  const formId = 3;
  const _defaultFormValue = {
    form_name: 'Siêu âm thai 2D',
    user_name: booking.user_name,
    birthday: booking.birthday,
    heart_rate: '   chu kỳ/phút',
    tu_the: 'ngả trước',
    kich_thuoc: 'lớn hơn bình thường',
    cau_truc: 'mật độ cơ tử cung đồng nhất, không có khối.',
    tui_noan: '____ túi noãn hoàng (+)',
    ctc: '+',
    boc_tach: 'không thấy hình ảnh bóc tách dưới túi thai',
    phan_phu: '- Buồng trứng phải: không có khối\n- Buồng trứng trái: không có khối\n- Douglas: không có dịch',
    note: '1'
  }

  const [form, setForm] = useState(_defaultFormValue);
  const [selectedImages, setSelectedImages] = useState(defaultSelectedImages);
  const [enableInnerPrintf, setEnableInnerPrintf] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddSelectedImages = imageSrc => {
    if (Array.isArray(imageSrc)) {
      setSelectedImages(imageSrc)
    } else {
      if (selectedImages.includes(imageSrc)) {
        setSelectedImages(selectedImages.filter(src => src !== imageSrc));
      } else {
        setSelectedImages([imageSrc, selectedImages[0] || '']);
      }
    }
    setEnableInnerPrintf(false);
  };

  const handleRemoveSelectedImages = imageSrc => {
    setSelectedImages(selectedImages.filter(src => src.id && src.id !== imageSrc.id));
    setEnableInnerPrintf(false);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setForm({
      ...form,
      [id]: value
    });
    setEnableInnerPrintf(false);
  };

  useEffect(() => {
    const _result = { ...result };
    const _objectFields = resultToObjectFields(_result, _defaultFormValue);
    setForm({
      weeks: booking.weeks || '',
      days: booking.days || '',
      due_date: booking.due_date || '',

      ..._result,
      ..._objectFields
    });
  }, [result]);

  useEffect(() => {
    if (!isPreview) {
      let _conclude = `- ${form.slts || '01'
        } thai trong buồng tử cung tương đương ${form.weeks ||
        '___'} tuần, ${form.days || 0} ngày trong buồng tử cung.\n- Tim thai (${form.ctc || '+'}) ${form.heart_rate || ''}.\n- Chưa phát hiện bất thường, hẹn mẹ khám lại sau 3 tuần.`;
      if (form.note === '2') {
        _conclude = '';
      }
      setForm(o => ({
        ...o,
        conclude: _conclude
      }));
    }
  }, [form.slts, form.weeks, form.days, form.heart_rate, form.note, form.ctc]);

  const getPrintfForm = () => {
    return <PrintResultPage hiddenHeader={true} />;
  };

  const handleOnPrint = () => {
    handleClickButton(form, formId, booking, selectedImages)
    setTimeout(() => {
      setEnableInnerPrintf(true);
    }, 100);
  };

  const preHandleAddResult = () => {
    const { hasImagesUrlConclude } = handleClickButton(form, formId, booking, selectedImages, false);
    if (!hasImagesUrlConclude) {
      setLoading(true)
      setTimeout(() => {
        setEnableInnerPrintf(true);
      }, 100);
    } else {
      handleAddResult(true)
    }
  };

  const handleAddResult = (updateStatus = false) => {
    handleFinishedExamination(form, formId, booking, result, updateStatus);
  };

  return (
    <div className={styles.wrapper} id="form-unique">
      <Form className={classnames('content-form', { 'is-preview': isPreview })}>
        <h1 className="uppercase clinic-name-title">{booking.clinic_name}</h1>
        {isPreview ? (
          <h3 className="uppercase big-name-title">
            {form.form_name}
          </h3>
        ) : (
          <input
            type="text"
            value={form.form_name || ''}
            style={{ width: '100%' }}
            id="form_name"
            onChange={handleChange}
            className="uppercase form_name"
          />
        )}
        <div className="header flex jutify-content-between">
          <div className="left flex-1 uppercase">
            <p>
              <b>
                Họ tên:{' '}
                {
                  <input
                    type="text"
                    className="w-username_fill input-user-name uppercase"
                    value={form.user_name || ''}
                    id="user_name"
                    onChange={handleChange}
                  />
                }
              </b>
            </p>
            <p>
              <b>Chuẩn đoán LS: Kiểm tra thai</b>
            </p>
          </div>
          <div className="right flex-1 uppercase text-right">
            <b>
              Năm sinh:{' '}
              <input
                type="text"
                className="w-100px input-user-name uppercase"
                value={form.birthday || ''}
                id="birthday"
                onChange={handleChange}
              />
            </b>
          </div>
        </div>
        <ImagesPreview selectedImages={selectedImages} />
        <div>
          <b>TỬ CUNG</b>
          <p className="flex">
            <span style={{ whiteSpace: 'pre' }}>- Tư thế: </span>
            {isPreview ? (
              form.tu_the
            ) : (
              <input
                type="text"
                style={{ width: '100%' }}
                value={form.tu_the || ''}
                id="tu_the"
                onChange={handleChange}
              />
            )}
          </p>
          <p className="flex">
            <span style={{ whiteSpace: 'pre' }}>- Kích thước: </span>
            {isPreview ? (
              form.kich_thuoc
            ) : (
              <input
                type="text"
                value={form.kich_thuoc || ''}
                style={{ width: '100%' }}
                id="kich_thuoc"
                onChange={handleChange}
              />
            )}
          </p>
          <p className="flex">
            <span style={{ whiteSpace: 'pre' }}>- Cấu trúc: </span>
            {isPreview ? (
              form.cau_truc
            ) : (
              <input
                type="text"
                value={form.cau_truc || ''}
                style={{ width: '100%' }}
                id="cau_truc"
                onChange={handleChange}
              />
            )}
          </p>
          <div>
            - Lòng tử cung: có hình ảnh 01 túi thai,
            {isPreview ? (
              form.tui_noan
            ) : (
              <input
                type="text"
                value={form.tui_noan || ''}
                className="w-350px"
                id="tui_noan"
                onChange={handleChange}
              />
            )}
            {'  '}
            <ul>
              <li>
                Chiều dài dầu mông CRL:
                {isPreview ? (
                  form.femur_length || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    value={form.femur_length || ''}
                    id="femur_length"
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </li>
              <li>
                Tuổi thai ước tính:{' '}
                {isPreview ? (
                  form.weeks || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="weeks"
                    value={form.weeks || ''}
                    onChange={handleChange}
                  />
                )}
                {'   '}
                tuần,{' '}
                {isPreview ? (
                  form.days || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="days"
                    value={form.days || ''}
                    onChange={handleChange}
                  />
                )}
                {'   '}
                ngày
              </li>
              <li>
                Tim thai: (
                {isPreview ? (
                  <span>{form.ctc}</span>
                ) : (
                  <input
                    type="text"
                    id="ctc"
                    value={form.ctc || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                )
                {isPreview ? (
                  form.heart_rate || '____'
                ) : (
                  <input
                    type="text"
                    className="w-200px"
                    id="heart_rate"
                    value={form.heart_rate || ''}
                    onChange={handleChange}
                  />
                )}
              </li>
              <li>
                <p>
                  Dự kiến ngày sinh:{' '}
                  {isPreview ? (
                    form.due_date || '____'
                  ) : (
                    <input
                      type="text"
                      id="due_date"
                      className="w-100px"
                      value={form.due_date || ''}
                      onChange={handleChange}
                      placeholder="dd/mm/yyyy"
                    />
                  )}{' '}
                  ± 2 tuần
                </p>
              </li>
              <li>
                <div className="flex">
                  <span style={{ whiteSpace: 'pre' }}>Bóc tách: </span>
                  {isPreview ? (
                    form.boc_tach
                  ) : (
                    <input
                      type="text"
                      id="boc_tach"
                      style={{ width: '100%' }}
                      value={form.boc_tach || ''}
                      onChange={handleChange}
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <b>PHẦN PHỤ</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {form.phan_phu}
              </span>
            ) : (
              <textarea
                rows={3}
                id="phan_phu"
                value={form.phan_phu || ''}
                onChange={handleChange}
              />
            )}
          </div>
          <div>
            <p><b>Chi tiết khác:</b> </p>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>{form.chi_tiet_khac}</span>
            ) : (
              <textarea
                type="text"
                id="chi_tiet_khac"
                value={form.chi_tiet_khac || ''}
                style={{ width: '100%' }}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="mt-2 block-media hidden-print">
          <b>Hình ảnh siêu âm</b>
          <TabsImage
            S3Client={S3Client}
            handleOnChange={(key, value) => setForm({ ...form, [key]: value })}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </div>
        <div className="mt-2">
          <b className="uppercase">Kết luận</b>
          <div
            className="ml-2 hidden-print"
            style={{ display: 'inline-block' }}
          >
            <Radio.Group
              defaultValue="1"
              onChange={event => setForm({ ...form, note: event.target.value })}
              value={form.note}
            >
              <Radio value="1">Chưa phát hiện bất thường</Radio>
              <Radio value="2">Bất thường</Radio>
            </Radio.Group>
          </div>
          <div>
            {isPreview ? (
              <p style={{ whiteSpace: 'break-spaces' }}>
                {result.conclude}
              </p>
            ) : (
              <textarea
                rows={3}
                id="conclude"
                value={form.conclude}
                onChange={handleChange}
                onKeyDown={handleChange}
              />
            )}
          </div>
        </div>
        <div className="footer mt-2">
          <div className="footer-content">
            <p>Ngày {defaultDateWithTime}</p>
            <p className="uppercase">Bác sĩ siêu âm</p>
            <br />
            <br />
            {booking.doctor && (
              <div>
                <p className="uppercase">
                  <b>
                    {getEducation(booking.doctor)}{' '}
                    {booking.doctor.phone_number
                      ? `(${booking.doctor.phone_number.replace('+84', '0')})`
                      : ''}
                  </b>
                </p>
                {booking.doctor.email && (
                  <p>
                  Gmail: {booking.doctor.email}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </Form>
      {!isPreview && (
        <div className="wrapper-btn-print mt-2">
          <Button className="mr-2" onClick={preHandleAddResult} loading={loading}>
            Khám xong
          </Button>
          <Button type="primary" onClick={handleOnPrint}>
            <PrinterOutlined /> In kết quả
          </Button>
          <Button
            type="primary"
            onClick={handleAddResult}
            id="btn-auto-complete"
            style={{ display: 'none' }}
          ></Button>

        </div>
      )}
      <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
        {enableInnerPrintf ? getPrintfForm() : <></>}
      </div>
    </div>
  );
}

export default FormResult3;
