import React, { useMemo, useState, useEffect } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { getResultFromForm } from 'utils/__function';
import { database } from 'configs/firebase';
import http from 'utils/http';
import S3 from 'aws-s3';

import FormResult1 from './form-result-1';
import FormResult2 from './form-result-2';
import FormResult3 from './form-result-3';
import FormResult4 from './form-result-4';
import FormResult5 from './form-result-5';
import FormResult6 from './form-result-6';
import FormResult7 from './form-result-7';
import FormResult8 from './form-result-8';
import FormResult9 from './form-result-9';
import FormResult10 from './form-result-10';
import FormResult11 from './form-result-11';
import FormResult12 from './form-result-12';
import FormResult13 from './form-result-13';
import FormResult14 from './form-result-14';

const options = [
  {
    id: 1,
    title: '01. SA thai 5D 12 tuần',
    form: props => <FormResult1 {...props} />
  },
  {
    id: 2,
    title: '02. SA thai 5D lớn hơn 12 tuần',
    form: props => <FormResult2 {...props} />
  },
  {
    id: 3,
    title: '03. SA thai nhỏ hơn 12 tuần',
    form: props => <FormResult3 {...props} />
  },
  {
    id: 4,
    title: '04. SA song thai 5D 12 tuần',
    form: props => <FormResult4 {...props} />
  },
  {
    id: 5,
    title: '05. SA song thai 5D lớn hơn 12 tuần',
    form: props => <FormResult5 {...props} />
  },
  {
    id: 6,
    title: '06. SA song thai nhỏ hơn 12 tuần',
    form: props => <FormResult6 {...props} />
  },
  {
    id: 14,
    title: '07. SA song thai lớn hơn 12 tuần',
    form: props => <FormResult14 {...props} />
  },
  {
    id: 7,
    title: '08. SA thai thường màu',
    form: props => <FormResult9 {...props} />
  },
  {
    id: 8,
    title: '09. SA đầu dò âm đạo',
    form: props => <FormResult8 {...props} />
  },
  {
    id: 9,
    title: '10. SA bụng tổng quát',
    form: props => <FormResult7 {...props} />
  },
  {
    id: 10,
    title: '11. SA tinh hoàn',
    form: props => <FormResult10 {...props} />
  },
  {
    id: 11,
    title: '12. SA tuyến giáp',
    form: props => <FormResult11 {...props} />
  },
  {
    id: 12,
    title: '13. SA vú màu',
    form: props => <FormResult12 {...props} />
  },
  {
    id: 13,
    title: '14. Dịch vụ khác (tư vấn, hồ sơ...)',
    form: props => <FormResult13 {...props} />
  }
];

const { Option } = Select;
const now = new Date();
const month = now.getMonth() + 1;

function FormResultOld({ booking }) {
  const clinic = useSelector(state => state.workspace.currentClinic);
  const employeeById = useSelector(state => state.employee.byId);

  const currentClinic = useSelector(state => state.workspace.currentClinic);
  let dirNameAWSS3 = null;
  let tryWaitCurrentClinic = setInterval(() => {
    if (currentClinic) {
      dirNameAWSS3 = `results/${currentClinic.id
        }/${now.getFullYear()}/${month}`;
      clearInterval(tryWaitCurrentClinic);
    }
  }, 200);

  const config = {
    bucketName: '2baby',
    dirName: dirNameAWSS3 /* optional */,
    region: 'ap-southeast-1',
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  };
  const S3Client = new S3(config);

  const [weeks] = useState(booking.weeks || '');

  const [formId, setFormId] = useState(null);

  const serviceId = useMemo(() => {
    if (booking.service_ids && booking.service_ids[0]) {
      return Number(booking.service_ids[0]);
    }
    return '';
  }, [booking.service_ids]);

  useEffect(() => {
    if (serviceId > 4) {
      setFormId(13);
    } else {
      if (weeks) {
        let _formId = null;
        if (serviceId === 1) {
          if (weeks < 12) {
            _formId = 3;
          }
        } else if (serviceId === 2) {
          if (weeks < 12) {
            _formId = 6;
          }
        } else if (serviceId === 3) {
          if (weeks > 12) {
            _formId = 2;
          } else if (weeks === 12) {
            _formId = 1;
          }
        } else if (serviceId === 4) {
          if (weeks > 12) {
            _formId = 5;
          } else if (weeks === 12) {
            _formId = 4;
          }
        }

        if (_formId) setFormId(_formId);
      }
    }
  }, [serviceId, weeks]);

  useEffect(() => {
    if (booking.result && booking.result.form_id) {
      setFormId(booking.result.form_id);
    }
  }, [booking.result]);

  const handleClickButton = (form, formId, booking, selectedImages, goToPrintPage = true) => {

    if (goToPrintPage)
      window.open(`/in-ket-qua-cu?form_id=${formId}`, '_blank');
    
    // try {
    //   let bookingImagesUrlConclude = localStorage.getItem('bookingImagesUrlConclude');
    //   if (bookingImagesUrlConclude) {
    //     bookingImagesUrlConclude = JSON.parse(bookingImagesUrlConclude)
    //     if (bookingImagesUrlConclude[booking.id]) {
    //       return { hasImagesUrlConclude: true }
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    const resultForm = getResultFromForm(form, formId);
    localStorage.setItem(
      'bookingPrintResult',
      JSON.stringify({
        ...booking,
        result: resultForm,
        selectedImages,
        current_form_id: formId
      })
    );

    return { hasImagesUrlConclude: false }
  };

  const handleFinishedExamination = (form, formId, booking, result, updateStatus) => {
    const resultForm = getResultFromForm(form, formId, true);
    let body = {
      ...booking,
      ...resultForm,
      booking_key: booking.key
    };
    try {
      let bookingImagesUrlConclude = localStorage.getItem('bookingImagesUrlConclude');
      if (bookingImagesUrlConclude) {
        bookingImagesUrlConclude = JSON.parse(bookingImagesUrlConclude)
        if (bookingImagesUrlConclude[booking.id])
          body.images_url_conclude = [bookingImagesUrlConclude[booking.id]];
      }
    } catch (error) {
      console.log(error);
    }

    http
      .put(`/bookings/${booking.id}/upload-result`, body)
      .then(response => {
        if (booking.key) {
          const bookingsRef = database.ref(
            `bookings/${currentClinic.id}/${booking.key}`
          );
          const bodyUpdate = {
            result: {
              ...result,
              uuid: response.data.uuid
            }
          }
          if (updateStatus === true) {
            bodyUpdate.status = { id: 6, name: 'Khám xong' };
            bodyUpdate.priority = 99999;
          }
          bookingsRef.update(bodyUpdate);
        }
      });
  };

  const formResultMemo = useMemo(() => {
    let _formResultMemo = null;
    for (let optionForm of options) {
      if (optionForm.id === Number(formId)) {
        _formResultMemo = optionForm.form({
          booking: {
            ...booking,
            clinic_name: clinic.name,
            doctor: employeeById[booking.doctor_id] || {}
          },
          result: booking.result || {},
          currentClinic,
          S3Client,
          handleClickButton,
          handleFinishedExamination
        });
        break;
      }
    }
    return _formResultMemo;
  }, [formId, employeeById, booking, clinic.name]);

  return (
    <div>
      <Select
        className="custom-select"
        value={formId}
        onChange={setFormId}
        placeholder="Chọn mẫu kết quả cần nhập"
      >
        {options.map(option => (
          <Option key={option.id} value={option.id}>
            {option.title}
          </Option>
        ))}
      </Select>
      {formResultMemo}
    </div>
  );
}

export default FormResultOld;
