import React from 'react';
import { Skeleton } from 'components';

import styles from './index.module.scss';

function SkeletonCustomerBookings() {
  return (
    <div className={styles.wrapper}>
      <table>
        <thead>
          <tr>
            <th>
              <Skeleton.Text />
            </th>
            <th>
              <Skeleton.Text />
            </th>
            <th>
              <Skeleton.Text />
            </th>
            <th>
              <Skeleton.Text />
            </th>
            <th>
              <Skeleton.Text />
            </th>
            <th>
              <Skeleton.Text />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
            <td>
              <Skeleton.Text width={'50%'} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SkeletonCustomerBookings;
