import _DataTableEmployee from './datatable-employee';
import _DataTableService from './datatable-service';
import _DataTableCustomer from './datatable-customer';
import _DataTableCustomerBookings from './datatable-customer-bookings';
import _DataTableWorkingShift from './datatable-work-shift';
import _DataTableExaminationForm from './datatable-examination-form';

export const DataTableEmployee = _DataTableEmployee;
export const DataTableService = _DataTableService;
export const DataTableCustomer = _DataTableCustomer;
export const DataTableCustomerBookings = _DataTableCustomerBookings;
export const DataTableWorkingShift = _DataTableWorkingShift;
export const DataTableExaminationForm = _DataTableExaminationForm;
