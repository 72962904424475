import { parse } from 'query-string';

export const isPhoneNumber = value => {
  if (!value) return false;
  let _value = value.replace('+84', '0');

  return /((09|03|07|08|05)+([0-9]{8})\b)/g.test(_value);
};

export const isEmail = value =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

export const isYoutubeUrl = url => {
  const regex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/;
  if (url.match(regex)) {
    return url.match(regex)[1];
  }
  return false;
};

export const formatPriceVND = price =>
  price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const getMetaPayload = payload => {
  const page = parse(payload.query).page
    ? Number(parse(payload.query).page)
    : 1;
  const per_page = parse(payload.query).per_page
    ? Number(parse(payload.query).per_page)
    : 10;
  const total_pages =
    payload.total % per_page === 0
      ? payload.total / per_page
      : Math.floor(payload.total / per_page) + 1;
  return {
    page,
    total_pages
  };
};
