import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import classnames from 'classnames';
import { Button, Radio } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import ImagesPreview from 'components/ImagesPreview';
import TabsImage from 'components/Form/FormUploadResult/TabsImage/TabsImage';
import { defaultDateWithTime } from 'utils/__variable';
import { getEducation, resultToObjectFields } from 'utils/__function';
import PrintResultPage from '../../../../pages/print-result';

import styles from '../index.module.scss';

function FormResult8({
  booking,
  isPreview,
  result = {},
  defaultSelectedImages = [],
  S3Client,
  handleClickButton,
  handleFinishedExamination
}) {
  const formId = 8;
  const _defaultFormValue = {
    form_name: 'PHIẾU SIÊU ÂM ĐẦU DÒ ÂM ĐẠO',
    user_name: booking.user_name,
    birthday: booking.birthday,
    tu_the: 'ngả trước',
    kich_thuoc: 'bình thường',
    cau_truc: 'Mật độ cơ tử cung đồng nhất, không có khối',
    long_tu_cung: 'Không có vòng, không có thai, niêm mạc dày bình thường',
    buong_trung_phai: 'Kích thước bình thường, không có khối',
    buong_trung_trai: 'Kích thước bình thường, không có khối',
    tui_cung_douglas: 'Không có dịch',
    conclude: '- Siêu âm phụ khoa không thấy hình ảnh bất thường.',
    note: '1'
  }

  const [form, setForm] = useState(_defaultFormValue);
  const [selectedImages, setSelectedImages] = useState(defaultSelectedImages);
  const [enableInnerPrintf, setEnableInnerPrintf] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddSelectedImages = imageSrc => {
    if (Array.isArray(imageSrc)) {
      setSelectedImages(imageSrc)
    } else {
      if (selectedImages.includes(imageSrc)) {
        setSelectedImages(selectedImages.filter(src => src !== imageSrc));
      } else {
        setSelectedImages([imageSrc, selectedImages[0] || '']);
      }
    }
    setEnableInnerPrintf(false);
  };

  const handleRemoveSelectedImages = imageSrc => {
    setSelectedImages(selectedImages.filter(src => src.id && src.id !== imageSrc.id));
    setEnableInnerPrintf(false);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setForm({
      ...form,
      [id]: value
    });
    setEnableInnerPrintf(false);
  };

  useEffect(() => {
    const _result = { ...result };
    const _objectFields = resultToObjectFields(_result, _defaultFormValue);
    setForm({
      weeks: booking.weeks || '',
      days: booking.days || '',
      due_date: booking.due_date || '',

      ..._result,
      ..._objectFields
    });
  }, [result]);

  const getPrintfForm = () => {
    return <PrintResultPage hiddenHeader={true} />;
  };

  const handleOnPrint = () => {
    handleClickButton(form, formId, booking, selectedImages)
    setTimeout(() => {
      setEnableInnerPrintf(true);
    }, 100);
  };

  const preHandleAddResult = () => {
    const { hasImagesUrlConclude } = handleClickButton(form, formId, booking, selectedImages, false);
    if (!hasImagesUrlConclude) {
      setLoading(true)
      setTimeout(() => {
        setEnableInnerPrintf(true);
      }, 100);
    } else {
      handleAddResult(true)
    }
  };

  const handleAddResult = (updateStatus = false) => {
    handleFinishedExamination(form, formId, booking, result, updateStatus);
  };

  return (
    <div className={styles.wrapper} id="form-unique">
      <Form className={classnames('content-form', { 'is-preview': isPreview })}>
        <h1 className="uppercase clinic-name-title">{booking.clinic_name}</h1>
        {isPreview ? (
          <h3 className="uppercase big-name-title">
            {form.form_name}
          </h3>
        ) : (
          <input
            type="text"
            value={form.form_name || ''}
            style={{ width: '100%' }}
            id="form_name"
            onChange={handleChange}
            className="uppercase form_name"
          />
        )}
        <div className="header">
          <div className="flex jutify-content-between">
            <div className="flex-1 uppercase">
              <p>
                <b>
                  Họ tên:{' '}
                  {
                    <input
                      type="text"
                      className="w-username_fill input-user-name uppercase"
                      value={form.user_name || ''}
                      id="user_name"
                      onChange={handleChange}
                    />
                  }
                </b>
              </p>
            </div>
            <div className="uppercase text-right">
              <b>
                Năm sinh:{' '}
                <input
                  type="text"
                  className="w-50px input-user-name uppercase"
                  value={form.birthday || ''}
                  id="birthday"
                  onChange={handleChange}
                />
              </b>
            </div>
            <div className="uppercase text-right">
              <b>
                Giới tính:{' '}
                <input
                  type="text"
                  className="w-50px input-user-name uppercase"
                  value={form.gender || ''}
                  id="gender"
                  onChange={handleChange}
                />
              </b>
            </div>
          </div>
        </div>
        <ImagesPreview selectedImages={selectedImages} />
        {isPreview ? (<h3 className="uppercase text-center">Kết quả siêu âm</h3>) : (<></>)}
        <div>
          <b className="uppercase">1. Mô tả tổn thương</b>
          {isPreview ? (
            <div>
              <span style={{ whiteSpace: 'break-spaces' }}>{form.mo_ta_ton_thuong}</span>
            </div>
          ) : (
            <input
              style={{ width: '100%' }}
              id="mo_ta_ton_thuong"
              value={form.mo_ta_ton_thuong || ''}
              onChange={handleChange}
            />
          )}
        </div>
        <div className="mt-2">
          <b className="uppercase">2. Kết quả chi tiết</b>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Tử cung:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>{form.tu_cung}</span>
                  ) : (
                    <textarea
                      id="tu_cung"
                      value={form.tu_cung}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Tư thế:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.tu_the}
                    </span>
                  ) : (
                    <textarea
                      id="tu_the"
                      value={form.tu_the || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Kích thước:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.kich_thuoc}
                    </span>
                  ) : (
                    <textarea
                      id="kich_thuoc"
                      value={form.kich_thuoc || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Cấu trúc:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.cau_truc}
                    </span>
                  ) : (
                    <textarea
                      id="cau_truc"
                      value={form.cau_truc || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Lòng tử cung:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.long_tu_cung}
                    </span>
                  ) : (
                    <textarea
                      id="long_tu_cung"
                      value={form.long_tu_cung || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td colSpan={2}>
                  <b>Phần phụ</b>
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Buồng trứng phải:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.buong_trung_phai}
                    </span>
                  ) : (
                    <textarea
                      id="buong_trung_phai"
                      value={form.buong_trung_phai || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Buồng trứng trái:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.buong_trung_trai}
                    </span>
                  ) : (
                    <textarea
                      id="buong_trung_trai"
                      value={form.buong_trung_trai || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '150px' }}>Túi cùng Douglas:</td>
                <td>
                  {isPreview ? (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {form.tui_cung_douglas}
                    </span>
                  ) : (
                    <textarea
                      id="tui_cung_douglas"
                      value={form.tui_cung_douglas || ''}
                      onChange={handleChange}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <p><b>Chi tiết khác:</b> </p>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>{form.chi_tiet_khac}</span>
            ) : (
              <textarea
                type="text"
                id="chi_tiet_khac"
                value={form.chi_tiet_khac || ''}
                style={{ width: '100%' }}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="mt-2 block-media hidden-print">
          <b>Hình ảnh siêu âm</b>
          <TabsImage
            S3Client={S3Client}
            handleOnChange={(key, value) => setForm({ ...form, [key]: value })}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </div>
        <div className="mt-2">
          <b className="uppercase">3. Kết luận</b>
          <div>
            {isPreview ? (
              <p style={{ whiteSpace: 'break-spaces' }}>
                {result.conclude}
              </p>
            ) : (
              <textarea
                rows={2}
                id="conclude"
                value={form.conclude || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="footer mt-2">
          <div className="footer-content">
            <p>Ngày {defaultDateWithTime}</p>
            <p className="uppercase">Bác sĩ siêu âm</p>
            <br />
            <br />
            {booking.doctor && (
              <div>
                <p className="uppercase">
                  <b>
                    {getEducation(booking.doctor)}{' '}
                    {booking.doctor.phone_number
                      ? `(${booking.doctor.phone_number.replace('+84', '0')})`
                      : ''}
                  </b>
                </p>
                {booking.doctor.email && (
                  <p>
                  Gmail: {booking.doctor.email}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </Form>
      {!isPreview && (
        <div className="wrapper-btn-print mt-2">
          <Button className="mr-2" onClick={preHandleAddResult} loading={loading}>
            Khám xong
          </Button>
          <Button type="primary" onClick={handleOnPrint}>
            <PrinterOutlined /> In kết quả
          </Button>
          <Button
            type="primary"
            onClick={handleAddResult}
            id="btn-auto-complete"
            style={{ display: 'none' }}
          ></Button>

        </div>
      )}
      <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
        {enableInnerPrintf ? getPrintfForm() : <></>}
      </div>
    </div>
  );
}

export default FormResult8;
