import React from 'react';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function ButtonExportExcel({ data, merges, fileName = new Date().getTime() }) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportFile = () => {
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: 1 });
    if (merges) {
      ws['!merges'] = merges;
    }
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const _data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(_data, fileName + fileExtension);
  };

  return (
    <Button type="primary" onClick={exportFile}>
      <FileExcelOutlined />
      Xuất file Excel
    </Button>
  );
}

export default ButtonExportExcel;
