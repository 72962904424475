import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {Button, DatePicker} from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SkeletonReportByCalendar } from 'containers/skeleton';
import moment from 'moment';
import http from 'utils/http';
import { filZeroNumber } from 'utils/__function';

import styles from './index.module.scss';
import {FileExcelOutlined} from "@ant-design/icons";
import * as FileSaver from 'file-saver';

const options = {
  chart: {
    type: 'pie'
  },
  title: {
    text: null
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.percentage:.1f} %',
        distance: -50,
        filter: {
          property: 'percentage',
          operator: '>',
          value: 0
        }
      },
      showInLegend: true
    }
  }
};

const convertMomentDate = mommentDate => {
  if (!mommentDate) {
    return '';
  }
  const now = new Date(mommentDate._d);
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);

  return `${_y}-${_m}-${_d}`;
};

function ReportByCalander() {
  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  const getMetric = useCallback(async () => {
    let _dataSource = [];
    setLoading(true);
    try {
      const response = await http.get(`/reports/schedule`, {
        params: {
          from_date: convertMomentDate(fromDate),
          to_date: convertMomentDate(toDate)
        }
      });
      _dataSource = response.data;
    } catch (error) {
      console.error(error);
    }
    setDataSource(_dataSource);
    setLoading(false);
  }, [fromDate, toDate]);

  useEffect(() => {
    getMetric();
  }, [getMetric]);

  const summaryObject = useMemo(() => {
    if (!dataSource) return {};
    let _summaryObject = {
      total_new: 0,
      total_reexamination: 0,
      total_cancel_appointment: 0
    };

    for (let item of dataSource) {
      _summaryObject.total_new += item.total_new;
      _summaryObject.total_reexamination += item.total_reexamination;
      _summaryObject.total_cancel_appointment += item.total_cancel_appointment;
    }
    return _summaryObject;
  }, [dataSource]);

  const onChangeFromDate = _fromDate => {
    setFromDate(_fromDate);
  };

  const onChangeToDate = _toDate => {
    setToDate(_toDate);
  };

  const isDisabledDate = _momentTime => fromDate > _momentTime;

  const fileNameDownload = `Thống kê theo lịch khám từ ${convertMomentDate(
      fromDate
  )} đến ${convertMomentDate(toDate)}`;

  const handleDownloadFileExport = () => {
    setExporting(true)
    http
        .get('/exports/by-schedule', {
          params: {
            from_date: convertMomentDate(fromDate),
            to_date: convertMomentDate(toDate)
          },
          responseType: 'blob'
        })
        .then(response => {
          setExporting(false)
          FileSaver.saveAs(response, `${fileNameDownload}.xlsx`);
        });
  };

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between">
        <div className="left flex">
          <div className="from-date mr-1">
            <span className="mr-1">Từ ngày</span>
            <DatePicker
              allowClear={false}
              value={fromDate}
              format="DD-MM-YYYY"
              onChange={onChangeFromDate}
            />
          </div>
          <div className="to-date">
            <span className="mr-1">Đến ngày</span>
            <DatePicker
              allowClear={false}
              value={toDate}
              format="DD-MM-YYYY"
              onChange={onChangeToDate}
              disabledDate={isDisabledDate}
            />
          </div>
        </div>
        <div className="right">
          <Button type="primary" onClick={handleDownloadFileExport} loading={exporting}>
            <FileExcelOutlined /> Xuất file Excel
          </Button>
        </div>
      </div>
      {!loading ? (
        <div className="main-content">
          <div className="left">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Ngày</th>
                  <th>Ca khám mới</th>
                  <th>Ca tái khám</th>
                  <th>Ca hủy</th>
                  <th>Tổng</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}></td>
                  <td>{summaryObject.total_new}</td>
                  <td>{summaryObject.total_reexamination}</td>
                  <td>{summaryObject.total_cancel_appointment}</td>
                  <td>
                    {summaryObject.total_new +
                      summaryObject.total_reexamination +
                      summaryObject.total_cancel_appointment}
                  </td>
                </tr>
                {dataSource.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.date}</td>
                    <td>{item.total_new}</td>
                    <td>{item.total_reexamination}</td>
                    <td>{item.total_cancel_appointment}</td>
                    <td>
                      {item.total_new +
                        item.total_reexamination +
                        item.total_cancel_appointment}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="right">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                series: [
                  {
                    name: 'Tỷ lệ',
                    data: [
                      {
                        name: 'Ca khám mới',
                        y: summaryObject.total_new
                      },
                      {
                        name: 'Ca tái khám',
                        y: summaryObject.total_reexamination
                      },
                      {
                        name: 'Ca hủy',
                        y: summaryObject.total_cancel_appointment
                      }
                    ]
                  }
                ]
              }}
            />
          </div>
        </div>
      ) : (
        <SkeletonReportByCalendar />
      )}
    </div>
  );
}

export default ReportByCalander;
