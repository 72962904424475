export const compareTime = (obj1, obj2) => {
  const _obj1 = obj1.date_time ? obj1.date_time : '00:00';
  const _obj2 = obj2.date_time ? obj2.date_time : '00:00';

  if (_obj1 === _obj2) {
    return 0;
  }

  const time1 = Number(_obj1.split(':')[0] * 60) + Number(_obj1.split(':')[1]);
  const time2 = Number(_obj2.split(':')[0] * 60) + Number(_obj2.split(':')[1]);

  if (time1 > time2) {
    return 1;
  }
  return -1;
};

export const comparePriority = (obj1, obj2) => {
  const priority1 = obj1.priority || 9999;
  const priority2 = obj2.priority || 9999;

  if (priority1 === priority2) {
    return 0;
  }
  if (priority1 > priority2) {
    return 1;
  }
  return -1;
};

export const compareStatus = (obj1, obj2) => {
  const isValidStatus = status => status && status.id;
  const _obj1 = isValidStatus(obj1.status)
    ? {
      ...obj1.status,
      id: obj1.status.id !== 5 ? 1 : 2
    }
    : {
      id: 1
    };
  const _obj2 = isValidStatus(obj2.status)
    ? {
      ...obj2.status,
      id: obj2.status.id !== 5 ? 1 : 2
    }
    : {
      id: 1
    };

  if (_obj1.id > _obj2.id) {
    return 1;
  }
  if (_obj1.id < _obj2.id) {
    return -1;
  }
  return 0;
};

export const arrayNumberRoom = roomNumber => {
  const array = [];
  for (let i = 0; i < roomNumber; i++) {
    array.push(Number(i + 1));
  }
  array.push(-1);
  return array;
};

export const filZeroNumber = number => (number < 10 ? '0' + number : number);

export const convertMomentDate = mommentDate => {
  const now = new Date(mommentDate._d);
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);
  const _h = filZeroNumber(now.getHours());
  const _min = filZeroNumber(now.getMinutes());

  return `${_d}-${_m}-${_y} ${_h}:${_min}`;
};

export const convertDateStringLocal = dateString => {
  const [_d, _m, _y] = dateString.split('-');
  return `${_y}/${_m}/${_d}`;
};

export const transformObjectToArray = object => Object.values(object);

export const transformArrayToObject = array =>
  Object.entries(array).reduce((r, v) => {
    return {
      ...r,
      [v[0]]: v[1]
    };
  }, []);

export const getStepTime = (fromTime = '8:00', toTime = '23:00', step = 30) => {
  const now = new Date();
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);

  const startTime = new Date(`${_y}-${_m}-${_d} ${fromTime}`);
  const endTime = new Date(`${_y}-${_m}-${_d} ${toTime}`);

  let _titleSubTable = [];
  const getHourMinuteOfTime = timeStamp => {
    const _time = new Date(timeStamp);
    return `${filZeroNumber(_time.getHours())}:${filZeroNumber(
      _time.getMinutes()
    )}`;
  };
  const getHourMinuteOfTimeValue = timeStamp => {
    const _time = new Date(timeStamp);
    return `${filZeroNumber(_time.getHours())}:${filZeroNumber(
      _time.getMinutes()
    )}`;
  };
  for (let index = startTime.getTime(); index < endTime.getTime();) {
    const _step = step * 60 * 1000;
    _titleSubTable.push({
      title: `${getHourMinuteOfTime(index)}`,
      fromTime: index,
      toTime: index + _step,
      value: `${getHourMinuteOfTimeValue(index)}`
    });
    index += _step;
  }

  return _titleSubTable;
};

export const getTimeMoment = (_mTime, getSeconds = false) => {
  const mDate = new Date(_mTime._d);

  if (!mDate) return '';

  const minutes = filZeroNumber(mDate.getMinutes());
  const hours = filZeroNumber(mDate.getHours());

  return getSeconds ? `${hours}:${minutes}:00` : `${hours}:${minutes}`;
};

export const copyToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  document.body.appendChild(textarea);
  textarea.select();
  const result = document.execCommand('copy');
  document.body.removeChild(textarea);
  return result
}

export const getEducation = (doctor = {}) => {
  const educations = doctor.educations || [];
  const education = educations[0] || {};

  if (education.title) {
    return education.title + ' ' + doctor.name;
  }

  return doctor.name;
};

export const resultToObjectFields = (_result = {}, _default = {}) => {
  let _objectFields = {};
  if (_result.other_fields) {
    const arrayFields = _result.other_fields.split('&&');
    for (let item of arrayFields) {
      const _arr = item.split('=');
      const key = _arr[0];
      _arr.shift();
      const value = _arr.join('=');
      _objectFields[key] = value || '';
    }
  } else {
    _objectFields = _default;
  }
  return _objectFields;
};

export const birthdayYear2Age = (birthdayYear) => {
  birthdayYear = parseInt(birthdayYear);
  const today = new Date();
  const currentYear = today.getFullYear();
  return currentYear - birthdayYear;
}

export const age2BirthdayYear = (age) => {
  age = parseInt(age);
  const today = new Date();
  const currentYear = today.getFullYear();
  return currentYear - age;;
}

export const getResultFromForm = (form, form_id, result_to_upload = false) => {
  const mainFields = [
    'weeks',
    'femur_length',
    'heart_rate',
    'weight',
    'video_url',
    'images_url_conclude',
    'images_url_ultrasound',
    'images_url_examination',
    'conclude',
    'due_date',
    'note',
    'user_name',
    'birthday',
    'gender'
  ];
  let _form = Object.assign({}, form);
  let objectResult = {};
  let otherFields = '';

  for (let key in _form) {
    if (mainFields.includes(key)) {
      if (result_to_upload && (_form[key])) {
        if (key === 'heart_rate') {
          const regex = /\d+/g;
          const matches = _form[key].match(regex)
          if (matches && matches.length) {
            _form[key] = matches.length > 2 ? parseInt(matches[1]) : parseInt(matches[0])
          } else {
            _form[key] = null;
          }
        } else if (key === 'images_url_ultrasound') {
          _form[key] = _form[key].map(i => i.url || i)
        }
      }
      objectResult[key] = _form[key];
    } else {
      if (key === 'age' && _form[key]) {
        _form['birthday'] = age2BirthdayYear(_form[key]);
      }
      otherFields += `&&${key}=${_form[key]}`;
    }
  }

  return {
    note: '1',
    ...objectResult,
    other_fields: otherFields,
    form_id
  };
};

export const getResultExaminationForm = (form, result_to_upload = false) => {
  let _form = Object.assign({}, form);
  let otherFields = '';
  const matchKeys = {
    'tim_thai': 'heart_rate',
    'chieu_dai_xuong_dui': 'femur_length',
    'can_nang': 'weight',
    // 'nam_sinh': 'birthday',
    'tuan_thai': 'weeks',
    'ngay_thai': 'days'
  }

  for (let key in _form) {

    if (result_to_upload && (_form[key])) {
      if (matchKeys[key]) {
        const regex = /\d+/g;
        const matches = `${_form[key]}`.match(regex)
        let value;
        if (matches && matches.length) {
          value = matches.length > 2 ? parseInt(matches[1]) : parseInt(matches[0])
        } else {
          value = null;
        }
        _form[matchKeys[key]] = value;
      } else if (key === 'images_url_ultrasound') {
        _form[key] = _form[key].map(i => i.url || i)
      } else if (key === 'nam_sinh') {
        const regex = /\d{4}/;
        const matches = `${_form[key]}`.match(regex)
        _form['birthday'] = matches ? matches[0] : null;
      } else if (key === 'ho_ten') {
        _form['user_name'] = _form[key]
      } else if (key === 'ket_luan') {
        _form['conclude'] = _form[key]
      } else if (key === 'tuoi') {
        _form['birthday'] = age2BirthdayYear(_form[key]);
      } else if (key === 'ngay_du_sinh') {
        _form['due_date'] = _form[key]
      }
      otherFields += `&&${key}=${_form[key]}`;
    }
  }

  return {
    ..._form,
    other_fields: otherFields
  };
};

export const getFormContentToPrint = (result, formContent, inputs, selectedImages) => {
  selectedImages = selectedImages.filter(img => img.imageBase64 || img.aws_s3_location)
  const regex = /\{\{.*?\}\}/gim;
  const matchs = formContent.match(regex);
  if (matchs) {
    const occurrences = matchs.reduce((acc, curr) => {
      return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
    }, {});
    for (const match in occurrences) {
      const input = inputs.find(i => `{{${i.code}}}` === match)
      if (input) {
        const code = input.code;
        const frequency = occurrences[match];
        for (let i = 0; i < frequency; i++) {
          let id = `${code}${i > 0 ? `_${i}` : ''}`
          let value = result[id];
          if (code === 'hinh_anh_sieu_am') {
            console.log(input);
            if (selectedImages.length) {
              value = `
              <h3 class="uppercase text-center">Kết quả siêu âm</h3>
              <div class="wrapper-images-preview">
                ${selectedImages.map(img => `<img class="item-image image-${input.size}" src=${img.imageBase64 || img.aws_s3_location} alt="Hình ảnh siêu âm" />`)}
              </div>
            `
              formContent = formContent.replace(match, value)
            } else {
              formContent = formContent.replace(match, '')
            }

          } if (code === 'ho_ten') {
            value = value.toUpperCase()
            formContent = formContent.replace(match, value)
          } else if (code === 'chon_ket_luan') {
            formContent = formContent.replace(match, '')
          } else if (input.type === 'text') {
            formContent = formContent.replace(match, `<span style="white-space: break-spaces">${value}</span>`)
          } else {
            formContent = formContent.replace(match, value)
          }
        }
      } else {
        formContent = formContent.replace(match, '')
      }
    };
  }
  return formContent;
}

export const getWorkingShifts = (working_shifts, date = null) => {
  if (date) {
    const day = date.getDay()
    working_shifts = working_shifts.filter(ws => ws.days_of_week.includes(`${day}`));
  }

  working_shifts = working_shifts
    .filter(ws => ws.status)
    .sort((a, b) => a.start_time.localeCompare(b.start_time));

  let workingShiftActive;
  let workingShifts = [];
  const now = new Date();
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);
  const _h = filZeroNumber(now.getHours());
  const _min = filZeroNumber(now.getMinutes());

  const _currentTime = new Date(`${_y}-${_m}-${_d} ${_h}:${_min}`);
  for (let working_shift of working_shifts) {
    let workingShift = {
      id: working_shift.id,
      name: working_shift.name,
      from: new Date(`${_y}-${_m}-${_d} ${working_shift.start_time}`),
      end: new Date(`${_y}-${_m}-${_d} ${working_shift.end_time}`),
      fromExtend: new Date(`${_y}-${_m}-${_d} ${working_shift.start_time_extend}`),
      endExtend: new Date(`${_y}-${_m}-${_d} ${working_shift.end_time_extend}`),
      fromText: working_shift.start_time,
      endText: working_shift.end_time,
      duration_kip_work: working_shift.duration_kip_work,
      totalBokings: []
    }
    if (_currentTime > workingShift.fromExtend && _currentTime < workingShift.endExtend) {
      workingShiftActive = workingShift.id;
    }
    workingShifts.push(workingShift);
  }
  return {
    workingShiftActive,
    workingShifts
  }
}

export const getWorkingShiftsByDay = (workingShifts) => {
  let workingShiftsByDay = []
  for (let i = 1; i <= 6; i++) {
    let items = workingShifts.filter(ws => ws.days_of_week.includes(`${i}`))
    workingShiftsByDay.push({
      key: i,
      title: `Thứ ${i + 1}`,
      workingShifts: items
    });
  }
  let items = workingShifts.filter(ws => ws.days_of_week.includes(`0`))
  workingShiftsByDay.push({
    key: 0,
    title: `Chủ nhật`,
    workingShifts: items
  });
  return workingShiftsByDay;
}