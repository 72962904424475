import React, { useMemo } from 'react';
import { Table, Popconfirm, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import http from 'utils/http';
import { deleteService } from 'reducers/service';
import { formatPriceVND } from 'utils';

function DataTableService({ dataSource, setService }) {
  const dispatch = useDispatch();
  const userAuth = useSelector(state => state.authorization.user);
  const roleUserAuth = useMemo(() => {
    if (!userAuth || !userAuth.roles) return '';
    const role = userAuth.roles[0] || {};

    return role;
  }, [userAuth]);

  const onConfirmDeleteService = serviceId => {
    
    http.delete(`/services/${serviceId}`).then(rsp => {
      dispatch(deleteService(serviceId));
      notification.success({
        message: 'Xóa dịch vụ thành công'
      });
    }).catch(err => {
      if (err.response.data) {
        notification.error({
          message: err.response.data.meta.message
        });
      } else {
        notification.error({
          message: 'Không thể xóa dịch vụ lúc này, vui lòng thử lại sau!'
        });
      }
    });
  };

  const columns = [
    {
      key: 'index',
      title: 'STT',
      dataIndex: 'index',
      render: (r, object, index) => {
        return index + 1;
      }
    },
    {
      key: 'name',
      title: 'Tên dịch vụ',
      dataIndex: 'name'
    },
    {
      key: 'description',
      title: 'Mô tả',
      dataIndex: 'description',
      width: '40%'
    },
    {
      key: 'price',
      title: 'Giá tiền',
      dataIndex: 'price',
      render: value => formatPriceVND(value)
    },
    {
      key: 'unit',
      title: 'Đơn vị',
      dataIndex: 'unit'
    },
    {
      key: 'type',
      title: 'Nhóm dịch vụ',
      dataIndex: 'type',
      render: value => {
        if (value === 10) {
          return 'Siêu âm'
        } else if (value === 20) {
          return 'Thai sản'
        } else if (value === 30) {
          return 'Xét nghiệm'
        }
        return value
      }
    },
    {
      key: 'action',
      render: (r, object) => {
        let isAllowDelete = roleUserAuth.name === 'clinic_admin' || roleUserAuth.name === 'clinic_manager';
        return (
          <>
            <EditOutlined
              className="pointer"
              onClick={() => setService(object)}
            />
            {isAllowDelete && (
              <Popconfirm
                placement="topLeft"
                title="Xác nhận xóa dịch vụ này?"
                okText="Xóa"
                cancelText="Hủy"
                okType="danger"
                onConfirm={() => onConfirmDeleteService(object.id)}
              >
                <DeleteOutlined className="ml-1" />
              </Popconfirm>
            )}
          </>
        );
      }
    }
  ];
  return <Table columns={columns} dataSource={dataSource.map(d => ({ ...d, key: d.id }))} pagination={false} />;
}

export default DataTableService;
