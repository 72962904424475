import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {Button, DatePicker} from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SkeletonReportByCalendar } from 'containers/skeleton';
import moment from 'moment';
import http from 'utils/http';
import { filZeroNumber } from 'utils/__function';

import styles from './index.module.scss';
import classnames from 'classnames';
import {FileExcelOutlined} from "@ant-design/icons";
import * as FileSaver from 'file-saver';

const options = {
  chart: {
    type: 'pie'
  },
  title: {
    text: null
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.percentage:.1f} %',
        distance: -50,
        filter: {
          property: 'percentage',
          operator: '>',
          value: 4
        }
      },
      showInLegend: true
    }
  }
};

const convertMomentDate = mommentDate => {
  if (!mommentDate) {
    return '';
  }
  const now = new Date(mommentDate._d);
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);

  return `${_y}-${_m}-${_d}`;
};

function ReportByService({ hiddenHeader, defaultFromDate, defaultToDate }) {
  if (hiddenHeader) {
    options.plotOptions.pie = {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}: {point.percentage:.1f} %',
        filter: {
          property: 'percentage',
          operator: '>',
          value: 4
        }
      }
    };
  }

  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);

  const [fromDate, setFromDate] = useState(
    defaultFromDate || moment().subtract(7, 'days')
  );
  const [toDate, setToDate] = useState(defaultToDate || moment());

  useEffect(() => {
    if (defaultFromDate && defaultToDate) {
      setFromDate(defaultFromDate);
      setToDate(defaultToDate);
    }
  }, [defaultFromDate, defaultToDate]);

  const getMetric = useCallback(async () => {
    let _dataSource = [];
    setLoading(true);
    try {
      const response = await http.get(`/reports/services`, {
        params: {
          from_date: convertMomentDate(fromDate),
          to_date: convertMomentDate(toDate)
        }
      });
      _dataSource = response.data;
    } catch (error) {
      console.error(error);
    }
    setDataSource(_dataSource);
    setLoading(false);
  }, [fromDate, toDate]);

  useEffect(() => {
    getMetric();
  }, [getMetric]);

  const summaryObject = useMemo(() => {
    if (!dataSource) return {};
    let _summaryObject = {
      total_new: 0,
      total_reexamination: 0,
      total_cancel_appointment: 0
    };

    for (let item of dataSource) {
      _summaryObject.total_new += item.total_new;
      _summaryObject.total_reexamination += item.total_reexamination;
      _summaryObject.total_cancel_appointment += item.total_cancel_appointment;
    }
    return _summaryObject;
  }, [dataSource]);

  const fileNameDownload = `Thống kê theo dịch vụ từ ${convertMomentDate(
      fromDate
  )} đến ${convertMomentDate(toDate)}`;

  const handleDownloadFileExport = () => {
    setExporting(true)
    http
        .get('/exports/by-service', {
          params: {
            from_date: convertMomentDate(fromDate),
            to_date: convertMomentDate(toDate)
          },
          responseType: 'blob'
        })
        .then(response => {
          setExporting(false)
          FileSaver.saveAs(response, `${fileNameDownload}.xlsx`);
        });
  };

  const dataPieChart = useMemo(() => {
    if (!dataSource) return [];
    let _dataPieChart = [];

    for (let item of dataSource) {
      _dataPieChart.push({
        name: item.service.name,
        y:
          item.total_new +
          item.total_reexamination +
          item.total_cancel_appointment
      });
    }
    return _dataPieChart;
  }, [dataSource]);

  const onChangeFromDate = _fromDate => {
    setFromDate(_fromDate);
  };

  const onChangeToDate = _toDate => {
    setToDate(_toDate);
  };

  const isDisabledDate = _momentTime => fromDate > _momentTime;

  return (
    <div className={styles.wrapper}>
      {!hiddenHeader && (
        <div className="header flex justify-content-between">
          <div className="left flex">
            <div className="from-date mr-1">
              <span className="mr-1">Từ ngày</span>
              <DatePicker
                allowClear={false}
                value={fromDate}
                format="DD-MM-YYYY"
                onChange={onChangeFromDate}
              />
            </div>
            <div className="to-date">
              <span className="mr-1">Đến ngày</span>
              <DatePicker
                disabledDate={isDisabledDate}
                allowClear={false}
                value={toDate}
                format="DD-MM-YYYY"
                onChange={onChangeToDate}
              />
            </div>
          </div>
          <div className="right">
            <Button type="primary" onClick={handleDownloadFileExport} loading={exporting}>
              <FileExcelOutlined /> Xuất file Excel
            </Button>
          </div>
        </div>
      )}

      {!loading ? (
        <div
          className={classnames('main-content', { 'is-full': hiddenHeader })}
        >
          <div className="left">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Dịch vụ</th>
                  <th>Ca khám mới</th>
                  <th>Ca tái khám</th>
                  <th>Ca hủy</th>
                  <th>Tổng</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}></td>
                  <td>{summaryObject.total_new}</td>
                  <td>{summaryObject.total_reexamination}</td>
                  <td>{summaryObject.total_cancel_appointment}</td>
                  <td>
                    {summaryObject.total_new +
                      summaryObject.total_reexamination +
                      summaryObject.total_cancel_appointment}
                  </td>
                </tr>
                {dataSource.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.service.name}</td>
                    <td>{item.total_new}</td>
                    <td>{item.total_reexamination}</td>
                    <td>{item.total_cancel_appointment}</td>
                    <td>
                      {item.total_new +
                        item.total_reexamination +
                        item.total_cancel_appointment}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="right">
            {summaryObject.total_new +
              summaryObject.total_reexamination +
              summaryObject.total_cancel_appointment !==
              0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  series: [
                    {
                      name: 'Số ca',
                      data: dataPieChart
                    }
                  ]
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <SkeletonReportByCalendar hiddenHeader={hiddenHeader} />
      )}
    </div>
  );
}

export default ReportByService;
