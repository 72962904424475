import employeeService from 'services/employee';
import serviceService from 'services/service';
import serviceExaminationForm from 'services/examinationForm';
import serviceInputForm from 'services/inputForm';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authorize } from 'reducers/authorization';
import { initWorkspace } from 'reducers/workspace';
import { setEmployees } from 'reducers/employee';
import { setServices } from 'reducers/service';
import { setExaminationForms } from 'reducers/examinationForm';
import { setInputForms } from 'reducers/inputForm';

function useInit() {
  const [loading, setLoading] = useState(true);
  const [textLoading, setTextLoading] = useState('');
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const getEmployees = useCallback(async () => {
    try {
      setTextLoading('Lấy danh sách nhân viên');
      const response = await employeeService.getAll();
      dispatch(setEmployees(response.data));
    } catch (error) {
      throw Error('Lấy danh sách nhân viên thất bại');
    }
  }, [dispatch]);

  const getServices = useCallback(async () => {
    try {
      setTextLoading('Lấy danh sách dịch vụ');
      const response = await serviceService.getAll();
      dispatch(setServices(response.data));
    } catch (error) {
      throw Error('Lấy danh sách dịch thất bại');
    }
  }, [dispatch]);

  const getExaminationForm = useCallback(async () => {
    try {
      setTextLoading('Lấy danh sách mẫu phiếu kết quả');
      const response = await serviceExaminationForm.getExaminationForms()
      dispatch(setExaminationForms(response.data));

      const rsp = await serviceInputForm.getAll()
      dispatch(setInputForms(rsp.data));
    } catch (error) {
      throw Error('Lấy danh sách mẫu phiếu kết quả thất bại');
    }
  }, [dispatch]);

  useEffect(() => {
    async function init() {
      if (localStorage.getItem('token')) {
        try {
          setTextLoading('Xác thực người dùng');
          await dispatch(authorize());
          setTextLoading('Lấy thông tin phòng khám');
          await dispatch(initWorkspace());
          await getEmployees();
          await getServices();
          await getExaminationForm();
        } catch (error) {
          setError(error);
        }
      }
      setTextLoading('');
      setLoading(false);
    }
    init();
  }, [dispatch, getEmployees, getServices, getExaminationForm]);

  return {
    error,
    loading,
    textLoading
  };
}

export default useInit;
