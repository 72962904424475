import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import classnames from 'classnames';

import { useSelector } from 'react-redux';

import styles from './index.module.scss';
import { DataTableWorkingShift } from 'containers/datatable';
import { FormWorkingShift } from 'containers/form';

import { getTimeMoment } from 'utils/__function';


function WorkingShiftPage() {
  const [tabActive, setTabActive] = useState('info');
  const [workingShift, setWorkingShift] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);

  const workingShifts = useSelector(state =>
    state.workspace.currentWokingShift.ids
      .filter(id => state.workspace.currentWokingShift.byId[id])
      .map(id => state.workspace.currentWokingShift.byId[id])
  );

  const openModal = () => {
    setWorkingShift({
      start_time: getTimeMoment('00:00', true),
      end_time: getTimeMoment('00:00', true),
      start_time_extend: getTimeMoment('00:00', true),
      end_time_extend: getTimeMoment('00:00', true),
    });
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setWorkingShift(null);
  };

  const handleSetWokingShift = _workingShift => {
    setWorkingShift(_workingShift);
    setVisibleModal(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between mt-2">
        <div className="left flex">
          <div className="tabs">
            <div
              className={classnames('tab-item', { active: tabActive === 'info' })}
              onClick={() => setTabActive('info')}
            >
              Thông tin ca kip
            </div>
          </div>
        </div>

        <div className="right">
          <Button type="primary" onClick={openModal}>
            Thêm mới ca
          </Button>
        </div>
      </div>
      <div className="main mt-2 working-shifts-table">
        <DataTableWorkingShift
          dataSource={workingShifts}
          setWorkingShift={handleSetWokingShift}
        />
      </div>
      <Modal
        maskClosable={false}
        title={workingShift && workingShift.id ? 'Cập nhật ca' : 'Thêm mới ca'}
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
      >
        <FormWorkingShift workingShift={workingShift} onClose={closeModal} />
      </Modal>
    </div>
  );
}

export default WorkingShiftPage;
