import http from 'utils/http';

export default {
  getClinics: () => http.get('/me/clinics?include=working_shifts'),
  edit: clinic => {
    const data = { ...clinic };
    delete data.id;
    return http.put(`/update-info`, data);
  }
};
