import React, { useState } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

import FormBooking from 'components/Form/FormBooking/FormBooking';
import { database } from 'configs/firebase';

import serviceBooking from 'services/booking';

export default ({
  onCancel,
  defaultDateParams,
  otherDayBookings,
  setOtherDayBookings,
  booking,
  doctorsByDate
}) => {
  const [resultText, setResultText] = useState(null);
  const currentClinic = useSelector(state => state.workspace.currentClinic);

  const [loading, setLoading] = useState(false);

  const handleAddOneBooking = values => {
    return new Promise(async resolve => {
      let _values = { ...values };

      delete _values.date_day;
      _values.status = values.status && values.status.id ? values.status.id : 1;
      _values.date_time = values.date_day + ' ' + values.date_time;

      const response = await serviceBooking.addBooking(
        _values
      );

      resolve(response.data);
    });
  };

  const onSubmit = async (values, isToday, callback) => {
    setLoading(true);

    try {
      const responses = await handleAddOneBooking(values);

      if (isToday) {
        const bookingsRef = database.ref(`bookings/${currentClinic.id}`);

        for (let index = 0; index < responses.length; index++) {
          const itemResponse = responses[index];
          bookingsRef.push(itemResponse);
        }
      } else {
        let _otherDayBookings = otherDayBookings || [];
        setOtherDayBookings([...responses, ..._otherDayBookings]);
      }

      callback(responses[0].numerical_order);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  if (resultText) {
    return (
      <div>
        Khách hàng: <b>{resultText.user_name}</b>
        <br />
        Giờ đặt hẹn: <b>{resultText.date_time}</b>
        <br />
        Địa chỉ: <b>{resultText.address}</b>
        <div className="group-action">
          <Button className="submit-button" type="primary">
            Copy và thoát
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBooking
      onCancel={() => {
        onCancel();
        setResultText(null);
      }}
      cancelText="Quay lại"
      submitText="Thêm"
      onSubmit={onSubmit}
      loading={loading}
      defaultDateParams={defaultDateParams}
      booking={booking}
      doctorsByDate={doctorsByDate}
    />
  );
};
