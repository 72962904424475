import React from 'react';
import { useSelector } from 'react-redux';

import { Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import serviceBooking from 'services/booking';

import { database } from 'configs/firebase';
import { defaultDate } from 'utils/__variable';

export default ({
  booking,
  defaultDateParams,
  otherDayBookings,
  setOtherDayBookings
}) => {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const onConfirm = () => {
    const isToday = booking.date_day === defaultDate;
    if (isToday) {
      const bookingsRef = database.ref(
        `bookings/${currentClinic.id}/${booking.key}`
      );

      serviceBooking.deleteBooking(booking.id);

      bookingsRef.remove();
    } else {
      if (booking.date_day === defaultDateParams._i) {
        setOtherDayBookings(otherDayBookings.filter(b => b.id !== booking.id));
      }
      serviceBooking.deleteBooking(booking.id);
    }
  };

  return (
    <Popconfirm
      placement="topRight"
      title="Bạn có thực sự muốn xóa lịch hẹn khám này không?"
      okText="Có"
      cancelText="Không"
      okType="danger"
      onConfirm={onConfirm}
    >
      <Button size="small" style={{ flexBasis: '48.5%' }}>
        <DeleteOutlined /> Xóa
      </Button>
    </Popconfirm>
  );
};
