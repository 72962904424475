import React from 'react';
import { Table, Button, Input, Space, Tooltip } from 'antd';
import {
  EyeOutlined,
  SearchOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import Logo from '../../../assets/images/logo.jpg';
import styles from './index.module.scss';

function DataTableCustomer({ dataSource, setCustomer }) {
  const searchNumericalRef = React.useRef(null);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex, placeholder = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchNumericalRef}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          if (searchNumericalRef && searchNumericalRef.current) {
            searchNumericalRef.current.focus();
          }
        }, 100);
      }
    },
    render: text => text
  });
  const columns = [
    {
      key: 'index',
      title: 'STT',
      dataIndex: 'index',
      render: (r, object, index) => {
        return index + 1;
      }
    },
    {
      key: 'name',
      title: 'Khách hàng',
      ...getColumnSearchProps('name', 'Nhập tên'),
      render: (r, object) => {
        return (
          <div className="flex align-items-center">
            <div
              className="user-avatar mr-1"
              style={{
                width: '30px',
                height: '30px',

                backgroundSize: 'cover',
                borderRadius: '50%',
                border: '1px solid #fff',
                boxShadow: '0 0 5px rgba(93,93,93,.32)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                alt={object.name}
                src={object.avatar}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = Logo;
                }}
                style={{
                  width: '30px',
                  height: '30px',
                  objectFit: 'cover',
                  borderRadius: '50%'
                }}
              />
            </div>
            <span>{object.name}</span>
          </div>
        );
      }
    },
    {
      key: 'birthday_year',
      title: 'Tuổi',
      dataIndex: 'birthday_year',
      render: r => (r ? r + ' tuổi' : '')
    },
    {
      key: 'gestational_week',
      title: 'Tuần thai',
      dataIndex: 'gestational_week',
      render: r => (r ? r + ' tuần' : '')
    },
    {
      key: 'phone_number',
      title: 'Số điện thoại',
      dataIndex: 'phone_number',
      ...getColumnSearchProps('phone_number', 'Nhập SDT')
    },
    {
      key: 'nearest_result',
      title: 'Khám gần nhất',
      dataIndex: 'nearest_result',
      render: (r, object) => {
        if (!r) return null;

        const tooltip = (
          <Tooltip
            overlayClassName={styles.tooltipDetail}
            title={
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {object.nearest_result.conclude}
              </span>
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        );
        if (r.note === '1') {
          return (
            <span style={{ color: 'rgb(142, 184, 56)', fontWeight: 'bold' }}>
              Chưa phát hiện bất thường {tooltip}
            </span>
          );
        } else if (r.note === '2')
          return (
            <span style={{ color: 'rgb(227, 147, 52)', fontWeight: 'bold' }}>
              Bất thường {tooltip}
            </span>
          );
        else return null;
      }
    },
    {
      key: 'created_at',
      title: 'Ngày tạo',
      dataIndex: 'created_at'
    },
    {
      key: 'action',
      align: 'right',
      render: (r, object) => {
        return (
          <>
            <Button size="small" onClick={() => setCustomer(object)}>
              <EyeOutlined className="pointer" />
              <span className="ml-1">Lịch sử khám</span>
            </Button>
          </>
        );
      }
    }
  ];
  return (
    <Table
      columns={columns}
      dataSource={dataSource.map(d => ({ ...d, key: d.id }))}
    />
  );
}

export default DataTableCustomer;
