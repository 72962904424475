import React from 'react';
import { useSelector } from 'react-redux';
import { Popconfirm, Tooltip } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import moment from 'moment';
import { filZeroNumber } from 'utils/__function';
import http from 'utils/http';

import styles from './calendarByDoctor.module.scss';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

function CalendarByDoctor({
  schedulesById,
  setSchedulesById,
  doctorsOption,
  doctorCalendarObject,
  titleDays,
  handleShowDoctorCalendar
}) {

  const servicesOption = useSelector(state =>
    state.service.ids
      .filter(id => state.service.byId[id])
      .map(id => state.service.byId[id])
  );

  const getServices = (serviceIds) => {
    let services = servicesOption.filter(s => serviceIds.includes(s.id)).map(s => s.name);
    return services.join('\n- ');
  }

  const getEducation = doctor => {
    const educations = doctor.educations || [];
    const education = educations[0] || {};

    if (education.title) {
      return education.title;
    }

    return '';
  };

  const handleRemoveDoctorCalendar = async idRemove => {
    try {
      await http.delete(`/schedules/${idRemove}`);
      setSchedulesById({
        ...schedulesById,
        [idRemove]: null
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        id="wrapper-image-doctor-calendar"
        className="main"
        style={{ paddingTop: '10px' }}
      >
        <div className="main-title">
          <div className="title-item">
            <div className="title-item-label">Bác sĩ</div>
            <div className="title-item-skips">
              {titleDays.map(titleDay => {
                const now = new Date();
                const _y = now.getFullYear();
                const _d = filZeroNumber(now.getDate());
                const _m = filZeroNumber(now.getMonth() + 1);

                const isDisabled =
                  new Date(titleDay.value) <
                  new Date(`${_y}-${_m}-${_d}`);
                return (
                  <div className="skip-item skip-item-bg" key={titleDay.value}>
                    <span className={classnames('title-skip', {
                      disabled: isDisabled,
                    })} >
                      <b>{titleDay.day}</b> <br />
                      <span>{titleDay.date}/{titleDay.month}</span>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
          {doctorsOption.map(doctor => (
            <div key={doctor.id} className="title-item">
              <div
                className="title-item-label">
                <div className="title-item-label__left">
                  <span>{getEducation(doctor)}<br />{doctor.name}</span>
                </div>
              </div>
              <div className="title-item-skips">
                {titleDays.map(titleDay => (
                  <div
                    key={titleDay.value}
                    className={`skip-item skip-item-shifts`}
                  >
                    {titleDay.workingShifts.map(workingShift => {
                      const calendarArray =
                        doctorCalendarObject[titleDay.value] || [];
                      const doctorWorkingShift = calendarArray.find(ca => ca.user.id === doctor.id && ca.shift === workingShift.id)

                      const now = new Date();
                      const isDisabled =
                        new Date(`${titleDay.value} ${workingShift.end_time}`) < now;
                      let tooltipTitle = `${workingShift.name}\nThời gian: ${workingShift.start_time} - ${workingShift.end_time}`
                      if (doctorWorkingShift) {
                        // if (doctorWorkingShift.service_ids)
                        //   tooltipTitle += `\nChi tiết Dịch vụ:\n- ${getServices(doctorWorkingShift.service_ids)}`
                        if (doctorWorkingShift.service_ids)
                          tooltipTitle += `\nChi tiết Dịch vụ:\n- ${getServices(doctorWorkingShift.service_ids)}`
                      }
                      return (
                        <div
                          key={workingShift.id}
                          className={classnames('service-type-shifts', {
                            active: doctorWorkingShift,
                            old: isDisabled && doctorWorkingShift,
                          })}
                          onClick={() => handleShowDoctorCalendar(doctorWorkingShift, isDisabled)}
                        >
                          {
                            doctorWorkingShift || (isDisabled && doctorWorkingShift) ?
                              <Tooltip placement="left" title={
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                  {tooltipTitle}
                                </span>
                              }>
                                <span><b>{workingShift.name}</b><br /><b>Thời gian</b>: {workingShift.start_time} - {workingShift.end_time}</span>
                              </Tooltip> :
                              <span><b>{workingShift.name}</b><br /><b>Thời gian</b>: {workingShift.start_time} - {workingShift.end_time}</span>
                          }

                          {!isDisabled && doctorWorkingShift && (
                            <span
                              className="delete-working-shift"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <EditOutlined onClick={() => handleShowDoctorCalendar(doctorWorkingShift, isDisabled)} />
                              <br />
                              <Popconfirm
                                placement="topRight"
                                title="Xác nhận xóa lịch làm việc này của bác sĩ?"
                                okText="Xóa"
                                cancelText="Hủy"
                                okType="danger"
                                onConfirm={() => handleRemoveDoctorCalendar(doctorWorkingShift.id)}
                              >
                                <CloseOutlined />
                              </Popconfirm>
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CalendarByDoctor;
