import _LoginPage from './login';
import _HomePage from './home';
import _BookingPage from './booking';
import _PrintOrderPage from './print-order';
import _CheckupPage from './checkup';
import _CheckupOldPage from './checkup-old';
import _EmployeePage from './employee';
import _ServicePage from './service';
import _PrintResultPage from './print-result';
import _PrintResultOldPage from './print-result-old';
import _ReportPage from './report';
import _CustomerPage from './customer';
import _SettingPage from './setting';
import _PrintQRCodePage from './print-qr-code';
import _ForgotPassword from './forgot-password';
import _ChangePassword from './change-password';
import _CalendarDoctorPage from './calendar-doctor';
import _LobbyPage from './lobby';
import _DocumentPage from './document';
import _ExaminationForm from './examination-form';
import _AddExaminationForm from './examination-form/AddExaminationForm';
import _EditExaminationForm from './examination-form/EditExaminationForm';
import _WorkingShiftPage from './working-shift';

export const LoginPage = _LoginPage;
export const HomePage = _HomePage;
export const BookingPage = _BookingPage;
export const PrintOrderPage = _PrintOrderPage;
export const CheckupPage = _CheckupPage;
export const CheckupOldPage = _CheckupOldPage;
export const EmployeePage = _EmployeePage;
export const ServicePage = _ServicePage;
export const PrintResultPage = _PrintResultPage;
export const PrintResultOldPage = _PrintResultOldPage;
export const ReportPage = _ReportPage;
export const CustomerPage = _CustomerPage;
export const SettingPage = _SettingPage;
export const PrintQRCodePage = _PrintQRCodePage;
export const ForgotPassword = _ForgotPassword;
export const ChangePassword = _ChangePassword;
export const CalendarDoctorPage = _CalendarDoctorPage;
export const LobbyPage = _LobbyPage;
export const DocumentPage = _DocumentPage;
export const ExaminationForm = _ExaminationForm;
export const AddExaminationForm = _AddExaminationForm;
export const EditExaminationForm = _EditExaminationForm;
export const WorkingShiftPage = _WorkingShiftPage;