import React from 'react';
import { Select } from 'antd';
import { filZeroNumber, getEducation } from 'utils/__function';
import { defaultDate } from 'utils/__variable';
import classnames from 'classnames';
import http from 'utils/http';

import moment from 'moment';

import styles from './calendarByDate.module.scss';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const { Option } = Select;

const checkDifferent = (stringJoin, arraySource) => {
  let result = [];

  for (let item of arraySource) {
    if (!stringJoin.includes(item.toString())) {
      result.push(item);
    }
  }
  return result;
};

const getAddIds = (oldArray, newArray) => {
  return checkDifferent(oldArray.join(), newArray);
};

const getRemoveIds = (oldArray, newArray) => {
  return checkDifferent(newArray.join(), oldArray);
};

function CalendarByDate({ schedulesById, setSchedulesById, doctorsOption, doctorById, doctorCalendarObject, workingShifts, titleDays }) {
  
  const handleRemoveDoctorCalendar = async idRemove => {
    try {
      await http.delete(`/schedules/${idRemove}`);
      setSchedulesById({
        ...schedulesById,
        [idRemove]: null
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddDoctorCalendar = async (
    user,
    service_type,
    itemSkip,
    titleDay
  ) => {
    try {
      const response = await http.post(
        '/schedules?include=user.educations,user.jobs',
        {
          user_id: user.id,
          schedules: [
            {
              date: titleDay.value,
              start_time: itemSkip.timeStart,
              finish_time: itemSkip.timeEnd,
              service_type,
              shift: itemSkip.key
            }
          ]
        }
      );

      const responseSchedule = response.data[0];

      setSchedulesById({
        ...schedulesById,
        [responseSchedule.id]: responseSchedule
      });
    } catch (error) {
      console.error(error);
    }
  };


  const arraySkips = workingShifts.map(wS => {
    return {
      key: wS.id,
      name: wS.name,
      timeStart: wS.fromText,
      timeEnd: wS.endText
    }
  })

  const getIdRemove = (doctorId, _doctorsCalendar) => {
    let idRemove = '';

    for (const d of _doctorsCalendar) {
      if (d.user?.id === doctorId) {
        idRemove = d.id;
        break;
      }
    }
    return idRemove;
  };

  const handleOnchangeDoctorCalendar = (
    _doctorsCalendar,
    newValue,
    serviceType,
    shift,
    titleDay
  ) => {
    const oldValue = _doctorsCalendar.map(dc => dc.user.id);
    const addIds = getAddIds(oldValue, newValue);
    const removeIds = getRemoveIds(oldValue, newValue);

    for (const id of addIds) {
      handleAddDoctorCalendar(doctorById[id], serviceType, shift, titleDay);
    }

    for (const id of removeIds) {
      const idRemove = getIdRemove(id, _doctorsCalendar);
      handleRemoveDoctorCalendar(idRemove);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        id="wrapper-image-doctor-calendar"
        className="main"
        style={{ paddingTop: '10px' }}
      >
        <div className="main-title">
          <div className="title-item">
            <div className="title-item-label" />
            <div className="title-item-skips">
              {arraySkips.map(itemSkip => (
                <div className="skip-item skip-item-bg" key={itemSkip.key}>
                  <span className="title-skip">
                    <b>{itemSkip.name}</b> <span>{itemSkip.timeStart} - {itemSkip.timeEnd}</span>
                  </span>
                  <div className="types-service">
                    <div className="type-service-item">Siêu âm</div>
                    <div className="type-service-item">
                      Tư vấn sản, khám phụ khoa
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {titleDays.map(titleDay => (
            <div key={titleDay.value} className="title-item">
              <div
                className={classnames('title-item-label', {
                  active: defaultDate === titleDay.label
                })}
              >
                <div className="title-item-label__left">
                  <span>{titleDay.day}</span>
                  <span>
                    {titleDay.date}/{titleDay.month}
                  </span>
                </div>
              </div>
              <div className="title-item-skips">
                {arraySkips.map(itemSkip => (
                  <div
                    key={itemSkip.key}
                    className={`skip-item ${itemSkip.key}-skip`}
                  >
                    {[10, 20].map(serviceType => {
                      const doctorCalendarArray =
                        doctorCalendarObject[titleDay.value] || [];

                      const doctorsCalendar = doctorCalendarArray.filter(
                        dc =>
                          dc.shift === itemSkip.key &&
                          dc.service_type === serviceType
                      );

                      const now = new Date();
                      const _y = now.getFullYear();
                      const _d = filZeroNumber(now.getDate());
                      const _m = filZeroNumber(now.getMonth() + 1);

                      const isDisabled =
                        new Date(titleDay.value) <
                        new Date(`${_y}-${_m}-${_d}`);

                      return (
                        <div
                          key={serviceType}
                          className="service-type-doctors wrapper-select-doctor"
                        >
                          {isDisabled && doctorsCalendar.length === 0 ? null : (
                            <Select
                              mode="multiple"
                              className={classnames({
                                'is-empty': doctorsCalendar.length === 0
                              })}
                              disabled={isDisabled}
                              dropdownClassName={styles.dropdownSelect}
                              showSearch={false}
                              style={{ width: '100%' }}
                              placeholder="Chọn bác sĩ"
                              value={doctorsCalendar
                                .filter(dc => dc.id)
                                .map(dc => dc.user.id)}
                              onChange={value =>
                                handleOnchangeDoctorCalendar(
                                  doctorsCalendar,
                                  value,
                                  serviceType,
                                  itemSkip,
                                  titleDay
                                )
                              }
                            >
                              {doctorsOption.map(d => (
                                <Option key={d.id} value={d.id}>
                                  {getEducation(d)}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CalendarByDate;
