import React, { useState } from 'react';
import { Button, Input, InputNumber, notification, Checkbox } from 'antd';
import { PrinterOutlined, EditOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import serviceClinic from 'services/clinic';

import { useSelector, useDispatch } from 'react-redux';

import styles from './index.module.scss';
import { setCurrentClinic } from 'reducers/workspace';

const { TextArea } = Input;

function SettingPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState('info');

  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const [name, setName] = useState(currentClinic.name || '');
  const [contact, setContact] = useState(currentClinic.contact || '');
  const [address, setAddress] = useState(currentClinic.address || '');
  const [roomNumber, setRoomNumber] = useState(currentClinic.room_number || 1);
  const [wifiInfo, setWifiInfo] = useState(currentClinic.wifi_info || '');
  const [notePrintOrder, setNotePrintOrder] = useState(currentClinic.note_print_order || '');
  const [cropAndFilter, setCropAndFilter] = useState(currentClinic.crop_and_filter || false);

  const handleChangeName = event => setName(event.target.value);
  const handleChangeContact = event => setContact(event.target.value);
  const handleChangeAddress = event => setAddress(event.target.value);
  const handleChangeRoomNumber = value => setRoomNumber(value);
  const handleChangeWifiInfo = event => setWifiInfo(event.target.value);
  const handleChangeNotePrintOrider = event => setNotePrintOrder(event.target.value);
  const handleChangeCropAndFilter = event => setCropAndFilter(event.target.checked);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await serviceClinic.edit({
        id: currentClinic.id,
        name,
        contact,
        address,
        wifi_info: wifiInfo,
        note_print_order: notePrintOrder,
        crop_and_filter: cropAndFilter,
        room_number: roomNumber
      });
      const clinicInfo = {
        name,
        contact,
        address,
        wifi_info: wifiInfo,
        note_print_order: notePrintOrder,
        crop_and_filter: cropAndFilter,
        room_number: roomNumber
      };
      dispatch(
        setCurrentClinic(clinicInfo)
      );
      localStorage.setItem(
        'clinicInfo',
        JSON.stringify(clinicInfo)
      );
      notification.success({
        message: 'Cập nhật thành công!'
      });
    } catch (error) {
      notification.error({
        message: 'Cập nhật thất bại!'
      });
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className="header">
        <div className="tabs">
          <div
            className={classnames('tab-item', { active: tabActive === 'info' })}
            onClick={() => setTabActive('info')}
          >
            Thông tin phòng khám
          </div>
        </div>
      </div>
      <div className="main flex mt-2">
        <div className="left">
          <img src={currentClinic.qr_image} alt="QR code" />
          <div className="mt-2 flex justify-content-center">
            <Button
              type="primary"
              onClick={() => {
                const _printQrCode = {
                  image: currentClinic.qr_image,
                  name: currentClinic.name
                };
                localStorage.setItem(
                  'printQrCode',
                  JSON.stringify(_printQrCode)
                );
                window.open('/in-qr-code', '_blank');
              }}
            >
              <PrinterOutlined /> In QR code
            </Button>
          </div>
        </div>
        <div className="right flex-1">
          <table>
            <tbody>
              <tr>
                <td>Tên phòng khám:</td>
                <td>
                  <Input value={name} onChange={handleChangeName} />
                </td>
              </tr>
              <tr>
                <td>Liên hệ:</td>
                <td>
                  <Input value={contact} onChange={handleChangeContact} />
                </td>
              </tr>
              <tr>
                <td>Địa chỉ:</td>
                <td>
                  <Input value={address} onChange={handleChangeAddress} />
                </td>
              </tr>
              <tr>
                <td>Thông tin WIFI:</td>
                <td>
                  <Input value={wifiInfo} onChange={handleChangeWifiInfo} />
                </td>
              </tr>
              <tr>
                <td>Ghi chú phiếu khám:</td>
                <td>
                  <TextArea
                    rows={3}
                    value={notePrintOrder}
                    onChange={handleChangeNotePrintOrider}
                  />
                </td>
              </tr>
              <tr>
                <td className="pt-1"></td>
                <td className="pt-1">
                  <Checkbox onChange={handleChangeCropAndFilter} checked={cropAndFilter}>Cho phép cắt và chỉnh sửa ảnh khi tải lên ảnh siêu âm</Checkbox>
                </td>
              </tr>
              <tr>
                <td className="pt-1">Số phòng khám:</td>
                <td className="pt-1">
                  <InputNumber
                    style={{ width: '120px' }}
                    min={1}
                    value={roomNumber}
                    onChange={handleChangeRoomNumber}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    type="primary"
                    loading={loading}
                    className="mt-2"
                    style={{ minWidth: '120px' }}
                    onClick={handleUpdate}
                  >
                    <EditOutlined /> Cập nhật
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SettingPage;
