import React, { useState } from 'react';
import classnames from 'classnames';
import {
  ReportByCalender,
  ReportByDoctor,
  ReportByRevenue,
  ReportByService
} from 'containers/chart';

import styles from './index.module.scss';

function Report() {
  const [typeReport, setTypeReport] = useState('calendar');
  return (
    <div className={styles.wrapper}>
      <div className="tabs flex">
        <div
          className={classnames('tab-item', {
            active: typeReport === 'calendar'
          })}
          onClick={() => setTypeReport('calendar')}
        >
          <span>Thống kê theo lịch khám</span>
        </div>
        <div
          className={classnames('tab-item', {
            active: typeReport === 'service'
          })}
          onClick={() => setTypeReport('service')}
        >
          <span>Thống kê theo dịch vụ</span>
        </div>
        <div
          className={classnames('tab-item', {
            active: typeReport === 'doctor'
          })}
          onClick={() => setTypeReport('doctor')}
        >
          <span>Thống kê theo bác sĩ </span>
        </div>
        <div
          className={classnames('tab-item', {
            active: typeReport === 'revenue'
          })}
          onClick={() => setTypeReport('revenue')}
        >
          <span>Thống kê theo doanh thu</span>
        </div>
      </div>
      <div
        className={classnames('report-content', {
          active: typeReport === 'calendar'
        })}
      >
        <ReportByCalender />
      </div>
      <div
        className={classnames('report-content', {
          active: typeReport === 'service'
        })}
      >
        <ReportByService />
      </div>
      <div
        className={classnames('report-content', {
          active: typeReport === 'doctor'
        })}
      >
        <ReportByDoctor />
      </div>
      <div
        className={classnames('report-content', {
          active: typeReport === 'revenue'
        })}
      >
        <ReportByRevenue />
      </div>
    </div>
  );
}

export default Report;
