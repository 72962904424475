import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Button,
  DatePicker,
  Modal,
  Radio
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import http from 'utils/http';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { LoadingOutlined, FileImageOutlined } from '@ant-design/icons';

import template from './template';
import { filZeroNumber, getWorkingShifts, getWorkingShiftsByDay } from 'utils/__function';
import { dateFormat, defaultDate } from 'utils/__variable';
import CalendarByDate from 'components/Form/FormCalendarDoctor/CalendarByDate';
import CalendarByDoctor from 'components/Form/FormCalendarDoctor/CalendarByDoctor';
import FormCalendar from 'containers/form/form-calendar';
import serviceClinic from 'services/clinic';
import { setCurrentClinic } from 'reducers/workspace';

import styles from './index.module.scss';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const convertMomentDate = mommentDate => {
  let _dateString = {
    label: '',
    value: ''
  };

  try {
    const now = new Date(mommentDate);
    const _y = now.getFullYear();
    const _d = filZeroNumber(now.getDate());
    const _m = filZeroNumber(now.getMonth() + 1);

    _dateString = {
      label: `${_d}-${_m}-${_y}`,
      value: `${_y}-${_m}-${_d}`
    };
  } catch (error) {
    console.error(error);
  }
  return _dateString;
};

function CalendarDoctorPage() {
  const dispatch = useDispatch();

  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const [settingCalendarByDoctor, setSettingCalendarByDoctor] = useState(currentClinic.setting_calendar_by_doctor ? true : false);
  const [schedulesById, setSchedulesById] = useState({});
  const [currentDay, setCurrentDay] = useState(defaultDate);
  const [objectQuery, setObjectQuery] = useState(null);
  const [loadingHandleExportImage, setLoadingHandleExportImage] = useState(false);
  const [loadingGetSchedules, setLoadingGetSchedules] = useState(false);
  const [doctorWorkingShift, setDoctorWorkingShift] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const _workingShifts = useSelector(state =>
    state.workspace.currentWokingShift.ids
      .filter(id => state.workspace.currentWokingShift.byId[id])
      .map(id => state.workspace.currentWokingShift.byId[id])
  );
  const { workingShifts } = getWorkingShifts(_workingShifts);
  const workingShiftsByDay = getWorkingShiftsByDay(_workingShifts)

  const getSchedulees = useCallback(async () => {
    if (!objectQuery) return;
    setLoadingGetSchedules(true);
    try {
      let _schedulesById = {};

      const params = {
        per_page: 9999,
        include: 'user.educations,user.jobs'
      };

      if (objectQuery.start) {
        params.from_date = convertMomentDate(objectQuery.start).value;
      }
      if (objectQuery.end) {
        params.to_date = convertMomentDate(objectQuery.end).value;
      }

      const response = await http.get('/schedules', { params });

      for (let item of response.data) {
        if (!item.user) continue;
        _schedulesById[item.id] = {
          ...item,
          temp_id: item.id
        };
      }

      setSchedulesById(_schedulesById);
    } catch (error) {
      console.error(error);
    }
    setLoadingGetSchedules(false);
  }, [objectQuery]);

  useEffect(() => {
    getSchedulees();
  }, [getSchedulees]);

  const employees = useSelector(state =>
    state.employee.ids
      .filter(id => state.employee.byId[id])
      .map(id => state.employee.byId[id])
  );

  const doctorsOption = useMemo(() => {
    return employees.filter(employee => {
      if (employee.roles) {
        const role = employee.roles[0] || {};

        if (role.name === 'clinic_doctor') return true;
        return false;
      }
      return false;
    });
  }, [employees]);

  const doctorById = useSelector(state => state.employee.byId);

  const handleClickThisWeek = () => {
    setCurrentDay(defaultDate);
    getWeekStartAndWeekEnd();
  };

  const getWeekStartAndWeekEnd = useCallback(
    _date => {
      const now = _date ? new Date(_date) : new Date();
      const startDay = 1; // 0 = sunday, 1 = monday etc.
      const d = now.getDay(); // get the current day
      const weekStart = new Date(
        now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000
      ); //rewind to start day
      const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000); //add 6 days to get

      setObjectQuery({
        start: weekStart,
        end: weekEnd
      });
    },
    [setObjectQuery]
  );

  useEffect(() => {
    getWeekStartAndWeekEnd();
  }, [getWeekStartAndWeekEnd]);

  const titleDays = useMemo(() => {
    let _titleDays = [];
    if (objectQuery) {
      const startDay = new Date(objectQuery.start).getTime();
      const endDay = new Date(objectQuery.end).getTime();
      const arrayDays = [
        'Chủ nhật',
        'Thứ 2',
        'Thứ 3',
        'Thứ 4',
        'Thứ 5',
        'Thứ 6',
        'Thứ 7'
      ];
      for (let i = startDay; i <= endDay;) {
        const _now = new Date(i);
        let ws = workingShiftsByDay.find(i => i.key === _now.getDay())
        const objectPush = {
          day: arrayDays[_now.getDay()],
          date: filZeroNumber(_now.getDate()),
          month: filZeroNumber(_now.getMonth() + 1),
          workingShifts: ws.workingShifts
        };

        objectPush.value = `${_now.getFullYear()}-${objectPush.month}-${objectPush.date
          }`;

        objectPush.label = `${objectPush.date}-${objectPush.month
          }-${_now.getFullYear()}`;

        _titleDays.push(objectPush);

        i += 86400 * 1000;
      }
    }
    return _titleDays;
  }, [objectQuery]);

  const doctorCalendarObject = useMemo(() => {
    let _doctorCalendarObject = {};

    for (let key in schedulesById) {
      const item = schedulesById[key];

      if (!item) continue;

      item.id = key;

      if (_doctorCalendarObject[item.date]) {
        _doctorCalendarObject[item.date].push(item);
      } else {
        _doctorCalendarObject[item.date] = [item];
      }
    }

    return _doctorCalendarObject;
  }, [schedulesById]);

  const handleExportImage = async () => {
    try {
      setLoadingHandleExportImage(true);
      const fromDate = convertMomentDate(objectQuery.start).label;
      const toDate = convertMomentDate(objectQuery.end).label;

      const elementClone = document.createElement('div');

      elementClone.innerHTML = template(
        currentClinic.name,
        fromDate,
        toDate,
        titleDays,
        doctorCalendarObject
      );

      elementClone.style.display = 'block';
      document.body.appendChild(elementClone);

      const canvas = await html2canvas(elementClone, { type: 'dataURL' });

      const imageUrl = canvas.toDataURL();

      canvas.toBlob(() => {
        const a = document.createElement('a'); //Create <a>
        a.href = imageUrl; //Image Base64 Goes here
        a.download = `lich_lam_viec_${fromDate}_${toDate}.png`; //File name Here
        a.click(); //Downloaded file
        elementClone.style.display = 'none';
        setLoadingHandleExportImage(false);
      }, 'image/jpeg');
    } catch (error) {
      console.error(error);
    }
  };


  const handleShowDoctorCalendar = (workingShift, isDisabled) => {
    if (workingShift) {
      workingShift.isDisabled = isDisabled;
      setVisibleModal(true)
      setDoctorWorkingShift(workingShift)
    }
  }
  const options = [
    { label: 'Theo bác sĩ', value: true },
    { label: 'Theo ngày trong tuần', value: false }
  ];

  const handleChangeSettingCalendarByDoctor = async event => {
    let val = event.target.value
    setSettingCalendarByDoctor(val)

    try {
      await serviceClinic.edit({
        id: currentClinic.id,
        ...currentClinic,
        setting_calendar_by_doctor: val
      });
      const clinicInfo = {
        ...currentClinic,
        setting_calendar_by_doctor: val
      };
      dispatch(
        setCurrentClinic(clinicInfo)
      );
      localStorage.setItem(
        'clinicInfo',
        JSON.stringify(clinicInfo)
      );
    } catch (error) {
      setSettingCalendarByDoctor(!val)
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className="header">
        <div className="flex justify-content-between">
          <div className="left">
            <Radio.Group
              options={options}
              onChange={handleChangeSettingCalendarByDoctor}
              value={settingCalendarByDoctor}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div className="right">
            {currentClinic.setting_calendar_by_doctor &&
              <Button className="mr-2" type="primary" onClick={() => {
                setDoctorWorkingShift(null)
                setVisibleModal(true)
              }}>
                Thêm mới
              </Button>
            }
            <Button className="mr-2" onClick={handleClickThisWeek}>
              Tuần này
            </Button>
            <DatePicker
              className="mr-2"
              inputReadOnly
              allowClear={false}
              placeholder=""
              picker="week"
              value={moment(currentDay, dateFormat)}
              onChange={value => {
                getWeekStartAndWeekEnd(value._d);
                setCurrentDay(value._d);
              }}
            />
            <Button
              onClick={handleExportImage}
              loading={loadingHandleExportImage}
              disabled={loadingHandleExportImage}
            >
              <FileImageOutlined /> Xuất file ảnh
            </Button>
            {loadingGetSchedules && <LoadingOutlined className="ml-2" />}
          </div>
        </div>
      </div>
      {currentClinic.setting_calendar_by_doctor ?
        <CalendarByDoctor
          schedulesById={schedulesById}
          setSchedulesById={setSchedulesById}
          doctorsOption={doctorsOption}
          doctorCalendarObject={doctorCalendarObject}
          titleDays={titleDays}
          handleShowDoctorCalendar={handleShowDoctorCalendar}
        />
        :
        <CalendarByDate
          schedulesById={schedulesById}
          setSchedulesById={setSchedulesById}
          doctorsOption={doctorsOption}
          doctorById={doctorById}
          doctorCalendarObject={doctorCalendarObject}
          workingShifts={workingShifts}
          titleDays={titleDays}
        />
      }
      <Modal
        maskClosable={false}
        width="850px"
        title={doctorWorkingShift ? 'Cập nhật lịch làm việc' : 'Thêm mới lịch làm việc'}
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
      >
        <FormCalendar
          doctorWorkingShift={doctorWorkingShift}
          schedulesById={schedulesById}
          setSchedulesById={setSchedulesById}
          doctors={doctorsOption}
          titleDays={titleDays}
          schedulesById={schedulesById}
          onClose={() => setVisibleModal(false)}
        />
      </Modal>
    </div>
  );
}

export default CalendarDoctorPage;
