import { createSlice } from '@reduxjs/toolkit';
import normalize from 'utils/normalize';

const examinationForm = createSlice({
  name: 'examinationForm',
  initialState: {
    byId: {},
    ids: []
  },
  reducers: {
    setExaminationForms: (state, action) => {
      const { byId, ids } = normalize(action.payload);
      state.byId = byId;
      state.ids = ids;
    },
    addExaminationForm: (state, action) => {
      const examinationForm = action.payload;
      if (!state.ids.includes(examinationForm.id)) {
        state.byId[examinationForm.id] = examinationForm;
        state.ids = [examinationForm.id, ...state.ids];
      }
    },
    editExaminationForm: (state, action) => {
      const examinationForm = action.payload;
      state.byId[examinationForm.id] = {
        ...state.byId[examinationForm.id],
        ...examinationForm
      };
    },
    deleteExaminationForm: (state, action) => {
      const examinationFormId = action.payload;
      state.byId = {
        ...state.byId,
        [examinationFormId]: null
      };
    }
  }
});

export const { setExaminationForms, addExaminationForm, editExaminationForm, deleteExaminationForm } = examinationForm.actions;

export default examinationForm.reducer;
