import React, { useMemo } from 'react';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import http from 'utils/http';

import { deleteEmployee } from 'reducers/employee';

function DataTableEmployee({ dataSource, setEmployee }) {
  const dispatch = useDispatch();
  const userAuth = useSelector(state => state.authorization.user);

  const roleUserAuth = useMemo(() => {
    if (!userAuth || !userAuth.roles) return '';
    const role = userAuth.roles[0] || {};

    return role;
  }, [userAuth]);

  const onConfirmDeleteEmployee = employeeId => {
    dispatch(deleteEmployee(employeeId));
    http.delete(`/employees/${employeeId}`);
  };

  const columns = [
    {
      key: 'index',
      title: 'STT',
      dataIndex: 'index',
      render: (r, object, index) => {
        return index + 1;
      }
    },
    {
      key: 'name',
      title: 'Họ tên',
      dataIndex: 'name'
    },
    {
      key: 'education',
      title: 'Học vị cao nhất',
      render: (r, object) => {
        const education = object.educations[0] || {};
        return education.title || '';
      }
    },
    {
      key: 'job',
      title: 'Nơi công tác',
      render: (r, object) => {
        const job = object.jobs[0] || {};
        return job.title || '';
      }
    },
    {
      key: 'phone_number',
      title: 'Số điện thoại',
      dataIndex: 'phone_number'
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email'
    },
    {
      key: 'role',
      title: 'Chức vụ',
      dataIndex: 'role',
      render: (r, object) => {
        const roles = object.roles;

        if (!roles) return '';

        const role = roles[0];
        return role.display_name;
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (r, object) => {
        let role_name = '';
        let isAllowDelete = false;

        const roles = object.roles;

        if (roles && roles[0]) {
          role_name = roles[0].name;
        }

        if (roleUserAuth.name === 'clinic_admin') {
          isAllowDelete = true;
        }

        if (roleUserAuth.name === 'clinic_manager') {
          if (role_name !== 'clinic_admin') {
            isAllowDelete = true;
          }
        }

        return (
          <>
            <EditOutlined
              className="pointer"
              onClick={() =>
                setEmployee({
                  ...object,
                  role_name
                })
              }
            />
            {isAllowDelete && (
              <Popconfirm
                placement="topRight"
                title="Xác nhận xóa nhân viên này?"
                okText="Xóa"
                cancelText="Hủy"
                okType="danger"
                onConfirm={() => onConfirmDeleteEmployee(object.id)}
              >
                <DeleteOutlined className="ml-1" />
              </Popconfirm>
            )}
          </>
        );
      }
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={
        dataSource
          .map(d => ({ ...d, key: d.id }))
          .filter(d => d.id !== userAuth.id)
      }
    />
  );
}

export default DataTableEmployee;
