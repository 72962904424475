import React from 'react';
import { Form, Tabs, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import FormUploadImages from '../FormUploadImages';

import styles from './TabsImage.module.scss';

const { TabPane } = Tabs;

export default ({
  handleOnChange,
  S3Client,
  selectedImages,
  handleAddSelectedImages,
  handleRemoveSelectedImages
}) => {
  const [activeKey, setActiveKey] = React.useState('1');

  return (
    <Form.Item
      className={styles.wrapper}
    >
      <FormUploadImages
        key="1-1"
        typeImage="images_url_ultrasound"
        S3Client={S3Client}
        handleOnChange={handleOnChange}
        selectedImages={selectedImages}
        handleAddSelectedImages={handleAddSelectedImages}
        handleRemoveSelectedImages={handleRemoveSelectedImages}
      />
      {/* <Tabs
        activeKey={activeKey}
        className="tabs-image"
        animated={false}
        onChange={setActiveKey}
      >
        <TabPane tab="" key="1" forceRender>
          <FormUploadImages
            key="1-1"
            typeImage="images_url_ultrasound"
            S3Client={S3Client}
            handleOnChange={handleOnChange}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </TabPane>
        <TabPane tab="Ảnh xét nghiệm" key="2" forceRender>
          <FormUploadImages
            key="2-2"
            typeImage="images_url_examination"
            S3Client={S3Client}
            handleOnChange={handleOnChange}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </TabPane>
        <TabPane tab="Ảnh kết luận" key="3" forceRender>
          <FormUploadImages
            typeImage="images_url_conclude"
            S3Client={S3Client}
            handleOnChange={handleOnChange}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </TabPane>
      </Tabs> */}
    </Form.Item>
  );
};
