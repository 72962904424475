import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Checkbox, Input, notification } from 'antd';

import authorizationService from 'services/authorization';

import imageBackground from 'assets/images/background-login-page.svg';

import styles from './index.module.scss';

function Login() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async values => {
    setLoading(true);
    try {
      const { email, password } = values;
      const response = await authorizationService.login({ email, password });

      localStorage.setItem('token', response.data.token);
      window.location.href = '/';
    } catch (error) {
      console.error(error);
      notification['error']({
        message: 'Đăng nhập thất bại!'
      });
    }
    setLoading(false);
  };

  if (localStorage.getItem('token')) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.wrapper}>
      <div className="left">
        <img src={imageBackground} alt="" />
      </div>
      <div className="right">
        <div className="content">
          <div className="page-title">2BABY & CLINIC</div>
          <div className="title">Đăng nhập</div>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              email: '',
              password: '',
              remember: true
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Vui lòng nhập email!' },
                { type: 'email', message: 'Email không đúng định dạng!' }
              ]}
              validateTrigger={['onBlur']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
              validateTrigger={['onBlur']}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Ghi nhớ đăng nhập</Checkbox>
              </Form.Item>
              {/* <span className="forgot-password">
                <Link to="/forgot-password">Quên mật khẩu?</Link>
              </span> */}
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
