import { filZeroNumber } from './__function';

export const dateFormat = 'DD-MM-YYYY';

const now = new Date();
const _y = now.getFullYear();
const _d = filZeroNumber(now.getDate());
const _m = filZeroNumber(now.getMonth() + 1);
const _h = filZeroNumber(now.getHours());
const _min = filZeroNumber(now.getMinutes());

export const defaultDate = `${_d}-${_m}-${_y}`;
export const defaultDateYMD = `${_y}-${_m}-${_d}`;
export const defaultTime = `${_h}:${_min}`;
export const defaultDateWithTime = `${_d}-${_m}-${_y} ${_h}:${_min}`;

export const arrayStatus = [
  { id: 1, name: 'Đặt lịch' },
  { id: 2, name: 'Đã checkin' },
  { id: 3, name: 'Chờ khám' },
  { id: 4, name: 'Đang khám' },
  { id: 5, name: 'Tạm dừng' },
  { id: 6, name: 'Khám xong' },
  { id: 7, name: 'Hủy khám' },
];