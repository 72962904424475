import firebase from 'firebase/';
import 'firebase/messaging';
import 'firebase/database';

const config = {
  apiKey: process.env.REACT_APP_FB_KEY,
  authDomain: process.env.REACT_APP_FB_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DB_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};

const initializeApp = firebase.initializeApp(config);

const messaging = initializeApp.messaging();
const database = initializeApp.database();

export { messaging, database };