import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import {
  LoginPage,
  HomePage,
  BookingPage,
  PrintOrderPage,
  CheckupPage,
  CheckupOldPage,
  EmployeePage,
  ServicePage,
  PrintResultPage,
  PrintResultOldPage,
  ReportPage,
  CustomerPage,
  SettingPage,
  PrintQRCodePage,
  ForgotPassword,
  ChangePassword,
  CalendarDoctorPage,
  LobbyPage,
  DocumentPage,
  ExaminationForm,
  AddExaminationForm,
  EditExaminationForm,
  WorkingShiftPage
} from 'pages';

import { PrivateRoute } from 'components';

function App() {
  return (
    <Router getUserConfirmation={(message, callback) => {
      const allowTransition = window.confirm(message);
      callback(allowTransition);
    }}>
      <Switch>
        {/*Auth path*/}
        <Route exact path="/dang-nhap" component={LoginPage} />
        <Route path="/quen-mat-khau" component={ForgotPassword} />
        <Route path="/doi-mat-khau" component={ChangePassword} />

        {/*Public path*/}
        <PrivateRoute path="/checkin" component={LobbyPage} />
        <PrivateRoute path="/lich-hen" component={BookingPage} />
        <PrivateRoute path="/danh-sach-kham" component={CheckupPage} />
        <PrivateRoute path="/danh-sach-kham-cu" component={CheckupOldPage} />
        <PrivateRoute path="/lich-lam-viec" component={CalendarDoctorPage} />
        <PrivateRoute path="/khach-hang" component={CustomerPage} />
        <Route path="/in-qr-code" component={PrintQRCodePage} />
        <Route path="/in-ket-qua" component={PrintResultPage} />
        <Route path="/in-ket-qua-cu" component={PrintResultOldPage} />
        <Route path="/in-phieu-kham" component={PrintOrderPage} />
        <PrivateRoute path="/huong-dan-su-dung" component={DocumentPage} />

        {/*Private pat*/}
        <PrivateRoute
          exact path="/"
          component={HomePage}
          roles={['clinic_admin', 'clinic_manager']}
        />
        <PrivateRoute
          path="/bao-cao"
          component={ReportPage}
          roles={['clinic_admin', 'clinic_manager']}
        />
        <PrivateRoute
          path="/nhan-vien"
          component={EmployeePage}
          roles={['clinic_admin', 'clinic_manager']}
        />
        <PrivateRoute
          path="/dich-vu"
          component={ServicePage}
          roles={['clinic_admin', 'clinic_manager']}
        />
        <PrivateRoute
          path="/cau-hinh"
          roles={['clinic_admin', 'clinic_manager']}
          component={SettingPage}
        />
        <PrivateRoute
          path="/cakip"
          roles={['clinic_admin', 'clinic_manager']}
          component={WorkingShiftPage}
        />
        <PrivateRoute
          path="/mau-phieu-ket-qua/tao-moi"
          roles={['clinic_admin', 'clinic_manager']}
          component={AddExaminationForm}
        />
        <PrivateRoute
          path="/mau-phieu-ket-qua/:id"
          roles={['clinic_admin', 'clinic_manager']}
          component={EditExaminationForm}
        />
        <PrivateRoute
          path="/mau-phieu-ket-qua"
          roles={['clinic_admin', 'clinic_manager']}
          component={ExaminationForm}
        />
        <Route path="*" component={LoginPage} />
      </Switch>
    </Router>
  );
}

export default App;
