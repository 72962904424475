import React, { useState } from 'react';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import {
  DeleteOutlined,
  ReloadOutlined
} from '@ant-design/icons';

function ImagesPreview({ selectedImages }) {
  const [removedImages, setRemovedImages] = useState([]);
  return (
    <div className="wrapper-images-preview">
      {selectedImages
        .filter(img => !!img)
        .map((img, idx) => (
          <div
            key={idx}
            className={classnames('item-image mb-2', {
              'is-deleted': removedImages.includes(img.id)
            })}
          >
            <img src={img.src} alt="Hình ảnh siêu âm" />
            <div className="overlay">
              <Tooltip
                title={
                  removedImages.includes(img.id) ? 'Khôi phục' : 'Xóa ảnh'
                }
              >
                {removedImages.includes(img.id) ? (
                  <ReloadOutlined
                    onClick={() => {
                      setRemovedImages(
                        removedImages.filter(rimg => rimg !== img.id)
                      );
                    }}
                  />
                ) : (
                  <DeleteOutlined
                    onClick={() => {
                      setRemovedImages(removedImages.concat(img.id));
                    }}
                  />
                )}
              </Tooltip>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ImagesPreview;