import React from 'react';
import { Button, Modal } from 'antd';
import classnames from 'classnames';

import { StockOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

const convertPhoneNumber = (phoneNumber) => {
  return (phoneNumber || '').replace('+84', '0')
}

function ModalLatestResult({ visible, onCancel, resultLatest, booking = {} }) {
  const convertDate = _date => {
    if (!_date) return '';
    const [_y, _m, _d] = _date.split('-');
    return `${_d}-${_m}-${_y}`;
  };

  const isNormal = resultLatest.note === "1";

  return (
    <Modal
      width="30%"
      visible={visible}
      title={null}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      wrapClassName={styles.wrapper}
    >
      <div className="wrapper-title">
        <span className="title-text">Kết quả gần nhất</span>
      </div>
      <span className={classnames("conclude-text", { 'is-normal': isNormal })}>
        {
          isNormal
            ? 'Chưa phát hiện bất thường'
            : 'Bất thường'
        }
      </span>
      <br />
      <b className="title-block">Thông tin chung:</b>
      <br />
      <>
        {resultLatest.weeks && (
          <span>
            - Tuần thai: <b>{resultLatest.weeks}</b> <br />
          </span>
        )}
        - Khách hàng: <b>{booking.user_name} ({convertPhoneNumber(booking.phone_number)})</b><br />
        - Nơi khám: <b>{resultLatest.clinic_name}</b>
        <br />
        - Ngày khám: <b>{convertDate(resultLatest.date)}</b>
        <br />
      </>
      <b className="title-block">Kết quả khám:</b>
      <br />
      - Tim thai: <b>{resultLatest.heart_rate} chu kỳ/phút</b>
      <br />
      - Cân nặng bé: <b>{resultLatest.weight} gr</b>
      <br />
      - Cân nặng mẹ: <b>{resultLatest.mother_weight} kg</b>
      <br />
      <b>Kết luận:</b><br />
      <span style={{ whiteSpace: 'break-spaces' }}>
        {resultLatest.conclude}
      </span>
      <br />
      <Button type="primary" className="mt-2" onClick={() => {
        window.open(`${process.env.REACT_APP_RESULT_URL}/timelines/${resultLatest.uuid}`, '_blank')
      }}>
        <StockOutlined /> Xem toàn bộ KQ khám
      </Button>
    </Modal>
  );
}

export default ModalLatestResult;
