import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Breadcrumb,
  notification
} from 'antd';
import useExitPrompt from 'hooks/useExitPrompt';
import { Prompt } from 'react-router-dom';

import { addExaminationForm } from 'reducers/examinationForm';
import FormExaminationForm from 'components/Form/FormExaminationForm/FormExaminationForm';
import serviceExaminationForm from 'services/examinationForm';

import qs from 'query-string';

import styles from './index.module.scss';

function AddExaminationForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const examinationForm = useSelector(state => state.examinationForm.byId[params.copy_id]) || {};
  const [loading, setLoading] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  useEffect(() => {
    setShowExitPrompt(true)
  }, [])

  const onSubmit = (values) => {
    setLoading(true)
    serviceExaminationForm.addExaminationForm(values)
      .then(rsp => {
        rsp.data.status = true;
        dispatch(addExaminationForm(rsp.data));
        notification.success({
          message: 'Thêm mẫu phiếu kết quả thành công'
        });
        setIsFormIncomplete(false)
        history.push('/mau-phieu-ket-qua')
      })
      .catch(err => {
        notification.error({ message: 'Thêm mẫu phiếu kết quả thất bại' });
      }).finally(() => {
        setLoading(false)
      })
  };

  return (
    <div className={styles.wrapper}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => history.push('/mau-phieu-ket-qua')}>Mẫu phiếu kết quả</a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>Thêm mới</Breadcrumb.Item>
      </Breadcrumb>
      <div className="header flex justify-content-between mb-2">
        <h1 style={{ marginBottom: 0 }}>Thêm mới mẫu phiếu kết quả</h1>
      </div>
      <div className="main">
        <FormExaminationForm
          submitText="Thêm mới"
          examinationForm={examinationForm}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
      <Prompt
       when={isFormIncomplete}
       message="Bạn có chắc chắn muốn hủy bỏ tạo mới phiếu kết quả và chuyển hướng?" />
    </div>
  );
}

export default AddExaminationForm;
