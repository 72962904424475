import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';

import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm, Select, Tooltip } from 'antd';
import { FormResultOld } from 'containers/form';
import { database } from 'configs/firebase';
import { defaultDate } from 'utils/__variable';
import { comparePriority, transformObjectToArray } from 'utils/__function';
import { FundViewOutlined, CloseOutlined } from '@ant-design/icons';
import { setDoctorId } from 'reducers/workspace';

import http from 'utils/http';

import iconUpFirst from 'assets/icons/up-first.png';
import iconUpSecond from 'assets/icons/up-second.png';

import styles from './index.module.scss';
import ModalLatestResult from 'components/Modal/ModalLatestResult';

const { Option } = Select;

const convertDate = dateString => {
  const [_d, _m, _y] = dateString.split('-');
  return new Date(`${_y}-${_m}-${_d}`);
};

function CheckupOldPage() {
  const dispatch = useDispatch();

  const [bookings, setBookings] = useState(null);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [resultLatest, setResultLatest] = useState({});
  const [idViewResult, setIdViewResult] = useState('');
  const [bookingViewDetail, setBookingDetail] = useState({});

  let { currentClinic, doctorId } = useSelector(state => ({
    currentClinic: state.workspace.currentClinic,
    doctorId: state.workspace.doctorId ? Number(state.workspace.doctorId) : null
  }));

  const serviceById = useSelector(state => state.service.byId);
  const doctorById = useSelector(state => state.employee.byId);

  const [selectedKey, setSelectedKey] = useState('');
  const [selectedOrders, setSelectedOrders] = useState({});
  const [schedules, setSchedules] = React.useState([]);

  const getSchedules = useCallback(async () => {
    let from_date = convertDate(defaultDate);
    let to_date = convertDate(defaultDate);

    const response = await http.get('/schedules', {
      params: {
        per_page: 9999,
        from_date,
        to_date
      }
    });
    setSchedules(response.data);
  }, [defaultDate]);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  const doctorsOption = useMemo(() => {
    const doctorIds = [];
    const options = [];
    schedules.forEach(s => {
      if (doctorIds.indexOf(s.user.id) === -1) {
        options.push(s.user);
        doctorIds.push(s.user.id);
      }
    })
    return options;
  }, [schedules]);

  if (doctorId) {
    const doctor = doctorsOption.find(d => d.id === doctorId)
    if (!doctor) doctorId = null
  }

  useEffect(() => {
    if (doctorId) {
      const bookingsRef = database.ref(`bookings/${currentClinic.id}`);

      bookingsRef
        .orderByChild('doctor_id')
        .equalTo(Number(doctorId))
        .on('value', snapshot => {
          let _bookings = [];

          if (snapshot.val()) {
            _bookings = Object.entries(snapshot.val()).reduce((r, v) => {
              const _v = { ...v[1] };
              if (_v.result && _v.result.images_url) {
                _v.result.images_url = transformObjectToArray(
                  _v.result.images_url
                );
              }
              return r.concat({ key: v[0], ...v[1] });
            }, []);
          }
          _bookings = _bookings
            .filter(b => b.date_day === defaultDate)
            .sort(comparePriority);
          setBookings(_bookings);
        });
    } else {
      setBookings([]);
    }
  }, [doctorId, currentClinic.id]);

  useEffect(() => {
    if (bookings) {
      let bookingActive = null;

      for (let _booking of bookings) {
        if (_booking.doctor_id !== doctorId) continue;

        const status = _booking.status || {};

        if (
          isEmpty(selectedOrders) &&
          !bookingActive &&
          selectedKey !== _booking.key
        ) {
          if (status.id === 4) {
            bookingActive = _booking;
          }
        }
      }

      if (bookingActive) {
        setSelectedOrders({
          [bookingActive.key]: bookingActive,
          ...selectedOrders
        });
        setSelectedKey(bookingActive.key);
      }
    }
  }, [bookings, selectedKey, selectedOrders, doctorId]);

  useEffect(() => {
    if (bookings) {
      const bookingKeys = bookings
        .filter(b => b.status && b.status.id === 6)
        .map(b => b.key);

      for (let key of bookingKeys) {
        if (selectedOrders[key]) {
          setSelectedOrders({
            ...selectedOrders,
            [key]: null
          });
          if (key === selectedKey) {
            setSelectedKey('');
          }
        }
      }
    }
  }, [bookings, selectedKey, selectedOrders]);

  useEffect(() => {
    if (bookings) {
      if (!selectedOrders[selectedKey]) {
        const _booking = bookings.find(b => b.key === selectedKey);
        if (_booking) {
          setSelectedOrders({
            ...selectedOrders,
            [_booking.key]: _booking
          });
        }
      }
    }
  }, [selectedKey, selectedOrders, bookings]);

  const selectedOrdersMemo = useMemo(() => {
    let _selectedOrders = [];
    for (let orderId in selectedOrders) {
      if (selectedOrders[orderId]) {
        _selectedOrders.push(selectedOrders[orderId]);
      }
    }
    return _selectedOrders;
  }, [selectedOrders]);

  const getEducation = (doctor = {}) => {
    const educations = doctor.educations || [];
    const education = educations[0] || {};

    if (education.title) {
      return education.title + ' ' + doctor.name;
    }

    return doctor.name;
  };

  const doctorMemo = useMemo(() => doctorById[doctorId] || {}, [
    doctorById,
    doctorId
  ]);

  const arrayBookingsMemo = useMemo(() => {
    let _bookingsFilter = [];

    for (let key in bookings) {
      const booking = bookings[key] || {};
      const status = booking.status || {};

      if (
        booking.doctor_id === doctorMemo.id &&
        [3, 4, 5, 6].includes(status.id)
      ) {
        _bookingsFilter.push(booking);
      }
    }

    return _bookingsFilter;
  }, [doctorMemo, bookings]);

  const metricBookings = useMemo(() => {
    let objectMetric = {
      '3': [0, 0, 0, 0],
      '6': [0, 0, 0, 0]
    };

    for (let b of arrayBookingsMemo) {
      const status = b.status || {};
      let statusId = (status.id || '').toString();
      const serviceId = b.service_id;

      // Chờ khám, đang khám, gián đoạn gộp vào chờ khám
      if (['3', '4', '5'].includes(statusId)) {
        statusId = '3';
      }

      if (['3', '6'].includes(statusId)) {
        objectMetric[statusId][0] += 1;
        if ([1, 2].includes(serviceId)) {
          objectMetric[statusId][1] += 1;
        } else if ([3, 4].includes(serviceId)) {
          objectMetric[statusId][2] += 1;
        } else {
          objectMetric[statusId][3] += 1;
        }
      }
    }

    return {
      isWait: objectMetric['3'],
      isDone: objectMetric['6']
    };
  }, [arrayBookingsMemo]);
  const getResultLatest = useCallback(
    async userId => {
      try {
        const response = await http.get(`/customers/${userId}/latest_result`);
        const result = response.data;
        if (result?.id) {
          setResultLatest(o => {
            return {
              ...o,
              [userId]: result?.id ? result : {}
            };
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [resultLatest]
  );

  useEffect(() => {
    for (let b of arrayBookingsMemo) {
      if (b.status?.id === 6) continue;
      const userId = b.user?.id || b.user_id;
      if (!userId) continue;

      if (b.getResult === undefined) {
        getResultLatest(userId);
        b.getResult = true
      }
    }
  }, [getResultLatest, arrayBookingsMemo, resultLatest]);

  if (!bookings) {
    return <h1>Loading</h1>;
  }

  const onChangeRoomIdWithReload = value => {
    dispatch(setDoctorId(value));
    localStorage.setItem('doctor_id', value.toString());
    setSelectedKey('');
    setSelectedOrders({});
  };

  const handleUpdateBooking = (bookingKey, dataUpdate) => {
    if (bookingKey) {
      const bookingsRef = database.ref(
        `bookings/${currentClinic.id}/${bookingKey}`
      );
      bookingsRef.update({ ...dataUpdate });
    }
  };

  const handleClickCheckup = _booking => {
    if (!selectedOrders[_booking.key]) {
      setSelectedOrders({
        ...selectedOrders,
        [_booking.key]: _booking
      });
    }

    if (selectedKey && _booking.key !== selectedKey) {
      if (
        selectedOrders[selectedKey] &&
        selectedOrders[selectedKey].status.id !== 6
      ) {
        handleUpdateBooking(selectedKey, {
          status: {
            id: 5,
            name: 'Gián đoạn'
          }
        });
      }
    }

    if (_booking.status.id !== 4 && _booking.status.id !== 6) {
      handleUpdateBooking(_booking.key, {
        status: {
          id: 4,
          name: 'Đang khám'
        }
      });
    }

    setTimeout(() => {
      setSelectedKey(_booking.key);
    }, 0);
  };

  const handleRemoveSelectedOrder = key => {
    if (key === selectedKey) {
      handleUpdateBooking(selectedKey, {
        status: {
          id: 5,
          name: 'Gián đoạn'
        }
      });
      setSelectedKey('');
    }
    setSelectedOrders({
      ...selectedOrders,
      [key]: null
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className="main flex">
        <div className="left">
          <div>
            <Select
              className="custom-select mb-2"
              placeholder="Chọn bác sĩ"
              onChange={onChangeRoomIdWithReload}
              value={doctorId ? Number(doctorId) : null}
            >
              {doctorsOption.map(d => (
                <Option key={d.id} value={d.id}>
                  {getEducation(doctorById[d.id])}
                </Option>
              ))}
            </Select>
            {doctorMemo.id && (
              <div
                className={classnames('room-info', {
                  'is-collapsed': isCollapsed
                })}
              >
                <div className="doctor-name">{getEducation(doctorMemo)}</div>
                <div className="total-bookings">
                  <span>Tổng ca:</span>{' '}
                  <b>
                    {metricBookings.isDone[0] + metricBookings.isWait[0]} ca
                  </b>
                </div>
                <div className="detail-total-bookings flex justify-content-between">
                  <div className="detail-item">
                    <div className="detail-item__total">
                      Đã khám: <b>{metricBookings.isDone[0]} ca</b>
                    </div>
                    <div className="detail-item__type">
                      2D: {metricBookings.isDone[1]} ca
                    </div>
                    <div className="detail-item__type">
                      5D: {metricBookings.isDone[2]} ca
                    </div>
                    <div className="detail-item__type">
                      Khác: {metricBookings.isDone[3]} ca
                    </div>
                  </div>
                  <div className="detail-item">
                    <div className="detail-item__total">
                      Đang chờ: <b>{metricBookings.isWait[0]} ca</b>
                    </div>
                    <div className="detail-item__type">
                      2D: {metricBookings.isWait[1]} ca
                    </div>
                    <div className="detail-item__type">
                      5D: {metricBookings.isWait[2]} ca
                    </div>
                    <div className="detail-item__type">
                      Khác: {metricBookings.isWait[3]} ca
                    </div>
                  </div>
                </div>
                <div
                  className="icon-collapse"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <img src={iconUpSecond} alt="up" />
                  <img src={iconUpFirst} alt="up" />
                </div>
              </div>
            )}
          </div>
          {arrayBookingsMemo
            .filter(booking => (booking.status || {}).id !== 6)
            .map(booking => (
              <div
                className={classnames(
                  { active: booking.status && booking.status.id === 4 },
                  { waiting: booking.status && booking.status.id === 3 },
                  { done: booking.status && booking.status.id === 6 },
                  'item-checkup pointer'
                )}
                key={booking.key}
                onClick={() => handleClickCheckup(booking)}
              >
                <div className="flex justify-content-between">
                  <b className="name">{booking.user_name}</b>
                  <b className="name">#{booking.numerical_order}</b>
                </div>
                <p>{booking.weeks || '???'} tuần</p>
                {booking.service_id && (
                  <div className="service">
                    {serviceById[booking.service_id].name}
                  </div>
                )}
                <div className="wapper-footer flex align-items-center justify-content-between">
                  <p className="status-name">
                    {booking.status ? booking.status.name : ''}
                  </p>
                  {resultLatest[booking?.user?.id || booking.user_id] && (
                    <Tooltip
                      title="Kết quả khám gần nhất"
                      onClick={event => {
                        event.stopPropagation();
                        setVisibleModal(true);
                        const userId = booking?.user?.id || booking.user_id;

                        if (userId) {
                          setBookingDetail(booking);
                          setIdViewResult(userId);
                        }
                      }}
                    >
                      <FundViewOutlined style={{ fontSize: '25px' }} />
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="right tap-checkup">
          <div className="flex tap-checkup__header">
            {selectedOrdersMemo.map(order => (
              <div
                className={classnames(
                  'item-checkup-selected pointer flex justify-content-between',
                  { active: selectedKey === order.key }
                )}
                key={order.key}
                onClick={event => {
                  event.stopPropagation();
                  handleClickCheckup(order);
                }}
              >
                <b>{order.user_name}</b>
                <Popconfirm
                  placement="topRight"
                  title="Bạn có thực sự muốn đóng phiếu lại? Mọi dữ liệu bạn đã nhập sẽ bị mất!"
                  okText="Có"
                  cancelText="Không"
                  okType="danger"
                  onConfirm={(event) => {
                    event.stopPropagation();
                    handleRemoveSelectedOrder(order.key)
                  }}
                >
                  <CloseOutlined />
                </Popconfirm>
              </div>
            ))}
          </div>
          <div className="tab-checkup-content">
            {selectedOrdersMemo.map(order => (
              <div
                className={classnames(
                  'item-checkup-content flex justify-content-between',
                  { active: selectedKey === order.key }
                )}
                key={order.key}
                onClick={event => {
                  event.stopPropagation();
                  handleClickCheckup(order);
                }}
              >
                <FormResultOld booking={order} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalLatestResult
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        resultLatest={resultLatest[idViewResult] || {}}
        booking={bookingViewDetail}
      />
    </div>
  );
}

export default CheckupOldPage;
