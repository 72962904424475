import { createSlice } from '@reduxjs/toolkit';
import normalize from 'utils/normalize';

const inputForm = createSlice({
  name: 'inputForm',
  initialState: {
    byId: {},
    ids: []
  },
  reducers: {
    setInputForms: (state, action) => {
      const { byId, ids } = normalize(action.payload);
      state.byId = byId;
      state.ids = ids;
    },
    addInputForm: (state, action) => {
      const inputForm = action.payload;
      if (!state.ids.includes(inputForm.id)) {
        state.byId[inputForm.id] = inputForm;
        state.ids = [inputForm.id, ...state.ids];
      }
    }
  }
});

export const { setInputForms, addInputForm } = inputForm.actions;

export default inputForm.reducer;
