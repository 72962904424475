import React from 'react';
import classnames from 'classnames';

import {
  QrcodeOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';

import './index.scss';

const StatusBooking = ({ status }) => {
  let iconStatus;

  if (status.id === 1) {
    iconStatus = <CalendarOutlined />;
  } else if (status.id === 2) {
    iconStatus = <QrcodeOutlined />;
  } else if (status.id === 3) {
    iconStatus = <ClockCircleOutlined />;
  } else if (status.id === 4) {
    iconStatus = <PauseCircleOutlined />;
  } else if (status.id === 5) {
    iconStatus = <PlayCircleOutlined />;
  } else if (status.id === 6) {
    iconStatus = <CheckCircleOutlined />;
  } else if (status.id === 7) {
    iconStatus = <CloseSquareOutlined />;
  } else {
    iconStatus = <QuestionCircleOutlined />;
  }

  return (
    <div className={classnames('wrapper-status', `status-${status.id}`)}>
      <span className="wrapper-status__icon">{iconStatus}</span>
      <span className="wrapper-status__title">{status.name}</span>
    </div>
  );
};

export default StatusBooking;
