import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from 'antd';
import { DataTableCustomer } from 'containers/datatable';
import http from 'utils/http';

import styles from './index.module.scss';
import CustomerDetail from 'components/CustomerDetail';

function CustomerPage() {
  const [customers, setCustomers] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const handleSetCustomer = _customer => {
    setCustomer(_customer);
    setVisibleModal(true);
  };

  const getCustomers = useCallback(async () => {
    let _customers = [];
    try {
      const response = await http.get('/customers', {
        params: {
          include: 'babies',
          per_page: 9999
        }
      });
      _customers = response.data.map(c => {
        let gestational_week = '';
        let due_date = '';
        let birthday_year = '';

        if (c.babies) {
          const baby = c.babies[0] || {};
          gestational_week = baby.gestational_week || '';
          due_date = baby.due_date || '';
        }

        if (!c.date_of_birth) birthday_year = '';
        if (c.date_of_birth) {
          const birthdayString = c.date_of_birth.split('-')[0];
          if (birthdayString) {
            birthday_year = new Date().getFullYear() - Number(birthdayString);
          }
        }

        return { ...c, gestational_week, due_date, birthday_year };
      });
    } catch (error) {
      console.error(error);
    }
    setCustomers(_customers);
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  if (!customers) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between mb-2">
        <h1 style={{ marginBottom: 0 }}>Danh sách khách hàng</h1>
      </div>
      <div className="main">
        <DataTableCustomer
          dataSource={customers}
          setCustomer={handleSetCustomer}
        />
      </div>
      <Modal
        width="60%"
        title="Lịch sử khám"
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
      >
        <CustomerDetail customer={customer} />
      </Modal>
    </div>
  );
}

export default CustomerPage;
