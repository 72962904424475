import { useSelector } from 'react-redux';

function useRole() {
  const user = useSelector(state => state.authorization.user);

  if (!user)
    return {
      roles: [],
      isValidRoles: () => false
    };

  let userRoles = [];

  for (let role of user.roles || []) {
    userRoles.push(role.name);
  }

  const isValidRoles = (roles = []) => {
    let isValid = false;
    for (let userRole of userRoles) {
      if (roles.includes(userRole)) {
        isValid = true;
        break;
      }
    }
    return isValid;
  };

  return { roles: userRoles, isValidRoles };
}

export default useRole;
