import http from 'utils/http';

export default {
  getAll: () =>
    http.get(
      `/employees?include=roles,educations,jobs`,
      {
        params: { per_page: 9999 }
      }
    ),
  add: (data) =>
    http.post(
      `/employees?include=roles,educations,jobs`,
      data
    ),
  edit: (data) => {
    const _data = { ...data };
    delete _data.id;
    // delete _data.email;
    // delete _data.phone_number;

    return http.put(
      `/employees/${data.id}?include=roles,educations,jobs`,
      _data
    );
  }
};
