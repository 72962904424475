import _FormEmployee from './form-employee';
import _FormService from './form-service';
import _FormResult from './form-result';
import _FormResultOld from './form-result-old';
import _FormWorkingShift from './form-working-shift';
import _FormCalemdar from './form-calendar';

export const FormEmployee = _FormEmployee;
export const FormService = _FormService;
export const FormResult = _FormResult;
export const FormResultOld = _FormResultOld;
export const FormWorkingShift = _FormWorkingShift;
export const FormCalemdar = _FormCalemdar;
