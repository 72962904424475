import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import TabsImage from 'components/Form/FormUploadResult/TabsImage/TabsImage';
import { defaultDateWithTime } from 'utils/__variable';
import { getEducation, birthdayYear2Age, getResultExaminationForm } from 'utils/__function';

import styles from './index.module.scss';
let contentConclude = '';

function ExaminationFormTemplate({
  optionForm,
  booking,
  defaultSelectedImages = [],
  S3Client,
  loading,
  onHandleFinished
}) {
  const [selectedImages, setSelectedImages] = useState(defaultSelectedImages);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [formContent, setFormContent] = useState('')
  const [note, setNote] = useState('1')

  useEffect(() => {
    booking.formData = {};
    let _formContent = optionForm.content;
    const inputs = optionForm.inputs;
    const regex = /\{\{.*?\}\}/gim;
    let input_ket_luan;
    const matchs = _formContent.match(regex);
    if (matchs) {
      const occurrences = matchs.reduce((acc, curr) => {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
      }, {});
      for (const match in occurrences) {
        const input = inputs.find(i => `{{${i.code}}}` === match)
        if (input) {
          const code = input.code;
          // get value to replace
          let value = input.value;
          if (code === 'ho_ten') {
            value = booking.user_name
          } else if (code === 'nam_sinh') {
            value = booking.birthday || ''
          } else if (code === 'tuoi') {
            value = booking.birthday ? birthdayYear2Age(booking.birthday) : ''
          } else if (code === 'gioi_tinh') {
            if (booking.user?.gender === 'male') {
              value = 'Nam'
            } else if (booking.user?.gender === 'female') {
              value = 'Nữ'
            }
          } else if (code === 'so_dien_thoai') {
            value = `${booking.phone_number}`.replace('+84', '0')
          } else if (code === 'tuan_thai') {
            value = (booking.weeks || '') + ` ${value}`
          } else if (code === 'ngay_thai') {
            value = (booking.days || '') + ` ${value}`
          } else if (code === 'ngay_du_sinh') {
            value = booking.due_date || ''
          } else if (code === 'ngay_sieu_am') {
            value = `Ngày ${defaultDateWithTime}`
          } else if (code === 'thong_tin_bac_si') {
            let doctorInfo = booking.doctor.phone_number
              ? `(SMS ${booking.doctor.phone_number.replace('+84', '0')})`
              : '';
            value = `${getEducation(booking.doctor)} ${doctorInfo}`
          }
          if (code !== 'ket_luan') {
            booking.formData[code] = value;
          }

          let rows = 3;
          if (input.size == 'medium') {
            rows = 5;
          } else if (input.size == 'large') {
            rows = 7;
          } else if (input.size == 'custom') {
            rows = input.number_row;
          }
          // replace match by value
          const frequency = occurrences[match];
          for (let i = 0; i < frequency; i++) {
            let id = `${code}${i > 0 ? `_${i}` : ''}`
            if (code === 'hinh_anh_sieu_am') {
              _formContent = _formContent.replace(match, '')
            } else if (code === 'chon_ket_luan') {
              _formContent = _formContent.replace(match, `
                <div class="ant-radio-group ant-radio-group-outline">
                  <label class="ant-radio-wrapper ant-radio-wrapper-checked">
                    <span class="ant-radio ant-radio-checked">
                      <input type="radio" class="ant-radio-input" value="1" checked="" name="note">
                      <span class="ant-radio-inner"></span>
                    </span>
                    <span>Chưa phát hiện bất thường</span>
                  </label>
                  <label class="ant-radio-wrapper">
                    <span class="ant-radio">
                      <input type="radio" class="ant-radio-input" value="2" name="note">
                      <span class="ant-radio-inner"></span>
                    </span>
                    <span>Bất thường</span>
                  </label>
                </div>
              `)
            } else if (code === 'ket_luan') {
              contentConclude = input.value
              input_ket_luan = input;

              _formContent = _formContent.replace('{{ket_luan}}', `
                <textarea
                  rows="${rows}"
                  id="${id}"
                  class="ket_luan form-input"
                />${updateConclude(note, input.value)}</textarea>
              `)
            } else if (input.type === 'string') {
              _formContent = _formContent.replace(match, `
                <input
                  type="text"
                  class="${code} form-input ${input.size || 'medium'}"
                  id="${id}"
                  value="${value}"
                />
              `)
            } else if (input.type === 'text') {

              _formContent = _formContent.replace(match, `
                <textarea
                  rows="${rows}"
                  id="${id}"
                  class="${code} form-input ${input.size || 'medium'}"
                />${value}</textarea>
              `)
            }
          }
        } else {
          _formContent = _formContent.replace(match, '')

        }
      }
    }

    if (input_ket_luan) {
      setTimeout(() => {
        document.querySelectorAll(`#form_booking_${booking.id} .ant-radio-input`).forEach(item => {
          item.addEventListener('change', event => {
            const { id, value } = event.target;
            setNote(value)
            var elems = document.querySelectorAll(".ant-radio");
            [].forEach.call(elems, function (el) {
              el.classList.remove("ant-radio-checked");
            });
            item.parentElement.classList.add("ant-radio-checked");

            updateConclude(value)
          })
        })

        document.querySelectorAll(`#form_booking_${booking.id} .form-input`).forEach(item => {
          item.addEventListener('change', event => {
            const { id, value } = event.target;
            booking.formData[id] = value
            if (id !== 'ket_luan') {
              updateConclude(note)
            }
          })
        })
      }, 500)
    }
    setFormContent(_formContent)
  }, [optionForm])

  const handleAddSelectedImages = imageSrc => {
    if (Array.isArray(imageSrc)) {
      setSelectedImages(imageSrc)
    } else {
      if (selectedImages.includes(imageSrc)) {
        setSelectedImages(selectedImages.filter(src => src !== imageSrc));
      } else {
        setSelectedImages([imageSrc, selectedImages[0] || '']);
      }
    }
  };

  const handleRemoveSelectedImages = imageSrc => {
    setSelectedImages(selectedImages.filter(src => src.id && src.id !== imageSrc.id));
  };

  const handleOnPrint = () => {
    let formData = getFormData()
    document.querySelectorAll(`#form_booking_${booking.id} .form-input`).forEach(item => {
      formData[item.id] = item.value;
    })
    let resultForm = getResultExaminationForm(formData);
    resultForm.images_url_ultrasound = uploadedImages;
    localStorage.setItem(
      `bookingPrintResult_${optionForm.id}`,
      JSON.stringify({
        ...booking,
        result: resultForm,
        selectedImages: selectedImages.map(i => { return { aws_s3_location: i.aws_s3_location } }),
        optionForm
      })
    );
    window.open(`/in-ket-qua?form_id=${optionForm.id}`, '_blank');
    handleFinished(false)
  };

  const handleFinished = async (updateBookingStatus = true) => {
    let formData = getFormData()
    onHandleFinished(formData, optionForm.inputs, optionForm.content, selectedImages, updateBookingStatus)
  };

  const getFormData = () => {
    let formData = {
      ...booking.formData,
      note: document.querySelector('input[name="note"]') ? document.querySelector('input[name="note"]:checked').value : 1
    }
    document.querySelectorAll(`#form_booking_${booking.id} .form-input`).forEach(item => {
      formData[item.id] = item.value;
    })
    return formData;
  }

  const handleOnChangeImages = (key, value) => {
    if (!booking.formData) {
      booking.formData = getFormData()
    }
    booking.formData[key] = value
    setUploadedImages(value)
  }

  const updateConclude = (noteValue, inputContent = '') => {
    let content = '';
    if (noteValue !== '1') {
      content = ''
    } else {
      const regex = /\{\{.*?\}\}/gim;
      content = contentConclude || inputContent;
      const matchs = content.match(regex);
      if (matchs) {
        matchs.forEach((match) => {
          let code = match.replace('{{', '').replace('}}', '')
          if (booking.formData[code]) {
            content = content.replace(match, booking.formData[code])
          } else {
            content = content.replace(match, ' ')
          }
        })
      }
    }

    if (document.getElementById('ket_luan')) {
      document.getElementById('ket_luan').value = content;
    }
    return content || ''
  }


  return (
    <div className={styles.wrapper} id="form-unique">
      <div>
        <Form
          className="content-form"
          id={`form_booking_${booking.id}`}
        >
          <div dangerouslySetInnerHTML={{ __html: formContent }} id="form-inputs"></div>
        </Form>
        <div className="form-upload-images">
          <h4>
            <b>Tải lên hình ảnh siêu âm</b>
          </h4>
          <TabsImage
            S3Client={S3Client}
            handleOnChange={handleOnChangeImages}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </div>
        <div className="wrapper-btn-print mt-2">
          <Button className="mr-2" onClick={_ => handleFinished(true)} loading={loading}>
            Khám xong
          </Button>
          <Button type="primary" onClick={handleOnPrint}>
            <PrinterOutlined /> In kết quả
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ExaminationFormTemplate;
