import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import classnames from 'classnames';
import { Button, Radio } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import ImagesPreview from 'components/ImagesPreview';
import TabsImage from 'components/Form/FormUploadResult/TabsImage/TabsImage';
import { defaultDateWithTime } from 'utils/__variable';
import { getEducation, resultToObjectFields } from 'utils/__function';
import PrintResultOldPage from '../../../../pages/print-result-old';

import styles from '../index.module.scss';

function FormResult1({
  booking,
  isPreview,
  result = {},
  defaultSelectedImages = [],
  S3Client,
  handleClickButton,
  handleFinishedExamination
}) {
  const formId = 1;
  const _defaultFormValue = {
    form_name: 'Siêu âm thai 5D chuẩn đoán trước sinh',
    user_name: booking.user_name,
    birthday: booking.birthday,
    heart_rate: 'tần số =   chu kỳ/phút',
    slts: '01',
    ctc: '+',
    ct: '+',
    ct_xuong_mui: '+',
    ngoi_thai: 'chưa cố định',
    droppler_ong_tinh_mach: 'BT',
    droppler_dong_mach_tu_cung: 'BT',
    dk_ngang_bung: '- ĐK ngang bụng   mm',
    goc_ois: 'lượng trung bình',
    day_ron: 'bình thường',
    vi_tri_bam: 'mặt ',
    do_truong_thanh: 'độ 0',
    phu_thai: `- Ối: lượng trung bình\n- Dây rốn: bình thường\n- Rau: + Vị trí bám: mặt\n           + Bề dầy:   mm\n           + Độ trưởng thành: độ 0`,
    note: '1'
  }

  const [form, setForm] = useState(_defaultFormValue);
  const [selectedImages, setSelectedImages] = useState(defaultSelectedImages);
  const [enableInnerPrintf, setEnableInnerPrintf] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddSelectedImages = imageSrc => {
    if (Array.isArray(imageSrc)) {
      setSelectedImages(imageSrc)
    } else {
      if (selectedImages.includes(imageSrc)) {
        setSelectedImages(selectedImages.filter(src => src !== imageSrc));
      } else {
        setSelectedImages([imageSrc, selectedImages[0] || '']);
      }
    }
    setEnableInnerPrintf(false);
  };

  const handleRemoveSelectedImages = imageSrc => {
    setSelectedImages(selectedImages.filter(src => src.id && src.id !== imageSrc.id));
    setEnableInnerPrintf(false);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setForm({
      ...form,
      [id]: value
    });
    setEnableInnerPrintf(false);
  };

  // init form khi chọn ở trang điều phối hoặc khi chuyển sang trang in kết quả
  useEffect(() => {
    const _result = { ...result };
    const _objectFields = resultToObjectFields(_result, _defaultFormValue);
    setForm({
      weeks: booking.weeks || '',
      days: booking.days || '',
      due_date: booking.due_date || '',

      ..._result,
      ..._objectFields
    });
  }, [result]);

  useEffect(() => {
    if (!isPreview) {
      let _conclude = `- ${form.slts || '01'} thai trong buồng tử cung tương đương ${form.weeks || '___'} tuần, ${form.days || '0'} ngày. Tim thai (${form.ctc || '+'}) ${form.heart_rate || ''
        }.\n- Hiện tại chưa phát hiện hình ảnh bất thường, hẹn mẹ khám lại sau 3 tuần.`;
      if (form.note === '2') {
        _conclude = '';
      }
      setForm(o => ({
        ...o,
        conclude: _conclude
      }));
    }
  }, [form.slts, form.weeks, form.days, form.heart_rate, form.note, form.ctc]);

  const getPrintfForm = () => {
    return <PrintResultOldPage hiddenHeader={true} />;
  };

  const handleOnPrint = () => {
    handleClickButton(form, formId, booking, selectedImages)
    setTimeout(() => {
      setEnableInnerPrintf(true);
    }, 100);
  };

  const preHandleAddResult = () => {
    const { hasImagesUrlConclude } = handleClickButton(form, formId, booking, selectedImages, false);
    if (!hasImagesUrlConclude) {
      setLoading(true)
      setTimeout(() => {
        setEnableInnerPrintf(true);
      }, 100);
    } else {
      handleAddResult(true)
    }
  };

  const handleAddResult = (updateStatus = false) => {
    handleFinishedExamination(form, formId, booking, result, updateStatus);
  };

  // var parts = "I am a cow; cows say moo. MOOOOO.".split(/(\bmoo+\b)/gi);
  // for (var i = 1; i < parts.length; i += 2) {
  //   parts[i] = <span className="match" key={i}>{parts[i]}</span>;
  // }
  // return <div>{parts}</div>;

  return (
    <div className={styles.wrapper} id="form-unique">
      <Form className={classnames('content-form', { 'is-preview': isPreview })}>
        <h1 className="uppercase clinic-name-title">{booking.clinic_name}</h1>
        {isPreview ? (
          <h3 className="uppercase big-name-title">
            {form.form_name}
          </h3>
        ) : (
          <input
            type="text"
            value={form.form_name || ''}
            style={{ width: '100%' }}
            id="form_name"
            onChange={handleChange}
            className="uppercase form_name"
          />
        )}
        <div className="header">
          <div className="flex jutify-content-between">
            <div className="left flex-1 uppercase">
              <p>
                <b>
                  Họ tên:{' '}
                  {
                    <input
                      type="text"
                      className="w-username_fill input-user-name uppercase"
                      value={form.user_name || ''}
                      id="user_name"
                      onChange={handleChange}
                    />
                  }
                </b>
              </p>
            </div>
            <div className="right flex-3 uppercase text-right">
              <b>
                Năm sinh:
                <input
                  type="text"
                  className="w-100px input-user-name uppercase"
                  value={form.birthday || ''}
                  id="birthday"
                  onChange={handleChange}
                />
              </b>
            </div>
          </div>
          <div className="flex uppercase">
            <p>
              <b>Chỉ định: Siêu âm kiểm tra hình thái thai nhi</b>
            </p>
          </div>
        </div>
        <ImagesPreview selectedImages={selectedImages} />
        <div>
          <b>1. Tổng quan thai</b>
          <div className="flex">
            <div className="left flex-1">
              <p>
                - Số lượng thai:{' '}
                {isPreview ? (
                  <span>{form.slts}</span>
                ) : (
                  <input
                    type="text"
                    id="slts"
                    value={form.slts || ''}
                    style={{ width: '160px', textAlign: 'left' }}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Tim thai: (
                {isPreview ? (
                  <span>{form.ctc}</span>
                ) : (
                  <input
                    type="text"
                    id="ctc"
                    value={form.ctc || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                )
                {' '}{isPreview ? (
                  <span>{form.heart_rate}</span>
                ) : (
                  <input
                    type="text"
                    className="w-200px"
                    value={form.heart_rate || ''}
                    id="heart_rate"
                    onChange={handleChange}
                  />
                )}
              </p>
            </div>
            <div className="right flex-1">
              <p>
                - Ngôi thai:{' '}
                {isPreview ? (
                  <span>{form.ngoi_thai}</span>
                ) : (
                  <input
                    type="text"
                    id="ngoi_thai"
                    value={form.ngoi_thai || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Cử động thai: (
                {isPreview ? (
                  <span>{form.ct}</span>
                ) : (
                  <input
                    type="text"
                    id="ct"
                    value={form.ct || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                )
              </p>
            </div>
          </div>
        </div>
        <div>
          <b>2. Thông số sinh học thai (BIOMETRY)</b>
          <div className="flex">
            <div className="left flex-1">
              <p>
                - Chiều dài đầu mông CRL:{' '}
                {isPreview ? (
                  <span>{form.femur_length || '____'}</span>
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="femur_length"
                    value={form.femur_length || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - ĐK lưỡng đỉnh:{' '}
                {isPreview ? (
                  <span>{form.dk_luong_dinh || '____'}</span>
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="dk_luong_dinh"
                    value={form.dk_luong_dinh || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - Chu vi đầu:{' '}
                {isPreview ? (
                  form.chu_vi_dau || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="chu_vi_dau"
                    value={form.chu_vi_dau || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - Xương mũi:{' '}(
                {isPreview ? (
                  <span>{form.ct_xuong_mui}</span>
                ) : (
                  <input
                    type="text"
                    id="ct_xuong_mui"
                    value={form.ct_xuong_mui || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                ){' '}
                {isPreview ? (
                  form.xuong_mui || '____'
                ) : (
                  <input
                    type="text"
                    id="xuong_mui"
                    value={form.xuong_mui || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <div>
                <span className="nowrap">- Droppler ống tĩnh mạch: </span>{' '}
                {isPreview ? (
                  form.droppler_ong_tinh_mach
                ) : (
                  <input
                    type="text"
                    id="droppler_ong_tinh_mach"
                    value={form.droppler_ong_tinh_mach || ''}
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
            <div className="right flex-1">
              <p>
                {isPreview ? (
                  form.dk_ngang_bung
                ) : (
                  <input
                    type="text"
                    className="w-200px"
                    id="dk_ngang_bung"
                    value={form.dk_ngang_bung || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Chu vi bụng:{' '}
                {isPreview ? (
                  form.chu_vi_bung || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="chu_vi_bung"
                    value={form.chu_vi_bung || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - Chiều dài xương đùi:{' '}
                {isPreview ? (
                  form.chieu_dai_xuong_dui || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="chieu_dai_xuong_dui"
                    value={form.chieu_dai_xuong_dui || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - Chiều dài bàn chân:{' '}
                {isPreview ? (
                  form.chieu_dai_ban_chan || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="chieu_dai_ban_chan"
                    value={form.chieu_dai_ban_chan || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <div>
                <span className="nowrap">- Droppler động mạch tử cung: </span>{' '}
                {isPreview ? (
                  form.droppler_dong_mach_tu_cung
                ) : (
                  <input
                    type="text"
                    id="droppler_dong_mach_tu_cung"
                    value={form.droppler_dong_mach_tu_cung || ''}
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            <p>
              <b>
                - Tuổi thai ước tính:{' '}
                {isPreview ? (
                  form.weeks || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="weeks"
                    value={form.weeks || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                tuần,{' '}
                {isPreview ? (
                  form.days || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="days"
                    value={form.days || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                ngày
              </b>
            </p>
            <p>
              <b>
                - Cân nặng thai ước tính:{' '}
                {isPreview ? (
                  form.weight || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="weight"
                    value={form.weight || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                gram{' '}
              </b>
            </p>
            <p>
              <b>
                - Dự kiến ngày sinh:{' '}
                {isPreview ? (
                  form.due_date || '____'
                ) : (
                  <input
                    type="text"
                    id="due_date"
                    className="w-100px"
                    value={form.due_date || ''}
                    onChange={handleChange}
                    placeholder="dd/mm/yyyy"
                  />
                )}{' '}
                ± 2 tuần
              </b>
            </p>
            <p>
              <b>
                - Khoảng sáng sau gáy:{' '}
                {isPreview ? (
                  form.khoang_sang_sau_gay || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="khoang_sang_sau_gay"
                    value={form.khoang_sang_sau_gay || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </b>
            </p>
          </div>
        </div>
        <div>
          <b>3. Phần phụ thai</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {form.phu_thai}
              </span>
            ) : (
              <textarea
                rows={5}
                id="phu_thai"
                value={form.phu_thai || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div>
          <b>4. Chi tiết khác</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>{form.chi_tiet_khac}</span>
            ) : (
              <textarea
                style={{ width: '100%' }}
                id="chi_tiet_khac"
                value={form.chi_tiet_khac || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="mt-2 block-media hidden-print">
          <b>5. Hình ảnh siêu âm</b>
          <TabsImage
            S3Client={S3Client}
            handleOnChange={(key, value) => setForm({ ...form, [key]: value })}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </div>
        <div className="mt-2">
          <b className="uppercase">Kết luận</b>
          <div
            className="ml-2 hidden-print"
            style={{ display: 'inline-block' }}
          >
            <Radio.Group
              defaultValue="1"
              onChange={event => setForm({ ...form, note: event.target.value })}
              value={form.note}
            >
              <Radio value="1">Chưa phát hiện bất thường</Radio>
              <Radio value="2">Bất thường</Radio>
            </Radio.Group>
          </div>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {result.conclude}
              </span>
            ) : (
              <textarea
                rows={2}
                id="conclude"
                value={form.conclude}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="footer mt-2">
          <div className="footer-content">
            <p>Ngày {defaultDateWithTime}</p>
            <p className="uppercase">Bác sĩ siêu âm</p>
            <br />
            <br />
            {booking.doctor && (
              <div>
                <p>
                  Họ tên:
                  <b className="uppercase">
                    {' '}{getEducation(booking.doctor)}{' '}
                  </b>
                  {booking.doctor.phone_number
                    ? `(SMS ${booking.doctor.phone_number.replace('+84', '0')})`
                    : ''}
                </p>
                {booking.doctor.email && (
                  <p>
                    Gmail: {booking.doctor.email}
                  </p>
                )}
                <p>
                  Mời Mẹ bầu nhắn họ tên vào page : ’’<b> Siêu Âm AN AN </b> ‘’
                  lấy ảnh và video.
                </p>
              </div>
            )}
          </div>
        </div>
      </Form>
      {!isPreview && (
        <div className="wrapper-btn-print mt-2">
          <Button className="mr-2" onClick={preHandleAddResult} loading={loading}>
            Khám xong
          </Button>
          <Button type="primary" onClick={handleOnPrint}>
            <PrinterOutlined /> In kết quả
          </Button>
          <Button
            type="primary"
            onClick={handleAddResult}
            id="btn-auto-complete"
            style={{ display: 'none' }}
          ></Button>

        </div>
      )}
      <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
        {enableInnerPrintf ? getPrintfForm() : <></>}
      </div>
    </div>
  );
}

export default FormResult1;
