import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useInit from 'hooks/useInit';
import useRole from 'hooks/useRole';

import Loading from './Loading';

import Layout from 'layouts';

export default function PrivateRoute({ component: Component, roles, ...rest }) {
  const { loading, textLoading, error } = useInit();
  const { isValidRoles } = useRole();

  if (loading) {
    return <Loading textLoading={textLoading} />;
  }
  
  if (error) {
    return <h1>Error</h1>;
  }

  if (!localStorage.getItem('token')) {
    return <Redirect to="/dang-nhap" />;
  }

  if (roles && !isValidRoles(roles)) {
    return <Redirect to="/lich-hen" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
