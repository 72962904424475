import React, {useMemo, useState} from 'react';
import {Form, Input, Button, notification, InputNumber, Select, Checkbox} from 'antd';
import {useDispatch} from 'react-redux';

import { formatPriceVND } from 'utils';
import { addService, editService } from 'reducers/service';

import serviceService from 'services/service';

import styles from './index.module.scss';
const { Option } = Select;
const { TextArea } = Input;

function FormService({ service, onClose }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const typesOption = [
    {value: '', title: 'Chọn nhóm dịch vụ'},
    {value: 10, title: 'Siêu âm'},
    {value: 20, title: 'Thai sản'},
    {value: 30, title: 'Xét nghiệm'},
  ];

  const isEdit = useMemo(() => {
    if (!service) return false;
    return true;
  }, [service]);

  const handleAdd = _service => {
    return serviceService.add(_service);
  };

  const handleEdit = _service => {
    return serviceService.edit({
      id: service.id,
      ..._service
    });
  };

  const onFinish = async values => {
    setLoading(true);
    try {
      let message = 'Thêm dịch vụ thành công!';

      if (isEdit) {
        const response = await handleEdit(values);
        message = 'Cập nhật dịch vụ thành công!';
        dispatch(editService(response.data));
      } else {
        const response = await handleAdd(values);
        dispatch(addService(response.data));
      }

      notification.success({ message });
      onClose();
    } catch (error) {
      console.error(error);
      const message = isEdit
        ? 'Cập nhật dịch vụ thất bại!'
        : 'Thêm dịch vụ thất bại!';
      notification.success({ message });
    }
    setLoading(false);
  };

  const initialValuesMemo = useMemo(() => {
    const _service = service || {};
    return {
      name: _service.name || '',
      description: _service.description || '',
      price: _service.price || '',
      unit: _service.unit || '',
      type: _service.type || '',
      print_by_group: _service.print_by_group || false
    };
  }, [service]);

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        layout="vertical"
        validateTrigger={['onBlur']}
        onFinish={onFinish}
        initialValues={initialValuesMemo}
      >
        <Form.Item
          required
          label="Tên dịch vụ"
          name="name"
          rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Giá dịch vụ"
          name="price"
          rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            formatter={value => formatPriceVND(value)}
          />
        </Form.Item>
        <Form.Item label="Nhóm dịch vụ" name="type">
          <Select placeholder="Chọn nhóm dịch vụ">
            {typesOption
                .map(type => (
                    <Option
                        key={type.value}
                        value={type.value}
                    >
                      {type.title}
                    </Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item name="print_by_group" valuePropName="checked" noStyle>
          <Checkbox>In phiếu khám theo nhóm</Checkbox>
        </Form.Item>
        <Form.Item label="Đơn vị" name="unit">
          <Input />
        </Form.Item>
        <Form.Item label="Mô tả dịch vụ" name="description">
          <TextArea />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-content-flex-end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {isEdit ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default FormService;
