import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FormBooking from 'components/Form/FormBooking/FormBooking';
import serviceBooking from 'services/booking';

import { database } from 'configs/firebase';
import { notification } from 'antd';
import { defaultDate } from 'utils/__variable';
export default ({
  dataSource,
  booking,
  doctorsByDate,
  onCancel,
  defaultDateParams,
  otherDayBookings,
  setOtherDayBookings,
  handleSetFlagCd
}) => {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values, isToday) => {
    setLoading(true);
    try {
      let _values = { ...values };

      delete _values.key;
      delete _values.date_day;

      _values.status = values.status && values.status.id ? values.status.id : 1;
      _values.date_time = values.date_day + ' ' + values.date_time;

      const serviceBookingUpdate = values.services[0] || {};
      if (serviceBookingUpdate.service_id) {
        _values.service_id = serviceBookingUpdate.service_id;
      }
      if (serviceBookingUpdate.doctor_id) {
        _values.doctor_id = serviceBookingUpdate.doctor_id;
      }
      _values.price = serviceBookingUpdate.price || 0;

      const _otherDayBookings = [...(otherDayBookings || [])];

      const response = await serviceBooking.editBooking(
        _values
      );

      const bookingsUpdate = response.data;
      let bookingIds = [];
      bookingsUpdate.forEach(b => {
        bookingIds.push(b.id)
        if (defaultDate === b.date_day) {
          const _bookingToday = dataSource.find(i => i.id === b.id && i.date_day === b.date_day);
          if (_bookingToday) {
            const bookingsRef = database.ref(
              `bookings/${currentClinic.id}/${_bookingToday.key}`
            );
            bookingsRef.update({ ...b });
          } else {
            const bookingsRef = database.ref(
              `bookings/${currentClinic.id}`
            );
            bookingsRef.push({ ...b });
          }
        } else {
          const _bookingToday = dataSource.find(i => i.id === b.id);
          if (_bookingToday) {
            const bookingsRef = database.ref(
              `bookings/${currentClinic.id}/${_bookingToday.key}`
            );
            bookingsRef.remove();
          }
        }
      })
      if (isToday) {
        setOtherDayBookings(_otherDayBookings.filter(bo => bookingIds.indexOf(bo.id) === -1));
        handleSetFlagCd();
      } else {
        if (_otherDayBookings) {
          bookingsUpdate.forEach(b => {
            const index = _otherDayBookings.findIndex(ob => ob.id === b.id);
            if (index !== -1) {
              _otherDayBookings[index] = {
                ..._otherDayBookings[index],
                date_day: b.date_day,
                date_time: b.date_time,
                service_id: b.service_id,
                doctor_id: b.doctor_id,
                price: b.price
              };
            } else {
              _otherDayBookings.push(b)
            }
          })
          setOtherDayBookings(_otherDayBookings);
        }
      }

      onCancel();
      notification.success({
        message: 'Cập nhật lịch hẹn thành công'
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Cập nhật lịch hẹn không thành công'
      });
    }
    setLoading(false);
  };

  return (
    <FormBooking
      loading={loading}
      onSubmit={onSubmit}
      booking={booking}
      doctorsByDate={doctorsByDate}
      onCancel={onCancel}
      defaultDateParams={defaultDateParams}
      submitText="Cập nhật"
    />
  );
};
