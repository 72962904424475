import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import StatusBooking from 'components/StatusBooking';
import serviceBooking from 'services/booking';
import {
  Button,
  Input,
  Table,
  Space,
  Form,
  Modal,
  notification,
  Tooltip
} from 'antd';

import {
  EditOutlined,
  SearchOutlined,
  LinkOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  MinusOutlined
} from '@ant-design/icons';

import DeleteBooking from 'pages/booking/DeleteBooking';
import CancelBooking from 'pages/booking/CancelBooking';

import { defaultDate } from 'utils/__variable';
import { database } from 'configs/firebase';
import http from 'utils/http';

import {
  comparePriority,
  compareTime,
  filZeroNumber,
  getStepTime,
  getWorkingShifts,
  getEducation
} from 'utils/__function';

import styles from './ListBooking.module.scss';
import { clearTimeout } from 'highcharts';

const convertDate = dateString => {
  const [_d, _m, _y] = dateString.split('-');
  return new Date(`${_y}-${_m}-${_d}`);
};

const now = new Date();
const _d = filZeroNumber(now.getDate());
const _m = filZeroNumber(now.getMonth() + 1);
const _y = now.getFullYear();

let timeoutTooltip;

const getDateTime = booking => {
  let dateTime = booking.date_time || '00:00';

  if (dateTime === '00:00') {
    if (booking.checked_time) {
      const checkedTime = booking.checked_time.split(' ');
      if (checkedTime[1]) {
        dateTime = checkedTime[1];
      }
    }
  }

  return dateTime;
};

let myRef = null;
export default ({
  loading,
  dataSource,
  defaultDateParams,
  setBooking,
  setVisibleModal,
  otherDayBookings,
  setOtherDayBookings,
  isToday,
  handleAddBookingSkip,
  date
}) => {
  const [skipActive, setSkipActive] = React.useState(1);

  const searchNumericalRef = useRef(null);
  const clinic = useSelector(state => state.workspace.currentClinic);
  const serviceById = useSelector(state => state.service.byId);
  const doctorById = useSelector(state => state.employee.byId);
  const [visibleModalUpdateLink, setVisibleModalUpdateLink] = React.useState(
    false
  );
  const [selectedBooking, setSelectedBooking] = React.useState({});
  const [videoUrl, setVideoUrl] = React.useState('');
  const [loadingUpdateLink, setLoadingUpdateLink] = React.useState(false);
  const [selectedTime, setSelectedTime] = React.useState([]);

  const [visibleTooltip, setVisibleTooltip] = React.useState('');
  const [visibleTooltipDetail, setVisibleModalDetail] = React.useState(false);

  const [detailSkips, setDetailSkips] = React.useState({});
  const [workingShifts, setWorkingShifts] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);

  const [doctorsExpanded, setDoctorsExpanded] = React.useState([0]);
  const [onSearch, setOnSearch] = React.useState(false);

  const _workingShifts = useSelector(state =>
    state.workspace.currentWokingShift.ids
      .filter(id => state.workspace.currentWokingShift.byId[id])
      .map(id => state.workspace.currentWokingShift.byId[id])
  );

  const getSchedules = useCallback(async () => {

    const rsp = getWorkingShifts(_workingShifts, convertDate(date || defaultDate));
    setWorkingShifts(rsp.workingShifts)
    setSkipActive(rsp.workingShiftActive);

    let from_date = convertDate(date || defaultDate);
    let to_date = convertDate(date || defaultDate);

    const response = await http.get(`/schedules`, {
      params: {
        per_page: 9999,
        from_date,
        to_date
      }
    });
    setSchedules(response.data);
  }, [date]);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    let _workingShifts = [...workingShifts];
    if (_workingShifts.length && dataSource.length) {
      for (let _workingShift of _workingShifts) {
        _workingShift.totalBokings = dataSource.filter(item => {
          const dateTime = getDateTime(item);
          const itemDateTime = new Date(`${_y}-${_m}-${_d} ${dateTime}`);
          return (
            itemDateTime >= _workingShift.fromExtend &&
            itemDateTime < _workingShift.endExtend
          );
        });
      }
      setWorkingShifts(_workingShifts)
    }
  }, [dataSource])

  const bookingsInShift = useMemo(() => {
    const _workingShift = workingShifts.find(wS => wS.id === skipActive);
    return _workingShift ? _workingShift.totalBokings : [];
  }, [workingShifts, skipActive]);

  const handleSearch = (selectedKeys, confirm) => {
    if (
      searchNumericalRef.current &&
      searchNumericalRef.current.state &&
      searchNumericalRef.current.state.value
    ) {
      setOnSearch(true);
    } else {
      setOnSearch(false);
    }
    confirm();
  };

  const handleReset = clearFilters => {
    setOnSearch(false);
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex, placeholder = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchNumericalRef}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          if (searchNumericalRef && searchNumericalRef.current) {
            searchNumericalRef.current.focus();
          }
        }, 100);
      }
    },
    render: text => text
  });

  const getBookingsSameNumberOrder = (b) => {
    let _dataSource = [...dataSource];
    const _bookings = _dataSource.filter(d => d.numerical_order === b.numerical_order && d.user_id === b.user_id)
    b.services = _bookings.map(b => {
      return {
        service_id: b.service_id,
        doctor_id: b.doctor_id,
        price: b.price,
        payment_method: b.payment_method,
        booking_id: b.id
      }
    })
    setBooking(b)
  }

  const dataMemo = useMemo(() => {
    const _workingShift = workingShifts.find(wS => wS.id === skipActive);
    if (!_workingShift) return [];
    let startTime = _workingShift.fromExtend;
    let endTime = _workingShift.endExtend;

    let _dataSource = dataSource.filter(item => {
      const dateTime = getDateTime(item);

      if (dateTime === '00:00') return true;

      const itemTime = new Date(`${_y}-${_m}-${_d} ${dateTime}`);
      return onSearch ? true : itemTime >= startTime && itemTime < endTime;
      // return true;
    });

    if (!selectedTime.length) {
      return _dataSource.sort(compareTime);
    }

    let _dataMemo = _dataSource.filter(item => {
      let isValid = false;

      for (let itemSelectedTime of selectedTime) {
        const hours = new Date(itemSelectedTime).getHours();
        const minutes = new Date(itemSelectedTime).getMinutes();

        const dateTimeCompare = `${filZeroNumber(hours)}:${filZeroNumber(
          minutes
        )}`;
        if (item.date_time === dateTimeCompare) {
          isValid = true;
          break;
        }
      }
      return isValid;
    });
    return _dataMemo.sort(comparePriority);
  }, [
    dataSource,
    selectedTime,
    workingShifts,
    skipActive,
    onSearch
  ]);

  const titleSubTable = useMemo(() => {
    const _objectSkip = workingShifts.find(wS => wS.id === skipActive);
    if (!_objectSkip) return [];
    return getStepTime(_objectSkip.fromText, _objectSkip.endText, _objectSkip.duration_kip_work);
  }, [skipActive, workingShifts]);

  useEffect(() => {
    setSelectedTime([]);
  }, [skipActive]);

  const bookingsDetail = useMemo(() => {
    if (!detailSkips.bookings) return [];

    let _bookingsDetail = [];

    for (let b of detailSkips.bookings) {
      const statusId = (b.status || {}).id;
      if (!statusId) continue;
      if (_bookingsDetail[statusId]) {
        _bookingsDetail[statusId].push(b);
      } else {
        _bookingsDetail[statusId] = [b];
      }
    }
    return _bookingsDetail.filter(bd => !!bd);
  }, [detailSkips]);

  const doctorsSchedule = useMemo(() => {
    let _doctorsSchedule = [];
    let _ids = [];
    const _workingShift = workingShifts.find(wS => wS.id === skipActive);
    if (!_workingShift) return _doctorsSchedule;
    let _schedules = schedules.filter(o => o.start_time === `${_workingShift.fromText}:00`).filter(s => s.shift === _workingShift.id);
    let _dataSource = dataSource.filter(o => {
      return o.date_time
        ? +o.date_time.replace(':', '') >=
        +_workingShift.fromText.replace(':', '') &&
        +o.date_time.replace(':', '') <=
        +_workingShift.endText.replace(':', '')
        : false;
    });
    for (const schedule of _schedules) {
      const doctor = schedule.user;
      if (!doctor) continue;

      if (!_ids.includes(doctor.id)) {
        _doctorsSchedule.push(doctor);
        _ids.push(doctor.id);
      }
    }

    for (const item of _dataSource) {
      const doctor = doctorById[item.doctor_id];
      if (!doctor || _ids.includes(item.doctor_id)) continue;
      _doctorsSchedule.push({
        id: doctor.id,
        name: doctor.name
      });
      _ids.push(item.doctor_id);
    }
    return _doctorsSchedule.concat({ name: 'Chưa có BS' });
  }, [schedules, dataSource, doctorById, skipActive, workingShifts]);

  const renderDetailType = arrayCount => {
    const types = ['2D', '5D', 'thai sản', 'xét nghiệm', 'siêu âm khác'];
    return (
      <>
        {arrayCount.map((itemCount, indexItemCount) => {
          return (
            <div key={indexItemCount}>
              {itemCount} ca {types[indexItemCount]}
            </div>
          );
        })}
      </>
    );
  };

  const isAllowAdd = React.useCallback(
    (dateTime = '00:00') => {
      let dateDay = date || defaultDate;

      const [d, m, y] = dateDay.split('-');

      if (new Date(`${y}-${m}-${d} ${dateTime}`) < new Date()) {
        return false;
      }

      return true;
    },
    [date]
  );

  const countTotalService = useCallback(
    (dId, kip) => {
      let _countTotalService = [0, 0, 0, 0, 0]; // 2D, 5D, thai sản, xét nghiệm, khác
      let _bookingsFilter = bookingsInShift;

      if (kip) {
        _bookingsFilter = _bookingsFilter.filter(b => b.date_time === kip.value || checkedTimeInKip(b.checked_time, kip));
      }

      if (dId) {
        _bookingsFilter = _bookingsFilter.filter(b => b.doctor_id === dId);
      } else {
        _bookingsFilter = _bookingsFilter.filter(b => !b.doctor_id);
      }

      for (const b of _bookingsFilter) {
        let service = serviceById[b.service_id];
        if ([1, 2].includes(b.service_id)) {
          _countTotalService[0] += 1;
        } else if ([3, 4].includes(b.service_id)) {
          _countTotalService[1] += 1;
        } else if (service && service.type === 20) {
          _countTotalService[2] += 1;
        } else if (service && service.type === 30) {
          _countTotalService[3] += 1;
        } else {
          _countTotalService[4] += 1;
        }
      }

      return _countTotalService;
    },
    [bookingsInShift]
  );

  const checkedTimeInKip = (checked_time, kip) => {
    if (!checked_time) return false;
    const timeChecked = checked_time.split(' ')[1];
    const dateChecked = (checked_time.split(' ')[0]).split('-');
    const checkTime = new Date(
      `${dateChecked[2]}-${dateChecked[1]}-${dateChecked[0]} ${timeChecked}`
    ).getTime();
    return kip.fromTime <= checkTime && checkTime <= kip.toTime;
  }

  const countNumberBooking = (doctor, kip) => {
    const doctorId = doctor.id;
    let _bookingsFilter = bookingsInShift;

    if (kip) {
      _bookingsFilter = _bookingsFilter.filter(b => b.date_time === kip.value || checkedTimeInKip(b.checked_time, kip));
    }

    if (!doctorId) {
      _bookingsFilter = _bookingsFilter.filter(b => !b.doctor_id);
    } else {
      _bookingsFilter = _bookingsFilter.filter(b => b.doctor_id === doctorId);
    }

    return _bookingsFilter.length;
  };

  const getBookingDetail = (doctorId, kip) => {
    return dataSource.filter(
      b => b.doctor_id === doctorId && (b.date_time === kip.value || checkedTimeInKip(b.checked_time, kip))
    );
  };

  const handleUpdateNote = async (target, note) => {
    if (myRef) {
      clearTimeout(myRef);
    }

    myRef = setTimeout(async () => {
      target.disabled = true;
      let noteTxt = target.value;
      if (isToday) {
        const bookingsRef = database.ref(`bookings/${clinic.id}/${note.key}`);
        bookingsRef.update({
          note: noteTxt
        });
        notification.success({
          message: 'Cập nhật note thành công'
        });
        target.disabled = false;
      } else {
        const response = await serviceBooking.updateNoteBooking(
          note.id,
          noteTxt
        );
        target.disabled = false;
        if (!response) {
          notification.error({
            message: 'Cập nhật note không thành công'
          });
          return;
        }

        if (!response.meta.code >= 400) {
          notification.error({
            message: 'Cập nhật note không thành công'
          });
          return;
        }
        notification.success({
          message: 'Cập nhật note thành công'
        });
      }
    }, 0);
  };

  useEffect(() => {
    if (selectedBooking.result && selectedBooking.result.video_url) {
      setVideoUrl(selectedBooking.result.video_url);
    } else {
      setVideoUrl('');
    }
  }, [selectedBooking]);

  const handleUpdateLink = async () => {
    let _booking = { ...selectedBooking };
    const result = _booking.result || {};
    delete _booking.result;

    if (isToday) {
      const bookingsRef = database.ref(`bookings/${clinic.id}/${_booking.key}`);
      bookingsRef.update({
        result: {
          ...result,
          video_url: videoUrl
        }
      });
    }
    setLoadingUpdateLink(true);
    try {
      await http.put(`/bookings/${_booking.id}/upload-result`, {
        video_url: videoUrl,
        id: _booking.id,
        date_day: _booking.date_day
      });
      if (!isToday) {
        let _otherDayBookings = [...(otherDayBookings || [])];
        const index = _otherDayBookings.findIndex(ob => ob.id === _booking.id);
        if (index !== -1) {
          _otherDayBookings[index] = {
            ..._otherDayBookings[index],
            result: {
              ..._otherDayBookings[index].result,
              video_url: videoUrl
            }
          }
        }
        setOtherDayBookings(_otherDayBookings);
      }
      setVisibleModalUpdateLink(false);
      notification.success({
        message: 'Cập nhật link Youtube kết quả thành công'
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Cập nhật link Youtube kết quả thất bại'
      });
    }

    setLoadingUpdateLink(false);
  };

  const columns = [
    {
      key: 'numerical_order',
      title: '#',
      align: 'center',
      dataIndex: 'numerical_order',
      className: 'td-order',
      fixed: 'left',
      ...getColumnSearchProps('numerical_order', 'Nhập số khám')
    },
    {
      key: 'user_name',
      title: 'Người dùng',
      className: 'td-user',
      ...getColumnSearchProps('user_name', 'Nhập tên'),
      render: r => (
        <>
          <span>{r.user_name}</span>
          <br />
          <span>
            {r.phone_number ? r.phone_number.replace('+84', '0') : ''}
          </span>
        </>
      )
    },
    {
      key: 'doctor',
      title: 'Bác sĩ',
      dataIndex: 'doctor_id',
      width: 150,
      render: r => {
        if (doctorById[r]) {
          return getEducation(doctorById[r]);
        }
        return '-';
      }
    },
    {
      key: 'weeks',
      title: 'Tuần thai',
      dataIndex: 'weeks',
      className: 'td-weeks',
      render: r => (r ? `${filZeroNumber(r)} tuần` : <span>-</span>)
    },
    {
      key: 'service_type',
      title: 'Dịch vụ',
      width: 200,
      className: 'td-service',
      render: r => {
        if (r.service_id && serviceById[r.service_id]) {
          const serviceName = serviceById[r.service_id].name;

          return (
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '200px'
              }}
            >
              {serviceName}
            </span>
          );
        }
        return <span>-</span>;
      }
    },
    {
      key: 'date_time',
      title: 'Giờ hẹn',
      dataIndex: 'date_time',
      className: 'td-date-time',
      align: 'right',
      render: r => {
        if (!r || r === '00:00') return '-';

        return r;
      }
    },
    {
      key: 'checked_time',
      title: 'Giờ đến',
      dataIndex: 'checked_time',
      className: 'td-checked-time',
      align: 'right',
      render: r => (r ? r.split(' ')[1] : null)
    },
    {
      key: 'status',
      title: 'Trạng thái',
      className: 'td-status',

      align: 'center',
      render: r => {
        if (!r.status) return '';

        return <StatusBooking status={r.status} />;
      }
    },
    {
      key: 'payment_method',
      title: 'Thanh toán',
      className: 'td-payment',
      width: 150,
      align: 'center',
      render: r => {
        return (
          <span>{r.payment_method === 2 ? 'Chuyển khoản' : 'Tiền mặt'}</span>
        );
      }
    },
    {
      key: 'note',
      title: 'Ghi chú',
      className: 'td-note',
      width: 200,
      align: 'center',
      render: r => {
        return (
          <>
            <Input
              defaultValue={r.note}
              onPressEnter={event => {
                handleUpdateNote(event.target, r);
              }}
            />
          </>
        );
      }
    },
    {
      key: 'result',
      title: 'Kết quả',
      className: 'td-result',
      align: 'center',
      render: (r, object) => {
        if (
          r.status &&
          r.status.id === 6 &&
          object.result &&
          object.result.uuid
        ) {
          function copyToClipboard(text) {
            const input = document.createElement('textarea');
            input.innerHTML = text;
            document.body.appendChild(input);
            input.select();
            const result = document.execCommand('copy');
            document.body.removeChild(input);

            return result;
          }

          return (
            <div className="flex" style={{ flexDirection: 'column' }}>
              <Button
                size="small"
                onClick={() => {
                  setSelectedBooking(object);
                  setVisibleModalUpdateLink(true);
                }}
              >
                <VideoCameraOutlined /> Link video
              </Button>
              <span id="btn-result-link"></span>
              <Tooltip
                title="Đã copy"
                trigger={['click']}
                visible={visibleTooltip === object.result.uuid}
              >
                <Button
                  style={{ marginTop: '5px' }}
                  size="small"
                  onClick={() => {
                    setVisibleTooltip(object.result.uuid);

                    copyToClipboard(
                      `${process.env.REACT_APP_RESULT_URL}/timelines/${object.result.uuid}`
                    );

                    if (timeoutTooltip) {
                      clearTimeout(timeoutTooltip);
                    }

                    timeoutTooltip = setTimeout(() => {
                      setVisibleTooltip('');
                    }, 750);
                  }}
                >
                  <LinkOutlined /> Link KQ
                </Button>
              </Tooltip>
            </div>
          );
        }
        return null;
      }
    },
    {
      key: '',
      align: 'right',
      className: 'td-action',
      fixed: 'right',
      render: r => {
        const isOverDate =
          !defaultDateParams ||
          defaultDateParams._i === defaultDate ||
          convertDate(defaultDateParams._i) >= convertDate(defaultDate);

        if (!isOverDate) {
          return null;
        }
        if (r.checked_time && (r.status.id <= 7 && r.status.id >= 4))
          return null;
        if (r.checked_time)
          return (
            <div
              className="flex"
              style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
              <CancelBooking
                defaultDateParams={defaultDateParams}
                booking={r}
                otherDayBookings={otherDayBookings}
                setOtherDayBookings={setOtherDayBookings}
              />
            </div>
          ); // đã in phiếu

        return (
          <div
            className="flex"
            style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
          >
            <Button
              size="small"
              style={{ flexBasis: '48.5%' }}
              onClick={() => {
                getBookingsSameNumberOrder(r)
                setVisibleModal(true);
              }}
            >
              <EditOutlined /> Sửa
            </Button>
            <DeleteBooking
              defaultDateParams={defaultDateParams}
              booking={r}
              otherDayBookings={otherDayBookings}
              setOtherDayBookings={setOtherDayBookings}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className={styles.wrapper}>
      <div className="flex" style={{ backgroundColor: '#f7e8e2' }}>
        <div className="tabs-skip">
          {workingShifts.map((workingShift, workingShiftIndex) => (
            <div
              key={workingShiftIndex}
              className={classnames('tab-skip-item', {
                active: skipActive === workingShift.id
              })}
              onClick={() => setSkipActive(workingShift.id)}
            >
              <span>{workingShift.name} ({workingShift.totalBokings.length})</span>
              <br />
              <small>{workingShift.fromText}-{workingShift.endText}</small>
            </div>
          ))}
        </div>
      </div>
      <div className="header flex justify-content-between">
        <div className="wrapper-steps-time" id="wrapper-steps-time">
          <div
            className={classnames('flex-1 item-step-time')}
            style={{ cursor: 'default', textAlign: 'left' }}
          >
            Bác sĩ trực <br />
            {doctorsSchedule.map((doctor, doctorIndex) => (
              <div key={doctor.id || Math.random()} className="item-sub-table">
                <div className="col-doctor">
                  <div>
                    <div
                      className="wrapper-icon-collapse"
                      onClick={() => {
                        if (doctorsExpanded.includes(doctorIndex)) {
                          setDoctorsExpanded(
                            doctorsExpanded.filter(di => di !== doctorIndex)
                          );
                        } else {
                          setDoctorsExpanded(
                            doctorsExpanded.concat(doctorIndex)
                          );
                        }
                      }}
                    >
                      {doctorsExpanded.includes(doctorIndex) ? (
                        <MinusOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                    </div>
                    <b className="ml-1 mr-1">{doctor.name}</b>
                  </div>
                  <span>({countNumberBooking(doctor)} ca)</span>
                </div>
                <div
                  className={classnames('detail-type', {
                    'is-show': doctorsExpanded.includes(doctorIndex)
                  })}
                  style={{ paddingLeft: '21px' }}
                >
                  {renderDetailType(countTotalService(doctor.id))}
                </div>
              </div>
            ))}
          </div>
          {titleSubTable.map(t => {
            return (
              <div
                key={t.fromTime}
                className={classnames('flex-1 item-step-time', {
                  active: selectedTime.includes(t.fromTime)
                })}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedTime([t.fromTime]);
                }}
              >
                <div
                  className="icon-close"
                  onClick={event => {
                    event.stopPropagation();
                    setSelectedTime([]);
                  }}
                >
                  X
                </div>
                <span style={{ paddingLeft: '8px' }}>{t.title}</span>
                <br />
                {doctorsSchedule.map((doctor, doctorIndex) => {
                  return (
                    <div
                      key={doctor.id || Math.random()}
                      className="item-sub-table"
                    >
                      <div style={{ paddingLeft: '8px' }}>
                        <span
                          className="total-booking"
                          onClick={event => {
                            event.stopPropagation();
                            if (countNumberBooking(doctor, t) !== 0) {
                              setVisibleModalDetail(true);
                              let objectDetail = {
                                time: t.value,
                                bookings: getBookingDetail(doctor.id, t),
                                doctor
                              };
                              setDetailSkips(objectDetail);
                            }
                          }}
                        >
                          {countNumberBooking(doctor, t)} ca
                        </span>
                        {isAllowAdd(t.value) ? (
                          <Tooltip title="Thêm ca mới vào khung giờ này">
                            <span
                              size="small"
                              className="btn-add-time-skip ml-2"
                              onClick={event => {
                                event.stopPropagation();

                                const objectBookingInit = {
                                  date_time: t.value,
                                  doctor_id: doctor.id
                                };
                                handleAddBookingSkip(objectBookingInit);
                              }}
                            >
                              <PlusOutlined />
                            </span>
                          </Tooltip>
                        ) : (
                          <span
                            size="small"
                            className="btn-add-time-skip ml-2 invalid"
                            onClick={event => {
                              event.stopPropagation();
                            }}
                          >
                            <PlusOutlined />
                          </span>
                        )}
                      </div>
                      <div
                        className={classnames('detail-type', {
                          'is-show': doctorsExpanded.includes(doctorIndex)
                        })}
                      >
                        {renderDetailType(
                          countTotalService(doctor.id, t)
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataMemo}
        pagination={false}
        scroll={{ x: 1200 }}
      />
      <Modal
        title="Cập nhật link Youtube video kết quả"
        visible={visibleModalUpdateLink}
        footer={null}
        onCancel={() => {
          setVisibleModalUpdateLink(false);
        }}
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item label="Link Youtube video kết quả:">
            <Input
              value={videoUrl}
              onChange={event => setVideoUrl(event.target.value)}
            />
          </Form.Item>
          <div>
            <Button
              disabled={!videoUrl}
              loading={loadingUpdateLink}
              onClick={handleUpdateLink}
            >
              Cập nhật
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title={`Chi tiết ca khám`}
        visible={visibleTooltipDetail}
        footer={null}
        onCancel={() => {
          setVisibleModalDetail(false);
        }}
        destroyOnClose
        wrapClassName={styles.modalDetailSkips}
      >
        <div>
          {(detailSkips.doctor || {}).name ? (
            <span>
              Bác sĩ làm việc: <b>{(detailSkips.doctor || {}).name}</b>
              <br />
            </span>
          ) : (
            <b>
              Chưa phân công bác sĩ cụ thể
              <br />
            </b>
          )}
          <span>
            Thời gian:{' '}
            <span>
              {date || defaultDate} {detailSkips.time}
            </span>
          </span>
          <div className="list-bookings-detail">
            <table>
              <thead>
                <tr>
                  <th>Trạng thái</th>
                  <th>Dịch vụ</th>
                  <th>Khách hàng</th>
                </tr>
              </thead>
              <tbody>
                {bookingsDetail.map((arrayBookings, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex justify-content-between">
                        <StatusBooking status={arrayBookings[0].status} />{' '}
                        <b>({arrayBookings.length} ca)</b>
                      </div>
                    </td>
                    <td>
                      {arrayBookings.map(b => {
                        const sId = b.service_id;
                        if (!sId) return null;
                        return (
                          <span key={b.id}>
                            - {serviceById[sId].name}
                            <br />
                          </span>
                        );
                      })}
                    </td>
                    <td>
                      {arrayBookings.map(b => {
                        return (
                          <span key={b.id}>
                            <span>{b.user_name}</span> -
                            <span>
                              {b.phone_number
                                ? b.phone_number.replace('+84', '0')
                                : ''}
                            </span>
                            <br />
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
