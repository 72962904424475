import React, { useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import http from 'utils/http';

import imageBackground from 'assets/images/background-forgot-password.svg';

import styles from './index.module.scss';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async values => {
    setLoading(true);
    try {
      await http.post('/auth/forgot-password', values);
      notification['error']({
        message: 'Gửi email thành công!'
      });
    } catch (error) {
      notification['error']({
        message: 'Gửi email thất bại!'
      });
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className="left">
        <img src={imageBackground} alt="" />
      </div>
      <div className="right">
        <div className="content">
          <div className="page-title">2BABY & CLINIC</div>
          <div className="title">Quên mật khẩu</div>
          <p>Nhập email để được cấp lại mật khẩu đăng nhập</p>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              email: 'nhiembv@stovietnam.com',
              password: '123qaz',
              remember: true
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Vui lòng nhập email!' },
                { type: 'email', message: 'Email không đúng định dạng!' }
              ]}
              validateTrigger={['onBlur']}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Cấp lại mật khẩu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
