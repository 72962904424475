import React from 'react';
import { Skeleton } from 'components';

import styles from './index.module.scss';

function SkeletonMetricHome() {
  return (
    <div className={styles.wrapper}>
      <div className="item">
        <div>
          <Skeleton.Text />
        </div>
        <div className="item-value">
          <Skeleton.Text width={'25%'} />
        </div>
      </div>
      <div className="item">
        <div>
          <Skeleton.Text />
        </div>
        <div className="item-value">
          <Skeleton.Text width={'25%'} />
        </div>
      </div>
      <div className="item">
        <div>
          <Skeleton.Text />
        </div>
        <div className="item-value">
          <Skeleton.Text width={'25%'} />
        </div>
      </div>
      <div className="item">
        <div>
          <Skeleton.Text />
        </div>
        <div className="item-value">
          <Skeleton.Text width={'25%'} />
        </div>
      </div>
    </div>
  );
}

export default SkeletonMetricHome;
