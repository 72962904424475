import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import classnames from 'classnames';
import { Button, Radio } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import ImagesPreview from 'components/ImagesPreview';
import TabsImage from 'components/Form/FormUploadResult/TabsImage/TabsImage';
import { defaultDateWithTime } from 'utils/__variable';
import { getEducation, resultToObjectFields } from 'utils/__function';
import PrintResultPage from '../../../../pages/print-result';

import styles from '../index.module.scss';

function FormResult9({
  booking,
  isPreview,
  result = {},
  defaultSelectedImages = [],
  S3Client,
  handleClickButton,
  handleFinishedExamination
}) {
  const _defaultFormValue = {
    form_name: 'Siêu âm thai thường màu',
    user_name: booking.user_name,
    birthday: booking.birthday,
    slts: '01',
    heart_rate: 'tần số   chu kỳ/phút',
    ctc: '+',
    ctc1: '+',
    format_weight: '± 200 gram',
    ngoi_thai: 'chưa cố định',
    di_tat: 'không quan sát thấy',
    dk_ngang_bung: '- ĐK ngang bụng   mm',
    phu_thai: `- Ối: Tổng 4 góc ối :   mm\n- Dây rốn: Cấu trúc: 2 ĐM và 1TM\n- Rau: + Vị trí bám: mặt\n           + Bề dầy:   mm\n           + Độ trưởng thành: độ 0`,
    note: '1'
  }
  const formId = 9;
  const [form, setForm] = useState(_defaultFormValue);
  const [selectedImages, setSelectedImages] = useState(defaultSelectedImages);
  const [enableInnerPrintf, setEnableInnerPrintf] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddSelectedImages = imageSrc => {
    if (Array.isArray(imageSrc)) {
      setSelectedImages(imageSrc)
    } else {
      if (selectedImages.includes(imageSrc)) {
        setSelectedImages(selectedImages.filter(src => src !== imageSrc));
      } else {
        setSelectedImages([imageSrc, selectedImages[0] || '']);
      }
    }
    setEnableInnerPrintf(false);
  };

  const handleRemoveSelectedImages = imageSrc => {
    setSelectedImages(selectedImages.filter(src => src.id && src.id !== imageSrc.id));
    setEnableInnerPrintf(false);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setForm({
      ...form,
      [id]: value
    });
    setEnableInnerPrintf(false);
  };

  useEffect(() => {
    const _result = { ...result };
    const _objectFields = resultToObjectFields(_result, _defaultFormValue);
    setForm({
      weeks: booking.weeks || '',
      days: booking.days || '',
      due_date: booking.due_date || '',

      ..._result,
      ..._objectFields
    });
  }, [result]);

  useEffect(() => {
    if (!isPreview) {
      let _conclude = `- ${form.slts
        } thai trong buồng tử cung tương đương ${form.weeks ||
        '___'} tuần, ${form.days || 0} ngày. Tim thai (${form.ctc || ''}) ${form.heart_rate || ''}.
- Cân nặng ước tính: ${form.weight || ''} gram ${form.format_weight || ''}.
- Ối: ${form.b_goc_oi ||
        'Tổng 4 góc ối'}: ${form.tong_4_goc_oi || ''} mm
- Chưa phát hiện bất thường, hẹn mẹ khám lại sau ___ tuần ___ ngày.`;
      if (form.note === '2') {
        _conclude = '';
      }
      setForm(o => ({
        ...o,
        conclude: _conclude
      }));
    }
  }, [
    form.slts,
    form.weeks,
    form.days,
    form.ctc,
    form.heart_rate,
    form.weight,
    form.format_weight,
    form.b_goc_oi,
    form.tong_4_goc_oi,
    form.note
  ]);

  const getPrintfForm = () => {
    return <PrintResultPage hiddenHeader={true} />;
  };

  const handleOnPrint = () => {
    handleClickButton(form, formId, booking, selectedImages)
    setTimeout(() => {
      setEnableInnerPrintf(true);
    }, 100);
  };

  const preHandleAddResult = () => {
    const { hasImagesUrlConclude } = handleClickButton(form, formId, booking, selectedImages, false);
    if (!hasImagesUrlConclude) {
      setLoading(true)
      setTimeout(() => {
        setEnableInnerPrintf(true);
      }, 100);
    } else {
      handleAddResult(true)
    }
  };

  const handleAddResult = (updateStatus = false) => {
    handleFinishedExamination(form, formId, booking, result, updateStatus);
  };

  return (
    <div className={styles.wrapper} id="form-unique">
      <Form className={classnames('content-form', { 'is-preview': isPreview })}>
        <h1 className="uppercase clinic-name-title">{booking.clinic_name}</h1>
        {isPreview ? (
          <h3 className="uppercase big-name-title">
            {form.form_name}
          </h3>
        ) : (
          <input
            type="text"
            value={form.form_name || ''}
            style={{ width: '100%' }}
            id="form_name"
            onChange={handleChange}
            className="uppercase form_name"
          />
        )}
        <div className="header flex jutify-content-between">
          <div className="flex-1 uppercase">
            <p>
              <b>
                Họ tên:{' '}
                {
                  <input
                    type="text"
                    className="w-username_fill input-user-name uppercase"
                    value={form.user_name || ''}
                    id="user_name"
                    onChange={handleChange}
                  />
                }
              </b>
            </p>
            <p>
              <b>Chỉ định: Siêu âm kiểm tra hình thái thai nhi</b>
            </p>
          </div>
          <div className="flex-3 uppercase text-right">
            <b>
              Năm sinh:{' '}
              <input
                type="text"
                className="w-100px input-user-name uppercase"
                value={form.birthday || ''}
                id="birthday"
                onChange={handleChange}
              />
            </b>
          </div>
        </div>
        <ImagesPreview selectedImages={selectedImages} />
        <div>
          <b>1. Tổng quan thai</b>
          <div className="flex">
            <div className="flex-1">
              <p>
                - Số lượng thai:{' '}
                {isPreview ? (
                  <span>{form.slts}</span>
                ) : (
                  <input
                    type="text"
                    id="slts"
                    value={form.slts || ''}
                    style={{ width: '160px', textAlign: 'left' }}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Tim thai: (
                {isPreview ? (
                  <span>{form.ctc}</span>
                ) : (
                  <input
                    type="text"
                    id="ctc"
                    value={form.ctc || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                ){' '}{isPreview ? (
                  form.heart_rate
                ) : (
                  <input
                    type="text"
                    className="w-200px"
                    value={form.heart_rate || ''}
                    id="heart_rate"
                    onChange={handleChange}
                  />
                )}
              </p>
            </div>
            <div className="flex-1">
              <p>
                - Ngôi thai:{' '}
                {isPreview ? (
                  form.ngoi_thai
                ) : (
                  <input
                    type="text"
                    id="ngoi_thai"
                    value={form.ngoi_thai || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Cử động thai: (
                {isPreview ? (
                  <span>{form.ctc1}</span>
                ) : (
                  <input
                    type="text"
                    id="ctc1"
                    value={form.ctc1 || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                )
              </p>
            </div>
          </div>
        </div>
        <div>
          <b>2. Thông số sinh học thai (BIOMETRY)</b>
          <div className="flex">
            <div className="left flex-1">
              <p>
                - ĐK lưỡng đỉnh:{' '}
                {isPreview ? (
                  form.dk_luong_dinh
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="dk_luong_dinh"
                    value={form.dk_luong_dinh || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - Chu vi đầu:{' '}
                {isPreview ? (
                  form.chu_vi_dau
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="chu_vi_dau"
                    value={form.chu_vi_dau || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
            </div>
            <div className="right flex-1">
              <p>
                {isPreview ? (
                  form.dk_ngang_bung
                ) : (
                  <input
                    type="text"
                    className="w-350px"
                    id="dk_ngang_bung"
                    value={form.dk_ngang_bung || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Chu vi bụng:{' '}
                {isPreview ? (
                  form.chu_vi_bung
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="chu_vi_bung"
                    value={form.chu_vi_bung || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                - Chiều dài xương đùi:{' '}
                {isPreview ? (
                  form.femur_length
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="femur_length"
                    value={form.femur_length || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
            </div>
          </div>
          <div>
            <p>
              - Tuổi thai ước tính:{' '}
              {isPreview ? (
                form.weeks
              ) : (
                <input
                  type="text"
                  className="w-50px"
                  id="weeks"
                  value={form.weeks || ''}
                  onChange={handleChange}
                />
              )}{' '}
              tuần,{' '}
              {isPreview ? (
                form.days
              ) : (
                <input
                  type="text"
                  className="w-50px"
                  id="days"
                  value={form.days || ''}
                  onChange={handleChange}
                />
              )}{' '}
              ngày
            </p>
            <p>
              - Cân nặng thai ước tính:{' '}
              {isPreview ? (
                form.weight || '____'
              ) : (
                <input
                  type="text"
                  className="w-50px"
                  id="weight"
                  value={form.weight || ''}
                  onChange={handleChange}
                />
              )}{' '}
              gram{' '}
              {isPreview ? (
                form.format_weight || ''
              ) : (
                <input
                  type="text"
                  className="w-100px"
                  id="format_weight"
                  value={form.format_weight || ''}
                  onChange={handleChange}
                />
              )}{' '}
            </p>
            <p>
              - Dự kiến ngày sinh theo kinh 3 tháng đầu:{' '}
              {isPreview ? (
                form.due_date
              ) : (
                <input
                  type="text"
                  id="due_date"
                  className="w-100px"
                  value={form.due_date || ''}
                  onChange={handleChange}
                  placeholder="dd/mm/yyyy"
                />
              )}{' '}
              ± 2 tuần
            </p>
            <p>
              - Dị tật:{' '}
              {isPreview ? (
                form.di_tat
              ) : (
                <input
                  type="text"
                  id="di_tat"
                  value={form.di_tat || ''}
                  onChange={handleChange}
                />
              )}{' '}
            </p>
          </div>
        </div>
        <div>
          <b>3. Phần phụ thai</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {form.phu_thai}
              </span>
            ) : (
              <textarea
                rows={5}
                id="phu_thai"
                value={form.phu_thai || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div>
          <b>4. Chi tiết khác</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>{form.chi_tiet_khac}</span>
            ) : (
              <textarea
                style={{ width: '100%' }}
                id="chi_tiet_khac"
                value={form.chi_tiet_khac}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="mt-2 block-media hidden-print">
          <b>5. Hình ảnh siêu âm</b>
          <TabsImage
            S3Client={S3Client}
            handleOnChange={(key, value) => setForm({ ...form, [key]: value })}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </div>
        <div className="mt-2">
          <b className="uppercase">Kết luận</b>
          <div
            className="ml-2 hidden-print"
            style={{ display: 'inline-block' }}
          >
            <Radio.Group
              onChange={event => {
                setForm({ ...form, note: event.target.value });
              }}
              value={form.note}
            >
              <Radio value="1">Chưa phát hiện bất thường</Radio>
              <Radio value="2">Bất thường</Radio>
            </Radio.Group>
          </div>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {result.conclude}
              </span>
            ) : (
              <textarea
                rows={4}
                id="conclude"
                value={form.conclude}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="footer mt-2">
          <div className="footer-content">
            <p>Ngày {defaultDateWithTime}</p>
            <p className="uppercase">Bác sĩ siêu âm</p>
            <br />
            <br />
            {booking.doctor && (
              <div>
                <p className="uppercase">
                  <b>
                    {getEducation(booking.doctor)}{' '}
                    {booking.doctor.phone_number
                      ? `(${booking.doctor.phone_number.replace('+84', '0')})`
                      : ''}
                  </b>
                </p>
                {booking.doctor.email && (
                  <p>
                    Gmail: {booking.doctor.email}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </Form>
      {!isPreview && (
        <div className="wrapper-btn-print mt-2">
          <Button className="mr-2" onClick={preHandleAddResult} loading={loading}>
            Khám xong
          </Button>
          <Button type="primary" onClick={handleOnPrint}>
            <PrinterOutlined /> In kết quả
          </Button>
          <Button
            type="primary"
            onClick={handleAddResult}
            id="btn-auto-complete"
            style={{ display: 'none' }}
          ></Button>

        </div>
      )}
      <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
        {enableInnerPrintf ? getPrintfForm() : <></>}
      </div>
    </div>
  );
}

export default FormResult9;
