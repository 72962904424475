/* eslint-disable no-await-in-loop */
import React, { useCallback, useState } from 'react';
import { Button, Form, Spin, Tooltip, notification, Modal, Row, Col } from 'antd';
import { DeleteOutlined, PictureOutlined, LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import encodeImageFileAsURL from 'utils/encodeImageFileAsURL';

import ReactCrop from 'react-image-crop';
import screenshot from 'image-screenshot'

import 'react-image-crop/dist/ReactCrop.css';

import styles from './FormUploadImages.module.scss';

const defaultFilter = [
  {
    id: 'contrast',
    title: 'Độ tương phản',
    value: '100%',
    step: 1,
    min: 0,
    max: 200
  },
  {
    id: 'hue',
    title: 'hue',
    value: '0deg',
    step: 1,
    min: 0,
    max: 360
  },
  {
    id: 'brightness',
    title: 'Độ sáng',
    value: '100%',
    step: 1,
    min: 0,
    max: 200
  },
  {
    id: 'saturate',
    title: 'Độ bão hòa',
    value: '100%',
    step: 1,
    min: 0,
    max: 100
  },
  {
    id: 'sepia',
    title: 'sepia',
    value: '0%',
    step: 1,
    min: 0,
    max: 100
  },
  {
    id: 'invert',
    title: 'invert',
    value: '0%',
    step: 1,
    min: 0,
    max: 100
  }
]

const FormUploadImages = ({
  handleOnChange,
  typeImage,
  S3Client,
  selectedImages,
  handleAddSelectedImages,
  handleRemoveSelectedImages
}) => {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const ref = React.useRef();
  const [imagesPreview, setImagePreview] = useState([]);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 16,
    height: 9
  })
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [showCroped, setShowCroped] = useState(false)
  const [settingFilter, setSettingFilter] = useState(defaultFilter)
  const [imgStyle, setImgStyle] = useState(null)
  const [usedFilter, setUsedFilter] = useState(null)
  const [loading, setLoading] = useState(false)

  const uploadImage = useCallback(
    async (imageFile, _imagesPreview, indexImagePreview) => {
      try {
        const response = await S3Client.uploadFile(imageFile);
        _imagesPreview[indexImagePreview].aws_s3_location = response.location;
        _imagesPreview[indexImagePreview].aws_s3_key = response.key.substring(
          response.key.lastIndexOf('/') + 1,
          response.key.length
        );
        _imagesPreview[indexImagePreview].uploading = false;
      } catch (error) {
        console.error(error);
        _imagesPreview[indexImagePreview].error = 'Upload error!';
      }
      _imagesPreview[indexImagePreview].uploaded = true;
      return _imagesPreview[indexImagePreview];
    },
    [S3Client]
  );

  const onChange = async event => {
    const { files } = event.target;
    validateFiles(files)
  };

  const handleRemoveImagePreview = (img) => {
    for (const _i of imagesPreview) {
      if (_i.id === img.id && _i.aws_s3_location) {
        S3Client.deleteFile(_i.aws_s3_key);
      }
    }

    const _imagesPreview = imagesPreview.filter(i => i.id !== img.id);
    setImagePreview(_imagesPreview);
    handleOnChange(
      typeImage,
      _imagesPreview.map(v => {
        return {
          uid: v.aws_s3_key,
          name: v.name,
          status: 'done',
          url: v.aws_s3_location,
          thumbUrl: v.aws_s3_location
        }
      })
    );
    handleRemoveSelectedImages(img);
  };

  const handleSetSelectedImagePreview = imageSrc => {
    handleAddSelectedImages(imageSrc);
  };

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
    e.preventDefault();
  }

  const dragLeave = (e) => {
    e.preventDefault();
  }

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    validateFiles(files)
  }

  const validateFiles = (files) => {
    var imageType = /image.*/;
    let _files = [];
    const maxSize = 1.5;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSize = file.size / 1048576; //size in mb 
      if (fileSize <= maxSize && file.type.match(imageType)) {
        _files.push(file)
      } else {
        if (fileSize > maxSize) {
          notification.error({ message: `Ảnh quá lớn, vui lòng chỉ tải ảnh có kích thước tối đa ${maxSize}MB.`, placement: 'bottomRight' });
        } else {
          notification.error({ message: 'Vui lòng chỉ tải lên hình ảnh.', placement: 'bottomRight' });
        }
      }
    }
    if (_files.length)
      handFiles(_files)
  }

  const handFiles = async (files) => {
    if (!files.length) return;

    let _imagesPreview = [...imagesPreview];
    let arrayPromise = [...imagesPreview];

    const startLoop = imagesPreview.length;
    const endLoop = imagesPreview.length + files.length;

    for (let i = startLoop; i < endLoop; i++) {
      const indexFile = i - imagesPreview.length;
      const file = files[indexFile];
      const name =
        file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
      const imageBase64 = await encodeImageFileAsURL(files[indexFile]);
      _imagesPreview = _imagesPreview.concat({
        id: Math.random(),
        uploading: true,
        imageBase64,
        name
      });
      setImagePreview(_imagesPreview);
      arrayPromise.push(uploadImage(file, _imagesPreview, i));
    }

    const responses = await Promise.all(arrayPromise);

    setImagePreview(responses);

    if (selectedImages.length === 0) {
      if (_imagesPreview.length > 1) {
        handleAddSelectedImages([_imagesPreview[0], _imagesPreview[1]])
      } else {
        handleAddSelectedImages(_imagesPreview[0]);
      }
    }

    if ((selectedImages.length === 2 && selectedImages[1] === '') || selectedImages.length === 1) {
      const selected = selectedImages[0];
      const notSelected = _imagesPreview.find(i => i.id !== selected.id);
      if (notSelected) {
        handleAddSelectedImages(notSelected);
      }
    }

    handleOnChange(
      typeImage,
      responses.reduce((r, v) => {
        if (!v.error) {
          r = r.concat({
            uid: v.aws_s3_key,
            name: v.name,
            status: 'done',
            url: v.aws_s3_location,
            thumbUrl: v.aws_s3_location
          });
        }
        return r;
      }, [])
    );
    ref.current.value = null;
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result)
        setVisibleModal(true)
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image)
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop)
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const _croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageUrl(_croppedImageUrl);
      setShowCroped(true)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return canvas.toDataURL('image/jpeg');
  }

  const handleChange = (e) => {
    let _settingFilter = [...settingFilter]
    var { id, value } = e.target;
    switch (id) {
      case 'contrast':
        _settingFilter[0].value = value + '%';
        break;
      case 'hue':
        _settingFilter[1].value = value + 'deg';
        break;
      case 'brightness':
        _settingFilter[2].value = value + '%';
        break;
      case 'saturate':
        _settingFilter[3].value = value + '%';
        break;
      case 'sepia':
        _settingFilter[4].value = value + '%';
        break;
      case 'invert':
        _settingFilter[5].value = value + '%';
        break;
    }
    setSettingFilter(_settingFilter)
    let _imgStyle = {
      filter: ` 
      contrast(${_settingFilter[0].value}) 
      hue-rotate(${_settingFilter[1].value}) 
      brightness(${_settingFilter[2].value}) 
      saturate(${_settingFilter[3].value}) 
      sepia(${_settingFilter[4].value})
      invert(${_settingFilter[5].value})`
      ,
      maxWidth: '100%'
    }
    setImgStyle(_imgStyle)
    setUsedFilter(true)
  }

  const dataURLtoFile = async (base64, fileName = Math.random()) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${fileName}.jpg`, { type: mime });
  }

  const handleExportImage = async () => {
    try {
      setLoading(true)
      let _image;
      if (usedFilter) {
        const img = document.getElementById('croped-image')
        const url = await screenshot(img)
        _image = await dataURLtoFile(url);
      } else {
        _image = await dataURLtoFile(croppedImageUrl);
      }
      await handFiles([_image])
    } catch (error) {
      console.error(error);
      return null
    } finally {
      setVisibleModal(false)
      setLoading(false)
    }
  };

  return (
    <>

      {currentClinic.crop_and_filter ?
        <div className="mb-2">
          <span>Cắt và chỉnh sửa ảnh: </span>
          <input type="file" accept="image/*" onChange={onSelectFile} ref={ref} />
        </div>
        :
        <div>
          <input
            ref={ref}
            multiple
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={onChange}
          />
          <Form.Item className={styles.input}
            name={typeImage}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={() => ref.current.click()}>
            <Button size="large">
              <PictureOutlined /> Kéo thả ảnh hoặc click vào đây để tải ảnh lên
            </Button>
          </Form.Item>
        </div>
      }

      <div className={styles.preview}>
        {imagesPreview.map((image, index) => {
          return (
            <div
              key={image.aws_s3_location || index}
              namedkey={image.aws_s3_location}
              className={classnames('image-preview', {
                'is-selected': selectedImages.find(img => img.id === image.id),
                'upload-error': image.error
              })}
            >
              {image.uploading ?
                <div>
                  <Spin tip="Uploading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
                :
                <div className="inner">
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${image.aws_s3_location})` }}
                  />
                  <Tooltip
                    title={
                      image.error
                        ? image.error
                        : selectedImages.find(img => img.id === image.id)
                          ? 'Bỏ chọn'
                          : 'Chọn để in'
                    }
                  >
                    <div
                      className={classnames('overlay', {
                        pointer: !image.error,
                        'un-selected-img': !selectedImages.find(img => img.id === image.id)
                      })}
                      onClick={event => {
                        event.stopPropagation();
                        handleSetSelectedImagePreview(image);
                      }}
                    />
                  </Tooltip>
                  {image.uploaded && (
                    <DeleteOutlined
                      className="icon-delete"
                      onClick={event => {
                        event.stopPropagation();
                        handleRemoveImagePreview(image);
                      }}
                    />
                  )}
                </div>
              }
            </div>
          );
        })}
      </div>

      <Modal
        width="90%"
        title="Chỉnh sửa ảnh siêu âm"
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
        maskClosable={false}
      >
        <Row className={styles.wrapper}>
          <Col span={8} className="col">
            <h4>Ảnh gốc</h4>
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
          </Col>
          <Col span={4} className="col">
            <div className="contentWrap">
              <div className="sidebar">
                <div className="title">Tùy chỉnh</div>
                {settingFilter.map((setting, index) => {
                  return (
                    <div className="setting" key={index}>
                      <label className="filterName">
                        <div>{setting.title}</div>
                        <div>{setting.value}</div>
                      </ label>
                      <input type="range"
                        step={setting.step}
                        min={setting.min}
                        max={setting.max}
                        id={setting.id}
                        onChange={handleChange}
                        defaultValue={setting.value}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
          <Col span={12} className="col preview-img">
            <h4>Ảnh sau chỉnh sửa</h4>
            {croppedImageUrl && showCroped ?
              <div>
                <img alt="Crop" style={imgStyle} src={croppedImageUrl} id="croped-image" />
                <Button className="mt-2" onClick={handleExportImage} type="primary" loading={loading}>
                  Tải ảnh lên
                </Button>
              </div>
              : <></>}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FormUploadImages;
