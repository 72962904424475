import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, DatePicker } from 'antd';
import Highcharts from 'highcharts';
import { FileExcelOutlined } from '@ant-design/icons';
import HighchartsReact from 'highcharts-react-official';
import { SkeletonReportByCalendar } from 'containers/skeleton';
import { ButtonExportExcel } from 'components';
import moment from 'moment';
import http from 'utils/http';
import { filZeroNumber } from 'utils/__function';
import * as FileSaver from 'file-saver';
import styles from './index.module.scss';
import { formatPriceVND } from 'utils';

const options = {
  chart: {
    type: 'pie'
  },
  title: {
    text: null
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.percentage:.1f} %',
        distance: -50,
        filter: {
          property: 'percentage',
          operator: '>',
          value: 4
        }
      },
      showInLegend: true
    }
  }
};

const convertMomentDate = mommentDate => {
  if (!mommentDate) {
    return '';
  }
  const now = new Date(mommentDate._d);
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);

  return `${_y}-${_m}-${_d}`;
};

function ReportByDoctor({ hiddenHeader, defaultFromDate, defaultToDate }) {
  const [dataSource, setDataSource] = useState(null);
  const [reportBy] = useState('turn');
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [doctorId, setDoctorId] = useState('');
  const [doctorName, setDoctorName] = useState('');

  const [fromDate, setFromDate] = useState(
    defaultFromDate || moment().subtract(7, 'days')
  );
  const [toDate, setToDate] = useState(defaultToDate || moment());

  useEffect(() => {
    setFromDate(defaultFromDate || moment().subtract(7, 'days'));
    setToDate(defaultToDate || moment());
  }, [defaultFromDate, defaultToDate]);

  const getMetric = useCallback(async () => {
    let _dataSource = [];
    setLoading(true);
    try {
      const response = await http.get(`/reports/doctors`, {
        params: {
          from_date: convertMomentDate(fromDate),
          to_date: convertMomentDate(toDate)
        }
      });
      _dataSource = response.data;
    } catch (error) {
      console.error(error);
    }
    setDataSource(_dataSource);
    setLoading(false);
  }, [fromDate, toDate]);

  useEffect(() => {
    getMetric();
  }, [getMetric]);

  const computeTotalRevenue = useCallback(_services => {
    let _totalRevenue = 0;
    for (let s of _services) {
      _totalRevenue += s.revenue;
    }
    return _totalRevenue;
  }, []);

  const computeTotalTurn = useCallback(_services => {
    let _totalTurn = 0;
    for (let s of _services) {
      _totalTurn +=
        s.total_new + s.total_reexamination + s.total_cancel_appointment;
    }
    return _totalTurn;
  }, []);

  const splitDataSource = useMemo(() => {
    if (!dataSource) return [];
    let _splitDataSource = [];

    for (let indexD = 0; indexD < dataSource.length; indexD++) {
      const item = dataSource[indexD];
      const doctorName = item.name;

      for (let index = 0; index < item.services.length; index++) {
        const s = item.services[index];
        let itemPush = {
          doctorName,
          index: indexD + 1,
          serviceName: s.name,
          total_new: s.total_new,
          total_reexamination: s.total_reexamination,
          total_cancel_appointment: s.total_cancel_appointment,
          revenue: s.revenue,
          total_turn: computeTotalTurn(item.services),
          total_revenue: computeTotalRevenue(item.services)
        };

        if (index === 0) {
          itemPush.rowSpan = item.services.length;
        }

        _splitDataSource.push(itemPush);
      }
    }

    return _splitDataSource;
  }, [dataSource, computeTotalRevenue, computeTotalTurn]);

  const dataPieChart = useMemo(() => {
    if (!dataSource) return [];
    let _dataPieChart = [];
    let isSliced = false;

    for (let item of dataSource) {
      const dataPush = {
        name: item.name,
        id: item.id,
        y:
          reportBy === 'turn'
            ? computeTotalTurn(item.services)
            : computeTotalRevenue(item.services)
      };

      if (!doctorId) {
        if (!isSliced) {
          isSliced = true;
          setDoctorId(item.id);
          setDoctorName(item.name);
        }
      } else {
        dataPush.sliced = doctorId === item.id;
      }
      _dataPieChart.push(dataPush);
    }
    return _dataPieChart;
  }, [dataSource, reportBy, computeTotalTurn, computeTotalRevenue, doctorId]);

  const dataPieChartByDoctorId = useMemo(() => {
    if (!dataSource || !doctorId) return [];

    let _dataPieChart = [];

    const doctor = dataSource.find(d => d.id === doctorId) || {};

    const serviceByDoctor = doctor.services;

    if (!serviceByDoctor) return [];

    for (let item of serviceByDoctor) {
      _dataPieChart.push({
        name: item.name,
        y:
          reportBy === 'turn'
            ? item.total_new +
              item.total_reexamination +
              item.total_cancel_appointment
            : item.revenue
      });
    }
    return _dataPieChart;
  }, [doctorId, dataSource, reportBy]);

  const fileNameDownload = hiddenHeader ? `Thống kê theo lịch khám từ ${convertMomentDate(
    fromDate
  )} đến ${convertMomentDate(toDate)}` : `Thống kê theo bác sĩ từ ${convertMomentDate(
      fromDate
  )} đến ${convertMomentDate(toDate)}`;

  const handleDownloadFileExport = () => {
    setExporting(true)
    http
      .get(hiddenHeader ? '/exports/by-schedule' : '/exports/by-doctor', {
        params: {
          from_date: convertMomentDate(fromDate),
          to_date: convertMomentDate(toDate)
        },
        responseType: 'blob'
      })
      .then(response => {
        setExporting(false)
        FileSaver.saveAs(response, `${fileNameDownload}.xlsx`);
      });
  };

  const onChangeFromDate = _fromDate => {
    setFromDate(_fromDate);
  };

  const onChangeToDate = _toDate => {
    setToDate(_toDate);
  };

  const isDisabledDate = _momentTime => fromDate > _momentTime;

  const contentChart =
    !dataSource || dataSource.length === 0 ? (
      <div className="mt-1">Chưa có lịch khám nào được hoàn thành</div>
    ) : (
      <div className="main-content">
        <div className="chart-content mt-2 flex justify-content-between">
          <div className="left">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                title: {
                  text:
                    reportBy === 'turn'
                      ? 'Biểu đồ lượt khám theo bác sĩ'
                      : 'Biểu đồ doanh thu theo bác sĩ'
                },
                series: [
                  {
                    name: reportBy === 'turn' ? 'Số ca' : 'Doanh thu',
                    data: dataPieChart,
                    events: {
                      click: function(e) {
                        let _doctorId = '';
                        let _doctorName = '';

                        try {
                          _doctorId = e.point.options.id;
                          _doctorName = e.point.options.name;
                        } catch (error) {
                          console.error(error);
                        }

                        setDoctorId(_doctorId);
                        setDoctorName(_doctorName);
                      }
                    }
                  }
                ]
              }}
            />
          </div>
          <div className="right">
            {doctorId && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  title: {
                    text: 'Biểu đồ  DV khám theo bác sĩ: ' + doctorName
                  },
                  series: [
                    {
                      name: 'Dịch vụ',
                      data: dataPieChartByDoctorId
                    }
                  ]
                }}
              />
            )}
          </div>
        </div>
        <div className="table-content mt-2">
          <table>
            <thead>
              <tr>
                <th>STT</th>
                <th>Bác sĩ</th>
                <th>Dịch vụ</th>
                <th>Ca khám mới</th>
                <th>Ca tái khám</th>
                <th>Ca hủy</th>
                <th>Doanh thu</th>
                <th>Tổng ca</th>
                <th>Tổng doanh thu</th>
              </tr>
            </thead>
            <tbody>
              {splitDataSource.map((item, index) => (
                <tr key={index}>
                  {item.rowSpan && <td rowSpan={item.rowSpan}>{item.index}</td>}

                  {item.rowSpan && (
                    <td rowSpan={item.rowSpan}>{item.doctorName}</td>
                  )}
                  <td>{item.serviceName}</td>
                  <td>{item.total_new}</td>
                  <td>{item.total_reexamination}</td>
                  <td>{item.total_cancel_appointment}</td>
                  <td>{formatPriceVND(item.revenue) + 'đ'}</td>
                  {item.rowSpan && (
                    <td rowSpan={item.rowSpan}>{item.total_turn}</td>
                  )}
                  {item.rowSpan && (
                    <td rowSpan={item.rowSpan}>
                      {formatPriceVND(item.total_revenue) + 'đ'}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between">
        {hiddenHeader ? (
          <b style={{ fontSize: '20px' }}>Thống kê dịch vụ theo bác sĩ khám</b>
        ) : (
          <div className="left flex">
            <div className="from-date mr-1">
              <span className="mr-1">Từ ngày</span>
              <DatePicker
                allowClear={false}
                value={fromDate}
                format="DD-MM-YYYY"
                onChange={onChangeFromDate}
              />
            </div>
            <div className="to-date">
              <span className="mr-1">Đến ngày</span>
              <DatePicker
                disabledDate={isDisabledDate}
                allowClear={false}
                value={toDate}
                format="DD-MM-YYYY"
                onChange={onChangeToDate}
              />
            </div>
          </div>
        )}
        <div className="right">
          <Button type="primary" onClick={handleDownloadFileExport} loading={exporting}>
            <FileExcelOutlined /> Xuất file Excel
          </Button>
        </div>
      </div>
      {!loading ? contentChart : <SkeletonReportByCalendar />}
    </div>
  );
}

export default ReportByDoctor;
