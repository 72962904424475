import React, { useMemo, useState } from 'react';
import { Form, Input, Button, notification, InputNumber, TimePicker, Row, Col, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { addWorkingShift, editWorkingShift } from 'reducers/workspace';
import { getTimeMoment } from 'utils/__function';

import serviceWorkingShift from 'services/workingShift';
import moment from 'moment';

import styles from './index.module.scss';

const { Option } = Select;

function FormWorkingShift({ workingShift, onClose }) {

  const dispatch = useDispatch();
  const format = 'HH:mm';

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const isEdit = useMemo(() => {
    if (workingShift && workingShift.id) return true;
    return false;
  }, [workingShift]);

  const handleAdd = _workingShift => {
    return serviceWorkingShift.addWorkingShift(_workingShift);
  };

  const handleEdit = _workingShift => {
    return serviceWorkingShift.editWorkingShift({
      id: workingShift.id,
      ..._workingShift
    });
  };

  const onFinish = async values => {
    setLoading(true);
    try {
      let message = 'Thêm ca thành công!';
      values.start_time = getTimeMoment(values.start_time, true);
      values.end_time = getTimeMoment(values.end_time, true);
      values.start_time_extend = getTimeMoment(values.start_time_extend, true);
      values.end_time_extend = getTimeMoment(values.end_time_extend, true);

      if (isEdit) {
        const response = await handleEdit(values);
        message = 'Cập ca vụ thành công!';
        dispatch(editWorkingShift(response.data));
      } else {
        const response = await handleAdd(values);
        dispatch(addWorkingShift(response.data));
      }

      notification.success({ message });
      onClose();
    } catch (error) {
      console.error(error);
      const message = isEdit
        ? 'Cập nhật ca thất bại!'
        : 'Thêm ca thất bại!';
      notification.success({ message });
    }
    setLoading(false);
  };

  const initialValuesMemo = useMemo(() => {
    const _workingShift = workingShift || {};
    return {
      name: _workingShift.name || '',
      start_time: moment(
        _workingShift.start_time,
        format
      ) || '',
      end_time: moment(
        _workingShift.end_time,
        format
      ) || '',
      start_time_extend: moment(
        _workingShift.start_time_extend,
        format
      ) || '',
      end_time_extend: moment(
        _workingShift.end_time_extend,
        format
      ) || '',
      duration_kip_work: _workingShift.duration_kip_work || '',
      days_of_week: _workingShift.days_of_week || []
    };
  }, [workingShift]);

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        layout="vertical"
        validateTrigger={['onBlur']}
        onFinish={onFinish}
        initialValues={initialValuesMemo}
      >
        <Form.Item
          required
          label="Tên ca"
          name="name"
          rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
        >
          <Input />
        </Form.Item>

        <Row>
          <Col span={24}><b>Thời gian hiển thị:</b> </Col>
          <Col span={12}>
            <Form.Item
              label="Bắt đầu"
              name="start_time"
              rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
            >
              <TimePicker
                showNow={false}
                allowClear={false}
                footer={null}
                format={format}
                shouldCloseOnSelect={false}
                minuteStep={5}
                placeholder=""
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Kết thúc"
              name="end_time"
              rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
            >
              <TimePicker
                allowClear={false}
                showNow={false}
                footer={null}
                format={format}
                shouldCloseOnSelect={false}
                minuteStep={5}
                placeholder=""
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}><b>Khoảng thời gian hiệu lực:</b> </Col>
          <Col span={12}>
            <Form.Item
              label="Bắt đầu"
              name="start_time_extend"
              rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
            >
              <TimePicker
                allowClear={false}
                showNow={false}
                footer={null}
                format={format}
                shouldCloseOnSelect={false}
                minuteStep={5}
                placeholder=""
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Kết thúc"
              name="end_time_extend"
              rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
            >
              <TimePicker
                allowClear={false}
                showNow={false}
                footer={null}
                format={format}
                shouldCloseOnSelect={false}
                minuteStep={5}
                placeholder=""
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Thời gian mỗi kip (phút)"
          name="duration_kip_work"
          rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          label="Ngày trong tuần"
          name="days_of_week"
          rules={[{ required: true, message: 'Chọn ít nhất 1 ngày trong tuần' }]}
        >
          <Select
            mode="tags" style={{ width: '100%' }}
          >
            <Option value={'1'} key={1}>Thứ 2</Option>
            <Option value={'2'} key={2}>Thứ 3</Option>
            <Option value={'3'} key={3}>Thứ 4</Option>
            <Option value={'4'} key={4}>Thứ 5</Option>
            <Option value={'5'} key={5}>Thứ 6</Option>
            <Option value={'6'} key={6}>Thứ 7</Option>
            <Option value={'0'} key={0}>Chủ nhật</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <div className="flex justify-content-flex-end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {isEdit ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default FormWorkingShift;
