import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import clinicService from 'services/clinic';
import normalize from 'utils/normalize';

export const initWorkspace = createAsyncThunk('workspace/initWorkspace', () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await clinicService.getClinics();
      localStorage.setItem(
        'clinicInfo',
        JSON.stringify(response.data[0])
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
});

const workspace = createSlice({
  name: 'workspace',
  initialState: {
    clinics: [],
    currentClinic: null,
    currentWokingShift: {
      byId: {},
      ids: [],
    },
    doctorId: localStorage.getItem('doctor_id'),
    loading: false,
    error: null
  },
  reducers: {
    setCurrentClinic: (state, action) => {
      state.currentClinic = {
        ...state.currentClinic,
        ...action.payload
      };
    },
    setDoctorId: (state, action) => {
      state.doctorId = action.payload;
    },
    addWorkingShift: (state, action) => {
      const workingShift = action.payload;
      if (!state.currentWokingShift.ids.includes(workingShift.id)) {
        state.currentWokingShift.byId[workingShift.id] = workingShift;
        state.currentWokingShift.ids = [workingShift.id, ...state.currentWokingShift.ids];
      }
    },
    editWorkingShift: (state, action) => {
      const workingShift = action.payload;
      state.currentWokingShift.byId[workingShift.id] = {
        ...state.currentWokingShift.byId[workingShift.id],
        ...workingShift
      };
    },
    deleteWorkingShift: (state, action) => {
      const workingShiftId = action.payload;
      state.currentWokingShift.byId = {
        ...state.currentWokingShift.byId,
        [workingShiftId]: null
      };
    }
  },
  extraReducers: {
    [initWorkspace.pending]: state => {
      state.loading = true;
    },
    [initWorkspace.fulfilled]: (state, action) => {
      const clinics = action.payload;

      const clinicId = localStorage.getItem('clinic_id');

      if (clinicId) {
        // neu da cached id cua phong kham
        for (let clinic of clinics) {
          if (clinic.id.toString() === clinicId) {
            state.currentClinic = clinic;
            const { byId, ids } = normalize(clinic.working_shifts);
            state.currentWokingShift.byId = byId;
            state.currentWokingShift.ids = ids;
            break;
          }
        }
        if (!state.currentClinic) {
          localStorage.clear()
          window.location.reload()
        }
      } else {
        if (clinics[0]) {
          // tu chon phong kham dau tien
          state.currentClinic = clinics[0];
          const { byId, ids } = normalize(clinics[0].working_shifts);
          state.currentWokingShift.byId = byId;
          state.currentWokingShift.ids = ids;

          localStorage.setItem('clinic_id', clinics[0].id);
        } else {
          // khong co phong kham trong tai khoan de chon
          localStorage.clear()
          window.location.reload()
        }
      }
      state.loading = false;
      state.clinics = clinics;
    },
    [initWorkspace.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { setCurrentClinic, setDoctorId, addWorkingShift, editWorkingShift, deleteWorkingShift } = workspace.actions;

export default workspace.reducer;
