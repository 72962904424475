import React from 'react';
import { Skeleton } from 'antd';

import styles from './index.module.scss';

function SkeletonAvatar({ active = true, width = '100%' }) {
  return (
    <div className={styles.wrapper}>
      <Skeleton avatar active={active} paragraph={null} title={null} />
    </div>
  );
}

export default SkeletonAvatar;
