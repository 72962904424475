import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { DatePicker } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SkeletonReportByCalendar } from 'containers/skeleton';
import { ButtonExportExcel } from 'components';
import moment from 'moment';
import http from 'utils/http';
import { formatPriceVND } from 'utils';
import { filZeroNumber } from 'utils/__function';

import styles from './index.module.scss';

const options = {
  chart: {
    type: 'areaspline'
  },
  title: {
    text: null
  },
  yAxis: [
    {
      title: {
        text: 'Doanh thu'
      },
      opposite: true
    },
    {
      title: {
        text: 'Số ca khám'
      }
    }
  ],
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle'
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false
      }
    }
  }
};

const convertMomentDate = mommentDate => {
  if (!mommentDate) {
    return '';
  }
  const now = new Date(mommentDate._d);
  const _y = now.getFullYear();
  const _d = filZeroNumber(now.getDate());
  const _m = filZeroNumber(now.getMonth() + 1);

  return `${_y}-${_m}-${_d}`;
};

function ReportByDoctor() {
  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoading] = useState(true);

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  const getMetric = useCallback(async () => {
    let _dataSource = [];
    setLoading(true);
    try {
      const response = await http.get(`/reports/revenue`, {
        params: {
          from_date: convertMomentDate(fromDate),
          to_date: convertMomentDate(toDate)
        }
      });
      _dataSource = response.data;
    } catch (error) {
      console.error(error);
    }
    setDataSource(_dataSource);
    setLoading(false);
  }, [fromDate, toDate]);

  useEffect(() => {
    getMetric();
  }, [getMetric]);

  const summaryObject = useMemo(() => {
    if (!dataSource) return {};
    let _summaryObject = {
      total: 0,
      revenue: 0
    };

    for (let item of dataSource) {
      _summaryObject.total += item.total;
      _summaryObject.revenue += item.revenue;
    }
    return _summaryObject;
  }, [dataSource]);

  const dataExcel = useMemo(() => {
    if (!dataSource) return [];
    let _dataExcel = [['STT', 'Ngày', 'Số ca khám', 'Doanh thu']];
    for (let index = -1; index < dataSource.length; index++) {
      let rowData = [];
      let objectData = dataSource[index];

      if (index === -1) {
        rowData[0] = '';
        rowData[1] = '';
        objectData = summaryObject;
      } else {
        rowData[0] = index + 1;
        rowData[1] = dataSource[index].date || '';
      }

      rowData[2] = objectData.total || 0;
      rowData[3] = objectData.revenue || 0;

      _dataExcel.push(rowData);
    }
    return _dataExcel;
  }, [dataSource, summaryObject]);

  const dataLineChart = useMemo(() => {
    let _dataLineChart = {
      labels: [],
      total: [],
      revenue: []
    };

    if (dataSource) {
      for (let item of dataSource) {
        _dataLineChart.labels.push(item.date);
        _dataLineChart.total.push(item.total);
        _dataLineChart.revenue.push(item.revenue);
      }
    }

    return _dataLineChart;
  }, [dataSource]);

  const onChangeFromDate = _fromDate => {
    setFromDate(_fromDate);
  };

  const onChangeToDate = _toDate => {
    setToDate(_toDate);
  };

  const isDisabledDate = _momentTime => fromDate > _momentTime;

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between">
        <div className="left flex">
          <div className="from-date mr-1">
            <span className="mr-1">Từ ngày</span>
            <DatePicker
              allowClear={false}
              value={fromDate}
              format="DD-MM-YYYY"
              onChange={onChangeFromDate}
            />
          </div>
          <div className="to-date">
            <span className="mr-1">Đến ngày</span>
            <DatePicker
              allowClear={false}
              value={toDate}
              format="DD-MM-YYYY"
              onChange={onChangeToDate}
              disabledDate={isDisabledDate}
            />
          </div>
        </div>
        <div className="right">
          <ButtonExportExcel
            fileName={`Thống kê theo doanh số từ ${convertMomentDate(
              fromDate
            )} đến ${convertMomentDate(toDate)}`}
            data={dataExcel}
            merges={[{ s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }]}
          />
        </div>
      </div>
      {!loading ? (
        <div className="main-content">
          <div className="chart-content">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...options,
                xAxis: [
                  {
                    categories: dataLineChart.labels,
                    crosshair: true
                  }
                ],
                series: [
                  {
                    name: 'Doanh thu',
                    type: 'areaspline',
                    yAxis: 0,
                    data: dataLineChart.revenue
                  },
                  {
                    name: 'Số ca khám',
                    type: 'areaspline',
                    yAxis: 1,
                    data: dataLineChart.total
                  }
                ]
              }}
            />
          </div>
          <div className="table-content">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Ngày</th>
                  <th>Số ca khám</th>
                  <th>Doanh thu</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}></td>
                  <td>{summaryObject.total}</td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPriceVND(summaryObject.revenue)} VND
                  </td>
                </tr>
                {dataSource.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.date}</td>
                    <td>{formatPriceVND(item.total)}</td>
                    <td style={{ textAlign: 'right' }}>
                      {formatPriceVND(item.revenue)} VND
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SkeletonReportByCalendar />
      )}
    </div>
  );
}

export default ReportByDoctor;
