import React from 'react';
import { Skeleton } from 'components';
import classnames from 'classnames';

import styles from './index.module.scss';

function SkeletonReportByCalendar({ hiddenHeader }) {
  return (
    <div className={classnames(styles.wrapper, { 'is-full': hiddenHeader })}>
      <div className="left">
        <table>
          <thead>
            <tr>
              <th>
                <Skeleton.Text />
              </th>
              <th>
                <Skeleton.Text />
              </th>
              <th>
                <Skeleton.Text />
              </th>
              <th>
                <Skeleton.Text />
              </th>
              <th>
                <Skeleton.Text />
              </th>
              <th>
                <Skeleton.Text />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}></td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
              <td>
                <Skeleton.Text width={'50%'} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="right">
        <div className="mt-2">
          <Skeleton.Avatar />
        </div>
        <div className="flex mt-2 justify-content-center">
          <div>
            <Skeleton.Text width={'100px'} />
          </div>
          <div className="ml-2 mr-2">
            <Skeleton.Text width={'100px'} />
          </div>
          <div>
            <Skeleton.Text width={'100px'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonReportByCalendar;
