import React from 'react';
import Header from './Header';

import styles from './index.module.scss';

export default function MainLayout({ children }) {
  return (
    <div className={styles.wrapper}>
      <Header />
      <section id="content">
        <main>{children}</main>
      </section>
    </div>
  );
}
