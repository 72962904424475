import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import serviceBooking from 'services/booking';
import { useSelector } from 'react-redux';
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { filZeroNumber } from 'utils/__function';
import { Select, Tooltip, Popconfirm, notification } from 'antd';
import { database } from 'configs/firebase';
import './index.scss';

const { Option } = Select;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : '#fff',
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#F4F1F0',
  flex: 1
});

const serviceName = {
  1: '2D đơn',
  2: '2D đa',
  3: '5D đơn',
  4: '5D đa'
};

const getEducation = doctor => {
  const educations = doctor.educations || [];
  const education = educations[0] || {};

  if (education.title) {
    return education.title + ' ' + doctor.name;
  }

  return doctor.name;
};

let interval;

const now = new Date();
const _y = now.getFullYear();
const _d = filZeroNumber(now.getDate());
const _m = filZeroNumber(now.getMonth() + 1);

const convertMinutesToTextTime = minutes => {
  if (minutes > 60) {
    const hours = Math.floor(minutes / 60);
    const _minutes = minutes - hours * 60;
    return `${hours}h${_minutes}'`;
  }
  return `${minutes}'`;
};

function ListBookingLobby({
  doctor = {},
  setIsActive,
  onCollapsed,
  isCollapsed,
  onChangeRoom,
  room,
  doctors,
  bookings,
  forceUpdate
}) {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const serviceById = useSelector(state => state.service.byId);
  const doctorById = useSelector(state => state.employee.byId);
  const [objectInterval, setObjectInterval] = useState({});
  const [selectedData, setSelectedData] = useState(null);
  // const [stepShift] = useState(30);

  const countWaitTime = useCallback(() => {
    let _objectInterval = {};
    for (let booking of bookings) {
      if (booking.checked_time) {
        const splitChecked = booking.checked_time.split(' ')[1];
        const checkTime = new Date(
          `${_y}-${_m}-${_d} ${splitChecked}`
        ).getTime();
        const currentTime = new Date().getTime();

        const totalSeconds = currentTime - checkTime;

        const totalMinutes = Math.floor(totalSeconds / 1000 / 60);
        _objectInterval[booking.id] = totalMinutes;
      }
    }
    setObjectInterval(o => {
      return {
        ...o,
        ..._objectInterval
      };
    });
  }, [bookings]);

  const isValidDoctor = useMemo(() => {
    if (!doctor.id) return false;

    let _isValidDoctor = false;

    for (const d of doctors) {
      if (doctor.id === d.id) {
        _isValidDoctor = true;
        break;
      }
    }

    return _isValidDoctor;
  }, [doctor, doctors]);

  useEffect(() => {
    countWaitTime();
    interval = setInterval(() => {
      countWaitTime();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [countWaitTime]);

  const renderStatusItem = statusId => {
    const titleTooltip = statusId === 4 ? 'Đang khám' : 'Gián đoạn';
    const iconTooltip =
      statusId === 4 ? (
        <PauseCircleOutlined style={{ color: '#dc7b62' }} />
      ) : (
        <PlayCircleOutlined style={{ color: 'red' }} />
      );

    return <Tooltip title={titleTooltip}>{iconTooltip}</Tooltip>;
  };
  const checkVisibleRemove = item => {
    if (!selectedData) {
      return false;
    }
    if (selectedData.id === item.id) {
      return true;
    }
    return false;
  };
  const handleOpenDelete = value => {
    setSelectedData(value);
  };
  const closePopDelete = () => {
    setSelectedData(null);
  };
  const onConfirmRemove = async () => {
    if (!selectedData) {
      return;
    }
    const bookingRefUpdate = database.ref(
      `bookings/${currentClinic.id}/${selectedData.key}`
    );
    bookingRefUpdate.update({
      status: { id: 7, name: 'Hủy khám' }
    });
    notification.success({
      message: 'Hủy lịch khám thành công'
    });
    await serviceBooking.cancelBooking(selectedData.id);
    forceUpdate();
    setSelectedData(null);
  };
  return (
    <div
      className={classnames('wrapper-list-booking-lobby', {
        'is-collapsed': isCollapsed
      })}
    >
      <div className="title-list" onClick={setIsActive}>
        <div>
          <Select
            className="select-doctor-room"
            placeholder="Chọn bác sĩ"
            allowClear
            onChange={value => onChangeRoom(value, room)}
            value={isValidDoctor ? doctor?.id : null}
            disabled={doctor && doctor.id === -1}
          >
            {doctors.map(r => (
              <Option key={r.id} value={r.id}>
                {r.name}
              </Option>
            ))}
          </Select>
        </div>
        <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>
          {room !== -1 ? `Phòng ${room}` : 'Chưa xác định'}
          <span onClick={onCollapsed} className="pointer ml-1">
            {isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </span>
        </b>
      </div>
      <div className="list-booking-lobby" style={{ paddingBottom: 0 }}>
        <div className="item-booking-lobby" style={getItemStyle(false)}>
          <div className="actions actions-header"></div>
          <div className="booking-numerical">Số chờ</div>
          <div className="booking-username">Họ tên</div>
          <div className="booking-service">Dịch vụ</div>
          <div className="booking-datetime">Giờ hẹn</div>
          <div className="booking-checkedtime">Giờ đến</div>
        </div>
      </div>
      {!doctor.id ? null : (
        <Droppable droppableId={doctor.id.toString()}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{
                ...getListStyle(snapshot.isDraggingOver),
                paddingTop: 0
              }}
              className="list-booking-lobby"
            >
              {bookings.map((item, index) => {
                let doctorRequired = null;
                const isRequiredDoctor = item.doctor_id_required;

                if (isRequiredDoctor) {
                  doctorRequired = doctorById[isRequiredDoctor];
                }

                const statusId = item.status?.id;

                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="item-booking-lobby"
                        id={item.status.id}
                      >
                        <div className="actions">
                          {[4, 5].includes(statusId) ? (
                              renderStatusItem(statusId)
                          ) : (
                            <Tooltip title={`Hủy lịch`}>
                              <Popconfirm
                                  placement="topRight"
                                  title="Bạn có thực sự muốn hủy dịch vụ khám này?"
                                  okText="Có"
                                  cancelText="Không"
                                  okType="danger"
                                  onConfirm={() => {
                                    onConfirmRemove();
                                  }}
                                  onCancel={closePopDelete}
                                  visible={checkVisibleRemove(item)}
                              >
                                <CloseOutlined
                                    onClick={() => {
                                      handleOpenDelete(item);
                                    }}
                                    style={{ color: '#dc7b62', cursor: 'pointer' }}
                                />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className="booking-numerical"
                          style={{
                            textAlign: 'unset'
                          }}
                        >
                          <>
                            <b>{item.numerical_order}</b>
                            {objectInterval[item.id] ||
                            objectInterval[item.id] === 0 ? (
                                <Tooltip title="Thời gian chờ">
                                  <span
                                      className={classnames('minutes-waited', {
                                        impress: objectInterval[item.id] >= 30
                                      })}
                                  >
                                    {convertMinutesToTextTime(
                                        objectInterval[item.id]
                                    )}
                                  </span>
                                </Tooltip>
                            ) : null}
                          </>
                        </div>
                        {isRequiredDoctor ? (
                          <Tooltip
                            title={`Yêu cầu ${getEducation(doctorRequired)}`}
                          >
                            <div className="booking-username">
                              <span>{item.user_name}</span>
                              <div
                                className={classnames('required-doctor', {
                                  'wrong-required-doctor':
                                    isRequiredDoctor !== item.doctor_id
                                })}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="booking-username">
                            <span>{item.user_name}</span>
                          </div>
                        )}
                        <div className="booking-service">
                          {item.service_id && (
                            <span>
                              {[1, 2, 3, 4].includes(item.service_id)
                                ? serviceName[item.service_id]
                                : serviceById[item.service_id].name}
                            </span>
                          )}
                        </div>
                        <div className="booking-datetime">
                          <span>
                            {item.date_time && item.date_time !== '00:00'
                              ? item.date_time
                              : '-'}
                          </span>
                        </div>
                        <div className="booking-checkedtime">
                          <span>
                            {item.checked_time
                              ? item.checked_time.split(' ')[1]
                              : '-'}
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
}

export default ListBookingLobby;
