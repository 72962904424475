export default data => {
  return data.reduce(
    (r, d) => {
      return {
        byId: {
          ...r.byId,
          [d.id]: d
        },
        ids: r.ids.concat(d.id)
      };
    },
    { byId: {}, ids: [] }
  );
};
