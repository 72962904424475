import React from 'react';
import { Spin } from 'antd';

import logo from 'assets/images/logo.jpg';
import styles from './index.module.scss';

function Loading({ textLoading }) {
  return (
    <div className={styles.wrapper}>
      <div className="inner">
        <img src={logo} alt="Logo" /> <br />
        <Spin /> <span className="text-loading">{textLoading}</span>
      </div>
    </div>
  );
}

export default Loading;
