import React, { useMemo, useState } from 'react';
import { Form, Input, Button, Select, Row, Col, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { isPhoneNumber } from 'utils';
import { addEmployee, editEmployee } from 'reducers/employee';

import employeeService from 'services/employee';

import styles from './index.module.scss';

const { Option } = Select;

function FormEmployee({ employee, onClose }) {
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  const [form] = Form.useForm();
  const [roleName, setRoleName] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [propsValidate, setPropsValidate] = useState({});

  const isEdit = useMemo(() => {
    if (!employee) return false;
    return true;
  }, [employee]);

  const handleAdd = _employee => {
    return employeeService.add(_employee, currentClinic.id);
  };

  const handleEdit = _employee => {
    return employeeService.edit(
      {
        id: employee.id,
        ..._employee
      },
      currentClinic.id
    );
  };

  const onFinish = async values => {
    setLoading(true);
    let propsValidate = {};
    setPropsValidate({});
    try {
      const _values = {
        ...values,
        phone_number: values.phone_number.includes('+84')
          ? `${values.phone_number}`
          : `+84${values.phone_number.substring(1)}`
      };

      if (values.role_name === 'clinic_doctor' || values.role_name === 'clinic_employee') {
        if (values.education) {
          _values.education = {
            title: values.education
          };
        }

        if (values.job) {
          _values.job = {
            title: values.job
          };
        }
      }

      let message = 'Thêm nhân viên thành công!';

      if (isEdit) {
        const response = await handleEdit(_values);
        message = 'Cập nhật nhân viên thành công!';
        dispatch(editEmployee(response.data));
      } else {
        const response = await handleAdd(_values);
        dispatch(addEmployee(response.data));
      }

      notification.success({ message });
      onClose();
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response;
        const errorData = errorResponse.data.data || {};

        const status = errorResponse.status;

        if (status === 400) {
          for (let field in errorData) {
            if (['email', 'phone_number'].includes(field)) {
              propsValidate[field] = {
                validateStatus: 'error',
                help: errorData[field].join()
              };
            }
          }
        }
      }
    }
    setPropsValidate(propsValidate);
    setLoading(false);
  };

  const initialValuesMemo = useMemo(() => {
    if (!employee) return;
    const _employee = employee || {};
    setRoleName(_employee.role_name || 'clinic_employee');
    const education = _employee.educations[0] || {};
    const job = _employee.jobs[0] || {};

    return {
      name: _employee.name || '',
      email: _employee.email || '',
      phone_number: _employee.phone_number || '',
      password: '',
      password_confirmation: '',
      role_name: _employee.role_name || 'clinic_employee',
      address: _employee.address || '',
      education: education.title || '',
      job: job.title || ''
    };
  }, [employee]);

  const handleOnBlurEmail = () => {
    if (propsValidate.email) {
      let _propsValidate = { ...propsValidate };
      delete _propsValidate.email;
      setPropsValidate(_propsValidate);
    }
  };

  const handleOnBlurPhoneNumber = () => {
    if (propsValidate.phone_number) {
      let _propsValidate = { ...propsValidate };
      delete _propsValidate.phone_number;
      setPropsValidate(_propsValidate);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        layout="vertical"
        validateTrigger={['onBlur']}
        onFinish={onFinish}
        initialValues={initialValuesMemo}
        autoComplete="off"
      >
        <Row gutter={12}>
          <Col xs={12} lg={8}>
            <Form.Item
              required
              label="Họ tên"
              name="name"
              rules={[{ required: true, message: 'Chưa nhập thông tin' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} lg={8}>
            <Form.Item
              required
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Không đúng định dạng' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value || getFieldValue('phone_number')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Bắt buộc nhập email hoặc số điện thoại'
                    );
                  }
                })
              ]}
              {...(propsValidate.email || {})}
            >
              <Input onBlur={handleOnBlurEmail} autoComplete="off" />
            </Form.Item>
          </Col>
          <Col xs={12} lg={8}>
            <Form.Item
              required
              label="Số điện thoại"
              name="phone_number"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value || getFieldValue('email')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Bắt buộc nhập số điện thoại hoặc email'
                    );
                  }
                }),
                () => ({
                  validator(rule, value) {
                    if (!value || isPhoneNumber(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Không đúng định dạng');
                  }
                })
              ]}
              {...(propsValidate.phone_number || {})}
            >
              {/* <Input onBlur={handleOnBlurPhoneNumber} disabled={isEdit} /> */}
              <Input onBlur={handleOnBlurPhoneNumber} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          {
            <>
              <Col xs={24} lg={8}>
                <Form.Item
                  required={!isEdit}
                  label={isEdit ? 'Mật khẩu mới' : 'Mật khẩu'}
                  name="password"
                  rules={[
                    { required: !isEdit, message: 'Chưa nhập thông tin' },
                    { min: 6, message: 'Mật khẩu phải dài hơn 6 ký tự' }
                  ]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  required={!isEdit}
                  label={isEdit ? 'Xác nhận mật khẩu mới' : 'Xác nhận mật khẩu'}
                  name="password_confirmation"
                  rules={[
                    { required: !isEdit, message: 'Chưa nhập thông tin' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          !value ||
                          getFieldValue('password') === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'Xác nhận mật khẩu không khớp'
                        );
                      }
                    })
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </>
          }
          <Col xs={24} lg={8}>
            <Form.Item
              required
              label="Chức vụ"
              name="role_name"
              rules={[{ required: true, message: 'Chưa chọn thông tin' }]}
            >
              <Select onChange={setRoleName}>
                <Option value="clinic_admin">Admin phòng khám</Option>
                <Option value="clinic_manager">Quản lý</Option>
                <Option value="clinic_doctor">Bác sĩ</Option>
                <Option value="clinic_employee">Nhân viên</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {(roleName === 'clinic_doctor' || roleName === 'clinic_employee') && (
          <Row gutter={12}>
            <Col lg={8}>
              <Form.Item label="Học vị cao nhất" name="education">
                <Input placeholder="Thạc sĩ" />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label="Nơi công tác" name="job">
                <Input placeholder="BV Bưu Điện" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item label="Địa chỉ" name="address">
          <Input />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-content-flex-end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {isEdit ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default FormEmployee;
