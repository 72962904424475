import http from 'utils/http';

export default {
  getAll: () => http.get(`/services`, { params: { count: 9999 } }),
  add: data => http.post(`/services`, data),
  edit: data => {
    const _data = { ...data };
    delete _data.id;

    return http.put(`/services/${data.id}`, _data);
  }
};
