import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from "classnames";

import Navbar from '../Navbar';

import { LogoutOutlined, QuestionOutlined } from '@ant-design/icons';

import { setTokenJwt } from 'reducers/authorization';

import iconArrowDown from 'assets/icons/arrow-down.svg';

import styles from './index.module.scss';

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentClinic = useSelector(state => state.workspace.currentClinic || {});
  const clinics = useSelector(state => state.workspace.clinics);

  const handleLogout = () => {
    dispatch(setTokenJwt(''));
    localStorage.removeItem('token');
    localStorage.removeItem('clinic_id');
    localStorage.removeItem('room_id');
    window.location.href = '/dang-nhap';
  };

  const clinicName = useMemo(() => {
    const nameSplit = (currentClinic.name || '').split(' ');

    const lastLetter = nameSplit[nameSplit.length - 1];

    return lastLetter[0] || 'U';
  }, [currentClinic.name]);

  return (
    <div className={styles.wrapper}>
      <div className="left flex">
        <span className="title pointer" onClick={() => history.push('/')}>
          2BABY & CLINIC
        </span>
        <Navbar />
      </div>
      <div className="right">
        <div
          className={classnames('navbar-item question', {
            'is-active avatar': history.location.pathname.includes('huong-dan-su-dung')
          })}
          onClick={() => history.push('huong-dan-su-dung')}
        >
          <QuestionOutlined />
        </div>
        <div>
          <div className="account flex justify-content-between">
            <div className="avatar">
              <span>{clinicName}</span>
            </div>
            <div>
              <img className="icon-arrow-down" src={iconArrowDown} alt="icon arrow down" />
            </div>
            <div className="dropdown-option">
              <div className="options-clinic">
                <div className="options-clinic__item">
                  <div className="item__title">
                    <b>Chọn cơ sở khác</b>
                  </div>
                  <div className="item__list">
                    {clinics.map((clinic, index) => (
                      <div
                        key={clinic.id}
                        className={classnames('item__list__clinic', {
                          'is-active': clinic.id === currentClinic.id
                        })}
                      >
                        {index + 1}. {clinic.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="options-clinic__item">
                  <div onClick={handleLogout}>
                    <LogoutOutlined /> Đăng xuất
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Header;
