const getName = (doctor) => {
  const education = doctor.educations[0] || {};

  if (education.title) {
    return education.title + ' ' + doctor.name
  }

  return doctor.name
}

const getJob = (doctor) => {
  const job = doctor.jobs[0] || {};

  if (job.title) {
    return ` (${job.title})`
  }

  return null
}

export default (clinicName, startTime, endTime, days, schedules) => {
  const renderSchedules = (key, shifts, serviceType) => {
    const _schedules = schedules[key] || [];

    let result = '';
    let ids = [];

    for (let _s of _schedules) {
      if (!ids.includes(_s.user.id)) {
        if (
          shifts.includes(_s.shift) &&
          serviceType.includes(_s.service_type)
        ) {
          result += `
            <span>
              <b>${getName(_s.user)}</b>${getJob(_s.user)}
              <br />
            </span>
          `;
          ids.push(_s.user.id);
        }
      }
    }

    if (!result) return '<b style="color: red">NGHỈ</b>';

    return result;
  };

  const renderDays = () => {
    let result = '';
    for (let index = 0; index < days.length; index++) {
      const day = days[index];
      result += `
        <tr>
          <td>
            <span>${day.day}</span>
            <br />
            <span>${day.date}/${day.month}</span>
          </td>
          <td style="text-align: left;">
            ${renderSchedules(day.value, [1], [10, 20])}
          </td>
          <td style="text-align: left;">
            ${renderSchedules(day.value, [2], [10])}
          </td>
          <td style="text-align: left;">
            ${renderSchedules(day.value, [3], [20])}
          </td>
        </tr>
      `;
    }
    return result;
  };

  return `
    <div style="padding: 15px">
      <style scope>
        #export-image-print-schedules table,
        #export-image-print-schedules td, 
        #export-image-print-schedules th {
          border: 1px solid black;
          font-weight: bold;
          padding: 5px 10px;
        }
      </style>
      <div id="export-image-print-schedules" style="text-align: center;">
        <h1 style="text-transform: uppercase; color: #376aa2; margin-bottom: 0px; font-weight: bold;">
          LỊCH LÀM VIỆC ${clinicName} <span style="color: red;">*</span>
        </h1>
        <span style="color: #376aa2;">(Từ ngày ${startTime} đến ngày ${endTime})</span>
        <table style="width: 100%; border-collapse: collapse; margin-top: 15px; border-top: 2px solid; border-left: 2px solid;">
          <thead>
            <tr>
              <th rowspan="2">Ngày</th>
              <th rowspan="2">
                <span>Giờ hành chính<span>
                <br />
                <span>T2-T6: 8h-11h và 14h-17h</span>
              </th>
              <th colspan="2">
                Ngoài giờ hành chính
                <br />
                <span>T2-T6: 17h-20h30</span>
                <span style="padding-left: 15px;">T7,CN: 8h-11h và 15h-20h</span>
              </th>
            </tr>
            <tr>
              <th>Siêu âm</th>
              <th>Tư vấn sản, khám phụ khoa</th>
            </tr>
          </thead>
          <tbody>
            ${renderDays()}
          </tbody>
        </table>
      </div>
      <div style="margin-top: 15px;">
        <span style="color: red;">*</span> Phòng khám thuộc hệ thống 2baby.vn
      </div>
    </div>
  `;
};
