import React, { useState, useMemo } from 'react';
import { Form, Button, Select, notification } from 'antd';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { getEducation, filZeroNumber } from 'utils/__function';
import serviceSchedule from 'services/schedule';

import styles from './index.module.scss';

const { Option } = Select;

function FormCalendar({ doctorWorkingShift = {}, schedulesById, setSchedulesById, doctors, titleDays, onClose }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [doctorWorkingShiftsOld, setDoctorWorkingShiftsOld] = useState([]);
  const [doctorWorkingShiftsNew, setDoctorWorkingShiftsNew] = useState([]);

  const isEdit = useMemo(() => {
    if (!doctorWorkingShift) return false;
    return true;
  }, [doctorWorkingShift]);

  const initialValuesMemo = useMemo(() => {
    if (!doctorWorkingShift) return;
    const _doctorWorkingShift = doctorWorkingShift || {};
    setDoctorWorkingShiftsNew([doctorWorkingShift])
    return {
      doctor_id: _doctorWorkingShift.user.id,
      service_ids: _doctorWorkingShift.service_ids || [],
    };
  }, [doctorWorkingShift]);

  const servicesOption = useSelector(state =>
    state.service.ids
      .filter(id => state.service.byId[id])
      .map(id => state.service.byId[id])
  );

  const selectDoctorWorkingShif = (date, workingShift, isDisabled, exists) => {
    if (isDisabled || exists || isEdit) return;
    let _doctorWorkingShiftsNew = [...doctorWorkingShiftsNew];
    const idx = _doctorWorkingShiftsNew.findIndex(i => i.date === date && i.id === workingShift.id)
    if (idx !== -1) {
      _doctorWorkingShiftsNew.splice(idx, 1)
    } else {
      _doctorWorkingShiftsNew.push({
        ...workingShift,
        date
      })
    }
    setDoctorWorkingShiftsNew(_doctorWorkingShiftsNew)
  }

  const handleOnchangeDoctor = (doctorId) => {
    let _doctorWorkingShiftsOld = []
    for (let key in schedulesById) {
      const item = schedulesById[key];

      if (!item) continue;
      if (item.user.id === doctorId)
        _doctorWorkingShiftsOld.push(item);
    }
    setDoctorWorkingShiftsOld(_doctorWorkingShiftsOld)
  }

  const onFinish = async values => {
    setLoading(true)
    if (isEdit) {
      try {
      const schedule = {
        user_id: values.doctor_id,
        service_ids: values.service_ids
      }
      const response = await serviceSchedule.edit(doctorWorkingShift.id, schedule);
      const responseSchedule = response.data;
      setSchedulesById({
        ...schedulesById,
        [responseSchedule.id]: responseSchedule
      });
        notification.success({ message: 'Cập nhật thành công thành công.' });
      } catch (error) {
        const errorResponse = error.response?.data?.meta?.message;
        notification.error({ message: errorResponse || 'Có lỗi xảy ra, vui lòng thử lại sau.' });
      } finally {
        setLoading(false)
        onClose()
      }
    } else {
      const schedules = doctorWorkingShiftsNew.map(ws => {
        return {
          date: ws.date,
          start_time: ws.start_time,
          finish_time: ws.start_time,
          service_type: 10,
          shift: ws.id,
          service_ids: values.service_ids
        }
      })
      if (schedules.length) {
        try {
          const response = await serviceSchedule.add({
            user_id: values.doctor_id,
            schedules
          });
          const items = response.data;
          let _schedulesById = { ...schedulesById };
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            _schedulesById[item.id] = item
          }
          setSchedulesById(_schedulesById);
          notification.success({ message: 'Thêm mới thành công.' });
        } catch (error) {
          notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại sau.' });
          console.error(error);
        } finally {
          setLoading(false)
          onClose()
        }
      } else {
        onClose()
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        layout="vertical"
        validateTrigger={['onBlur']}
        onFinish={onFinish}
        initialValues={initialValuesMemo}
        autoComplete="off"
      >
        <Form.Item
          required
          label="Bác sĩ"
          name="doctor_id"
          rules={[{ required: true, message: 'Vui lòng chọn một bác sĩ' }]}
        >
          <Select
            showSearch={true}
            placeholder="Chọn bác sĩ"
            disabled={doctorWorkingShift && doctorWorkingShift.isDisabled}
            onChange={value => handleOnchangeDoctor(value)}
          >
            {doctors.map(d => (
              <Option key={d.id} value={d.id}>
                {getEducation(d)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          required
          label="Dịch vụ"
          name="service_ids"
          rules={[{ required: true, message: 'Vui lòng chọn một dịch vụ' }]}
        >
          <Select
            mode="multiple"
            showSearch={true}
            placeholder="Chọn dịch vụ"
            disabled={doctorWorkingShift && doctorWorkingShift.isDisabled}
          >
            {servicesOption.map(s => (
              <Option key={s.id} value={s.id}>
                {s.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="main-title">
          <div className="title-item">
            <div className="title-item-skips">
              {titleDays.map(titleDay => {
                const now = new Date();
                const _y = now.getFullYear();
                const _d = filZeroNumber(now.getDate());
                const _m = filZeroNumber(now.getMonth() + 1);

                const isDisabled =
                  new Date(titleDay.value) <
                  new Date(`${_y}-${_m}-${_d}`);

                return (
                  <div key={titleDay.value}>
                    <div className="skip-item skip-item-bg">
                      <span className={classnames('title-skip', {
                        disabled: isDisabled,
                      })}>
                        <b>{titleDay.day}</b> <br />
                        <span>{titleDay.date}/{titleDay.month}</span>
                      </span>
                    </div>

                    <div className="skip-item skip-item-shifts">
                      {titleDay.workingShifts.map(workingShift => {
                        const isDisabled =
                          new Date(`${titleDay.value} ${workingShift.end_time}`) < now;
                        const idx = doctorWorkingShiftsNew.findIndex(i => i.date === titleDay.value && (i.id === workingShift.id || i.shift === workingShift.id))
                        const idxOld = doctorWorkingShiftsOld.findIndex(i => i.date === titleDay.value && i.shift === workingShift.id)
                        return (
                          <div
                            key={workingShift.id}
                            className={classnames('service-type-shifts', {
                              active: idx !== -1,
                              old: idxOld !== -1,
                              disabled: isDisabled,
                            })}
                            onClick={() => selectDoctorWorkingShif(titleDay.value, workingShift, isDisabled, idxOld !== -1)}
                          >
                            <span><b>{workingShift.name}</b><br /> {workingShift.start_time} - {workingShift.end_time}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <Form.Item>
          <div className="flex justify-content-flex-end">
            <Button className="mr-2" onClick={() => onClose()}>Hủy</Button>
            {(!doctorWorkingShift || !doctorWorkingShift.isDisabled) && <Button type="primary" htmlType="submit" loading={loading}>
              {isEdit ? 'Cập nhật' : 'Thêm mới'}
            </Button>
            }
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
export default FormCalendar;