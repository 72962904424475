import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import iconHomeActive from 'assets/icons/home-active.svg';
import iconCalendarActive from 'assets/icons/calendar-active.svg';
import iconCalendarAssistantActive from 'assets/icons/calendar-assistant-active.svg';
import iconCalendarDoctorActive from 'assets/icons/calendar-doctor-active.svg';
import iconCustomerActive from 'assets/icons/customer-active.svg';
import iconGraphActive from 'assets/icons/graph-active.svg';
import iconChartActive from 'assets/icons/chart-active.svg';

import useRoles from 'hooks/useRole';

import styles from './index.module.scss';
import classNames from 'classnames';

const items = [
  {
    key: 'home',
    path: '/',
    title: 'Trang chủ',
    iconActive: iconHomeActive,
    roles: ['clinic_admin', 'clinic_manager']
  },
  {
    key: 'calendar',
    path: '/lich-hen',
    title: 'Lịch hẹn',
    iconActive: iconCalendarActive
  },
  {
    key: 'checkup',
    keys: ['checkin', 'checkup'],
    title: 'Danh sách khám',
    iconActive: iconCalendarAssistantActive,
    subs: [
      {
        key: 'checkin',
        path: '/checkin',
        title: 'Checkin - Đặt lịch'
      },
      {
        key: 'checkup',
        path: '/danh-sach-kham',
        title: 'Điều phối phòng khám'
      },
      // {
      //   key: 'checkup-old',
      //   path: '/danh-sach-kham-cu',
      //   title: 'Điều phối phòng khám - cũ'
      // }
    ]
  },
  {
    key: 'scheduler',
    path: '/lich-lam-viec',
    title: 'Lịch làm việc bác sĩ',
    iconActive: iconCalendarDoctorActive
  },
  {
    key: 'customer',
    path: '/khach-hang',
    title: 'Khách hàng',
    iconActive: iconCustomerActive
  },
  {
    key: 'report',
    path: '/bao-cao',
    title: 'Báo cáo',
    iconActive: iconChartActive,
    roles: ['clinic_admin', 'clinic_manager']
  },
  {
    key: 'setting',
    keys: ['setting', 'clinicInfo', 'formTemplate', 'service', 'workingShift', 'employee'],
    title: 'Cài đặt',
    iconActive: iconGraphActive,
    roles: ['clinic_admin', 'clinic_manager'],
    subs: [
      {
        key: 'clinicInfo',
        path: '/cau-hinh',
        title: 'Thông tin phòng khám',
        roles: ['clinic_admin']
      },
      {
        key: 'formTemplate',
        path: '/mau-phieu-ket-qua',
        title: 'Mẫu phiếu kết quả',
        roles: ['clinic_admin']
      },
      {
        key: 'service',
        path: '/dich-vu',
        title: 'Dịch vụ',
        roles: ['clinic_admin', 'clinic_manager']
      },
      {
        key: 'workingShift',
        path: '/cakip',
        title: 'Cakip làm việc',
        roles: ['clinic_admin', 'clinic_manager']
      },
      {
        key: 'employee',
        path: '/nhan-vien',
        title: 'Nhân viên',
        roles: ['clinic_admin', 'clinic_manager']
      }
    ]
  },
];

const itemsTitle = {
  'home': 'Trang chủ',
  'calendar': 'Lịch hẹn',
  'checkin': 'Checkin - Đặt lịch',
  'checkup': 'Điều phối phòng khám',
  'checkup-old': 'Điều phối phòng khám cũ',
  'scheduler': 'Lịch làm việc bác sĩ',
  'customer': 'Danh sách khách hàng',
  'employee': 'Danh sách nhân viên',
  'service': 'Quản lý dịch vụ',
  'clinicInfo': 'Thông tin phòng khám',
  'report': 'Báo cáo thống kê',
  'formTemplate': 'Mẫu phiếu kết quả',
  'addFormTemplate': 'Thêm mới mẫu phiếu kết quả',
  'editFormTemplate': 'Cập nhật mẫu phiếu kết quả',
  'workingShift': 'Cakip làm việc'
};

function Navbar() {
  const history = useHistory();
  const { isValidRoles } = useRoles();

  const activeSidebar = useMemo(() => {
    const { pathname } = history.location;

    switch (pathname) {
      case '/':
        return 'home';
      case '/lich-hen':
        return 'calendar';
      case '/checkin':
        return 'checkin';
      case '/danh-sach-kham':
        return 'checkup';
      case '/danh-sach-kham-cu':
        return 'checkup-old';
      case '/lich-lam-viec':
        return 'scheduler';
      case '/khach-hang':
        return 'customer';
      case '/nhan-vien':
        return 'employee';
      case '/dich-vu':
        return 'service';
      case '/cau-hinh':
        return 'clinicInfo';
      case '/mau-phieu-ket-qua':
        return 'formTemplate'
      case '/cakip':
        return 'workingShift'
      case '/mau-phieu-ket-qua/tao-moi':
        return 'addFormTemplate'
      case '/mau-phieu-ket-qua/:id':
        return 'editFormTemplate'
      case '/bao-cao':
        return 'report';
      default:
        return '';
    }
  }, [history.location]);

  useEffect(() => {
    document.title = itemsTitle[activeSidebar]
  }, [activeSidebar]);

  return (
    <div className={styles.wrapper}>
      {items
        .filter(item => !item.roles || isValidRoles(item.roles))
        .map(item => (
          <div
            key={item.key}
            className={classNames('navbar-item', {
              'is-active': (item.keys || [item.key]).includes(activeSidebar)
            })}
            onClick={() => history.push(item.path)}
          >
            <img src={item.iconActive} alt="" />
            <span>{item.title}</span>
            {item.subs && (
              <div className="navbar-item__sub">
                {item.subs.map(sub => (
                  <div
                    className={classnames("item-sub", { 'is-active': activeSidebar === sub.key })}
                    key={sub.key}
                    onClick={() => history.push(sub.path)}
                  >
                    {sub.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default Navbar;
