import React from 'react';
import { Skeleton } from 'antd';

import styles from './index.module.scss';

function SkeletonText({ active = true, width = '100%' }) {
  return (
    <div className={styles.wrapper}>
      <Skeleton active={active} paragraph={{ rows: 1, width }} title={null} />
    </div>
  );
}

export default SkeletonText;
