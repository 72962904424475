import React, { useEffect, useState } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

function PrintQRCodePage() {
  const [qrCode, setQrCode] = useState({});

  useEffect(() => {
    try {
      const _qrCode = JSON.parse(localStorage.getItem('printQrCode'));
      setQrCode(_qrCode);
    } catch (error) {
      setQrCode(null);
    }

    return () => {
      localStorage.setItem('printQrCode', '');
    };
  }, []);

  if (!qrCode) {
    window.location.href = '/cau-hinh';
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className={styles.wrapper}>
      <div className="header-print flex justify-content-between">
        <div className="left">
          <b>Xem trước bản in</b>
        </div>
        <div className="right">
          <button className="pointer" onClick={handlePrint}>
            <PrinterOutlined /> In QR code
          </button>
        </div>
      </div>
      <div className="content-preview" id="content-preview">
        <h1>{qrCode.name}</h1>
        <div className="inner">
          <img src={qrCode.image} alt="qr code" />
          <div className="mt-2">
            <span>Mã QR code phục vụ checkin tại phòng khám</span>
            <br />
            <span>
              Dịch vụ được cung cấp bởi hệ sinh thái <b>2baby.vn</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintQRCodePage;
