import http from 'utils/http';

export default {
  getBookings: (params = {}) =>
    http.get(`/bookings`, {
      params: {
        ...params,
        per_page: 9999
      }
    }),
  addBooking: (booking) => {
    return http.post(`/bookings`, booking);
  },
  editBooking: (booking) => {
    const _booking = { ...booking };
    delete _booking.id;

    return http.put(`/bookings/${booking.id}`, _booking);
  },
  updateNoteBooking: (bookingId, note) => {
    const body = { note: note };
    // console.log(body);
    return http.put(`/bookings/${bookingId}`, body);
  },
  deleteBooking: (bookingId) =>
    http.delete(`/bookings/${bookingId}`),
  cancelBooking: (bookingId) =>
    http.delete(`/bookings/${bookingId}/cancel`)
};
