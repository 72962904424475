import http from 'utils/http';

export default {
  getAll: (params = {}) =>
    http.get(`/input-forms`, {
      params: {
        ...params,
        per_page: 9999
      }
    }),
  add: (inputForm) => {
    return http.post(`/input-forms`, inputForm);
  },
};
