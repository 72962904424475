import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import { useSelector } from 'react-redux';
import { formatPriceVND } from 'utils';
import StatusBooking from 'components/StatusBooking';

function DataTableCustomerBookings({ dataSource }) {
  const servicesById = useSelector(state => state.service.byId);

  const columns = [
    {
      key: 'id',
      title: 'Mã HĐ',
      dataIndex: 'id',
      render: r => <b>#{r}</b>
    },
    {
      key: 'date_day',
      title: 'Ngày khám',
      dataIndex: 'date_day'
    },
    {
      key: 'weeks',
      title: 'Tuần thai',
      dataIndex: 'weeks',
      render: r => (r ? r + ' tuần' : '')
    },
    {
      key: 'mother_weight',
      title: 'Cân nặng mẹ',
      dataIndex: 'mother_weight',
      render: r => (r ? r + ' kg' : '')
    },
    {
      key: 'service_id',
      title: 'Dịch vụ',
      dataIndex: 'service_id',
      render: r => {
        return <span>{servicesById[r]?.name}</span>;
      }
    },
    {
      key: 'price',
      title: 'Tổng tiền',
      dataIndex: 'price',
      render: r => (r ? formatPriceVND(r) + ' VND' : '')
    },
    {
      key: 'status',
      title: 'Trạng thái',
      dataIndex: 'status',
      render: r => <StatusBooking status={r} />
    },
    {
      key: 'result',
      title: 'Kết quả',
      align: 'right',
      render: (r, object) => {
        const result = object.result;

        if (result && result.uuid) {
          return (
            <Button
              size="small"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_RESULT_URL}/timelines/${result.uuid}`,
                  '_blank'
                );
              }}
            >
              <LinkOutlined /> Link
            </Button>
          );
        }
        return null;
      }
    }
  ];
  return <Table columns={columns} dataSource={dataSource.map(d => ({ ...d, key: d.id }))} />;
}

export default DataTableCustomerBookings;
