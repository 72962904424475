import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrinterOutlined, InboxOutlined, UploadOutlined, LinkOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import qs from 'query-string';
import http from 'utils/http';
import html2canvas from 'html2canvas';
import S3 from 'aws-s3';
import getOriginImage from 'utils/getOriginImage';
import { database } from 'configs/firebase';
import { Button, Upload, Tooltip, message, notification } from "antd";

import FormResult1 from 'containers/form/form-result-old/form-result-1';
import FormResult2 from 'containers/form/form-result-old/form-result-2';
import FormResult3 from 'containers/form/form-result-old/form-result-3';
import FormResult4 from 'containers/form/form-result-old/form-result-4';
import FormResult5 from 'containers/form/form-result-old/form-result-5';
import FormResult6 from 'containers/form/form-result-old/form-result-6';
import FormResult7 from 'containers/form/form-result-old/form-result-7';
import FormResult8 from 'containers/form/form-result-old/form-result-8';
import FormResult9 from 'containers/form/form-result-old/form-result-9';
import FormResult10 from 'containers/form/form-result-old/form-result-10';
import FormResult11 from 'containers/form/form-result-old/form-result-11';
import FormResult12 from 'containers/form/form-result-old/form-result-12';
import FormResult14 from 'containers/form/form-result-old/form-result-14';
import styles from './index.module.scss';

const { Dragger } = Upload;

const now = new Date();
const month = now.getMonth() + 1;
const options = {
  1: props => <FormResult1 {...props} />,
  2: props => <FormResult2 {...props} />,
  3: props => <FormResult3 {...props} />,
  4: props => <FormResult4 {...props} />,
  5: props => <FormResult5 {...props} />,
  6: props => <FormResult6 {...props} />,
  7: props => <FormResult7 {...props} />,
  8: props => <FormResult8 {...props} />,
  9: props => <FormResult9 {...props} />,
  10: props => <FormResult10 {...props} />,
  11: props => <FormResult11 {...props} />,
  12: props => <FormResult12 {...props} />,
  14: props => <FormResult14 {...props} />
};
let timeoutTooltip;

function PrintResultPageOld({ hiddenHeader = false }) {
  const location = useLocation();
  const [bookingPrintResult, setBookingPrintResult] = useState({});
  const [formId, setFormId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formCapture, setFormCapture] = useState(null);
  const [showUploadZone, setShowUploadZone] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [bookingId, setBookingId] = useState(null);
  const [resultUuid, setResultUuid] = React.useState('');
  const [visibleTooltip, setVisibleTooltip] = React.useState(null);

  let bookingImagesUrlConclude = {}
  const currentClinic = useSelector(state => state.workspace.currentClinic);
  let clinicId;
  if (currentClinic) {
    clinicId = currentClinic.id;
  } else {
    clinicId = localStorage.getItem('clinic_id');
  }

  let dirNameAWSS3 = null;
  let tryWaitCurrentClinic = setInterval(() => {
    if (currentClinic) {
      dirNameAWSS3 = `results/${currentClinic.id
        }/${now.getFullYear()}/${month}`;
      clearInterval(tryWaitCurrentClinic);
    }
  }, 200);
  const config = {
    bucketName: '2baby',
    dirName: dirNameAWSS3 /* optional */,
    region: 'ap-southeast-1',
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  };
  const S3Client = new S3(config);

  const draggerProps = {
    accept: 'image/*',
    name: 'file',
    multiple: true,
    listType: "picture",
    defaultFileList: [...uploadedImages],
    openFileDialogOnClick: true,
    beforeUpload: file => {
      const imageType = /image.*/;
      if (!file.type.match(imageType)) {
        message.error(`Vui lòng chỉ tải lên hình ảnh.`);
      }
      return file.type.match(imageType) ? true : false;
    },
    customRequest: async (options) => {
      setLoading(true)
      const { onSuccess, onError, file, onProgress } = options;
      const response = await S3Client.uploadFile(file);
      if (response && response.location) {
        file.url = response.location;
        onSuccess(response.location);
      } else {
        const error = new Error("Some error");
        onError({ error });
      }

      return response && response.location ? response.location : '';
    },
    onChange(info) {
      const { status } = info.file;
      const allDone = info.fileList.every(f => f.status === 'done' || f.status === 'error')
      if (allDone) {
        setLoading(false)
      }
      if (status === 'done') {
        setUploadedImages(info.fileList.slice())
        message.success(`${info.file.name} ảnh được tải lên thành công.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} ảnh được tải lên thất bại.`);
      }
    },
    onDrop(e) { },
    onRemove(file) {
      let _uploadedImages = [...uploadedImages];
      _uploadedImages = _uploadedImages.filter(i => i.uid !== file.uid);
      setUploadedImages(_uploadedImages)
    }
  };

  useEffect(() => {
    try {
      (async () => {
        if (!loading || showUploadZone) return;
        const _image = await getOriginImage(formCapture, `form-capture`);
        const response = await S3Client.uploadFile(_image);
        if (response && response.location) {
          bookingImagesUrlConclude[booking.id] = response.location;
          localStorage.setItem('bookingImagesUrlConclude', JSON.stringify(bookingImagesUrlConclude));
        }
        const myHiddenBtn = document.querySelector('#btn-auto-complete');
        if (myHiddenBtn && hiddenHeader) {
          myHiddenBtn.click();
        }
      })();
    } catch (error) {
      console.error(error);
    }
  }, [S3Client, formCapture]);


  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    setFormId(params.form_id);
  }, [location.search]);

  useEffect(() => {
    try {
      const _bookingPrintResult = JSON.parse(
        localStorage.getItem('bookingPrintResult')
      );
      setBookingPrintResult(_bookingPrintResult);

      if (_bookingPrintResult.result.images_url_ultrasound) {
        let _uploadedImages = _bookingPrintResult.result.images_url_ultrasound;
        setUploadedImages(_uploadedImages)
      }
      if (hiddenHeader)
        handleExportImage();
    } catch (error) {
      console.log(error);
      setBookingPrintResult(null);
    }
  }, []);

  const booking = useMemo(() => {
    if (isEmpty(bookingPrintResult)) return {};
    const _bookingPrintResult = { ...bookingPrintResult };
    delete _bookingPrintResult.result;
    return _bookingPrintResult;
  }, [bookingPrintResult]);

  const result = useMemo(() => {
    if (!bookingPrintResult.result) return {};

    // update weeks
    if (bookingPrintResult.result.other_fields) {
      const arrayFields = bookingPrintResult.result.other_fields.split('&&');
      for (let item of arrayFields) {
        const [key, value] = item.split('=');
        if (key === 'weeks' && value) {
          bookingPrintResult.result[key] = value;
        }
      }
    }

    return bookingPrintResult.result;
  }, [bookingPrintResult.result]);

  const formResultMemo = () => {
    const a = JSON.parse(localStorage.getItem('bookingPrintResult'));
    const idC = a ? a.current_form_id : '';
    let mahId = formId || idC;
    if (options[mahId]) {
      return options[mahId]({
        booking,
        result,
        isPreview: true,
        defaultSelectedImages: a.selectedImages,
        currentClinic,
        dirNameAWSS3
      });
    }
    return null;
  };
  if (!bookingPrintResult) {
    window.location.href = '/danh-sach-kham';
  }
  const handleExportImage = async () => {
    // localStorage.setItem('bookingPrintResult', '');
    setTimeout(async () => {
      try {
        const elementClone = document.querySelector('#content-preview');
        const canvas = await html2canvas(elementClone, {
          type: 'dataURL',
          y: elementClone.offsetTop,
          allowTaint: false,
          useCORS: true
        });
        setTimeout(() => {
          const imageUrl = canvas.toDataURL();
          setFormCapture(imageUrl);
        }, 10);
      } catch (error) {
        console.error(error);
      }
    }, 1000);

  };
  const handlePrint = () => {
    setBookingId(booking.id)
    setShowUploadZone(false)
    setLoading(false)
  };

  useEffect(() => {
    if (showUploadZone === false) {
      try {
        if (clinicId) {
          window.onafterprint = () => {
            setShowUploadZone(true)
          };
        }
        window.print();
      } catch (error) {
        console.log(error);
      }
    }
  }, [showUploadZone])

  const copyToClipboard = (text) => {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);

    return result;
  }

  const updateImages = () => {
    let _uploadedImages = [...uploadedImages];
    setLoading(true)
    const images_url_ultrasound = _uploadedImages.map(i => i.url || i.response)
    http.put(`/bookings/${bookingId}/result`, {
      images_url_ultrasound
    }).then(response => {
      setResultUuid(response.data.uuid)
      if (booking.key) {
        const bookingsRef = database.ref(
          `bookings/${clinicId}/${booking.key}`
        );
        bookingsRef.update({
          result: {
            ...result,
            uuid: response.data.uuid
          },
          status: { id: 6, name: 'Khám xong' },
          priority: 99999
        });
      }
      notification.success({ message: 'Cập nhật hình ảnh thành công', placement: 'bottomRight' });
    }).catch((err) => {
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại.', placement: 'bottomRight' });
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={styles.wrapper}>
      {
        hiddenHeader ? <></> :
          (<div className="header-print flex justify-content-between">
            <div className="left">
              <b>Xem trước bản in</b>
            </div>
            <div className="right">
              {!resultUuid ? (<></>) : (
                <Tooltip
                  title="Đã copy"
                  trigger={['click']}
                  visible={visibleTooltip === resultUuid}
                >
                  <Button
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      setVisibleTooltip(resultUuid);

                      copyToClipboard(
                        `${process.env.REACT_APP_RESULT_URL}/timelines/${resultUuid}`
                      );

                      if (timeoutTooltip) {
                        clearTimeout(timeoutTooltip);
                      }

                      timeoutTooltip = setTimeout(() => {
                        setVisibleTooltip('');
                      }, 750);
                    }}
                  >
                    <LinkOutlined /> Link kết quả
                  </Button>
                </Tooltip>
              )}
              <Button
                type="primary"
                onClick={handlePrint}
              >
                <PrinterOutlined /> In kết quả
              </Button>
            </div>
          </div>)
      }

      <div className="content-preview" id="content-preview">
        {showUploadZone ? (
          <div className="upload-list-inline">
            <div className="user-fino">
              <table>
                <tbody>
                  <tr>
                    <td colSpan="4" className="td-clinic-name">
                      {booking.clinic_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="numerical-order" style={{ textAlign: 'center' }}>
                      <b>{booking.numerical_order}</b>
                    </td>
                    <td colSpan={3} className="date-time">
                      {booking.checked_time}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-first">Họ tên:</td>
                    <td className="td-name">{result.user_name}</td>
                    <td className="td-second" style={{ width: '75px' }}>Năm sinh:</td>
                    <td className="td-third">{result.birthday}</td>
                  </tr>
                  <tr>
                    <td className="td-first">SĐT:</td>
                    <td>{booking.phone_number}</td>
                    <td className="td-second">Tuần thai:</td>
                    <td className="td-third">{result.weeks}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text"><b>Kéo thả ảnh hoặc click vào đây để tải ảnh lên</b></p>
            </Dragger>
            <Button
              className="update-images"
              type="primary"
              loading={loading}
              onClick={updateImages}
            >
              <UploadOutlined /> Cập nhật hình ảnh
            </Button>
          </div>
        ) : <>{formResultMemo()}</>}
      </div>
    </div>
  );
}

export default PrintResultPageOld;
