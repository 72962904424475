import { combineReducers } from '@reduxjs/toolkit';

import authenticationReducer from './authorization';
import workspaceReducer from './workspace';
import employeeReducer from './employee';
import serviceReducer from './service';
import examinationFormReducer from './examinationForm';
import inputFormReducer from './inputForm';

const rootReducer = combineReducers({
  authorization: authenticationReducer,
  workspace: workspaceReducer,
  employee: employeeReducer,
  service: serviceReducer,
  examinationForm: examinationFormReducer,
  inputForm: inputFormReducer
});

export default rootReducer;
