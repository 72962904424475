import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import classnames from 'classnames';
import { Button, Radio } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import ImagesPreview from 'components/ImagesPreview';
import TabsImage from 'components/Form/FormUploadResult/TabsImage/TabsImage';
import { defaultDateWithTime } from 'utils/__variable';
import { getEducation, resultToObjectFields } from 'utils/__function';
import PrintResultPage from '../../../../pages/print-result';

import styles from '../index.module.scss';

function FormResult5({
  booking,
  isPreview,
  result = {},
  defaultSelectedImages = [],
  S3Client,
  handleClickButton,
  handleFinishedExamination
}) {
  const formId = 5;
  const _defaultFormValue = {
    form_name: 'SIÊU ÂM THAI 5D CHẨN ĐOÁN TRƯỚC SINH',
    user_name: booking.user_name,
    birthday: booking.birthday,
    slts: '02',
    ngoi_thai_1: 'chưa cố định',
    ngoi_thai_2: 'chưa cố định',
    ctc: '+',
    ct: '+',
    heart_rate: 'thai 1:   chu kỳ/phút, thai 2:   chu kỳ/phút',
    format_weight: '±200 gram',
    format_weight_2: '±200 gram',
    vach_trong_suot: 'bình thường',
    cot_song: 'bình thường',
    cac_chi: 'bình thường',
    ctc1: '+',
    ctc2: '+',
    ctc3: '+',
    ctc4: '+',
    vach_lien_that: 'bình thường',
    xuat_phat_cac_mach_mau_lon: 'bình thường',
    thanh_bung_truoc: 'bình thường',
    dk_ngang_bung: 'ĐK ngang bụng   mm',
    dk_ngang_bung_2: 'ĐK ngang bụng   mm',
    phu_thai: `- Ối: 02 buồng ối. Góc ối sâu nhất / Tổng 4 góc ối, thai 1:    mm, thai 2:   mm\n- Dây rốn: + Cấu trúc: 2 ĐM và 1TM\n                  + Dòng chảy trong ĐM rốn: bình thường\n- Rau: 02 bánh rau\n+ Vị trí bám: thai 1: mặt, thai 2: mặt\n+ Bề dầy:   mm\n+ Độ trưởng thành: độ 0`,
    note: '1'
  }

  const [form, setForm] = useState(_defaultFormValue);
  const [selectedImages, setSelectedImages] = useState(defaultSelectedImages);
  const [enableInnerPrintf, setEnableInnerPrintf] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddSelectedImages = imageSrc => {
    if (Array.isArray(imageSrc)) {
      setSelectedImages(imageSrc)
    } else {
      if (selectedImages.includes(imageSrc)) {
        setSelectedImages(selectedImages.filter(src => src !== imageSrc));
      } else {
        setSelectedImages([imageSrc, selectedImages[0] || '']);
      }
    }
    setEnableInnerPrintf(false);
  };

  const handleRemoveSelectedImages = imageSrc => {
    setSelectedImages(selectedImages.filter(src => src.id && src.id !== imageSrc.id));
    setEnableInnerPrintf(false);
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setForm({
      ...form,
      [id]: value
    });
    setEnableInnerPrintf(false);
  };

  useEffect(() => {
    const _result = { ...result };
    const _objectFields = resultToObjectFields(_result, _defaultFormValue);
    setForm({
      weeks: booking.weeks || '',
      days: booking.days || '',
      due_date: booking.due_date || '',

      ..._result,
      ..._objectFields
    });
  }, [result]);

  useEffect(() => {
    if (!isPreview) {
      let _conclude = `- ${form.slts || '02'} thai trong buồng tử cung tương đương ${form.weeks || '___'} tuần, ${form.days || 0} ngày.
- Tim thai (${form.ctc || '+'}) ${form.heart_rate || ''}.
- Cân nặng ước tính thai 1: ${form.weight || ''} gram ${form.format_weight || ''} , thai 2: ${form.weight_2 || ''} gram ${form.format_weight_2 || ''}.
- Góc ối sâu nhất ${form.goc_oi || ''} thai 1: ${form.goc_oi_sau_nhat_1 || ''} mm, thai 2: ${form.goc_oi_sau_nhat_2 || ''} mm.`;
      if (form.note === '2') {
        _conclude = '';
      }
      setForm(o => ({
        ...o,
        conclude: _conclude
      }));
    }
  }, [form.slts, form.weeks, form.days, form.ctc, form.heart_rate, form.weight, form.format_weight, form.weight_2, form.format_weight_2,
  form.goc_oi, form.goc_oi_sau_nhat_1,
  form.goc_oi_sau_nhat_2,
  form.note]);

  const getPrintfForm = () => {
    return <PrintResultPage hiddenHeader={true} />;
  };

  const handleOnPrint = () => {
    handleClickButton(form, formId, booking, selectedImages)
    setTimeout(() => {
      setEnableInnerPrintf(true);
    }, 100);
  };

  const preHandleAddResult = () => {
    const { hasImagesUrlConclude } = handleClickButton(form, formId, booking, selectedImages, false);
    if (!hasImagesUrlConclude) {
      setLoading(true)
      setTimeout(() => {
        setEnableInnerPrintf(true);
      }, 100);
    } else {
      handleAddResult(true)
    }
  };

  const handleAddResult = (updateStatus = false) => {
    handleFinishedExamination(form, formId, booking, result, updateStatus);
  };


  return (
    <div className={styles.wrapper + ' ' + (isPreview ? styles.wrapper_f3 : '')} id="form-unique">
      <Form className={classnames('content-form', { 'is-preview': isPreview })}>
        <h1 className="uppercase clinic-name-title">{booking.clinic_name}</h1>
        {isPreview ? (
          <h3 className="uppercase big-name-title">
            {form.form_name}
          </h3>
        ) : (
          <input
            type="text"
            value={form.form_name || ''}
            style={{ width: '100%' }}
            id="form_name"
            onChange={handleChange}
            className="uppercase form_name"
          />
        )}
        <div className="header flex jutify-content-between">
          <div className="left flex-1 uppercase">
            <p>
              <b>
                Họ tên:{' '}
                {
                  <input
                    type="text"
                    className="w-username_fill input-user-name uppercase"
                    value={form.user_name || ''}
                    id="user_name"
                    onChange={handleChange}
                  />
                }
              </b>
            </p>
            <p>
              <b>Chỉ định: SIÊU ÂM KIỂM TRA HÌNH THÁI THAI NHI</b>
            </p>
          </div>
          <div className="flex-3 uppercase text-left">
            <b>
              Năm sinh:{' '}
              <input
                type="text"
                className="w-100px input-user-name uppercase"
                value={form.birthday || ''}
                id="birthday"
                onChange={handleChange}
              />
            </b>
          </div>
        </div>
        <ImagesPreview selectedImages={selectedImages} />
        <div>
          <b>1. Tổng quan thai</b>
          <div className="flex">
            <div className="flex-3">
              <p>
                - Số lượng thai:{' '}
                {isPreview ? (
                  <span>{form.slts}</span>
                ) : (
                  <input
                    type="text"
                    id="slts"
                    value={form.slts || ''}
                    style={{ width: '160px', textAlign: 'left' }}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Ngôi thai: {' '}
                {isPreview ? (
                  form.ngoi_thai
                ) : (
                  <input
                    type="text"
                    id="ngoi_thai"
                    value={form.ngoi_thai || ''}
                    onChange={handleChange}
                  />
                )} thai 1:{' '}
                {isPreview ? (
                  <span style={{ whiteSpace: 'break-spaces' }}>
                    {form.ngoi_thai_1}
                  </span>
                ) : (
                  <input
                    type="text"
                    id="ngoi_thai_1"
                    value={form.ngoi_thai_1 || ''}
                    onChange={handleChange}
                  />
                )}
                , thai 2:{' '}
                {isPreview ? (
                  <span style={{ whiteSpace: 'break-spaces' }}>
                    {form.ngoi_thai_2}
                  </span>
                ) : (
                  <input
                    type="text"
                    id="ngoi_thai_2"
                    value={form.ngoi_thai_2 || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Tim thai: (
                {isPreview ? (
                  <span>{form.ctc}</span>
                ) : (
                  <input
                    type="text"
                    id="ctc"
                    value={form.ctc || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                ){' '}
                {isPreview ? (
                  form.heart_rate
                ) : (
                  <input
                    type="text"
                    className="w-350px"
                    value={form.heart_rate || ''}
                    id="heart_rate"
                    onChange={handleChange}
                  />
                )}
              </p>
            </div>
            <div className="flex-1">
              <p>
                - Cử động thai: (
                {isPreview ? (
                  <span>{form.ct}</span>
                ) : (
                  <input
                    type="text"
                    id="ct"
                    value={form.ct || ''}
                    style={{ width: '14px', textAlign: 'center' }}
                    onChange={handleChange}
                  />
                )}
                )
              </p>
            </div>
          </div>
        </div>
        <div>
          <b>2. Thông số sinh học thai (BIOMETRY)</b>
          <div className="flex">
            <div className="flex-1">
              <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Thai 1 {isPreview ? (
                  form.thai_1
                ) : (
                  <input
                    type="text"
                    id="thai_1"
                    value={form.thai_1 || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                ĐK lưỡng đỉnh:{' '}
                {isPreview ? (
                  form.dk_luong_dinh_1 || '____'
                ) : (
                  <input
                    type="text"
                    id="dk_luong_dinh_1"
                    className="w-50px"
                    value={form.dk_luong_dinh_1 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                Chu vi đầu:{' '}
                {isPreview ? (
                  form.chu_vi_dau_1 || '____'
                ) : (
                  <input
                    type="text"
                    id="chu_vi_dau_1"
                    className="w-50px"
                    value={form.chu_vi_dau_1 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                {isPreview ? (
                  form.dk_ngang_bung
                ) : (
                  <input
                    type="text"
                    className="w-200px"
                    id="dk_ngang_bung"
                    value={form.dk_ngang_bung || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                Chu vi bụng:{' '}
                {isPreview ? (
                  form.chu_vi_bung_1 || '____'
                ) : (
                  <input
                    type="text"
                    id="chu_vi_bung_1"
                    className="w-50px"
                    value={form.chu_vi_bung_1 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                Chiều dài xương đùi:{' '}
                {isPreview ? (
                  form.femur_length || '____'
                ) : (
                  <input
                    type="text"
                    id="femur_length"
                    className="w-50px"
                    value={form.femur_length || ''}
                    onChange={handleChange}
                  />
                )}
                mm
              </p>
              <p>
                Chiều dài bàn chân:{' '}
                {isPreview ? (
                  form.chieu_dai_ban_chan_1 || '____'
                ) : (
                  <input
                    type="text"
                    id="chieu_dai_ban_chan_1"
                    className="w-50px"
                    value={form.chieu_dai_ban_chan_1 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                <b>
                  Cân nặng ước tính:{' '}
                  {isPreview ? (
                    form.weight || '____'
                  ) : (
                    <input
                      type="text"
                      id="weight"
                      className="w-50px"
                      value={form.weight || ''}
                      onChange={handleChange}
                    />
                  )}{' '}gram{' '}
                  {isPreview ? (
                    form.format_weight
                  ) : (
                    <input
                      type="text"
                      className="w-100px"
                      id="format_weight"
                      value={form.format_weight || ''}
                      onChange={handleChange}
                    />
                  )}{' '}
                </b>
              </p>
            </div>
            <div className="flex-1">
              <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Thai 2 {isPreview ? (
                  form.thai_2
                ) : (
                  <input
                    type="text"
                    id="thai_2"
                    value={form.thai_2 || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                ĐK lưỡng đỉnh:{' '}
                {isPreview ? (
                  form.dk_luong_dinh_2 || '____'
                ) : (
                  <input
                    type="text"
                    id="dk_luong_dinh_2"
                    className="w-50px"
                    value={form.dk_luong_dinh_2 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                Chu vi đầu:{' '}
                {isPreview ? (
                  form.chu_vi_dau_2 || '____'
                ) : (
                  <input
                    type="text"
                    id="chu_vi_dau_2"
                    className="w-50px"
                    value={form.chu_vi_dau_2 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                {isPreview ? (
                  form.dk_ngang_bung_2
                ) : (
                  <input
                    type="text"
                    className="w-200px"
                    id="dk_ngang_bung_2"
                    value={form.dk_ngang_bung_2 || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                Chu vi bụng:{' '}
                {isPreview ? (
                  form.chu_vi_bung_2 || '____'
                ) : (
                  <input
                    type="text"
                    id="chu_vi_bung_2"
                    className="w-50px"
                    value={form.chu_vi_bung_2 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                Chiều dài xương đùi:{' '}
                {isPreview ? (
                  form.chieu_dai_xuong_dui_2 || '____'
                ) : (
                  <input
                    type="text"
                    id="chieu_dai_xuong_dui_2"
                    className="w-50px"
                    value={form.chieu_dai_xuong_dui_2 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                Chiều dài bàn chân:{' '}
                {isPreview ? (
                  form.chieu_dai_ban_chan_2 || '____'
                ) : (
                  <input
                    type="text"
                    id="chieu_dai_ban_chan_2"
                    className="w-50px"
                    value={form.chieu_dai_ban_chan_2 || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                mm
              </p>
              <p>
                <b>
                  Cân nặng ước tính:{' '}
                  {isPreview ? (
                    form.weight_2 || '____'
                  ) : (
                    <input
                      type="text"
                      id="weight_2"
                      className="w-50px"
                      value={form.weight_2 || ''}
                      onChange={handleChange}
                    />
                  )}{' '}gram{' '}
                  {isPreview ? (
                    form.format_weight_2
                  ) : (
                    <input
                      type="text"
                      className="w-100px"
                      id="format_weight_2"
                      value={form.format_weight_2 || ''}
                      onChange={handleChange}
                    />
                  )}{' '}
                </b>
              </p>
            </div>
          </div>
          <div>
            <p>
              <b>
                - Tuổi thai ước tính:{' '}
                {isPreview ? (
                  form.weeks || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="weeks"
                    value={form.weeks || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                tuần,{' '}
                {isPreview ? (
                  form.days || '____'
                ) : (
                  <input
                    type="text"
                    className="w-50px"
                    id="days"
                    value={form.days || ''}
                    onChange={handleChange}
                  />
                )}{' '}
                ngày
              </b>
            </p>
            <p>
              <b>
                - Dự kiến ngày sinh theo kinh 3 tháng đầu:{' '}
                {isPreview ? (
                  form.due_date
                ) : (
                  <input
                    type="text"
                    id="due_date"
                    className="w-100px"
                    value={form.due_date || ''}
                    onChange={handleChange}
                    placeholder="dd/mm/yyyy"
                  />
                )}{' '}
                ± 2 tuần
              </b>
            </p>
          </div>
        </div>
        <div>
          <b>3. Dị tật</b>
          <div className="flex">
            <div className="flex-1">
              <div>
                - Vùng đầu mặt:
                <ul className="mb-0">
                  <li>
                    <div>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Vách trong suốt:
                      </span>{' '}
                      {isPreview ? (
                        form.vach_trong_suot
                      ) : (
                        <input
                          type="text"
                          id="vach_trong_suot"
                          value={form.vach_trong_suot || ''}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </li>
                  <li>
                    K/T tiểu não: thai 1:{' '}
                    {isPreview ? (
                      <span>{form.kt_tieu_nao_1 || '____'} </span>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="w-50px"
                          id="kt_tieu_nao_1"
                          value={form.kt_tieu_nao_1 || ''}
                          onChange={handleChange}
                        />{' '}
                      </>
                    )}mm
                    , thai 2:{' '}
                    {isPreview ? (
                      <span>{form.kt_tieu_nao_2 || '____'} </span>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="w-50px"
                          id="kt_tieu_nao_2"
                          value={form.kt_tieu_nao_2 || ''}
                          onChange={handleChange}
                        />
                      </>
                    )}{' '}
                    mm
                  </li>
                  <li>
                    Não thất bên: thai 1:{' '}
                    {isPreview ? (
                      <span>{form.nao_that_ben_1 || '____'} </span>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="w-50px"
                          id="nao_that_ben_1"
                          value={form.nao_that_ben_1 || ''}
                          onChange={handleChange}
                        />
                      </>
                    )}{' '}
                    mm
                    , thai 2:{' '}
                    {isPreview ? (
                      <span>{form.nao_that_ben_2 || '____'}</span>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="w-50px"
                          id="nao_that_ben_2"
                          value={form.nao_that_ben_2 || ''}
                          onChange={handleChange}
                        />{' '}
                        mm
                      </>
                    )}{' '}
                    mm
                  </li>
                  <li>
                    K/C 2 hố mắt: thai 1:{' '}
                    {isPreview ? (
                      form.kc_2_ho_mat_1 || '____'
                    ) : (
                      <input
                        type="text"
                        className="w-50px"
                        id="kc_2_ho_mat_1"
                        value={form.kc_2_ho_mat_1 || ''}
                        onChange={handleChange}
                      />
                    )}{' '}
                    mm, thai 2:{' '}
                    {isPreview ? (
                      form.kc_2_ho_mat_2 || '____'
                    ) : (
                      <input
                        type="text"
                        className="w-50px"
                        id="kc_2_ho_mat_2"
                        value={form.kc_2_ho_mat_2 || ''}
                        onChange={handleChange}
                      />
                    )}{' '}
                    mm
                  </li>
                  <li>
                    Xương sống mũi: thai 1:{' '}
                    {isPreview ? (
                      form.xuong_song_mui_1 || '____'
                    ) : (
                      <input
                        type="text"
                        className="w-50px"
                        id="xuong_song_mui_1"
                        value={form.xuong_song_mui_1 || ''}
                        onChange={handleChange}
                      />
                    )}{' '}
                    mm, thai 2:{' '}
                    {isPreview ? (
                      form.xuong_song_mui_2 || '____'
                    ) : (
                      <input
                        type="text"
                        className="w-50px"
                        id="xuong_song_mui_2"
                        value={form.xuong_song_mui_2 || ''}
                        onChange={handleChange}
                      />
                    )}{' '}
                    mm
                  </li>
                </ul>
              </div>
              <p>
                - Cột sống:{' '}
                {isPreview ? (
                  form.cot_song
                ) : (
                  <input
                    id="cot_song"
                    value={form.cot_song || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
              <p>
                - Các chi:{' '}
                {isPreview ? (
                  form.cac_chi
                ) : (
                  <input
                    id="cac_chi"
                    value={form.cac_chi || ''}
                    onChange={handleChange}
                  />
                )}
              </p>
            </div>
            <div className="flex-1">
              <div>
                - Tim thai:
                <ul className="mb-0">
                  <li>
                    <div className="flex">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Cấu trúc 4 buồng: (
                        {isPreview ? (
                          <span>{form.ctc4}</span>
                        ) : (
                          <input
                            type="text"
                            id="ctc4"
                            value={form.ctc4 || ''}
                            style={{ width: '14px', textAlign: 'center' }}
                            onChange={handleChange}
                          />
                        )}
                        )
                      </span>{' '}
                      {isPreview ? (
                        form.cau_truc_4_buong
                      ) : (
                        <input
                          type="text"
                          id="cau_truc_4_buong"
                          value={form.cau_truc_4_buong || ''}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </li>
                  <li>
                    Vách liên thất:{' '}
                    {isPreview ? (
                      form.vach_lien_that
                    ) : (
                      <input
                        type="text"
                        id="vach_lien_that"
                        value={form.vach_lien_that || ''}
                        onChange={handleChange}
                      />
                    )}
                  </li>
                  <li>
                    X/P các mạch máu lớn:{' '}
                    {isPreview ? (
                      form.xuat_phat_cac_mach_mau_lon
                    ) : (
                      <input
                        type="text"
                        id="xuat_phat_cac_mach_mau_lon"
                        value={form.xuat_phat_cac_mach_mau_lon || ''}
                        onChange={handleChange}
                      />
                    )}
                  </li>
                </ul>
              </div>

              <div>
                - Bụng:
                <ul className="mb-0">
                  <li>
                    Thành bụng trước:{' '}
                    {isPreview ? (
                      form.thanh_bung_truoc
                    ) : (
                      <input
                        type="text"
                        id="thanh_bung_truoc"
                        value={form.thanh_bung_truoc || ''}
                        onChange={handleChange}
                      />
                    )}
                  </li>
                  <li>
                    Hình ảnh dạ dày: (
                    {isPreview ? (
                      <span>{form.ctc1}</span>
                    ) : (
                      <input
                        type="text"
                        id="ctc1"
                        value={form.ctc1 || ''}
                        style={{ width: '14px', textAlign: 'center' }}
                        onChange={handleChange}
                      />
                    )}
                    ){' '}
                    {isPreview ? (
                      form.hinh_anh_da_day
                    ) : (
                      <input
                        type="text"
                        id="hinh_anh_da_day"
                        value={form.hinh_anh_da_day || ''}
                        onChange={handleChange}
                      />
                    )}
                  </li>
                  <li>
                    Thận: (
                    {isPreview ? (
                      <span>{form.ctc2}</span>
                    ) : (
                      <input
                        type="text"
                        id="ctc2"
                        value={form.ctc2 || ''}
                        style={{ width: '14px', textAlign: 'center' }}
                        onChange={handleChange}
                      />
                    )}
                    ){' '}
                    {isPreview ? (
                      form.than
                    ) : (
                      <input
                        type="text"
                        id="than"
                        value={form.than || ''}
                        onChange={handleChange}
                      />
                    )}
                  </li>
                  <li>
                    Bàng quang: (
                    {isPreview ? (
                      <span>{form.ctc3}</span>
                    ) : (
                      <input
                        type="text"
                        id="ctc3"
                        value={form.ctc3 || ''}
                        style={{ width: '14px', textAlign: 'center' }}
                        onChange={handleChange}
                      />
                    )}
                    ){' '}
                    {isPreview ? (
                      form.bang_quang
                    ) : (
                      <input
                        type="text"
                        id="bang_quang"
                        value={form.bang_quang || ''}
                        onChange={handleChange}
                      />
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p>- Các hình ảnh khác:</p>
          {isPreview ? (
            <span style={{ whiteSpace: 'break-spaces' }}>{form.cac_hinh_anh_khac}</span>
          ) : (
            <textarea
              style={{ width: '100%' }}
              id="cac_hinh_anh_khac"
              value={form.cac_hinh_anh_khac || ''}
              onChange={handleChange}
            />
          )}
        </div>
        <div>
          <b>4. Phần phụ thai</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {form.phu_thai}
              </span>
            ) : (
              <textarea
                rows={7}
                id="phu_thai"
                value={form.phu_thai || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div>
          <b>5. Chi tiết khác</b>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>{form.chi_tiet_khac}</span>
            ) : (
              <textarea
                style={{ width: '100%' }}
                id="chi_tiet_khac"
                value={form.chi_tiet_khac || ''}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="mt-2 block-media hidden-print">
          <b>6. Hình ảnh siêu âm</b>
          <TabsImage
            S3Client={S3Client}
            handleOnChange={(key, value) => setForm({ ...form, [key]: value })}
            selectedImages={selectedImages}
            handleAddSelectedImages={handleAddSelectedImages}
            handleRemoveSelectedImages={handleRemoveSelectedImages}
          />
        </div>
        <div className="mt-2">
          <b className="uppercase">Kết luận</b>
          <div
            className="ml-2 hidden-print"
            style={{ display: 'inline-block' }}
          >
            <Radio.Group
              defaultValue="1"
              onChange={event => setForm({ ...form, note: event.target.value })}
              value={form.note}
            >
              <Radio value="1">Chưa phát hiện bất thường</Radio>
              <Radio value="2">Bất thường</Radio>
            </Radio.Group>
          </div>
          <div>
            {isPreview ? (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {result.conclude}
              </span>
            ) : (
              <textarea
                rows={5}
                id="conclude"
                value={form.conclude}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="footer mt-2">
          <div className="footer-content">
            <p>Ngày {defaultDateWithTime}</p>
            <p className="uppercase">Bác sĩ siêu âm</p>
            <br />
            <br />
            {booking.doctor && (
              <div>
                <p>
                  Họ tên:
                  <b className="uppercase">
                    {' '}{getEducation(booking.doctor)}{' '}
                  </b>
                  {booking.doctor.phone_number
                    ? `(SMS ${booking.doctor.phone_number.replace('+84', '0')})`
                    : ''}
                </p>
                {booking.doctor.email && (
                  <p>
                    Gmail: {booking.doctor.email}
                  </p>
                )}
                <p>
                  Mời Mẹ bầu nhắn họ tên vào page : ’’<b> Siêu Âm AN AN </b> ‘’
                  lấy ảnh và video.
                </p>
                <p>
                  <i>Siêu âm chỉ có thể phát hiện được 85% các dị tật lớn</i>
                </p>
              </div>
            )}
          </div>
        </div>
      </Form>
      {!isPreview && (
        <div className="wrapper-btn-print mt-2">
          <Button className="mr-2" onClick={preHandleAddResult} loading={loading}>
            Khám xong
          </Button>
          <Button type="primary" onClick={handleOnPrint}>
            <PrinterOutlined /> In kết quả
          </Button>
          <Button
            type="primary"
            onClick={handleAddResult}
            id="btn-auto-complete"
            style={{ display: 'none' }}
          ></Button>

        </div>
      )}
      <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
        {enableInnerPrintf ? getPrintfForm() : <></>}
      </div>
    </div>
  );
}

export default FormResult5;
