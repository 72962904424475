import React, { useState } from 'react';
import { DataTableEmployee } from 'containers/datatable';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';

import { FormEmployee } from 'containers/form';

import styles from './index.module.scss';

function EmployeePage() {
  const employees = useSelector(state =>
    state.employee.ids
      .filter(id => state.employee.byId[id])
      .map(id => state.employee.byId[id])
  );

  const [employee, setEmployee] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const openModal = () => {
    setEmployee(null);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setEmployee(null);
  };

  const handleSetEmployee = _employee => {
    setEmployee(_employee);
    setVisibleModal(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className="header flex justify-content-between mb-2">
        <h1>Quản lý nhân viên</h1>
        <Button type="primary" onClick={openModal}>
          Thêm mới
        </Button>
      </div>
      <div className="main">
        <DataTableEmployee
          dataSource={employees}
          setEmployee={handleSetEmployee}
        />
      </div>
      <Modal
        width="80%"
        title={employee ? 'Cập nhật nhân viên' : 'Thêm mới nhân viên'}
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        destroyOnClose
      >
        <FormEmployee employee={employee} onClose={closeModal} />
      </Modal>
    </div>
  );
}

export default EmployeePage;
